import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const InsideAnger = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>
                  Юнус (а.с.) нинг ғазабланиши  
                  </p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

      

          <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p> <sup>1</sup> Қуръон 21:87: «Зуннунни эсла. У ғазабланган ҳолда чи­қиб кетган эди».
              </p>
              <br />
              <p> <sup>2</sup> Бу сифатларнинг ҳаммаси Қуръони каримда асмаул-ҳусна василасида баён қи­лин­ган (масалан, Ҳашр сураси 59:22-24 га қаранг): Оллоҳ Ар-Раҳ­мондир, Оллоҳ Ар-Ра­ҳийм­дур. Ас-Сабур, Ал-Ва­дуд (меҳр-муҳаб­бат со­ҳи­би), Ал-Ғафур (ке­чир­гув­чи), Ал-Латифдир.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
               Буни кўриб Юнус (а.с.) қаттиқ хафа бўлди­лар. Ғазабланиб: <sup>1</sup> «Мен шун­дай қили­шинг­ни бо­ши­да­ёқ айтган эдим. Шунинг учун ке­ма­­га миниб қоч­ган­дим. Сенинг раҳм-шаф­қатли, сабрли, меҳри бе­поён,<sup>2</sup> жазо бе­ришни хоҳламайдиган Худо эканингни би­лар­дим. Энди менга яшаш­нинг кераги йўқ, жонимни олиб қўя қол. Бундан кўра ўл­га­ним яхши», дедилар. 
            </p>
            <p>Шунда Оллоҳ  Юнус пайғамбарга: «Сенинг хафа бў­ли­шинг тўғрими ўзи?» деб айтган экан. </p>
            <p></p>
          </div>

          <div className='paragraph_heading'>
                    <p> «Унинг устига қовоқ дарахтини ўстириб қўйдик».  </p>  
                </div>

            
          <div className='main_text'>
            <p>
            Юнус (а.с.) кейин шаҳардан чиқиб, шарқ томонда чайла қуриб, энди шаҳарга нима бўлар экан, деб чайла соясида кутиб ўтирди. 
            </p>
            <p>
            Оллоҳ эса Юнуснинг бошига соя берсин, жони ором олсин, деб бир ўсимлик ўстирди. Унинг ўсиб кўланка берганини кўрган Юнус (а.с.) ғоят хурсанд бўлдилар.
            </p>
            <p>
            Эртасига эса эрталаб Оллоҳ  бир қурт юборди. Қурт келиб ўсимликнинг то­ми­рини қирқиб, уни қувратди. Қуёш те­па­га кўтарилгач, Оллоҳ шарқдан иссиқ шамол ҳам эстирди.
            </p>
            <p> 
            Офтоб Юнус (а.с.) нинг бошини шундай қиздирдики, ҳолдан кетишига оз қолди. Хафа бўлиб: «Бундай яшагандан кўра ўлганим яхши, жонимни ола қол!» деб ўзига ўлим тиладилар. 
            </p>
         
            <p>Шунда Худо у кишидан: «Бир ўтнинг қув­ра­ганига хафа бўлишинг тўғрими?» деб сўради. «Ҳа, тўғри, ўлишга ҳам розиман», деб жавоб бердилар набий (а.с.). </p>
            
            <p>
            Шунда Оллоҳ азза ва жалла: «Меҳнат қилмай, экмай-боқмай, ўзи бир кечада ўсиб чиқиб, бир кечада нобуд бўлган ўтга сен шунча ачиндинг. Шундай экан, нега Mен Найнавога ачинмаслигим керак?! 
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}
            >
              <p> <sup>3</sup>  Убай ибн Каъб Расулуллоҳ (с.а.в.) дан: «Биз уни юз минг, балки ундан ҳам кўпроқ одамга пайғамбар қилдик» деган оятнинг маз­муни ҳақида сўради. Шунда Расулуллоҳ (с.а.в.): «Юз мингдан ортиғи йигир­ма минг эди», деб жавоб бердилар (Ибн Касир, Қисасул-анбия).
              </p>
            </div>
            <p onClick={() => handleSupClick(3)} >
          Ахир бу ерда ўнг қўлини сўнг қўлидан ажратолмайдиган одамларнинг ўзи юз йигирма мингдан<sup>3</sup>  ортиқ. Шунча одамга ва шунча жонзодга Менинг раҳмим келмасинми?» деб сўраган экан у кишидан. 
            </p>
            <p></p>
          </div>
       
          <div className='paragraph_heading'>
                    <p> ХОТИМА  </p>  
                </div>

            
          <div className='main_text vitalic'>
            <p>
            Қуръони карим бизга бу қиссадан бир неча нарсани сабоқ қилиб ўргатади. Масалан, сабрли бўлиш ва нафратсиз яшашни ўрганиш жуда муҳим. Оллоҳга бўйсун­а­ман, жаннатга боргим келяпти, десангиз, бу сабоқни ҳам қабул қилинг. Душманидан нафратланган одам охир-оқибат Оллоҳга гина қилишни бошлайди. Агар Оллоҳ душманларингизга нисбатан сабр қилаётган бўлса, демак сиз ҳам сабрли бў­ли­шин­гиз керак. 
            </p>
            <div className='speech-bubble' style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}
            >
              <p> <sup>4</sup> Қуръон ояти, 10:98.
              </p>
            </div>
            <p onClick={() => handleSupClick(4)} >
            Вақти келганда, ёмонлар ба­ри­бир жазосини олади. Найнаво халқи ҳам кейинроқ яна издан чиқиб, Оллоҳнинг жа­зо­сига учраган. Найнаво шаҳри вайрон бўл­ган. Унинг харобаларини ҳозир ҳам Ироқда, Мосул шаҳри яқинида кўришингиз мум­кин. Қуръони ка­римнинг Юнус сурасида ёзилишича, най­на­воликлар тавба қил­ган­дан сўнг, Оллоҳ таоло <span className='boldItalic'>«уларни дун­ё­даги хорлик азоби­дан қутқариб, маълум бир вақтгача»</span><sup>4</sup> фойдалантирган. 
            </p>
            <p>
            Афсус­ки, вақт ўтиши билан найнаво­лик­лар Оллоҳга берган ваъдаларини унут­ди­лар. Оллоҳ таоло эса одил. Биз унинг адола­ти­га, чиқараётган ҳукмининг тўғрилигига заррача гумон қилмаслигимиз керак. Ғазабда яшаётган одам Оллоҳнинг ҳукмига гумон қилаётган бўлиб қолади. 
            </p>
            <p>
            Юнус (а.с.) Оллоҳнинг Найнаво учун чи­қар­­ган ҳукмини қабул қил­ма­дилар. Чунки Най­наво аҳли ҳалок бўлиши керак, деб ҳи­соблар эдилар. Шу сабабдан бошида, Худо­нинг буйруғига бўйсунмай, денгизга қоч­ган эдилар. Бўрон пайти сувга ғарқ бўл­ган вақ­ти­да тавба қиладилар ва Оллоҳга тасбеҳ айтадилар. Агар Оллоҳ қутқармаса, балиқ қорнида то қиёматгача қолиб кетган бўлар эдилар. Худодан иккинчи марта амр келга­ни­да Юнус (а.с.) итоат этди. Найнаволик­лар­га нисбатан ғазаби сўнмаган бўлса ҳам, Оллоҳнинг буйруғидан бўйин товламади. 
            </p>
            <p>
            Кейин Худо Юнус (а.с.) нинг қалбини тазкия қилиш учун қурт юбориб, соя бериб турган ўсимликни нобуд қилади. Балки Юнус  бу ўсимликни Найнаво қавмидан ҳам муҳимроқ, деб билгандир. Гуноҳкорларга ўлим тилаётган одам, Оллоҳ (с.в.т.) нинг Юнусга берган сабоғини тушунмаган, деб айтсак адашмасак керак. Нахотки Худонинг ёмонлар учун ҳам жони ачиса? Нахотки Худо ёмонлар учун ҳам қайғурса? Нахотки У бадкор ва мушрикларга ҳам раҳмдил бўлса? Биз-чи? Биз ҳам сабр қиламизми? Душман­ларимизнинг инсофга келишини кутамизми? Ё: «Ўлиб кетсин!» деймизми? Ҳеч бўлмаса уларни Оллоҳнинг қўлига топ­ширинг, нима қилса Оллоҳ қилсин! Сабр билан Оллоҳнинг ҳукмини кутинг. 
            </p>
            <p>
            Юнус (а.с.) қиссасидан яна бир нарсани ўр­га­ниш мумкин. Гуноҳга ботган, ҳоли паст бўлган одамларга бу қисса умид ба­ғиш­лайди. Биз агар саркашлик қилиб гу­ноҳ­дан қайтмасак ҳам, охиратимиз ҳақида қайғурмасак ҳам, Оллоҳ раҳмдил, Оллоҳ меҳрибон! 
            </p>
            <p>
            Ул зот Юнус (а.с.) ни балиқ қорнида ташлаб қўйгани йўқ. Агар тавба қилсак, ёрдам сўрасак, бизни ҳам ташлаб қўймайди. Бақара сурасида ёзилганки, Оллоҳ тавба қилгувчиларни севади: «инналлоҳа юҳиббут-тав­ва­бийн». Агар ҳаётингизга диққат билан назар солсангиз, Оллоҳ суб­ҳа­наҳу ва таоло сизга ҳам: «Имонингни янгила. Гуноҳ­ла­рингдан тавба қил», деб меҳр билан кутиб турибди. 
            </p>
            <p>
            Оллоҳ азза ва жалла бизга ўхшамайди. Тавба қиладиган одамлардан Ул Зот Ўз меҳрини аямайди. Ҳатто бадкорлар ҳам, агар тавба қилсалар, Оллоҳнинг раҳматига эришадилар. Кимки бош эгса, таслим бўлса, меҳрига, мағфиратига ноил бўлади. Шундай экан, қалбингизни очинг. Оллоҳга ва Унинг пайғамбарларига имон келтиринг. Иншааллоҳ, икки дунё саодатига муяссар бўлғайсиз.
            </p>
            <p> 
            </p>
               </div>

          


<BottomNav 
            labelLeft='Балиқ ичида' 
            pathLeft='/public-resources/qisas-uzbek/insideFish'

            labelCenter='Юнус (а.с.) ғазабланиши'
            pathCenter='/public-resources/qisas-uzbek/insideAnger'

            labelRight='Закариё (а.с.)'
             pathRight='/public-resources/qisas-uzbek/zakariya'
          />   


        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default InsideAnger;