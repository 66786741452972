import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'
import BottomNav from './components/BottomNav';


const EXAMPLE = () => {

  const navigate = useNavigate();


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 7;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      // console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p> Тушлар боби, зиндондан  </p>
            <p>чиқиш</p>
          </div>

          <div className='decor_line'>
            <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
          </div>

          <div className='main_text'>
            <p className="boldItalic">
              «<span className="vitalic">(Кейин) </span> қамоққа икки йигит  тушди. Улардан бири: «Мен тушимда, шароб тайёрлаб юрибман», деди. Бошқаси эса: «Мен тушимда бошимда нон кўтариб юрган эканман, қуш ундан ер экан», деди» (Юсуф сураси 12:36).
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> НОВВОЙ ВА СОҚИЙНИНГ ТУШ КЎРГАНИ </p>
          </div>

          <div className='main_text'>
            <p>
              Кунлардан бир кун фиръавннинг новвойи билан соқийси фиръавннинг ғазабига учраб, иккаласи   Юсуф (а.с.) ётган зиндонга тушади.  Сарой қўриқчиларининг қўмондони Юсуф (а.с.) ни уларнинг хизматига тайин этади.
            </p>
            <p>
              Орадан анча вақт ўтгач, бир кеча соқий ҳам, новвой ҳам туш кўрди. Ҳар бирининг туши ўзига хос маънога эга эди.  Эртасига Юсуф (а.с.) уларнинг олдига келдилар. Қарасалар, иккаласининг ҳам хаёли паришон.
            </p>
          </div>

          <div className='main_text'>
            <p>
              —Нима бўлди? Хафа кўринасизлар? — деди.
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Кеча икковимиз ҳам туш кўрибмиз. Бироқ бу ерда тушимизни таъбир қилиб берадиган одам йўқ, — дейишди улар. Шунда Юсуф (а.с.):
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Тушни таъбир қилиш салоҳияти Худодан берилади. Айтинг қани, нима туш кўрдингиз? Таъбирига Худо ёрдам берар, — дедилар.
            </p>
            <p>
              Аввал соқийбоши кўрган тушини айтиб берди: «Қарасам олдимда бир ток турибди.  Токнинг учта новдаси бор экан. Бирдан новдалар куртак чиқариб, гуллаб, гуллардан ғужум-ғужум узумлар пишди.  Бундай қарасам, қўлимда фиръавннинг шароб қуядиган косаси турган эмиш. Узумларни узиб, сувини косага сиқдим. Кейин косани фиръавнга узатдим», деди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ХУДОНИНГ ТУШЛАРНИ ТАЪБИР ҚИЛГАНИ
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}


            >
              <p>
                <sup>1</sup>  Забурда бу зиндон ҳақида бундай деб ёзилган: «Юсуфнинг сўзлари бажо бўладиган вақт келгунча, Эгамизнинг каломи унинг ҳақлигини синагунча, унинг оёқларига занжир урилди, бўйнидан темир ҳалқа ўтказилди» (105).
              </p>
            </div>
            <p onClick={() => handleSupClick(1)}>
              «Тушингизнинг таъбири бундоқ, — деди Юсуф (а.с.). — Уч новда — уч кун дегани.  Уч кундан кейин фиръавн сизни зиндондан озод қилиб, яна ўзига соқий этиб тайинлайди.  Ҳаётингиз изига тушиб кетгандан кейин, илтимос, мени эсланг. Фиръавнга мен ҳақимда гапириб, зиндондан озод қилишини сўранг.  Сабаби мени юртимдан зўрлик билан бу ерга олиб келишган. Бу ерда ҳам ҳеч қандай жиноят қилганим йўқ. Шундай бўлса ҳам мени зиндонга ташладилар», деди.<sup>1</sup>
            </p>
            <p>
              Соқийнинг туши яхшиликка йўрилганини кўриб, новвой ҳам Юсуфга ўз тушини айтди: «Тушимда қарасам, бошимда уч сават турган эмиш.  Энг тепадаги саватда фиръавнга аталган ҳар хил пишириқлар бор экан. Лекин бир тўда қуш саватдаги нонларни еб ётган эмиш», деди.
            </p>
            <p>
              «Сизнинг тушингизнинг таъбири бундоқ, — деди Юсуф (а.с.). — Уч сават — уч кун дегани.  Уч кундан кейин фиръавн бошингизни олиб, танангизни дорга осади. Кейин қушлар келиб гўштингизни ейди».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ТУШЛАРНИНГ РЎЁБГА ЧИҚИШИ
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}


            >
              <p>
                <sup>2</sup> Қуръони каримда Юсуф (а.с.) нинг бу икки кишини Оллоҳнинг йўлидан юришга даъват қилгани ёзилган. Ҳатто қийин шароитда ҳам мўмин киши ҳақ йўлни танлаши ва бошқаларни ҳақ йўлга даъват қилиши керак.
              </p>
            </div>
            <p className='boldItalic' onClick={() => handleSupClick(2)}>
              «Эй ҳамзиндон дўстларим, сизлардан бирингиз хўжасига хамр соқийлиги қилур. Бошқаси бўлса, осилур ва қушлар унинг бошидан ейдир. Сиз билмоқчи бўлган иш битди».<sup>2</sup>  Ва <span className="vitalic">(Юсуф)</span> нажот топгувчи деб билган шахсга: «Хўжангиз ҳузурида мени эсга олинг», деди. <span className="vitalic">(Лекин) </span> шайтон унга хўжасига эслатишни унуттирди» <span className="vitalic">Юсуф сураси 12:41-42.</span>
            </p>
            <p>
              Уч кундан кейин фиръавннинг туғилган куни эди. Шу муносабат билан у ҳамма амалдорларига катта зиёфат берди. Кейин, худди ҳазрати Юсуф таъбир қилганидек, соқий аввалги вазифага тайинланди,  новвой бўлса дорга осилди.  Вазифага қайта тайинлангач, соқий Юсуф (а.с.) ни эсидан чиқариб юборди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ФИРЪАВННИНГ ТУШЛАРИ
            </p>
          </div>

          <div className='main_text'>
            <p className="boldItalic">
              «Подшоҳ: «Мен тушимда еттита семиз сигирни еттита ориқ сигир еяётганини ва еттита яшил бошоқни ва шунча қуруғини кўрмоқдаман. Эй аъёнлар, агар туш таъбирини қиладиган бўлсангиз, менга тушимнинг фатвосини беринг», деди. Улар: «Бу алғов-далғов туш. Биз бундай тушларнинг таъбирини билувчи эмасмиз», дедилар» (Юсуф сураси 12:43-44).
            </p>
            <p>
              Орадан икки йил вақт ўтиб, фиръавн бир куни туш кўради.  Тушида Нил дарёси бўйида турган эмиш. Бирдан дарёдан яхши семиз етти сигир чиқиб, соҳилдаги қамишзорда ўтлай бошлабди.  Уларнинг орқасидан эса етти ёмон, ориқ сигир дарёдан чиқибди-да семиз сигирларнинг олдига бориб семиз сигирларни еб қўйибди. Шу ерга келганда фиръавн уйғониб кетди.
            </p>
            <p>
              Бироздан кейин кўзи илиниб, яна туш кўрди. Тушида бир поядан етти яхши тўқ бошоқлар ўсиб чиққан эмиш.  Ундан кейин яна етти дона, шарқ шамоли қовжиратиб қўйган, донлари пуч бошоқлар ўсиб чиқибди.  Кейин, ҳалиги донлари пуч, қовжираган бошоқлар, яхши тўқ бошоқларни ютиб юборибди. Шу ерга келганда фиръавн яна уйғониб кетди. Қараса, туши экан.
            </p>
            <p>
              Бу тушлар фиръавнни қаттиқ ташвишга солди. Эрталабданоқ Мисрдаги ҳамма сеҳргару оқилларни ҳузурига чақирди. Лекин ҳеч ким унинг тушларини таъбир қила олмади.
            </p>
            <p>
              Шунда фиръавннинг соқийси: «Аъло ҳазрат, бугун менинг бир айбим эсимга тушди, — деди.  — Сиз ғазабланиб, мен билан новвойни зиндонга ташлаган эдингиз.   Зиндонда ётганимизда бир кеча иккаламиз ҳам туш кўрдик. Tушларимиз ҳар хил таъбирга эга эди.  Шунда зиндонда бир ёш иброний йигит — қўриқчилар қўмондонининг қули ҳам бор эди. Кўрган тушларимизни ана шу йигитга айтдик. У эса тушларимизни таъбирлаб берди.  Уч кундан сўнг у айтган нарсалар айнан амалга ошди: мен аввалги вазифамга қайтдим, новвой эса дорга осилди», деди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}


            >
              <p>
                <sup>3</sup> Қуръондан, 12:45.
              </p>
              <p>
                <sup>4</sup> Юсуф (а.с.) Фиръавннинг тушларини зиндонда туриб билган эдилар.
              </p>
            </div>
            <p className='vitalic' onClick={() => handleSupClick(4)}>
              Кейин: <span className="boldItalic"> «Бу <span className='vitalic'>(тушлар)</span> таъбирини мен сизга етказаман. Мени юборинг»,</span> <sup>3</sup>  деб зиндонга, Юсуф (а.с.) нинг олдига борди ва келиб Фиръавнга хабарини берди.<sup>4</sup>   Фиръавн: «Юсуф (а.с.) ни олиб келинглар», деб зиндонга одам юборди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ЮСУФ (А.С.) ФИРЪАВН ҲУЗУРИДА
            </p>
          </div>

          <div className='main_text'>
            <p className='boldItalic'>
              «Элчи келган пайтда <span className="vitalic">(Юсуф)</span>: «Хўжанг ҳузурига қайтиб бор ва ундан қўлларини кесган хотинларнинг ҳоли нима бўлганини сўра», дедилар  <span className='vitalic'>(12:50). Шу тариқа Юсуф (а.с.) нинг жинояти яна бир бор тафтиш қилиниб, айбсизлиги яна исботланди.</span>
            </p>
            <p>
              Шундан кейин ҳазрати Юсуфни тезлик билан зиндондан олиб чиқдилар. Соч-соқолини олиб, кийимларини алмаштириб, фиръавннинг ҳузурига олиб келдилар.
            </p>
            <p>
              «Мен туш кўрдим, — деди фиръавн унга. — Лекин ҳеч ким тушимни таъбир қилолмаяпти. Айтишларича, сен туш эшитсанг, таъбир қила олар экансан».
            </p>
            <p>
              «Бу иш менинг қўлимдан келмайди, — деди ҳазрати Юсуф фиръавнга. — Лекин Оллоҳ таъбир қилиб юрагингизни хотиржам қилади».
            </p>
            <p>Шундан сўнг фиръавн кўрган тушини айтиб берди:</p>
            <p>
              «Тушимда Нил дарёси бўйида турган эмишман.  Бирдан дарёдан етти яхши семиз сигир чиқиб, дарё бўйидаги қамишзорда ўтлай бошлади.  Уларнинг орқасидан яна етти ориқ ёмон сигир дарёдан чиқди. Мен Мисрнинг ҳеч қаерида бундай ёмон сигирларни кўрган эмасман.  Кейин ориқ, ёмон сигирлар олдин чиққан етти семиз сигирни еб қўйди.  Еб қўйиб ҳам уларнинг шакл-шамойили ўзгаргани йўқ. Ҳали ҳам кўриниши аввалгидай ёмон эди. Кейин уйғониб кетдим.
            </p>
            <p>
              Бир оздан сўнг яна туш кўрдим. Тушимда бир поядан етти яхши, донлари тўқ бошоқлар ўсиб чиқди. Кейин яна етти дона, шарқ шамолидан қовжираган,  донлари пуч бошоқлар ўсиб чиқиб, донлари тўқ бошоқларни еб қўйди. Кўрган тушларим шу. Сеҳргарларга айтган эдим, ҳеч қайсиси таъбир қила олмади».
            </p>
          </div>


          <div className='paragraph_heading'>
            <p>
              ТУШЛАРНИНГ ТАЪБИРИ
            </p>
          </div>

          <div className='main_text'>
            <p>
              «Аъло ҳазрат, иккала тушингизнинг маъноси бир, — деди Юсуф (а.с.) фиръавнга. — Худо Ўзининг нима қилмоқчи эканини сизга билдирган. Етти яхши сигир билан етти яхши бошоқ етти йил давом этадиган фаровонлик аломати.  Улардан кейин келган етти ориқ ёмон сигирлар билан шарқ шамолидан қовжираган етти пуч бошоқ эса, етти йил давом этадиган очарчиликка ишора.  Худо сизга нима қилмоқчи эканини кўрсатибди:  етти йил бутун Мисрда ҳосил мўл бўлади.  Аммо кейинги етти йилда шундай ёмон очарчилик юз берадики,  бўлиб ўтган фаровонлик ҳамманинг эсидан чиқиб кетади.  Тушнинг такрорлангани эса, Худо бу ишни амалга оширишга қарор қилганидан ва уни тез кунларда амалга оширмоқчи эканидан далолат беради.
            </p>

            <p>
              Аъло ҳазрат, сиз энди доно бир одам топиб, Миср ерлари устидан жавобгар қилиб тайинланг. Кейин мамлакатнинг ҳамма жойига махсус нозирлар қўйинг. Улар етти йиллик фаровонлик даврида Мисрда олинадиган ҳосилнинг бешдан бир қисмини шаҳарларда захира қилсинлар.  Кейин келадиган етти йил очарчилик даврида ана шу захирадан фойдаланасиз. Бўлмаса, Миср очликдан вайрон бўлади».
            </p>
          </div>


          <div className='paragraph_heading'>
            <p>
              ЮСУФ (А.С.) НИНГ ВАЗИФАГА ТАЙИНЛАНИШИ
            </p>
          </div>

          <div className='main_text'>
            <p className="boldItalic">
              «(Фиръавн Юсуф) билан гаплашгач: «Албатта, сен бугунги кунда ҳузуримизда маконатли ва ишончли кишилардансан», деди» <span className="vitalic">(Қуръон, 12:54).</span>
            </p>

            <p>
              Юсуф (а.с.) нинг гаплари Миср подшоҳига ҳам, сарой аъёнларига ҳам маъқул тушди.  Сўнгра фиръавн ўз амалдорларига: «Ким ҳам бу ишни Юсуфдан кўра яхшироқ бажара олади, деб ўйлайсизлар? Худо уни донолик руҳи билан тўлдиргани кўриниб турибди!» деди.
            </p>
            <p>
              Кейин Юсуф (а.с.) га қараб: «Модомики Худо буларнинг ҳаммасини сенга ошкор қилган экан, демак сендан ақллироқ ва донороқ одам йўқ, — деди. — Мана шу ишга ўзинг жавобгар бўласан. Бугундан бошлаб сен менинг ўнг қўл вазиримсан. Мендан бошқа ҳамма энди сенга бўйсунади. Бутун Миср сенга итоат этади. Салтанат ишларини энди сен бошқарасан», деди.
            </p>
            <p>
              Сўнгра қўлидан муҳр узугини чиқариб, Юсуф (а.с.) нинг бармоғига тақди. Устига нафис катондан тикилган либос кийдириб, бўйнига тилла занжир осди.  Кейин уни салтанатнинг иккинчи аравасига миндирди. Ҳазрати Юсуф қаерга бормасин, жарчилар унинг араваси олдида: «Таъзим қилинглар, таъзим қилинглар!» деб жар солар эдилар.
            </p>
            <p>
              Шундай қилиб фиръавн, ҳазрати Юсуфни бутун Миср устидан бошлиқ қилиб тайинлади  ва ўз аъёнларидан бирининг қизига уйлантирди.  Ўшанда Юсуф (а.с.) ўттиз ёшга кирган эдилар.
            </p>
            <p></p>
          </div>

          <BottomNav 
            labelLeft='Юсуф (а.с.) қиссаси' 
            pathLeft='/public-resources/qisas-uzbek/joseph'

            labelCenter='Тушлар боби'
            pathCenter='/public-resources/qisas-uzbek/dreams-chapter'

            labelRight='Акаларининг келиши'
            pathRight='/public-resources/qisas-uzbek/meeting-with-brothers'
            />
  
        </main>
        <aside> </aside>
      </div>
    </div >
  )
}

export default EXAMPLE
