import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'
import BottomNav from './components/BottomNav';


const AbrahamicCovenant = () => {

  const navigate = useNavigate();
  // const [search, setSearch] = useState('')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 8;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      // console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p>  АҲД ВА БАРАКА ҲАҚИДАГИ  </p>
            <p>ВАЪДА</p>
          </div>

          <div className='decor_line'>
            <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
          </div>

          <div className='main_text'>
            <p className='boldItalic'>
              «Динда энг яхши бўлган кимса ким? Ўшаки ўзини Оллоҳга таслим қилса. Ўшаки яхшилик қилса. Ўшаки Иброҳимнинг ҳаниф миллатига эргашса. Чунки Оллоҳ уни ўзига дўст қилиб олган эди» <span className="vitalic">(Нисо сураси 4:125).</span>
            </p>
            <p>
              Бир куни Худо ҳазрати Иброҳимга: «Отанг уйини қолдир, Мен сенга кўрсатадиган юртга бор, — деди. — Мен сендан буюк халқ яратаман, хайру барака бериб, отингни улуғ қиламан. Сен барака сарчашмаси бўласан.   Сени дуо қилганларга барака бераман. Сени лаънатлаганларни лаънатлайман. Ердаги ҳамма қавмлар сенинг василангда барака топади», деди.
            </p>

            <p className='vitalic'>
              Оллоҳ таоло инсониятнинг Ўзи билан муносабатни тиклашини, ҳазрати Одамнинг хилофаси давом этишини истар эди. Шу сабабдан У Иброҳим (а.с.) билан аҳд тузишга қарор қилади.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>БАРАКА ҲАҚИДАГИ ВАЪДА </p>
          </div>

          <div className='main_text'>
            <p className="vitalic">
              Оллоҳ азза ва жалла шу тариқа Иброҳим (а.с.) ни Ўз паноҳи остига олди ва: «Ердаги ҳамма қавмлар сенинг василангда барака топади», деди. Эсингиздами, Одам (а.с.) ва Момо Ҳавво (р.а.) бошида жаннатда, Оллоҳнинг ҳузурида яшаганлар. Ким Оллоҳга дўст бўлса барака остида бўлади. Бироқ улар кейин тақиқланган мевани еб, йўлдан оздилар. Натижада ерга ўлим, лаънат ва гуноҳ кириб келди. Оллоҳ: «Ундан еган куниёқ ўласан!» деб бекорга огоҳлантирмаган эди. (Бу ерда гап фақат танадаги ўлим эмас, балки руҳий ўлим ҳам — Оллоҳнинг ҳузуридан маҳрум бўлиш ҳам назарда тутилган.)
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p>
                <sup>1</sup> Қуръонда бу ҳақда яна бир оят бор:   <span className="boldItalic">«Ўзини эси паст санаганларгина Иброҳимнинг миллатидан юз ўгиради. Албатта, Биз уни бу дунёда танлаб олдик ва, албатта, у охиратда солиҳлардан бўлгай» </span> (Бақара 130).
              </p>
            </div>
            <p className="vitalic" onClick={() => handleSupClick(1)}>
              Энди эса Оллоҳ Иброҳим пайғамбарга ер юзидаги ҳамма одамларга, ҳамма миллатларга бериладиган баракани — иймон баракасини ваъда қиляпти. Кимки Иброҳимнинг оиласига аъзо бўлиб кирса, айни баракага шерик бўлади. Гуноҳлари мағфират бўлиб, Оллоҳга яқин инсонлардан бирига айланади. Шунинг учун Қуръонда  <span className='boldItalic'>«Иброҳимнинг миллатига эргашинглар»,<sup>1</sup>  </span> деб айтилган.
            </p>
            <p className='vitalic'>
              Иброҳим (а.с.) нинг авлодлари ким? Ҳамма ҳам унинг оиласига аъзоман, деб айта олмайди-ку! Қандай қилиб сиз унинг оиласига аъзо бўлишингиз мумкин?
            </p>
            <p></p>
          </div>
          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p>
                <sup>2</sup>  Лут (а.с.) ўз амакиси Ибро­ҳим (а.с.) ­га биринчи бўлиб иймон келтирган одам эди. (Анкабут сурасига қаранг, 29:26.) Ке­йин у киши Оллоҳ йўлида Ибро­ҳим (а.с.) билан бирга ҳиж­рат этади (Мир Хованд, «Рав­зот ус-сафо»).
              </p>
            </div>

            <p onClick={() => handleSupClick(2)}>
              Расулуллоҳ ўшанда етмиш беш ёшда эдилар.
              Оллоҳга итоат этиб, хотини Сорани, укаси Лутни,<sup>2</sup>  Ҳоронда орттирган мол-давлати ва хизматкорларини олиб, Ҳоронни тарк этдилар ва Канъон юртига бордилар.
              Канъонга келганларидан сўнг, Оллоҳ таоло Иброҳим (а.с.) га Ўз муродини аён этиб: «Мана шу ерни Мен сенинг наслингга бераман», деди. (Ҳолбуки, ўшанда ҳазрати Иброҳим ҳали ҳам бефарзанд эдилар.)
            </p>

            <p className="vitalic">
              Иброҳим (а.с.) пайғамбарлар ичида “Хали­луллоҳ”, яъни Оллоҳнинг дўс­ти деган ном билан машҳур. У киши Оллоҳга ишонар эди­лар. Ишонгани учун, итоат қи­лар­дилар. Худо йўлга туш, деб айтган пайтда қаерга бораётганини билма­салар ҳам йўлга туш­ди­лар. Иймони орқали бегона бир юртга бориб ўрнашди­лар, Худо айтган жойда ғарибларча чо­дирда яшадилар. У киши ҳа­қи­қий мас­канга, меъморию қурув­чи­си Оллоҳ бўлган боқий бир ўлка­га кўз тутиб юрган эдилар.
            </p>
          </div>
          <div className='paragraph_heading'>
            <p>  ИБРОҲИМ (А.С.) НИНГ МИСРГА ҚИЛГАН САФАРИ </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[5] ? 'block' : 'none' }}
              onClick={() => handleSupClick(5)}
            >
              <p>
                <sup>3</sup> Имом Бухорийнинг ҳадисида Иброҳим (а.с.) нинг: «Мен сени уларга синглим деб танитдим. Сўзимни ёлғонга чиқарманг. Валлоҳи, ер юзида сен ва мендан бошқа иймонли бўлган яна ким бор?» деб айтган гаплари ривоят қилинган.
              </p>
              <p>
                <sup>4</sup> Муслим, «Саҳиҳ», 4-жилд, 1840.
              </p>
              <p>
                <sup>5</sup> Масъудий, «Ахбор уз-замон». Бухорий 4-жилд, 112-бетга ҳам қаранг.
              </p>
            </div>
            <p onClick={() => handleSupClick(5)}>
              Шу орада қаттиқ очарчилик бошланиб, расулуллоҳ вақтинча Мисрга кетдилар.
              Мисрга яқинлашар эканлар хотини Сорага: «Сен жуда чиройлисан.
              Агар мисрликлар мен сенинг эринг эканимни билса, сени деб мени ўлдиришади.
              Шунинг учун “мен унинг синглисиман”,<sup>3</sup> деб айт. Шунда улар менга яхши муомала қилади, мени ўлдирмайди»,<sup>4</sup>  дедилар.          Ҳақиқатан ҳам, Мисрга етиб келишлари билан, Соранинг жамоли ҳаммани ўзига жалб қилди.               Соҳибжамол хотинни кўрган фиръавннинг амалдорлари унинг ҳуснини фиръавнга таъриф қилдилар<sup>5</sup>  ва Сора фиръавннинг саройига олинди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>  ЛЕКИН... </p>
          </div>

          <div className="main_text">
            <p>
              Лекин Оллоҳ таоло, фиръавнни ҳам, унинг сарой аҳлини ҳам, Сора (р.а.) ни саройга олиб келганлари учун ёмон касалликларга гирифтор қилди.
              Нима бўлаётганини тушуниб қўрқиб кетган фиръавн тез ҳазрати Иброҳимни саройига чақиртирди: «Нега бундай қилдинг? Нима учун Сора хотининг эканини айтмадинг?
              Нимага уни синглим деб атадинг! Агар тўғрисини айтганингда мен уни саройга келтирмаган бўлардим. Энди майли, хотинингни олиб, менинг юртимни тарк эт», деди.
            </p>
            <p>

            </p>
          </div>
          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[6] ? 'block' : 'none' }}
              onClick={() => handleSupClick(6)}
            >
              <p>
                <sup>6</sup>  Ўшанда Фиръавн Иброҳим (а.с.) га кўп мол-мулк ҳадя қилган экан. Сора бибига берилган канизак — гўзал жориялардан бўлмиш Ҳожар онамиз ҳам Фиръавннинг совғаси эди (Ибн Сод, «Табақот»).
              </p>
            </div>
            <p onClick={() => handleSupClick(6)}>
              Кейин одамларига: «Бу кишини хотинию бутун мол-мулки билан чегарагача олиб бориб қўйинглар!» деб буйруқ берди. Шундай қилиб, Иброҳим (а.с.) хотинию бор мол-мулкини олиб, Канъонга қайтиб келдилар.<sup>6</sup>
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>  ЛУТ (А.С.) НИНГ ИБРОҲИМ ПАЙҒАМБАРДАН БЎЛИНИШИ </p>
          </div>

          <div className='main_text'>
            <p>
              Ҳазрати Иброҳимнинг ҳам, у билан бирга юрган Лут (а.с.) нинг ҳам қўй-сигирлари ва хизматкорлари кўп эди.   Кунлардан бир кун молларга яйлов торлик қилиб,
              Иброҳим (а.с.) билан ҳазрати Лутнинг чўпонлари орасида жанжал чиқди. Канъонийлар ва перизийлар ҳам ўша пайтда у ерда яшар эдилар.  Шунда ҳазрати Иброҳим Лутга: «Биз бир-биримизга ака-укамиз. Бизнинг орамизда ҳам, чўпонларимиз орасида ҳам ҳеч жанжал бўлмаслиги керак, — дедилар. —
              Ер кўп-ку! Кел, айрилайлик. Агар сен шу ерда қолсанг, мен бошқа жойга кўчаман. Агар сен кетсанг, мен шу ерда қоламан».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>  ҲАЗРАТИ ЛУТ САДЎМНИ ТАНЛАДИ </p>
          </div>

          <div className='main_text'>
            <p>
              Лут (а.с.) Садўм томонга, Ўрдун дарасига қараб, ундан кўз узолмай қолди. Бу ерлар Миср ерларига ўхшаб серсув обод жойлар эди.  «Мен Ўрдун дарасини танладим», деди ҳазрати Лут ва Иброҳим (а.с.) дан айрилиб, моллари ва хизматкорларини олиб, ўша ёққа кўчди ва Ўрдун дарасидаги шаҳарлар орасида яшай бошлади.  Садўмга яқин жойда чодирлар тикди.
              (Садўм аҳолиси гуноҳ ишларга берилиб Оллоҳга осий бўлган ёмон одамлар эди.) <span className="vitalic">Бу воқеаларнинг қандай тугагани ҳақида сал кейинроқ ҳикоя қиламиз.</span>
            </p>
            <p></p>
          </div>
 
          <BottomNav 
            labelLeft='Иброҳим (a.с.) қиссаси ' 
            pathLeft='/public-resources/qisas-uzbek/abrahamic-story'
            labelCenter='Аҳд ҳақида Ваъда'
            // pathCenter='/center-route'
            pathRight='/public-resources/qisas-uzbek/covenant_confirmation'
            labelRight='Аҳднинг тасдиқланиши' 
            />  
 
  
     
        </main>
        <aside> </aside>
      </div>
    </div >
  )
}

export default AbrahamicCovenant
