import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const TobeContinued = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>Вақтинчалик саҳифа </p>
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

       
            
          <div className='main_text space-y-4'>
            <div className='min-h-10 bg-yellow-200 p-10'>
              <p>SABR SABR SABR</p>
            </div>
            <div className='min-h-10 bg-yellow-200 p-10'>
              <p>SABR SABR SABR</p>
            </div>
            <div className='min-h-10 bg-yellow-200 p-10'>
              <p>SABR SABR SABR</p>
            </div>
            <div className='min-h-10 bg-yellow-200 p-10'>
              <p>SABR SABR SABR</p>
            </div>
            <p> 
              Кейинги ҳикояларни яқинда қўшамиз, озроқ сабр қилинг.
            </p>
            <p></p>
          </div>

          <BottomNav 
            labelLeft='Tolut' 
            pathLeft='/public-resources/qisas-uzbek/tolutIsRejected'

            labelCenter='Вақтинча саҳифа'
            pathCenter='/public-resources/qisas-uzbek/monarchyStarted'

            labelRight='давоми ҳозирча йўқ'
            pathRight='/public-resources/qisas-uzbek/tobe_continued'
          />

        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default TobeContinued;