import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const EnterPromisedLand = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 6;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>
                    Ваъда қилинган юртга кириш 
                  </p>
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

                  <div className='paragraph_heading'>
                      <p>
                          ХАТНА ҚИЛИШ
                      </p>
                </div>

                      <div className='main_text'>
                        <p>
                        Гилгалда Оллоҳдан Юъшо пайғамбарга: «Тошпичоқлар яса, ўғил болалар ва эркакларни хатна қил», деган амр нозил бўлди. 
                        </p>
                          <div className='speech-bubble'
                              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
                              onClick={() => handleSupClick(1)}
                          >
                              <p>
                                  <sup>1</sup> Халқнинг чўлда юрганига қирқ йил бўлган пайт.
                              </p>
                          </div>

                          <p onClick={() => handleSupClick(1)}>
                              Ўша куни ҳамма эркак зоти хатна қилинди. Мисрдан чиққан эркаклар кестирилган бўлса-да, чўлда юрган пайтда туғилган ўғил болалар хатна қилинмаган эди.<sup>1</sup>
                          </p>
                          <p className='vitalic'>
                              Эсингиздами, Бани Исроил Мусо (а.с.) даврида биринчи бор Канъон чегарасига келганида, у ерга киришдан қўрқиб, Оллоҳнинг амрига бўйсунмаган эдилар.
                          </p>
                          <p className='vitalic'>
                          Мана энди у одамларнинг деярли ҳаммаси вафот этган, Оллоҳ таолонинг: “Мен сизларни сут ва асал ўлкасига киргизмайман!” деган ваъдаси амалга ошган эди. Уларнинг фарзандлари эса ваъда қилинган юртга кириш арафасида турган эдилар.
                          </p>
                          <p></p>
                      </div>

                      <div className='main_text'>
                          <div className='speech-bubble'
                              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
                              onClick={() => handleSupClick(2)}
                          >
                            <p>
                              <sup>2</sup> Шунинг учун ҳам бу жойга кейин Гилгал деб от қўйишган.
                            </p>
                          </div>

                          <p onClick={() => handleSupClick(2)}>
                              «Сизлар аввал Мисрда қул эдингизлар, — деди Оллоҳ таоло шу куни. — Мен сизларни қулликдан, шармандаликдан озод этдим».<sup>2</sup>
                          </p>
                      </div>

                      <div className='paragraph_heading'>
                        <p>МАНН ЁҒИШИ ТЎХТАДИ</p>
                     </div>
                     <div className='main_text'>
                      <p>
                          Исроил халқи ойнинг ўн тўртинчи куни кечқурун Қурбонлик байрамини нишонладилар. Эртасига ўша юртнинг ҳосилидан, хамиртурушсиз нон ва қовурилган буғдойдан тановул қилдилар. Ўша кундан бошлаб, осмондан ун ёғиши тўхтади.
                      </p>
                     </div>

                    <div className='paragraph_heading'>
                        <p>ШАҲАР АТРОФИНИ АЙЛАНИШ</p>
                    </div>

                      <div className='main_text'>
                        <p>
                          Ариҳоликлар Бани Исроилдан қўрқиб, шаҳар дарвозаларини маҳкам беркитган эдилар. 
                        </p>
                        <p>
                            Оллоҳ таоло Юъшо пайғамбарга: «Жангчиларни олиб, олти кун кунига бир мартадан шаҳар теварагини айлан, — деди. — Энг олдинда бурғу кўтарган етти руҳоний юрсин. Еттинчи куни шаҳарни етти марта айланасизлар. Руҳонийлар бурғу чалиб боради. Одамларга айт: бурғу узоқ янграган вақтда ҳамма овози борича бақирсин. Шунда шаҳар деворлари йиқилади. Кейин шаҳарга ҳужум қиласизлар», деди.
                        </p>
                        <p>
                            Шундай қилиб, энг олдинда қуролланган аскарлар, уларнинг орқасида бурғу кўтарган етти руҳоний, кейин Аҳд сандиғини кўтарган руҳонийлар, энг орқада эса навкарлардан иборат катта бир жамоа йўлга тушди.
                        </p>
                        <p>
                            Руҳонийлар йўл бўйи тўхтамай бурғу чалдилар. Халқ эса товуш чиқармас эди. Чунки Юъшо алайҳис-салом уларга: “Шаҳар атрофини айланганда ҳеч ким гапирмасин, сесингиз ҳам чиқмасин”, деб тайинлаган эдилар.
                        </p>
                        <p>
                            Шу куни Аҳд сандиғини кўтариб Ариҳони бир марта айландилар. Кейин қароргоҳга келиб тунни ўтказишди.
                        </p>
                        <p>
                            Эртасига ҳамма барвақт турди. Худди кечагидек, олдинда қуролланган аскарлар, уларнинг ортида бурғу чаладиган руҳонийлар, кейин эса Аҳд сандиғини кўтарган руҳонийлар, орқада бўлса, қолган аскарлар йўлга тушдилар. Ҳаммалари шаҳар теварагини бир айланиб қароргоҳга қайтиб келдилар.  
                        </p>
                        </div>

                        <div className='paragraph_heading'>
                            <p>ДЕВОРЛАРНИНГ АҒНАШИ</p>
                        </div>

                        <div className='main_text'>
                        <p>
                            Олти кун мобайнида ҳар куни Ариҳо шаҳрини бир мартадан айландилар. Еттинчи куни эса шаҳар теварагини етти марта айланишди. Еттинчи марта айланаётганларида Юъшо пайғамбар одамларга: 
                        </p>
                        <p>
                          «Ҳозир руҳонийлар бурғуларни узоқ чалади, шунда ҳамма овози борича бақирсин, — дедилар.
                            — Қалъа деворлари қулаб тушади. Кейин шаҳарга ҳужум қилиб, шаҳарни вайрон қиласизлар. Фақат фоҳиша Раҳоба билан унинг уйидагиларга тегманглар, чунки у биз юборган элчиларни яширган», дедилар.
                        </p>
                        <p>
                            Сўнгра бурғу овози узоқ янгради. Халқ бурғу товушини эшитгач баланд овоз билан ҳайқирди. Ариҳо шаҳрининг деворлари, Оллоҳ таоло айтганидек, гумбурлаб ағдарилди.
                            Бани Исроил очиқ қолган шаҳарга ҳужум қилиб кирди ва бутун аҳолини, эркагу аёл, ёшу қари, қиличдан ўтказди.</p>
                          <p></p>
                        </div>

                        <div className='main_text'>
                        <div className='speech-bubble'
                            style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
                            onClick={() => handleSupClick(3)}
                        >
                            <p>
                                <sup>3</sup> Ўшанда Юъшо пайғамбар Ариҳони лаънатлаб, бундоғ деган эдилар: «Кимки Ариҳо шаҳрини қайта тикласа, тўнғич ўғлини қабрга қўяди, кимки дарвозаларини қўйса, кенжа ўғлини ҳаётдан маҳрум қилади!»
                            </p>
                        </div>

                        <p onClick={() => handleSupClick(3)}>
                            Ҳазрати Юъшо Раҳобани танийдиган одамларни чақириб: «Фоҳишани уйдаги қариндош-уруғи билан бирга олиб келинглар», деб айтган эдилар.
                            Улар Раҳобани, ота-онаси, ака-укалари, қариндош-уруғлари билан бирга шаҳар ташқарисига, Бани Исроил қароргоҳ қурган жойга олиб кетишди. Сўнгра шаҳарга ўт қўйдилар.<sup>3</sup>
                        </p>
                        </div>

                        <div className='paragraph_heading'>
                            <p>ОЙ ШАҲРИНИНГ ЗАБТ ЭТИЛИШИ</p>
                        </div>
                        <div className='main_text'>
                            <p className='vitalic'>
                                Ибронийлар кейин Байтилнинг шарқида жойлашган Ой шаҳрини қўлга киритишди.
                            </p>
                            <p>
                                Шаҳарга ҳужум қилиб, бутун аҳолини қиличдан ўтказдилар. Ўша куни ўлган одамлар сони ўн икки мингтача эди. Кейин Юъшо пайғамбар, шаҳарга ўт қўйинглар, деб амр қилдилар.
                            </p>
                        </div>

                        <div className='paragraph_heading'>
                            <p>АМЎРИЙЛАР БИЛАН БЎЛГАН ЖАНГ</p>
                        </div>
                  
                        <div className='main_text' >
                        <div className='speech-bubble'
                        style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
                        onClick={() => handleSupClick(4)}>
                            <p>
                                <sup>4</sup>  «Оллоҳнинг нусратидан. У хоҳлаганига нусрат берур. Ва У азизу роҳиймдир» (Рум сураси 30:5)
                            </p>
                        </div>

                    <p onClick={() => handleSupClick(4)}>
                        Орадан бир қанча вақт ўткач, амўрийларнинг беш подшоҳи кучларини бирлаштириб, Бани Исроилга қарши уруш эълон қилади. Булар Байтул-Муқаддас, Ҳабрун, Ярмут, Лахиш ва Иглон подшоҳлари эди.<sup>4</sup> 
                    </p>
                    <p>
                        Оллоҳ таоло Юъшо (а.с.) га: «Қўрқма, Мен уларнинг ҳаммасини сенинг қўлингга таслим қиламан», деб хабар берди. Ҳазрати Юъшо нафарлари билан кечаси йўл босиб, Жабъунга, душманлар тўпланган жойга етиб келдилар ва тўсатдан ҳужум қилдилар.
                    </p>
                    <p>
                        Ваҳимага тушган душман лашкари Байт-Ҳоронга қараб қочди. Шунда Худо уларнинг устига осмондан катта-катта дўл ёғдирган экан. Натижада шу куни, улар орасида қиличдан эмас, балки дўлдан ўлганларнинг сони кўпроқ бўлди.
                    </p>
                    <p>
                        Шундан сўнг ҳеч ким ибронийларга қарши бош кўтаришга журъат эта олмади. 
                    </p>
                    <p>
                          Бани Исроил кейин Канъоннинг бошқа шаҳарларини ҳам бирма-бир қўлга ола бошладилар. Ҳар бир жангдан ғалаба қозониб қайтар эдилар. Чунки Оллоҳ таоло улар билан бирга эди.
                        </p>
                        <p>
                            Ҳаммаси бўлиб, ўттиз битта подшоҳни мағлуб қилдилар. (Филистийлар ва жашурийлар юрти, канъонийлар ери, Лубнон ўлкаси, сайдунликлар қаламрави остидаги ерлар ҳали забт этилмаган эди.)
                        </p>
                    <p></p>
                     </div>

                     <div className='main_text vitalic'>
                        <p>
                            Шундай қилиб, Оллоҳ таолонинг Иброҳим пайғамбарга берган иккинчи ваъдаси ҳам амалга ошди — унинг авлодлари Оллоҳ ваъда қилган Ўлкага эга бўлдилар.
                        </p>
                     </div>

        <BottomNav 
            labelLeft='Юъшо (а.с.)' 
            pathLeft='/public-resources/qisas-uzbek/joshua'

            labelCenter='Ваъда қилинган юрт'
            pathCenter='/public-resources/qisas-uzbek/enterPromisedLand'

            labelRight='Танланг'
            pathRight='/public-resources/qisas-uzbek/chooseWhoToServe'
        />

        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default EnterPromisedLand;