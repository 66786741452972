import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';
import LazyBoardMembers from '../feature/servants/components/LazyBoardMembers';

const AdvertiseProfiles2 = () => {
  const axiosPrivate = useAxiosPrivate();
  const [thirdColUsers, setThirdColUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const { data } = await axiosPrivate.get(`${process.env.REACT_APP_BACKEND_URL}/api/users?boardMembers=true`);
        setThirdColUsers(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, [axiosPrivate]);

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    return age;
  };

  // Shuffle and limit users to two random ones
  const getRandomUsers = (users, count) => {
    const shuffled = [...users].sort(() => Math.random() - 0.5);
    return shuffled.slice(0, count);
  };

  const randomUsers = getRandomUsers(thirdColUsers, 2);

    const [selectedUser, setSelectedUser] = useState('')
      const navigate = useNavigate()

  return (
    <>
      {isLoading ? (
        <LazyBoardMembers userName="Otajon Rahmat" className="lazy_delay1" userAge="73" />
      ) : (
        <>
          {randomUsers?.map((chosenObject, index) => (
            <div key={chosenObject._id} className="mb-4 hover:bg-slate-100 p-6 pb-4 cursor-pointer border border-1 rounded-md space-y-5"
            onClick={() => {
             
              setSelectedUser(chosenObject._id)
              navigate(`/profile-page/${chosenObject._id}`)
            }}
            >
              <div className="flex gap-3 max-w-full">
                <div className="w-full">
                  <p className="font-bold text-sm">{chosenObject.nick}</p>
                  <p className="green text-sm mb-2">{chosenObject.searchName}</p>
                  <p className="text-sm">work: {chosenObject.occupation}</p>
                  <p className="text-sm">🌎: {chosenObject.location}</p>
                  <p className="text-sm">Family Status: {chosenObject.familyStatus}</p>
                  <p className="text-sm">DOB: {chosenObject.birthDate}</p>
                </div>
                <div className="profile_image">
                  <img src={chosenObject.image} alt="profile" />
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default AdvertiseProfiles2;
