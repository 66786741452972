import React, { useState} from 'react'

import '../../feature/blogPage/blogPage.css'

import UmightLike from '../../components/UmightLike'
import Search from '../../components/Search'
 
 
import blog1 from '../../images/blog1.png';

import CreatePostButtonPublic from '../components/CreatePostButtonPublic'

import { useTranslation } from 'react-i18next';
 
import AdvertiseProfiles2 from '../../components/AdvertiseProfiles2'
import PostShablonPublic from './PostShablonPublic'
import PostShablonPublic2 from './PostShablonPublic2'

const BlogsHere = () => {
    const { t } = useTranslation();
      const [search, setSearch] = useState('')

  return (
    <>
      <div className='minister-home'>
        <div className='topbar'>
          <div className='middle_part1'>
            <div className='icon_and_h2'>
              <div className="h2_icon_container">
               <img src={blog1} alt="" />
              </div>
              <h1>{t('Blog.MainHeading')}</h1>
              {/* <h1>BLO<span>G</span> PA<span>G</span>E</h1> */}
            </div>

            {/* {showReportPostPopup && (
              <ReportPostButton post={currentSelectedPost} onClose={handleCloseReportPostPopup} />
            )}

            {showReplyPopup && (
              <ReplyPopup post={currentSelectedPost} onClose={handleCloseReplyPopup} />
            )}

            {showRepostPopup && (
              <RepostPopup post={currentSelectedPost} onClose={handleCloseRepostPopup} />
            )}

            {showPostDetailsPopup && (
              <PostDetailsPopup post={currentSelectedPost} onClose={handleClosePostDetailsPopup} />
          )} */}

      
            <div className='network_icons_topbar'>
             <CreatePostButtonPublic />
            </div>
          </div>

          <div className="middle_part2">
            <Search setSearch={setSearch} />
          </div>
        </div>

        <div className="separate_into_two_columns">
          <main className='sep_part1'>
            <div className='posts-general-container'>
             <PostShablonPublic />
             <PostShablonPublic2 />
            </div>
          </main>
          <aside>
            <div className="right_column_contain">
        
            <div className="right_column_posts">
              <h3>
                THERE IS MUCH MORE!
              </h3>
              <div className="spacer10px"></div>
              <p className='text-sm'>
              Join us to unlock a world of possibilities! 
              Contact the admin and register today and gain access to exclusive resources, 
              participate in engaging group discussions, join video chats, 
              and even create your own communities. 
              Take the next step—your journey starts here!
              </p>
              <p className='text-sm mt-2'>
                Admin: info@wayoflove.online
              </p>
            </div>
       
          
              {/* <AdvertiseProfiles2 />
              <UmightLike
                  h3="Publications"
                  titleName="Soul & Body"
                  btnWord="Read" /> */}
            </div>
          </aside>
        </div >
      </div >
    </>
  )
}

export default BlogsHere