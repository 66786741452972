import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const MizpahNationalCouncil = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>Мисфада халқ кенгаши </p>
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>


          <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p> <sup>1</sup> Уларнинг тўпланганидан биняминликлар ҳам хабардор бўлишди.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
              Кейин бутун Бани Исроилдан – шимолдаги Дон шаҳридан жанубдаги Беър­-Шеба аҳолисигача, ҳатто Ўрдун дарёсининг шарқида, Жилъод ерида истиқомат қилувчи исроилликларгача – қилич кўтарган тўрт юз минг одам бир тан-­бир жон бўлиб Мисфага Оллоҳ учун йиғилдилар.<sup>1</sup> 
            </p>
            <p>
            Кейин халқ пешволари гувоҳларни чақириб, нима бўлди, деб сўрадилар.
            </p>
            <p>
            «Мен хотиним билан бир кеча тунаш учун Биняминга Гива шаҳрига кирган эдим, – деди афройимлик лобий. – Қоронғи тушгандан кейин, шаҳардаги эркаклар келиб уйни ўраб олишди. Мени ўлдирмоқчи бўлишди. Кейин хотинимни зўрлашди, бечора охири ўлди. Кейин мен мурдани бўлак-­бўлак қилиб, Оллоҳ бизга берган бу юртда содир бўлган қонунсизликни, шармандаликни кўрсин, деб Исроилнинг ўн икки қабиласига жўнатдим. Мана энди ҳаммангиз бу ишни кўриб чиқиб айтинг: нима қиласизлар?»
            </p>
          
          </div>
          <div className='paragraph_heading'>
            <p> КЕНГАШ ҚАРОРИ </p>  
                  </div>
              
                  <div className='main_text'>
                    <p> 
                    Шунда бутун халқ бир оғиздан: «То бу иснодни ювмагунча ҳеч қайсимиз уйга қайтмаймиз, – дедилар. – Қуръа ташлаб йигитларнинг ўндан бирини озиқ­-овқат таъминоти учун ажратамиз. Кейин Гивага бориб, Исроилга иснод келтирганларни жазолаймиз».
                    </p>
                    <p>
                    Шундай қилиб, бутун Бани Исроил биргаликда Гивага қарши юриш қиладиган бўлди. Кейин Биняминнинг ҳар бир шаҳар ва қишлоғига элчи юбориб: 
                    </p>
                    <p>
                    «Орангизда бўлган бу ёвузлик ҳақида нима дейсизлар?! Гивадаги бузуқиларни бизга беринглар, биз уларни ўлдириб, Исроилдан разолатни йўқотамиз!» дейишди. 
                    </p>
                    <p>
                    Бироқ Бинямин қабиласи уларнинг гапига қулоқ солмай, Гивада йиғилиб, жангга ҳозирлик кўра бошладилар.
                    </p>
                    <p>
                    Жами йигирма олти минг одам тўпланди. Ундан ташқари, Гиванинг ўзида ҳам етти юзта сара жангчи бор эди. Бу баҳодирлар чапақай бўлиб, палахмонда тош отсалар қилни уриб туширадиган мерганлар эди.
                    </p>
                    <p>
                      Исроилликлар эса Бинямин қабиласига қарши тўрт юз минг жангчи тўпладилар.
                    </p>
                
                    <p></p>
                  </div>
       
                  <div className='main_text'>
                    <div className='speech-bubble' style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
                      onClick={() => handleSupClick(1)}
                    >
                      <p> <sup>2</sup> Байтил сўзининг маъноси
                      “Оллоҳ нинг уйи” демакдир.
                      </p>
                    </div>
                    <p onClick={() => handleSupClick(2)} >
                      Кейин Байтилга<sup>2</sup>  бориб, Оллоҳдан: «Бизлардан ким Биняминга қарши биринчи бўлиб жанг қилади?» деб сўрадилар.  Оллоҳ таоло: «Яҳузо қабиласи», деб жавоб берди.
                    </p>
                    <p>
                      Эрталаб Бани Исроил йўлга чиқди ва Гива яқинига келиб қароргоҳ қурди. Сўнг Бинямин қабиласига қарши жанг қилиш учун саф тортдилар.
                    </p>  
                  </div>

                  <div className='paragraph_heading'>
                    <p>  БИНЯМИН БИЛАН УРУШ  </p>  
                  </div>
              

                  <div className='main_text'>
                    <p>Биняминликлар Гивадан чиқиб, уларга қарши ҳужумга ўтдилар. Ўша куни Бани Исроилнинг йигирма икки минг лашкари ҳалок бўлди. Исроил қўшини мағлубиятга учраб, мотам тутиб Байтилга келдилар ва кечгача Оллоҳ таолонинг ҳузурида ибодатда бўлдилар.</p>
                    <p>
                    Кейин Худодан: «Биродарларимиз Биняминга қарши яна жанг қилайликми?» деб сўрадилар. «Ҳа, – деди Худо. – Жанг қилинглар!»
                    </p>
                    <p>
                    Шундан сўнг Исроил лашкари бир­-бирларига далда бериб, яна жангга сафландилар. Биняминликлар яна Гивадан чиқиб уларга ҳужум қилди ва уларни мағлубиятга учратди.
                    </p>
                    <p>
                    Бу сафар Бани Исроилдан ўн саккиз минг одам ҳалок бўлган эди. Бутун Исроил халқи яна аза тутиб Байтилга келдилар. 
                    </p>
                    <p>
                    Ўша куни кечгача ҳеч ким ҳеч нарса емади, кечгача Оллоҳнинг ҳузурида йиғлаб мотам тутдилар. Кейин Оллоҳга атаб қурбонликлар келтиришди. (Ўша пайтда Аҳд сандиғи ҳам ўша ерда эди.)

                    </p>
                    <p>
                    Исроил фарзандлари Оллоҳдан сўрадилар: «Биродарларимиз Биняминга қарши яна жанг қилайликми?» 
                    </p>
                    <p>«Қилинглар, – деди Оллоҳ. – Эртага Мен уларни сизларга таслим қиламан».</p>
           
                  </div>

                  <div className='paragraph_heading'>
                    <p>  ҲАЛ ҚИЛУВЧИ ЖАНГ</p>  
                  </div>

                  <div className='main_text'>
                    <p> 
                      Шундан сўнг Бани Исроил ўз лашкарининг бир қисмини Гива шаҳри теварагига  пистирмага жойлаштирди.  
                    </p>
                    <p>
                    Уларга: «Биняминликлар   ҳужум қилганда, биз қочаётган   бўлиб уларни шаҳардан узоқлаштирамиз.   Сизлар ҳужум қилиб шаҳарни эгаллайсизлар»,   деб тайинладилар. 
                    </p>
                    <p>
                    Ўзлари эса худди аввалгидек шаҳар рўпарасида сафга тизилдилар.   Биняминликлар яна аввалгидек жангга   чиқиб, Исроил қўшинини орқага тислантира бошлади. 
                    </p>
                    <p>
                    Жанг Байтил билан Гива йўлида бўлаётган эди. Ўттизтача одам ҳалок бўлгач, Бани Исроил Баал­-Таморга қараб қочишга тушди. 
                    </p>
                    <p>
                    Бинямин аҳли: “Биз яна ғалаба қозондик”, деб уларнинг орқасидан қувишга тушдилар. Ўша пайт, Гивада пистирма қуриб ётган жангчилар шаҳарга бостириб кириб, у ердаги одамларнинг ҳаммасини қиличдан ўтказдилар, уйларга ўт қўйдилар. Бутун шаҳар оловга бурканди.
                    </p>
                    <p>
                    Қочаётган Исроил қўшини қачон шаҳардан тутун чиқишини кутаётган эди. Баал-Таморга етиб келганларида тутун чиқаётганини кўриб, тезда сафларини тўғрилаб олдилар ва душманга қарши жангга кирдилар.
                    </p>
                    <p>
                    Исроилнинг энг сара ўн минг аскари ҳужумга ўтиб душманга қақшатгич зарба берди. Шафқатсиз жанг бошланди.
                    </p>
                    <p>
                    Бинямин лашкари ўзларининг оғир аҳволга тушиб қолганларидан ҳали бехабар эдилар. Бир вақт қарасалар, Гива олов ичида, шаҳарнинг ҳамма жойидан осмонга тутун ўрлаяпти! Шундан сўнг улар даҳшатга тушиб,даштга қараб қоча бошладилар.
                    </p>
                    <p>
                    Шу пайт яқин-­атрофдаги шаҳарлардан одамлар келиб биняминликларга қарши ҳужум қилдилар. Исроил қўшини рақибини қуршовга олиб, Гиванинг шарқий чегарасигача таъқиб қилиб борди. 
                    </p>
                    <p>
                    Бу пайтда Бинямин ўзининг энг сара ўн саккиз минг жангчисидан айрилган эди. Қолганлар Румон қояси томон қоча бошладилар. 
                    </p>
                    <p>
                    Йўл-йўлакай Биняминдан яна беш минг одам ўлди. Бани Исроил уларни Гидомгача қувиб бориб, яна икки минг жангчисини қиличдан ўтказдилар.
                    </p>
                    <p> 
                      Шу тариқа Бани Исроил ниҳоят ғалабага эришди. Бинямин қабиласидан йигирма беш мингдан ортиқ баҳодир ҳалок бўлди. Улардан фақат олти юзтаси Румон қоясига қочиб, жонларини сақлаб қолдилар. Исроилликлар қайтиб келиб Бинямин қабиласи яшайдиган шаҳару қишлоқларга ҳужум қилдилар. Учраганларнинг ҳаммасини, одамларни ҳам, ҳайвонларни ҳам ўлдирдилар, шаҳарларга ўт қўйдилар.
                    </p>
                  </div>

                  <BottomNav 
                    labelLeft='Разолат' 
                    pathLeft='/public-resources/qisas-uzbek/dishonourInGibeah'

                    labelCenter='Мисфада халқ кенгаши'
                    pathCenter='/public-resources/qisas-uzbek/mizpahNationalCouncil'

                    labelRight='Сомуил (а.с.) қиссаси'
                    pathRight='/public-resources/qisas-uzbek/samuel'
                  />
    
        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default MizpahNationalCouncil;