import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import kamar from '../../images/kamar.png';
import BottomNav from './components/BottomNav';

const JephthahSacrifice = () => {
    const navigate = useNavigate();
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p> Ёффай розияллоҳу анҳунинг назри</p>     
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

                {/* <div className='paragraph_heading'>
                    <p> Дувора алайҳас-салом қиссаси  </p>  
                </div> */}

            
          <div className='main_text'>
            <p> 
            Бироқ Амўн шоҳи Ёффайнинг гапларига
            қулоқ солгани йўқ.
            </p>
            <p>
            Кейин Ёффай Оллоҳдан қудрат олиб, Жилъ од ва Манашега, кейин Жилъоднинг Мисфасига борди, у ердан эса амўнийларга қарши урушга чиқди.
            </p>
            <p> 
              Душманга яқинлашар экан ўзича: «Ё Оллоҳ! Агар менга ғалаба берсанг, уйга қайтганимда нима биринчи бўлиб дарвозамдан чиқса, Сенга назр қилиб, оловда куйдираман», деб қасам ичди.
            </p>
            <p>
            Ўша куни Худо Ёффайни қўллади. У душманларга қаттиқ зарба бериб, Ароердан Миннитгача, то Абил Карамимгача уларни тор­мор қилди. Ҳаммаси бўлиб йигирмата шаҳар унинг қўлига ўтди. Ғалаба билан Мисфага ­уйига яқинлашар экан, не ҳолки, отамни ғалаба билан табриклайман, деб тамбурин чалиб, рақсга тушиб ... қизи дарвозадан чиқади. Ёффайнинг ана шу қизидан бошқа фарзанди йўқ эди.
            </p>

            <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p> 
                <sup>1</sup> Аслида шариат бўйича, агар бўйнингизга олган назр олсангиз ва унинг ҳаром амал эканини билиб қолсангиз, уни бажармаслигингиз лозим, аксинча ўрнига каффора берасиз. Бажарсангиз икки марта гуноҳкор бўласиз, аввало хато назрни ният қилганингиз учун, кейин эса, ҳаром қилинган амални бажарганингиз учун.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
            Буни кўриб Ёффайнинг қалбидан оҳ деган нола чиқди. Мусибатдан кўйлагини йиртиб: «Эҳ қизгинам, нима қилиб қўйдинг?! Бошимга не кўргулик солдинг! – деб фарёд қилди. – Мен Оллоҳга қасам ичувдим, энди қасамимни қандай бузаман?!»<sup>1</sup>
            </p>
            <p></p>
          </div>

            <p>
            «Отажон, – деди қизи отасига, – Оллоҳнинг олдида қасам ичган бўлсангиз, қасамингизни бажаринг. Оллоҳ душманларингиздан қасос олишга ёрдам берди­ку! Менга фақат икки ой муҳлат берсангиз бас, дугоналарим билан тоққа чиқиб, бокиралигимга мотам тутиб келаман».
            </p>
            <p>
            Шу билан Ёффайнинг қизи дугоналари билан тоғларга чиқиб, икки ой бокиралигига мотам тутди. Икки ойдан сўнг, уйга қайтгач, отаси ичган қасамини адо этди. 
            </p>
            <p></p>
          </div>

          <div className='main_text'>
 
            <p>
               Шу-­шу Исроилнинг қизлари ҳар йили тўрт кун Ёффайнинг қизи учун йиғлаб мотам тутишни ўзларига одат қилганлар.
            </p>
            <p></p>
          </div>

          <BottomNav 
            labelLeft='Ёффай (р.а.)' 
            pathLeft='/public-resources/qisas-uzbek/jephthah'

            labelCenter='Ёффайнинг назри'
            pathCenter='/public-resources/qisas-uzbek/jephthahSacrifice'

            labelRight='Гивадаги разолат'
            pathRight='/public-resources/qisas-uzbek/dishonourInGibeah'
          />
      
        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default JephthahSacrifice;