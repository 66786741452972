import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'
import BottomNav from './components/BottomNav';


const BaniIsraelAcceptedShariah = () => {

  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 24;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      // console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p> Бани Исроил Шариат Аҳдини  </p>
            <p>қабуллайди</p>
          </div>

          <div className='decor_line'>
            <p>
              &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p >
                <sup>1</sup> «Мусо мийқотимизга қав­ми­дан етмиш кишини танлаб олди» (Аъроф сураси 155-оят). Мийқот сўзи араб тилида “учрашув” деган маънони билдиради.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)}>
              Кейин Оллоҳ субҳанаҳу ва таоло Мусо (а.с.) га пастга тушишни, Ҳорун (а.с.) ни, Надаб ибн Ҳорунни, Абиҳу ибн Ҳорунни ҳамда Исроил оқсоқолларидан етмиш кишини<sup>1</sup> олиб тоққа чиқишни амр қилди. Бани Исроилга эса тоққа чиқишни ман қилди. Мусо алайҳис-салом бориб, Оллоҳ таолонинг амрларини халққа етказдилар. Ҳамма бир оғиздан: «Яратганнинг барча амрларини бажарамиз», деб жавоб берди. Шундан сўнг Мусо пайғамбар Оллоҳ берган ҳамма амрларни хатга туширдилар.
            </p>
            <p>
              Эртасига эса эрталаб тоғ этагида қурбонгоҳ тикладилар. Оллоҳга атаб қурбонликлар сўйиб, қонини тоғораларга олдилар. Кейин Аҳдномани халққа ўқиб бердилар. Халқ: «Оллоҳнинг ҳамма амрларига қулоқ соламиз, айтганларини бажарамиз», деб ваъда берди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p >
                <sup>2</sup>  Шу тарзда Бани Исроил Оллоҳ таоло билан аҳдга кирди. Лекин бу аҳд Иброҳим (а.с.) билан бўлган аҳддан фарқли эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(2)}>
              Шундан сўнг ҳазрати Мусо тоғорадаги қонни қўлига олиб: «Оллоҳ сизлар билан аҳд тузмоқда. Мана шу қон ҳозир мен сизларга ўқиб берган сўзлар учун гувоҳ бўлади», деб эълон қилдилар.<sup>2</sup>
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ОЛЛОҲНИНГ ЖАЛОЛИ ОСТИДА </p>
          </div>

          <div className='main_text'>
            <p>
              Кейин ҳазрати Мусо, Ҳорун, Надаб, Абиҳу ва Исроил оқсоқолларидан етмиш киши тоққа — Оллоҳ таоло айтган жойга кўтарилдилар.
            </p>
            <p>
              Уларнинг тепасида ложувард тошига ўхшаш осмондек тиниқ бир саҳн турар, саҳн устида эса Оллоҳнинг жалоли ярқирар эди. Оллоҳ Бани Исроилнинг пешволарига зарар етказмади. Улар саҳн остида зиёфат едилар, Аҳд таомидан тановул қилдилар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}
            >
              <p >
                <sup>3</sup> Ўшанда Мусо (а.с.) акаси Ҳо­рунга: “Қавмимда менинг ўр­ним­ни олинг, яхши иш қилинг, буз­ғун­чи­лар­нинг йўлига эргашманг”, деб тайинлаган эди­лар (Аъроф сураси, 142). Оллоҳ таоло ўшанда Бани Ис­ро­илнинг оқсоқолларига Ўз ҳу­зу­ри­га кўтарилишга ижозат бер­ма­ган.
              </p>
            </div>
            <p onClick={() => handleSupClick(3)}>
              Зиёфатдан сўнг Оллоҳ таоло Мусо пайғамбарни Ўз ҳузурига даъват қилди. Худо у кишига амрлар битилган тош лавҳаларни бермоқчи эди. «Мен тепага, Оллоҳнинг ҳузурига чиқаман, — дедилар Мусо алайҳис-салом. — Сизлар шу ерда кутиб туринглар. Бирор гап бўлса, Ҳорун ёки Ҳурнинг маслаҳати билан иш қиласизлар».<sup>3</sup>
            </p>
            <p>
              Расулуллоҳ юқорига кўтарилиши билан, тоғни оловли булут қоплади. Худованднинг шукуҳи тоққа энди.
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}
            >
              <p >
                <sup>4</sup> Ўша пайтда Мусо (а.с.) нинг ёрдамчиси Юъшо ибн Нун ҳам Мусо (а.с.) нинг ёнида эдилар.
              </p>
            </div>
            <p onClick={() => handleSupClick(4)}>
              Олти кун мобайнида тоғни булут ўраб турди ва еттинчи кун Оллоҳ таоло булут ичидан Мусо алайҳис-саломга гапира бошлади. <sup>4</sup>
            </p>
            <p>
              Худованднинг шукуҳ ва жалоли Бани Исроилга тоғ чўққисида ёнаётган дурухшон бир оловдек кўринар эди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[5] ? 'block' : 'none' }}
              onClick={() => handleSupClick(5)}
            >
              <p >
                <sup>5</sup> «Мусо билан ўттиз кечага ваъдалашдик. Кейин эса, уни ўн (кеча) билан тугал қилдик. Бас, Раббининг белгилаган вақти тўлиқ қирқ кеча бўлди» (Аъроф сураси, 142).
              </p>
            </div>
            <p onClick={() => handleSupClick(5)}>
              Мусо пайғамбар қирқ кечаю қирқ кундуз<sup>5</sup> тоғда, Оллоҳ таолонинг ҳузурида бўлдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ОЛТИН БУЗОҚ </p>
          </div>

          <div className='main_text'>
            <p className="boldItalic">
              «Мусо билан қирқ кечага ваъдалашганимиз, у кетганидан кейин ўзингизга зулм қилган ҳолда бузоққа ибодат қилганингизни эсланглар!» (Бақара сураси 51).
            </p>
            <p>
              Мусо пайғамбар тоғдан тушавермагач, одамлар Ҳорун (а.с.) ни ўртага олиб: «Мусога тоғда бир нарса бўлган. Тур, биз учун илоҳ яса. Бўлмаса ким саҳрода бизга раҳнамолик қилади?» дедилар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[6] ? 'block' : 'none' }}
              onClick={() => handleSupClick(6)}
            >
              <p >
                <sup>6</sup> Ҳорун (а.с.) ўша пайтда муш­риклар босимини тўх­та­та ол­ма­ган. Қуръонда ёзи­­ли­ши­ча, у киши фитна чиқ­ма­син, халқ таф­ри­қага туш­ма­син, деб хавотирланган экан (Қуръон, 20:94).
              </p>
            </div>
            <p onClick={() => handleSupClick(6)} className='vitalic'>
              Тасаввур қилинг, Бани Исроил салкам қирқ кундан бери Мусо пайғамбарни кутмоқда. Лекин ҳануз у кишидан — Оллоҳ тайин этган раҳбардан хабар йўқ. Нима қилиш керак? Энди халққа ким раҳнамолик қилади? Кимга умид боғлаш мумкин?<sup>6</sup>
            </p>
            <p className="vitalic">
              Ҳа, Бани Исроил кута-кута чарчаган эди. Мусо (а.с.) йўқ бўлса, ким энди уларни Оллоҳ билан боғлайди? Ким уларга худолик қилади? Охири улар ўзлари учун худо ясашга қарор қилишди. Оллоҳнинг наздида бу ниҳоятда оғир гуноҳ эди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[7] ? 'block' : 'none' }}
              onClick={() => handleSupClick(7)}
            >
              <p >
                <sup>7</sup>  Тоҳо сураси 20:88. Ибн Аббoс дeганлар: “Йўқ, валлoҳи, бузoқ ҳeч қачoн бў­кир­маган, фақат шамoл унинг oрқа тeшигидан кириб, oғ­зи­дан чиқарди ва сас пайдo бў­лар­ди” (Ҳайсамий, “Мажмо-уз за­воид”, 7-жилд, 64-бет).
              </p>
            </div>
            <p onClick={() => handleSupClick(7)} className='vitalic'>
              Қуръони каримда ёзилишича: <sup>7</sup> <span className="boldItalic">  «(Сомирий) уларга бўкирадиган бузоқ шаклини чиқариб берди. Халқ: «Мана илоҳингиз, Мусонинг ҳам илоҳи, фақат у (сизларга айтишни) унутган эди», дедилар».</span>
            </p>
            <p>
              Ҳа, мушриклар энди бор овозлари билан: «Эй Бани Исроил! Мана, бизни Мисрдан олиб чиққан худойимиз!» деб қийқира бошлаган эди.
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[8] ? 'block' : 'none' }}
              onClick={() => handleSupClick(8)}
            >
              <p >
                <sup>8</sup>  Тоҳо сураси 20:90.
              </p>
            </div>
            <p onClick={() => handleSupClick(8)} className='vitalic'>
              Бошқача қилиб айтганда, халқ ичида фитна бошланган эди. Ҳорун (а.с.) одамларни гуноҳдан қайтариш учун:    <span className="boldItalic"> «Эй қавмим, бу билан фитнага дучор қилиндингиз. Шубҳасиз, раббингиз Раҳмондир. Бас, менга эргашинг. Менинг амримга итоат қилинг»,</span> деб даъват қиладилар.

              <sup>8</sup>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[9] ? 'block' : 'none' }}
              onClick={() => handleSupClick(9)}
            >
              <p >
                <sup>9</sup>  Ҳорун (а.с.) шу тариқа халқни Оллоҳ таолога қайтармоқчи эдилар.
              </p>
              <p className='MarginTop10px'>
                <sup>10</sup> Тоҳо сураси 20:91.
              </p>
            </div>
            <p onClick={() => handleSupClick(9)}>
              Кейин олтин бузоқнинг олдида қурбонгоҳ тиклаб: «Эртага Оллоҳга атаб байрам қиламиз», деб эълон этади.<sup>9</sup>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p onClick={() => handleSupClick(10)} >
              <span className="vitalic"> Лекин халқ йўлдан чиқиб кетган эди.</span>  <span className="boldItalic">«Бани Исроил: «То Мусо бизга қайтгунича, биз (бузоққа) ибодат қилишни давом эттираверамиз», деди»</span>  ва эртасига саҳар туриб, бузоққа қурбонликлар келтира бошлади. Еб-ичиш, ўйин-кулги авжига чиқди.
              <sup>10</sup>
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> «ХАЛҚИНГ ФАСОДГА БОТДИ!»  </p>
          </div>

          <div className='main_text'>
            <p>
              Оллоҳ таоло уларнинг қилмишини кўриб, Мусо пайғамбарга: «Халқинг фасодга ботди. Менинг амрларимдан дарров юз ўгириб, сиғиниш учун ўзларига бузоқ ясади. Ана, ҳаммалари бузоққа қурбонлик сўйиб: “Эй Бани Исроил! Мана, сизларни Мисрдан олиб чиққан худо”, деб жар солмоқдалар», деди.
            </p>
            <p>
              Уларнинг ишидан Оллоҳ таоло қаттиқ ғазабланган эди!
            </p>
            <p>
              «Бу халқ ўжар! Қанчалик ўжар! Мен уларни бутунлай қириб ташлайман! Ер юзидан йўқ қиламан! Кейин ўзингдан катта бир халқ яратаман», деди.
            </p>
            <p>
              Мусо пайғамбар эса: «Ё Раббим! Халқингга раҳминг келсин! — деб ёлвора бошладилар. — Уларни куч-қудратинг билан Мисрдан олиб чиқдинг. Энди мисрликлар: “Оллоҳ ёмон ниятда олиб чиққан экан! Тоққа олиб бориб ўлдириш учун олиб кетган экан”, деб айтишмасин! Қулларинг Иброҳим, Исҳоқ ва Исроилни эсла. Сен уларга: “Насл-насабингизни осмондаги юлдузлардек кўпайтираман, ваъда қилган ерни авлодларингизга абадий мерос қилиб бераман”, деб қасам ичган эдинг-ку!» деб ялиндилар. Шундан сўнг Оллоҳ таоло Мусо (а.с.) нинг дуосини ижобат қилди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ТОШ ЛАВҲАЛАРНИНГ СИНИШИ </p>
          </div>

          <div className='main_text'>
            <p>
              Мусо пайғамбар тоғдан тушар эканлар, қўлларида икки томонлама битик битилган тош лавҳалар бор эди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[11] ? 'block' : 'none' }}
              onClick={() => handleSupClick(11)}
            >
              <p >
                <sup>11</sup>  «(Мусога) лавҳларда ҳамма нарсани мавъизаси ва тафсилоти билан ёзиб бердик. Бас, уларни қувват ила тут ва аҳлингга буюр, уларни аҳсани билан ушласинлар, дедик» (Аъроф сураси, 145).
              </p>
            </div>
            <p onClick={() => handleSupClick(11)}>
              Бу лавҳаларни Оллоҳнинг Ўзи ясаган, уларга ўйиб битилган оятлар ҳам Оллоҳнинг дастхати эди.<sup>11</sup>
            </p>
            <p>
              Бу пайтда қароргоҳда ўйин-кулги авж олган, одамлар бузоқ атрофида айланиб рақс тушаётган эдилар. Мусо (а.с.) буни кўриб, бениҳоят ғазабланиб, қўлларидаги лавҳаларни улоқтириб юбордилар. (Илоҳий оятлар битилган лавҳалар тошларга урилиб чил-парчин бўлди.) Кейин келиб, улар ясаган бузоқни оловда куйдирдилар. Майда-майда қилиб янчиб, кулини халқ ичадиган бу­лоққа тўкдилар.
            </p>
            <p>
              Кейин акаси Ҳорундан: «Нега халқни бундай оғир гуноҳга ботирдингиз? Нима қилди улар сизга?» деб сўрадилар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[12] ? 'block' : 'none' }}
              onClick={() => handleSupClick(12)}
            >
              <p >
                <sup>12</sup>  Қуръоннинг Аъроф сурасида бундай деб айтилган: «Қачонки Мусо ўз қавмига ғазабланган ва афсус қилган ҳолида қайтганида: «Менинг кетимдан қандай ҳам ёмон халифа бўлдингиз. Раббингизнинг амридан (олдин) шошилдингизми?!» деб лавҳларни улоқтириб, акасининг бошидан торта бошлади. (Ҳорун): «Эй онамнинг боласи, шубҳасиз, бу қавм мени хўрлади, ўлдириб қўйишига сал қолди. Энди сен душманларни устимдан кулдирма, мени ҳам золим қавмлардан деб билма», деди» (Аъроф сураси 7:150).
              </p>
            </div>
            <p onClick={() => handleSupClick(12)}>
              «Ҳазратим, қаҳрингиз келмасин, — дедилар Ҳорун (а.с.). — Халқнинг қанчалик ёмонликка мойил эканини биласиз-ку. Улар келиб, Мусога бир нарса бўлган, энди сен бизга худо ясаб бер, бизга раҳнамолик қилсин, деб туриб олдилар. Шундан сўнг мен тилла сирғаларни олиб келинглар, дедим. Мана, натижаси мана шу бузоқ бўлди», дедилар.<sup>12</sup>
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>  “КИМ ХУДО ТАРАФИДА”  </p>
          </div>

          <div className='main_text'>
            <p>
              Шундай қилиб, Бани Исроил издан чиққан, ўзларини душманлар олдида шарманда қилган эди.
            </p>
            <p>
              Расулуллоҳ қароргоҳ майдонига келиб: «Ким Оллоҳ тарафида бўлса, буёққа ўтсин!» деб ҳайқирдилар. Лобий қабиласининг йигитлари у кишининг атрофига тўпланди.
            </p>
            <p>
              «Оллоҳнинг амри бу: ҳар бирингиз қўлга қилич олиб қароргоҳдаги эркакларни ўлдиринг, — деб буюрди Мусо пайғамбар. — Биродарми-қариндошми, аяб ўтирманг!»
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[13] ? 'block' : 'none' }}
              onClick={() => handleSupClick(13)}
            >
              <p >
                <sup>13</sup> «Мусонинг ўз қавмига: «Эй қавмим, албатта, сиз бузоқни (худо) тутишингиз ила ўзингизга зулм қилдингиз. Энди сизни йўқдан бор қилувчи зотга тавба қилинг, ўз (одамларингизни) ўлдиринг, шундоқ қилмоғингиз йўқдан бор қилувчи зот ҳузурида сиз учун яхшидир... Чунки У тавбани кўплаб қабул қилувчи ва раҳмли зотдир», деганини эсланг» (Бақара, 54).
              </p>
            </div>
            <p onClick={() => handleSupClick(13)}>
              Ўша куни уч мингтача одам тиғдан ўтказилди.  Оллоҳ Бани Исроилни олтин бузоқ ясагани учун ана шу йўсинда жазолаган эди.<sup>13</sup>
            </p>
            <p>
              Эртасига Мусо (а.с.) одамларга дедилар: «Гуноҳингиз жуда ҳам оғир. Шундай бўлса ҳам мен Раббимиз олдига бораман, балки сизлар учун каффора топарман». Кейин Оллоҳ азза ва жаллнинг ҳузурига бориб: «Халқ оғир гуноҳ қилди, ўзларига тилла бут ясади. Ё Оллоҳ! Шунда ҳам уларнинг гуноҳини кечир. Ўтинаман! Кечирмасанг, мени ҳам рўйхатингдан ўчира қол!» деб ялиндилар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[14] ? 'block' : 'none' }}
              onClick={() => handleSupClick(14)}
            >
              <p >
                <sup>14</sup> Кейин Оллоҳ таоло олтин бузоқ ясаб ширкка қўл урганлар устига офат юборади.
              </p>
            </div>
            <p onClick={() => handleSupClick(14)}>
              Оллоҳ деди: «Гуноҳ қилганларни ўчираман, вақти келганда жазосини <sup>14</sup> бераман. Сен эса тур, Мисрдан чиқарган халқингни олиб, ота-боболарингиз Иброҳим, Исҳоқ ва Яъқубга ваъда қилинган юртга, Мен “сизларнинг наслингизга бераман”, деб қасам ичган юртга кетинглар. Сенинг олдингда фаришта юради, канъонийларни, амўрийларни, ҳитий, перизий, ҳавий ва ябусийларни ҳайдаб чиқаради. Сут ва асал ўлкасига боринглар, лекин Мен сизлар билан бормайман, токи йўлда сизларни ҳалок қилмай, чунки сизлар ўжар халқсизлар. Исроил халқига айт: “Сизлар ўжар халқсиз. Мен бир лаҳза сизлар билан бирга бўлсам, сизларни қириб ташлайман. Энди зеб-зийнатларингизни ечиб қўйинг, Мен сизларни нима қилиш тўғрисида қарор чиқараман”», деди.
            </p>
            <p>
              Халқ бу гапни эшитгач, дод солиб йиғлади. Ҳеч ким зеб-зийнатларини тақмади. Шундай қилиб, Исроил халқи Сино тоғида зеб-зийнатларини ечиб олди ва бошқа тақмади.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> Учрашув Чодири </p>
          </div>

          <div className='main_text'>
            <p className="vitalic">
              Мусо пайғамбарга берилган амрлардан бири махсус чодир ҳақида эди.
            </p>
            <p>
              Мусо (а.с.) уни қароргоҳдан ташқарига ўрнатиб, унга “Учрашув Чодири” деб от қўйдилар. Шундан сўнг кимки Худодан бирор нарса сўрамоқчи бўлса Учрашув Чодирига келадиган бўлди.
            </p>
            <p>
              Ҳар сафар ҳазрати Мусо Чодирга бораётганида, одамлар чодирларидан чиқар ва то у киши Чодирга киргунча орқаларидан қараб турар эдилар. Расулуллоҳ Чодирга кирганларидан сўнг Булут устуни тепадан пастга тушиб, Чодир эшиги олдида турар, бу эса Оллоҳ таолонинг расулуллоҳ билан суҳбат қилаётганидан дарак берарди. Одамлар Булутнинг Чодир эшиги олдида турганини кўрсалар, ўринларидан туриб, чодирлари олдида Оллоҳга сажда қилар эдилар.
            </p>
            <p>
              Худо Мусо (а.с.) билан худди инсон дўсти билан гаплашаётгандек тўғридан-тўғри суҳбат қиларди. Суҳбатдан сўнг ҳазрати Мусо қароргоҳга қайтиб келар, ёрдамчиси Юъшо ибн Нун эса Чодирни тарк этмасдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> Мусо (а.с.) нинг яна “биз билан бирга бўл” деб илтимос қилгани </p>
          </div>

          <div className='main_text'>
            <p>
              Кейин расулуллоҳ яна Оллоҳ (с.в.т.) га дуо қилиб дедилар: «Парвардигоро, Сен менга бу халққа раҳбарлик қил, дединг, лекин кимни мен билан бирга юборасан?  Ким у Сен айтган фаришта? Ахир Сен менга: Мен сени танладим, Ўзимга яқин олдим, Менинг назаримда марҳамат топдинг, деб айтган эдинг-ку! Агар марҳамат топган бўлсам, илтижо қиламан иродангни менга нозил қил, токи Сени яхшироқ таний, Сени хушнуд қилай. Ахир Бани Исроил Сенинг халқинг-ку!»
            </p>
            <p>
              Шунда Худованди карим унга деди: «Мен сен билан бирга бўламан. Қалбингга тинчлик бераман».
            </p>
            <p>
              «Йўқ, — дедилар ҳазрати Мусо (а.с.) — Агар Бани Исроил билан бирга бўлмасанг, бизни бу ердан жўнатма. Ахир, мендан ва халқингдан рози эканинг қаердан билинади? Биз билан бирга бўлишингдан эмасми? Бизнинг ер юзидаги бошқа халқлардан фарқимиз шу эмасми?»
            </p>
            <p>
              Шунда Оллоҳ (с.в.т.) деди: «Менинг малагим сизлар билан боради, чунки сендан хушнудман, сен Менга кўп яқинсан».
            </p>
          </div>

          <div className='paragraph_heading' onClick={() => handleSupClick(15)}>
            <p>  «Ё Раббим, Сенга бир назар солай!»<sup>15</sup></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[15] ? 'block' : 'none' }}
              onClick={() => handleSupClick(15)}
            >
              <p >
                <sup>15</sup> Аъроф сураси 143.
              </p>
            </div>
            <p onClick={() => handleSupClick(15)}>
              Кейин Мусо (а.с.) Худога ялиндилар: «Ё Раббим, ўтинаман, Сени бир кўрай, салобатингни менга кўрсат».
            </p>
            <p>
              Оллоҳ деди: «Мен сенга Ўз раҳматимни кўрсатаман, Раҳмон исмимни нозил қиламан. Кимга раҳм қилмоқчи бўлсам раҳм қиламан, кимга раҳмат бермоқчи бўлсам, раҳмат бераман. Лекин юзимни кўролмайсан, чунки Мени кўрган одам тирик қолмайди», деди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[16] ? 'block' : 'none' }}
              onClick={() => handleSupClick(16)}
            >
              <p >
                <sup>16</sup>  «Мусо мийқотимизга келиб..: «Раббим, менга (жамолингни) кўрсат, Сенга назар солай», деди. У зот: «Ҳеч кўра олмассан. Лекин тоққа назар сол, агар у маконида собит тура олса, бас, Мени кўрасан», деди. Рабби тоққа тажаллий қилган эди, уни титилган ҳолга келтирди ва Мусо ҳушидан кетиб йиқилди...» (Аъроф сураси, 143-оят).
              </p>
            </div>
            <p onClick={() => handleSupClick(16)}>
              Кейин: «Мана бу ерда бир қоя бор. Сен шу қоянинг устига чиқасан. Мен тажалли қилган вақтда сени қоядаги ғорга қўйиб қўлим билан ёпиб тураман. Жалолим ўтгандан сўнг қўлимни оламан, шунда Мени парда ортидан кўргандай бўласан,<sup>16</sup> — деди. — Ҳозир аввалгиларга ўхшатиб икки тош лавҳа йўн. Мен уларнинг устига амрларимни қайтадан ёзиб, сенга бераман. Эртага эрталаб тайёр бўл. Саҳар Менинг ҳузуримга, Сино тоғига чиқ», деди.
            </p>
            <p>
              Мусо (а.с.) лавҳаларни тайёрладилар ва эртасига вақтли туриб, Сино тоғига — Оллоҳ таолонинг ҳузурига чиқдилар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[17] ? 'block' : 'none' }}
              onClick={() => handleSupClick(17)}
            >
              <p >
                <sup>17</sup>  «Деди (Оллоҳ): «Азобим ила кимни хоҳласам, тутаман. Раҳ­ма­тим эса, ҳамма нарсадан кенг­дир» (Аъроф сураси, 156).
              </p>
            </div>
            <p onClick={() => handleSupClick(17)}>
              Оллоҳ азза ва жалл нурли булут ичра тоққа тажалли қилди. Мусо (а.с.) нинг олдидан ўтар экан деди: «Мен Оллоҳман-Оллоҳ, раҳмон ва раҳийм, ғазабга секин, меҳру вафога бой<sup>17</sup>  Худоман. Мени севганларнинг ҳаммаси раҳмат топур, гуноҳлари мағфират бўлур. Бадкорлар эса албатта жазо олур. Оталар гуноҳи учун авлодларини учинчи-тўртинчи наслгача мажозот қиламан», деди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> РАСУЛУЛЛОҲНИНГ ЮЗИДАН ЁҒИЛГАН НУР </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[18] ? 'block' : 'none' }}
              onClick={() => handleSupClick(18)}
            >
              <p >
                <sup>18</sup> Бу лавҳалардаги ёзув ҳам Оллоҳ азза ва жаллнинг ёзуви эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(18)}>
              Қирқ кечаю қирқ кундуз Мусо пайғамбар тоғда Оллоҳнинг ҳузурида таълим олдилар. Қирқ кунгача ҳеч нарса емадилар, ҳеч нарса ичмадилар. Қирқ кундан сўнг аҳднома ёзилган лавҳалар<sup>18</sup> билан одамлар олдига қайтиб келдилар. Оллоҳ таоло билан гаплашгани учун юзларидан нур ёғилар, аммо ўзи бундан бехабар эди. Одамлар у кишининг юзидан чиқаётган нурни кўриб, яқинлашишга қўрқдилар.
            </p>
            <p>
              Ҳазрати Мусо уларни чақирдилар. Кейин Оллоҳ берган барча амрларни уларга айтдилар. Гаплашиб бўлганларидан сўнг, юзларига парда тортдилар. Шу-шу, қачон у киши Оллоҳнинг ҳузурига борсалар, юзидаги пардани олар, кейин келиб Бани Исроилга Оллоҳ берган амрларни тушунтирар эдилар. Ҳар суҳбатда Исроил халқи унинг юзидан нур порлаётганига гувоҳ бўларди. Кейин Мусо (а.с.) яна Худонинг ҳузурига боргунча юзларига парда тортар эдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ВАЪДА ҚИЛИНГАН ЮРТГА КИРГАНДА... </p>
          </div>

          <div className='main_text'>
            <p className="vitalic">
              Оллоҳ таоло Мусо пайғамбарга амр қилиб берган нарсалардан бири ваъда қилинган юрт ҳақида эди:
            </p>
            <p>
              «Мен Канъон ўлкасини амўрийлар ва канъонийлардан, ҳитий ва перизийлар, ҳавий ва ябусийлардан олиб сизларга бераман. Аммо сизлар Менинг амрларимга итоат этмоғингиз лозим. Маҳаллий халқларнинг ҳеч қайсиси билан аҳд тузманг. Акс ҳолда, уларнинг гуноҳ ишларини такрорлайсиз. Уларнинг қурбонгоҳларини бузинг, топинадиган тошларини синдиринг. Мен Ўз халқимни қизғанаман, бутларга сиғинишини хоҳламайман.
            </p>
            <p>
              Бу халқлар билан ҳеч қандай келишув ё битимга борманг. Улар ўзларининг худоларига сиғинар экан, сизларни ҳам бутпарастликка даъват этади. Кейин сизлар ҳам Менга хиёнат қилиб, улар қилаётган ишларни такрорлайсизлар. Уларнинг бутпараст қизларини ўғилларингизга олиб берсангиз, ўғилларингиз Мендан юз ўгириб, хотинлари тавоф қиладиган бутларга кўнгил қўядилар».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> Муқаддас Маскан  </p>
          </div>

          <div className='main_text'>
            <p className="vitalic">
              Оллоҳ субҳанаҳу ва таоло кейин Мусо (а.с.) га яна бир чодир қуришни амр қилди. Оллоҳ учун қилинадиган қурбонлик ва назрлар энди мана шу ерда қилиниши керак эди.  Чодирнинг тевараги девор билан ўралган, ҳовлисида қурбонгоҳ учун махсус жой ажратилган, эшиги олдида эса таҳорат олиш учун махсус идиш бор эди. Таҳоратсиз Чодирга кириш манъ қилинган эди. Чодирнинг ичи икки бўлмадан иборат бўлиб, уларни бир-биридан қимматбаҳо парда ажратиб турарди.
            </p>
            <p className="vitalic">
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[19] ? 'block' : 'none' }}
              onClick={() => handleSupClick(19)}
            >
              <p >
                <sup>19</sup> Аҳд сандиғи ҳақида Бақара сурасида оят бор: «Албатта, унинг подшоҳлигининг белгиси, сизларга Раббингиздан сандиқ келмоғидир, унда Сакийна ва Мусо ҳамда Ҳорун аҳлидан қолган нарса бўлиб, фаришталар кўтариб келур. Агар мўмин бўлсаларингиз, албатта бу нарсада сизларга белги бор» (248-оят). Манн солинган хум ҳам мана шу сандиқ ичида сақланган.
              </p>
            </div>
            <p onClick={() => handleSupClick(19)} className='vitalic'>
              Сиртқи бўлмада руҳонийларнинг ибодат учун ишлатадиган буюмлари бўлган. Ички бўлма эса “Муқаддаслар муқаддаси” деб аталиб, у ерга олийруҳонийдан бошқа ҳеч ким киролмас эди. Аҳднома солинган Аҳд сандиғи<sup>19</sup> ҳам ана шу ерда, ички бўлмада турган.
            </p>
            <p>
              Чодир қўйилган куни Чодирни — Аҳднома сақланадиган Масканни булут қоплади. Кечаси то тонг ёришгунча булутдан худди оловга ўхшаш бир нарса порлаб турди. Бу воқеа ҳар куни такрорланар эди: кундузи булут, кечаси эса олов Чодирни қоплаб турарди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> Оллоҳнинг паноҳида </p>
          </div>

          <div className='main_text'>
            <p>
              Оллоҳ таолонинг жалоли нурли булут кўринишида Чодирни қамраб турарди. Қачон булут Чодирдан кўтарилиб, ҳаракатга келса, Исроил халқи ҳам кўчларини йиғиб, йўлга тушар,   қачон булут тўхтаса, Исроил халқи ҳам тўхтаб қароргоҳ қурар эди.
            </p>
            <p>
              Булут баъзан анча вақтгача Чодирдан кўтарилмас, шунинг учун халқ ҳам ҳеч қаерга бормасдан кутиб ўтирарди. Баъзи пайтларда эса булут Чодир тепасида қисқа муддат тўхтарди. Исроил халқи шу тариқа баъзан икки кун, баъзан бир ой, баъзан бир неча ой бир жойда қолган пайтлари бўлган.
            </p>
            <p>
              Оллоҳ таолонинг амри билан улар тўхтар, Оллоҳ таолонинг амри билан йўлга чиқар эдилар. Бутун сафар мобайнида Оллоҳнинг булути халқнинг кўз олдида бўлди. Кундузи булут шаклида, кечаси эса олов кўринишида халққа раҳнамолик қилди.
            </p>
            <p></p>
          </div>

          <BottomNav 
            labelLeft='Шариат аҳкоми' 
            pathLeft='/public-resources/qisas-uzbek/examples-of-shariah'

            labelCenter='Аҳдлашиш'
            pathCenter='/public-resources/qisas-uzbek/bani-israel-accepted-shariah'

            labelRight='Амрлар'
            pathRight='/public-resources/qisas-uzbek/more-shariah-laws'
            />
  
        </main >
        <aside> </aside>
      </div >
    </div >
  )
}

export default BaniIsraelAcceptedShariah
