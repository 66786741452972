import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const TolutsDeath = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>
                    Шоҳ Толутнинг ҳалок бўлиши
                  </p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

                <div className='paragraph_heading'>
                    <p> Толутнинг арвоҳдан маслаҳат сўрагани  </p>  
                </div>

            
          <div className='main_text'>
            <p>Сомуил пайғамбар ўлганда бутун Исроил халқи аза тутган ва у кишини ўз шаҳ­ри Ра­ма­да дафн қилган эдилар. Ўша пайт­лар­да под­шоҳ Толут жоду билан шуғулла­на­ди­ган, руҳлар билан алоқа қиладиган одам­лар­ни бани Исроилдан қувғин қилган эди. </p>
            <p>
            Филистийлар Шунам шаҳрига келиб қа­рор­гоҳ қургач, Толут ҳам ўз лашкарини Жал­буда йиғди. Лекин филистийлар қў­ши­ни­ни кўриб, қаттиқ қўрқди. «Энди нима бўлади, нима қилишим керак?» деб Худо­дан сўраган эди, на тушида, на руҳо­ний­лар орқали ва на пайғамбарлар орқали жавоб келмади. 
            </p>
            <p>
            Шундан сўнг аъёнларига: «Ўлганларнинг руҳини чақирадиган бир хотин топинглар, нима бўлишини билайлик», деб буйруқ берди.
            </p>
            <p>
            «Эндўрда бир хотин бор», дейишди аъёнлари. 
            </p>
            <p>
            Ўша куни кечаси, Толут бошқача кийимлар кийиб, икки киши билан ҳалиги хотиннинг уйига борди ва: «Менга бир одамнинг руҳини чақириб бер», деб ил­ти­мос қилди. 
            </p>
            <p>
            «Мени ўлдирмоқчимисан? – деди хотин. – Ким бу ишни қилса соғ қолмайди! Толут ҳаммасини йўқ қилди». 
            </p>
            <p>
            «Оллоҳга қасам, сенга ҳеч қандай зарар етмайди», деди Толут. «Кимни чақирай?» деб сўради хотин. «Сомуил пайғамбарни», деди Толут. 
            </p>
            <p>
            Хотин Сомуил пайғамбарни кўргач оҳ уриб подшоҳга деди: «Сиз Толут экансиз-ку! Нега мени алдадингиз?»
            </p>
            <p> «Қўрқма! – деди подшоҳ. – Нимани кўр­яп­сан?» 
            </p>
            <p>
            «Ер тагидан бир руҳ чиқиб кел­яп­ти». 
            </p>
            <p>
            «Кўриниши қандай?» «Қария, устида ридоси бор», деди хотин. Толут унинг Сомуил пайғамбар эканини билиб, ерга бош қўйиб таъзим қилди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> «Эртага менинг олдимга келасан» </p>
          </div>
     
          <div className='main_text'>
            <p>
            «Нега мени безовта қилдинг?» деб сў­ра­ди­лар Сомуил (а.с.).
            </p>
            <p>
            «Қийналиб кет­дим, – деди Толут. – Филистийлар ҳу­жум­га ўтмоқчи. Оллоҳ мени тарк этди. На пайғамбарлар орқали, на туш орқали жа­воб бермаяпти. Нима қилай, айтинг?» 
            </p>
            <p>
            «Оллоҳ сенга қарши бўлса, сени тарк этса, мендан нимани сўрайсан? – дедилар Со­­му­ил пайғамбар. – Сенга айтган эдим, Оллоҳ салтанат тахтини сендан олиб, До­вуд­га беради. Чунки сен Унинг амрига ито­ат этмадинг, Амолиқ ҳақида бер­ган ҳук­ми­ни бажармадинг. Шунинг учун бу кун­лар бошингга тушди. Эртага фи­лис­тий­лар Исроил лашкарини мағлуб қилади. Сен ҳам, ўғилларинг ҳам менинг ёнимга ке­ла­сизлар».
            </p>
            <p>
            Толут бу гапларни эшитиб, қаттиқ қўр­қиб, ерга йиқилди. Бир кеча-кундуз туз тот­ма­ган, танасида қувват қолмаган эди.
            </p>
            <p>  Хотин унинг қўрқиб кетганини кўриб: «Аъло­ҳазрат! Мен жонимни хатарга қўйиб, айт­га­нингизни қилдим. Энди сиз ҳам менинг гапимга киринг: озгина бўлса ҳам нон енг. Йўлга чиққанингизда дармон бўлади», деди. 
            </p>
            <p>
            «Емайман!» деди Толут. Лекин аъёнлари ҳам, хотин ҳам қистайвергач туриб, сўрига ўтирди. Хотин уйидаги бўрдоқи бузоғини сўйди. Хамир қориб, хамиртурушсиз нон ёпди. Толут билан аъёнлари еб-ичиб, ўша куни тунда жўнаб кетдилар.
            </p>
          </div>
         
          <div className='paragraph_heading'>
            <p> Филистийларнинг Довуд  га ишонмагани </p>
          </div>
     
          <div className='main_text'>
            <p>
            Филистийлар бор кучини Афиққа йи­ға­ёт­ган эдилар. Юз ва минг кишидан иборат аскарлар саф-саф бўлиб борар, Довуд  ва унинг одамлари эса Ахиш билан бирга орқада келаётган эдилар. Филистий амирлари: «Бу ибронийлар бу ерда нима қилиб юрибди?» деб сўрадилар. 
            </p>
            <p>
            «Булар Довуднинг одамлари, – деди Ахиш. – Довуд Толутнинг амалдори эди. Мана, бир йил бўлдики менинг ёнимда. Бугун­га­ча ундан ҳеч қандай ёмонлик кўрмадим».
            </p>
            <p>
            Лекин филистий амирларининг жаҳли чиқиб: «Орқага қайтсин! – деб туриб олдилар. – Биз билан урушга бормайди! Бошимизга бало бўлади ҳали. Аввалги хўжайинига ёқиш учун йигитларимиз бошини кесмайди, деб ким айта олади? “Мингини ўлдирди Толут, ўн мингин эса Довуд!” деган ашула эсингизда йўқми?»
            </p>
          <p>
          Шундан кейин Ахиш Довуд (а.с.) ни ёнига чақириб: «Сен яхши одамсан. Мен сенга ишонаман. Лекин бекларимиз урушга бо­ри­шингни хоҳламаяпти. Бу сафар тинчлик билан орқага қайт, бекларимиз хафа бўл­масин», деди. 
          </p>
            <p>
            «Мендан шубҳаланманг, – дедилар До­вуд (а.с.). – Келганимдан бери мендан ҳеч ёмонлик кўрдингизми? Нега энди сиз билан бирга душманларингизга қарши жангга чиқмас эканман?!» 
            </p>
            <p> «Биламан, – деди Ахиш, – сен менинг ол­димда фариштадай поксан. Лекин фи­лис­тий амирлари урушга боришингга кўнмаяпти. Шунинг учун эртага эрталаб йигитларинг билан орқага қайт». 

            </p>
            </div>
            
            <div className='paragraph_heading'>
            <p> Амолиқлар билан бўлган жанг </p>
          </div>
     
          <div className='main_text'>
            <p>
            
            Довуд пайғамбар йигитлари билан орқага қайтдилар ва учинчи куни турган жойлари – Сиқлағ шаҳрига етиб келдилар. 
            </p>
            <p>
            Келсалар шаҳар талон-тарож бўлган, амолиқийлар ҳужум қилиб, шаҳарни ёндириб, хотину бола-чақаларни асир қилиб олиб кетишган эди. 
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p> <sup>1</sup> Амолиқийлар Довуд  нинг икки хотинини ҳам асир қилиб олиб кетган эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
                Довуд (а.с.) нинг одамлари бўлган во­қеа­ни кўриб, то ҳолдан тойгунча дод-вой солиб йиғладилар.<sup>1</sup>
            </p>
            <p></p>
          </div>
        
          <div className='main_text'>
            <p>
            Ҳолат жуда ёмон эди. Чунки эркаклар ўғил-қизларини ўйлаб тушкунликка туш­ган, кўплари Довуд (а.с.) ни тошбўрон қилмоқчи бўлиб турган эдилар. 
            </p>
            <p>
            Довуд пайғамбар Оллоҳдан: «Уларнинг ор­қа­сидан таъқиб қилайми? Қувласам, етиб оламанми?» деб сўради. «Ҳа, – деган жавоб келди. – Етиб оласан. Асирларни ҳам озод қиласан». 
            </p>
            <p>
            Шундан сўнг, Довуд (а.с.) олти юз одами билан амолиқларни таъқиб қилиб, Басўр сойига келдилар. Икки юз киши чарчаб, ҳолдан кетиб, ўша ерда қолди. Ҳазрати Довуд  қолган тўрт юз йигити билан қувлашда давом этдилар. 
            </p>
            <p>
            Йўлда, даштда ётган бир чалажон миср­ликни кўриб қолиб,   Довуд (а.с.) нинг олдига келтирдилар. Бечора уч кечаю уч кундуз ҳеч нарса емаган эди. Унга сув, нон, қуритилган анжир, икки ҳовуч майиз бердилар. Овқат еб бироз ўзига келди. 
            </p>
            <p>
            Довуд алайҳис-салом ундан: «Хўжа­йи­нинг ким? Қаердансан?» деб сўрадилар. 
            </p>
            <p>
            «Миср­ликман, бир амолиқнинг қулиман, – деди йигит. – Уч кун олдин касал бўлиб қол­ган эдим, хўжайиним чўлда ташлаб кет­ди. Жанубга, каритийлар юртига, холиб­лар минтақасига ҳужум қилган эдик, Сиқлағ шаҳрига ўт қўйдик». 
            </p>
            <p>
            «Улар ҳозир қаерда? Улар турган жойни менга кўр­са­та оласанми?» деб сўрадилар Довуд (а.с.). 
            </p>
            <p>
            «Агар сени ўлдирмайман, хў­жа­йи­нинг қўлига топширмайман, деб қасам ичсангиз, кўрсатаман», деди миср­лик қул ва уларни амолиқлар қароргоҳига бошлаб борди. Улар даштда тўрт томонга яйралиб, қўлга киритган ўлжаларини еб-ичиб хур­сандчилик қилиб ўлтирган эдилар. 
            </p>
            <p>
            Довуд пайғамбар тонг отар пайтда ҳужум қилиб, то кун ботгунча уларни тиғдан ўтказди­лар. Фақат тўрт юз одам туяларга миниб қочиб кетишга муваффақ бўлди.
            </p>
            <p>
            Довуд (а.с.) нинг йигитлари улар олиб кетган ҳамма нарса­ни қайтариб олдилар. Асир бўлганларни ҳам: ёшу қари, ўғиллари, қизлари, ўзла­ри­га тааллуқли бўлган ҳамма моллари билан соғ-саломат қўлга киритишди. Амо­лиқ­лар­нинг мол-қўйларини ҳам ўзлари­нинг моллари билан бирга ўлжа қилиб олиб кетдилар.
            </p>
            <p>
            Орқага қайтишда, Басўр сойида қолиб кет­ган икки юз одами ҳам уларга қў­шил­ди. Довуд (а.с.) нинг нафарларидан баъзи бир ёмон одамлар: «Улар биз билан бирга жанг қилганлари йўқ, шунинг учун ўлжа олган мол­лардан уларга ҳеч нарса бермаймиз. Фақат хотин-бола-чақасини олади», де­йиш­ди. 
            </p>
            <p> «Биродарлар, бундай қилманглар, – деди ҳазрати Довуд, – Бу нарсаларни бизга Оллоҳ берди. Бизни Оллоҳ сақлади, амолиқлар устидан ғалабага эриштирди. Урушга борганлар билан анжомлар ёнида қолганлар бир хил улушга эга бўлади». 
            </p>
            <p>
            Ўша-ўша, бу ҳукм Исроилда қонун си­фа­ти­да қабул қилинди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> Толут билан ўғилларининг ҳалок бўлгани </p>
          </div>
     
          <div className='main_text'>
            <p>
            Бу пайтда филистийлар Бани Исроил билан жанг қилаётган эдилар. Исроилдан кўп одамлар ўлиб, қолганлари қоча бошлади. Филистий аскарлари Толут подшоҳ билан ўғилларининг йўлини тўсиб, Янатон, Абу­на­доб ҳамда Малкишуйни ўлдирдилар. 

            </p>
            <p>
            Ён-атрофда жанг авжига чиқиб, ка­мон­чи­лар отган ўқлардан подшоҳ ҳам қаттиқ ярадор бўлди. Кейин қуролбардорига: «Қи­личингни олиб мени ўлдир, ано­ви кес­­ти­­рилмаганлар келиб, мени хўр­ла­ма­син», деди.
            </p>
            <p>
            Лекин қуролбардори бу ишни қилишга қўрқди. Шунда Толут ўзининг қиличини олиб, устига ўзини ташлаб ҳалок бўлди. Буни кўрган қуролбардори ҳам ўзи­ни шу йўсинда ўлдирди. Шундай қилиб, Толут, унинг учала ўғли, қуролбардори ва бутун лашкари бир кунда у дунёга равона бўлдилар.
            </p>
            <p> Эртаси куни филистийлар Толут билан ўғил­ларининг жасадини топиб, Толут­нинг бо­шини кесиб, жасадини Байт-шон шаҳ­ри­да деворга осдилар. Қурол-аслаҳасини эса Асториш бутхонасига қўйишди.
            </p>
            </div>
           
            <div className='paragraph_heading'>
            <p> Хабарчининг ўлими </p>
          </div>
     
          <div className='main_text'>
            <p>
            Довуд (а.с.) Сиқлағ шаҳрига қайтиб кел­ган­ларидан сўнг учинчи кун у кишининг ҳу­зу­рига кийим­ла­ри­ йиртилган, бошига тупроқ сочилган бир одам келди. Келиб, бошини ерга қўйиб, ҳазрати Довуднинг олдида таъзим қилди. 
            </p>
            <p>
            «Қаердан келяпсан?» деб сўрадилар Довуд алайҳис-салом. 
            </p>
            <p>
            «Урушдан, Исроил қарор­го­ҳидан қочиб келдим», деди у. 
            </p>
            <p>
            «Нима бўлди?!»
            </p>
            <p>
            «Бизникилар қочди, одам­ла­ри­миздан кўпи ўлди. Толут билан ўғли Янатон ҳам ҳалок бўлди».
            </p>
            <p>
            «Толут билан Яна­тоннинг ҳалок бўлганини қаердан билдинг?» деб сўрадилар ҳазрати Довуд. 
            </p>
            <p>
            «Мен Жилбўъ тоғида эдим, – деди у. – То­лут найзасига суяниб турган экан. Душ­ман отлиқлари, жанг аравалари унга яқин­ла­шиб қолган эди. Толут мени кўриб чақир­ди. Кимсан, деб сўради. Амолиқман, де­дим. У, мени ўлдир, ярадор бўлдим, бари­бир ўламан, деди. Бошқа яшай олмас­ли­ги­га кўзим етиб, уни ўлдирдим. Бошидаги тожи билан қўлидаги билакузугини сизга олиб келдим».
            </p>
            <p>
            Довуд (а.с.) буни эшитиб, фарёд қилиб, ки­йимларини йиртдилар. У кишининг ёни­да­гилар ҳам, кийимларини йиртиб, йиғлаб, кечгача ҳеч нарса емай, аза тутдилар. 
            </p>
            <p>
            Кейин ҳазрати Довуд хабарчи йигитни ча­­қи­риб: «Қаердансан?» деб сўрадилар.
            </p>
            <p>
            «Отам амолиқ. Лекин Исроилда яшаймиз», деди у. 
            </p>
            <p> «Ҳазрати Толутни Оллоҳнинг Ўзи подшоҳ қилиб тайинлаган эди! Шундай одамни ўл­ди­ришга қандай ҳаддинг сиғ­ди?» деди Довуд алайҳис-салом. Кейин йи­гит­ла­ри­дан бирини чақириб: «Ўлдир уни!» деб амр қилдилар. 
            </p>
          </div>

          <BottomNav 
            labelLeft='ортга қайтиш' 
            pathLeft='/public-resources/qisas-uzbek/somuelsDeath'

            labelCenter='Толутнинг ўлиши'
            pathCenter='/public-resources/qisas-uzbek/tolutsDeath'

            labelRight='Довуд (а.с.) тахтда'
             pathRight='/public-resources/qisas-uzbek/davidOnThrone'
          />     
        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default TolutsDeath;