import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const Aqsa = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>
                  Ал-Ақсонинг қурилиши  
                  </p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

                   <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p> <sup>1</sup> Муҳиддин ибн-Арабий, “Му­xо­да­ро”, 1-жилд, 134-бет; Абул­фидо, “Ал бидоя ван-ни­ҳоя”, 2-жилд, 32-бет.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
               Ҳазрати Сулаймон подшоҳлигининг тўр­тинчи йили,<sup>1</sup>  йилнинг иккинчи ойи, яъни Зив ойида Байтуллоҳнинг қурилиши бош­ланди. Ўшанда Бани Исроилнинг Миср­дан чиққанига тўрт юз саксон йил бўлган эди. 
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p>
            Оллоҳ таоло Сулаймон (а.с.) га: «Агар қо­нун­ларимга риоя қилсанг, барча аҳком ва амрларимга итоат этсанг, отанг Довудга берган ваъдамни сен орқали амалга оши­ра­ман. Мен халқим Бани Исроил ора­си­да яшайман ва ҳеч қачон сизларни тарк эт­май­ман», деб айтган эди. 
            </p>
            <p>
            Ниҳоят Байтуллоҳнинг қурилиши ҳам охи­рига етди. Маъбаднинг ички де­вор­ла­ри ердан шифтгача садр дарахти билан қоп­лан­ди. Ерига эса сарв ёғочини тўша­ди­лар. Деворлар юзига хурмо дарахтлари, қовоқ ва очилиб турган гуллар нақш қилиб ўйилди. 
            </p>
            <p>
            Энг ичкарига эса Аҳд Сандиғини қўйиш учун бир хона тайёрладилар. Унинг узунлиги, эни ва баландлиги йигирма тирсак эди.
            </p>
            <p>
            Ҳазрати Сулаймон бу хонанинг ички деворларини ҳамда сақфи ва саҳнини соф тилла билан қоплатдилар. Кейин садр ёғо­чидан бир қурбонгоҳ қурдирдилар. Қур­бонгоҳни ҳам тилла билан қоплатдилар. 
            </p>
            <p>
            Сулаймон  подшоҳлигининг ўн бирин­чи йилида, йилнинг саккизинчи ойи­да Бай­тул­лоҳ­нинг қурилиши ниҳоя­си­га етди. Қу­ри­лиш­га етти йил вақт кетган эди.
            </p>
            <p>
            Байтуллоҳ битгандан кейин, Сулаймон (а.с.) Аҳд Сандиғини Байтуллоҳга кўчи­риш учун Исроил халқининг оқсоқол­ла­ри­ни, бутун қабила ва уруғ бошлиқла­ри­ни Байт-ул-Муқаддасга таклиф қилдилар. Кейин руҳонийлар билан лобийлар Аҳд Сандиғини, Учрашув Чодирини ва ундаги барча муқаддас буюмларни Байтуллоҳга олиб келдилар. Ўша куни шунчалик кўп қўй ва ҳўкизлар қурбон қилин­ди­ки, ҳи­со­бига етиб бўлмасди. 
            </p>
            <p>
            Руҳонийлар “Муқаддаслар Муқаддаси” хо­на­сидан чиққанларида ўзидан нур сочиб тур­ган бир булут Байтуллоҳга энди. Булут шу қадар ёруғ эдики, руҳонийларнинг кўзи қа­ма­шиб, ишларини давом эттира ол­ма­ди­лар.
            </p>
            <p>
            Кейин қурбонликлар сўйилди. Йигирма икки минг қорамол ва бир юз йигирма минг қўй қурбон қилинди. Ёқилади­ган қур­бонликлар, ун ва ёғ назрлари, сало­мат­лик қурбонликлари Байтуллоҳдаги брон­за қур­бонгоҳга сиғмагани учун, ўша куни Байтул­лоҳ ҳовлисининг ўрта қисмини ҳам қурбон­гоҳ сифатида ишлатдилар. 
            </p>
            <p> Байрам ўн тўрт кун давом этди. Байрамда бутун Исроилдан: Ҳамот гузаргоҳидан тор­тиб, Миср сарҳадигача беҳисоб одамлар иштирок этди. 
            </p>
         
            <p>Байрамдан сўнг Сулаймон алайҳис-салом халққа жавоб бердилар. Улар подшоҳни алқаб, Оллоҳ таолонинг Ўз қули Довуд ва халқи Исроил учун қилган яхшиликларидан хурсанд бўлиб уйларига тарқалдилар.</p>
          </div>



                <div className='paragraph_heading'>
                    <p> Оллоҳ таоло яна ҳазрати Сулаймонга гапиради  </p>  
                </div>
              
           
     
          <div className='main_text'> 
            <p>
            Сулаймон пайғамбар Байтуллоҳни, сал­та­нат қасрини ва қиламан деб ният қилган барча ишларини битирганларидан сўнг, Оллоҳ таоло яна у кишига гапирди: 
            </p>
            <p>
            «Дуо ва илтижоларингни эшитдим. Байтул­лоҳни Ўз даргоҳимга айлантирдим. Кўзим ҳам, қалбим ҳам ҳамиша у ерда. Агар отанг Довуд каби, садоқат ва тўғрилик билан Менга итоат этсанг, аҳком ва қонунларимга бўйсунсанг, Довудга ваъда берганимдек, ҳамиша бир киши сенинг наслингдан Бани Исроилга подшоҳлик қилади. 
            </p>
            <p> Лекин Мендан юз ўгирсанглар, амрларимга бўй­сун­масанглар, бутпарастликка юз тут­санг­лар, Бани Исроилни бу юртдан ҳайдаб чиқараман, Байтуллоҳни тарк эта­ман. Хал­қингиз расво бўлиб ҳамманинг оғзида мас­ха­ра бўлади. Байтуллоҳ шундай вайрон бўла­дики, ўтган-кетганлар ҳайрат ва даҳшат билан: “Нима учун Оллоҳ бу юртни ва бу уйни бундай аҳволга солди экан?” деб сўрайдилар. Кейин бу саволга: “Чун­ки улар Оллоҳни, оталарини Мисрдан олиб чиққан худони тарк этиб бошқа худолар­га сиғинган. Шунинг учун Оллоҳ уларнинг бошига бу офатни юборган”, деб жавоб берадилар». 
            </p>
             </div>
            
             <div className='paragraph_heading'>
            <p> Сабаъ маликасининг ташрифи </p>
          </div>
     
          <div className='main_text'>
            <p>
            Сулаймон (а.с.) нинг шону шуҳрати, Оллоҳ таолодан олган ҳикмати Сабаъ ма­ли­ка­си­нинг қулоғига ҳам етиб келди. Шунда у бориб, ҳазрати Сулаймонни қийин са­вол­лар билан синаб кўрмоқчи бўлди. Ҳар хил зираворлар, кўп миқдорда олтин ва қим­матбаҳо тошлар ортилган катта бир карвон билан Байт-ул-Муқаддасга ташриф буюрди ва ўйидаги ҳамма мавзулар ҳақида саволлар берди. 
            </p>
            <p>
            Сулаймон (а.с.) унинг ҳамма саволларига жа­воб бердилар. Ҳеч бир масала у киши учун қийин эмас эди. Сулаймон подшоҳнинг ҳикматини, қурдирган қасрини, шоҳона дас­турхонини, аркони давлатининг гўзал либослар билан ўтириб-туришини, амал­дор ва соқийларининг муназзам хиз­ма­ти­ни, Байтуллоҳда қилинаётган қур­бон­лик­лар­ни кўздан кечириб лол қолган ма­ли­ка подшоҳга деди: 
            </p>
            <p>
              «Сиз ҳақингизда эшитганларим ҳаммаси рост экан! Кўзим билан кўрмаганим учун ишонмаган эдим. Буларнинг ҳатто ярмини ҳам менга айтмаган эканлар. Ҳикматингиз ҳам, бойлигингиз ҳам мен эшитганимдан минг марта кўп экан! Донолигингиздан баҳ­ра­манд бўлган халқ, хизматингизни қи­ла­ёт­ган амалдорлар қандай бахтли! Сизни тахт­га ўтқазган худойингиз Оллоҳ­га ҳамду сано­лар бўлсин! Бу халқни сев­га­ни учун, адо­лат ва тўғриликни таъмин қилишингиз учун Ул зот сизни подшоҳ қилиб тайин эт­ган экан», деди. 
              </p> 
          </div>
          
          <div className='paragraph_heading'>
            <p> Хотима </p>
          </div>
     
          <div className='main_text'>
            <p>
            Ҳазрати Сулаймон дарҳақиқат дунёдаги ҳамма подшоҳлардан бойроқ ва донороқ эдилар. Ҳамма миллат вакиллари Худо у кишига ато этган ҳикматни эшитиш учун Байт-ул-Муқаддасга келар эдилар.
            </p>
            <p>
            Йиллар давомида одам узилмас, келганларнинг ҳаммаси тилла ва кумуш буюмлар, ҳар хил либослар, зираворлар, қурол-яроғ ва уловлар каби ҳадялар кўтариб, у киши­нинг ҳузурига киришни орзу қилар эдилар. 
            </p>
            <p>
            Ўша даврда Байт-ул-Муқаддасда кумуш де­ган нарса чўл қумидай кўп эди, қиммат­ба­ҳо садр ёғочи эса оддий ёғочдек масраф қи­линган.
            </p>
            <p> Сулаймон (а.с.) қирқ йил Байт-ул-Муқаддас­да подшоҳлик қилдилар. Ўлган­ла­ридан сўнг у кишини отаси Довуд шаҳрида дафн этдилар.
            </p>
          </div>

            
        

          {/* <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[9999] ? 'block' : 'none' }}
              onClick={() => handleSupClick(9999)}
            >
              <p> <sup>9999</sup> YYYYYYY
              </p>
            </div>
            <p onClick={() => handleSupClick(9999)} >
               <sup>9999</sup> XXXXXXXXXXX
            </p>
            <p></p>
          </div> */}

          <BottomNav 
            labelLeft='Тилак' 
            pathLeft='/public-resources/qisas-uzbek/sulaymanAsksWisdom'

            labelCenter='ал-Ақсо масжидининг қурилиши'
            pathCenter='/public-resources/qisas-uzbek/aqsa'

            labelRight='Юнус (а.с.)'
             pathRight='/public-resources/qisas-uzbek/yunus'
          />  

        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default Aqsa;