import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const SomuelsDeath = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>
                    Сомуил (а.с.) нинг вафоти  
                  </p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

                  <div className='main_text'>
                    <p> 
                    Сомуил пайғамбар оламдан ўтди. Бутун Исроил халқи йиғилиб, аза тутдилар. У кишини ўз она шаҳри Рамада дафн қилдилар.
                    </p>
                  </div>

                <div className='paragraph_heading'>
                    <p> Довуд (а.с.) Толутнинг қароргоҳида  </p>  
                </div>

            
          <div className='main_text'>
            <p> Орадан бир қанча вақт ўтди. Кунлардан бир куни, зифликлардан бир нечта одам Гива шаҳрига, Толут подшоҳнинг олдига келиб: «Довуд ҳозир Яшмонда, Ҳохило те­па­ли­гида яшириниб юрибди», деб хабар бер­ди­лар. 
            </p>
            <p>
            Толут, лашкаридан энг сара уч минг ода­мини олиб, Довуд алайҳис-саломни уш­лаш учун Зиф даштига борди ва Ҳохила тепа­лигида, йўл бўйида қароргоҳ қурди.
            </p>
            <p>
            Довуд (а.с.) ўша пайтда даштда эдилар. “Толут сизни излаб келибди”, деган гапни эшитгач, аввал айғоқчиларини юбордилар. Ишонч ҳосил қилганларидан сўнг, ўзла­ри кечаси Толутнинг қароргоҳига келдилар. 
            </p>
            <p> Толут билан лашкарбошиси Абнур қа­рор­гоҳ­нинг ўртасида, йигитлари эса атро­фи­да, ҳаммалари ухлаб ётган эдилар. Довуд (а.с.) Ахималик билан Абушайга: «Ким мен билан Толутнинг олдига боради?» деб сўрадилар.
            </p>
            <p></p>
          </div>


          <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p> <sup>1</sup> Сурайё Биби Довуд (а.с.) нинг опаси бўлган.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
               (Ахималик – ҳиттий эди. Абу­шай эса Сурайё Бибининг<sup>1</sup>  ўғли, Ёваб­нинг акаси эди.) 
            </p>
            <p>
            «Мен бораман», деди Абушай. 
            </p>
            <p>
            Қароргоҳга кирдилар. Толут найзасини бош томонга ерга қадаб, қароргоҳ ўртасида ухлаб ётар эди. Абнур билан йигитлари ҳам уйқуда эдилар. 
            </p>
            <p>
            «Мана, Оллоҳ душманингизни қў­лин­гизга берди, – деди Абушай Довуд (а.с.) га. – Хўп десангиз, ўзининг найзаси билан бир уриб ерга михлаб қўяман!» 
            </p>
            <p> «Йўқ, – дедилар ҳазрати Довуд шивирлаб, – Оллоҳ масҳ қилган подшоҳга қўл кў­та­риб гуноҳкор бўлмайлик. Худо шоҳид! То­лут­нинг жонини Худо олади, ажали ке­либ ё тўшакда, ё жангда ўлади. Лекин Раб­би­миз танлаган подшоҳга қўл кўтаришдан Ўзи мени сақласин! Юр, Толутнинг найзаси би­лан кўзасини олиб кетамиз».
            </p>
          </div>
          
          <div className='paragraph_heading'>
            <p> Толутнинг иккинчи марта тавба қилгани
            </p>
          </div>
     
          <div className='main_text'>
            <p>
            Найза билан сувкўзани олиб, қароргоҳ­дан чиқиб кетдилар. Ҳамма ухлаб ётган эди, ҳеч ким уйғонгани йўқ. Оллоҳ таоло уларни қаттиқ уйқуга солган эди. 
            </p>
            <p>
            Довуд (а.с.) йигитлари билан даранинг нариги та­ра­фига ўтиб, бир тепаликнинг устига чиқ­ди­лар. Ўртада бехатар бир масофа бор эди. Ўша ердан туриб: «Абнур, Абнур!» деб чақирдилар. 
            </p>
            <p>
            Абнур уйғониб: «Ким ўзи бу, подшоҳни безовта қиладиган?!» деди. 
            </p>
            <p>
           «Сен қандай одамсан, Абнур? – дедилар Довуд (а.с.). – Исроилнинг энг ишончли йигити сен эмас­ми? Нега унда подшоҳингни қў­риқ­ла­ма­динг? Бугун биров у кишини ўл­дир­моқчи бўлиб, қароргоҳга кирди, сен бўл­санг йи­гит­ларинг билан ухлаб ётибсан! Худо ҳақи, ҳам­мангизни ўлимга ҳукм қи­лиш керак, Оллоҳ танлаган подшоҳни муҳофаза қил­ма­динглар. Қара-чи, под­шоҳ­нинг бош та­ра­фи­да турган найзаси билан кўзаси қа­ер­да экан?»
            </p>
            <p>
            Подшоҳ, Довуд (а.с.) нинг овозини таниб: «Довуд, ўғлим, бу сенмисан?» деб сўради. 
            </p>
            <p>
            «Ҳа, подшоҳим, менман, – деб жавоб бер­ди­лар у киши. – Мен сизга нима гуноҳ қилдим? Нега мени таъқиб қиляпсиз? Агар бу иш Худодан бўлса, тавба қилай. Гу­но­ҳимни кечириши учун қурбонлик сўяй. Аммо агар бу иш одамлардан бўлса, Худо уларни лаънатласин. Мен энди кофирлар би­лан яшайми? Бегона юртларда ўлиб ке­тай­ми? Таъқиб қилишингизга ар­зий­ман­ми ўзи?» 
            </p>
            <p>
            Бу гаплар подшоҳга қаттиқ таъсир қилди: «Довуд, ўғлим, мени кечир. Мен сенинг олдингда гуноҳкорман. Сен учун менинг ҳаётим азиз экан, мен қаттиқ хато қи­либ­ман. Аҳмақ эканман. Юр, энди ватанингга қайт. Мен сенга ёмонлик қилмайман». 
            </p>
            <p>
            «Мана найзангиз, йигитларингиз келиб олсин, – дедилар Довуд (а.с.), – Оллоҳ ҳар бир одамга тўғрилиги ва садоқатига қараб ажр беради. Бугун менда имконият бор эди, лекин мен сизни ўлдиришни хоҳ­ла­мадим. Ҳаётингизни сақлаб қолдим. Оллоҳ ҳам менинг ҳаётимни сақласин, бало-қазолардан қутқарсин». 
            </p>
            <p> «Довуд, ўғлим, Оллоҳ сени ёрлақасин, сен катта ишлар қиласан, катта ютуқларга эришасан», деди подшоҳ. Шундан сўнг Довуд (а.с.) ўз йўлига, подшоҳ эса уйига қайтди.
            </p>
          </div>
         
          <div className='paragraph_heading'>
            <p> Довуд  филистийлар орасида </p>
          </div>
     
          <div className='main_text'>
            <p>
            Ҳазрати Довуд: «Подшоҳ мени барибир бир кун эмас-бир кун ўлдиради, – деб ўйлар эди. – Ундан кўра филистийлар юртига қочганим яхши. Шунда мени таъқиб қилишдан тўхтайди». 
            </p>
            <p>
            Шундай қилиб Довуд (а.с.) ва у киши билан бирга олти юз эркак, оилалари билан бирга Жатга, амир Ахишнинг ерига бордилар. Довуд пайғамбарнинг икки хотини – язареллик Ахиноам ва Наволнинг беваси кармиллик Абижайл ҳам Довуд  билан бирга эдилар. Дарҳақиқат, шундан сўнг Толут Довуд (а.с.) ни таъқиб қилмай қўйди. 
            </p>
            <p>
            Жат амири уларга Сиқлағ шаҳрида яшашга рухсат берди ва Довуд (а.с.) филистийлар юртида бир йилу тўрт ой истиқомат қилдилар.
            </p>
            <p>
            Ахиш Довуд (а.с.) га ишонч қўйган, Довуд ўз халқи Бани Исроилга ўзини душ­ман қилиб қўйди, энди тоабад менинг хизматимда бўлади, деб ўйлар эди.
            </p>
            <p>  Шу кунларнинг бирида филистийлар Бани Исроил билан жанг қилиш учун лаш­кар тўплайди. Шунда Ахиш Довуд (а.с.) га: «Йи­гитларинг билан биз тарафда жанг қиласан», деб таъкидлади. 
            </p>
            <p>
            «Албатта, – дедилар ҳазрати Довуд. – Каминанинг ни­ма­ларга қодир эканини кўрасиз».
            </p>
            <p>
            «Яхши, – деди Ахиш, – сен йигитларинг билан мени қўриқлайсан».
            </p>
           </div>

          <BottomNav 
            labelLeft='Қочқинликда' 
            pathLeft='/public-resources/qisas-uzbek/davidOnRun'

            labelCenter='Сомуил (а.с.) нинг вафоти'
            pathCenter='/public-resources/qisas-uzbek/somuelsDeath'

            labelRight='Толутнинг ўлими'
             pathRight='/public-resources/qisas-uzbek/tolutsDeath'
          />     
        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default SomuelsDeath;