import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import kamar from '../../images/kamar.png';
import BottomNav from './components/BottomNav';

const Deborah = () => {
    const navigate = useNavigate();
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading'>
                    <div className="kamar">
                        <img src={kamar} alt="" />
                    </div>
                    <div className="img_container" style={{ padding: '11px' }}>

                    <svg fill='rgb(175, 157, 0)'  id="_Слой_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1417.3 1417.3">

<path d="M1246.8,603.9c2.8-2.3,1.9-10.2,1.5-13.8-2.3-20.4-22.9-67-31.5-88.3-2.4-6-16.1-47.9-23.8-42.2-2.3,1.7-18.6,48.7-18.6,53.8,0,10.4,8.2,32.2,9.3,46.1,9.7,124.8,20.6,262.6,24.8,387.1.7,19.6,2.9,84-3.9,97.8-13.5,27.5-67.7,31.5-93.7,21.3-40.9-16.1-27.3-11.1-8.6-46.7,18.7-35.6,23.1-75.9,9.1-113.7-8.9-23.9-32-65-62.8-47.4-42.8,24.4-39.4,123.5-24.8,164.1-31.9,8.2-64.1,29.9-95.3,38.5-4.2,1.2-12.1,3.6-15.4,0,40.6-40.2,67.1-95.5,26.2-146-77.6-95.8-167.7-32.6-221.3,46.4-10.7,15.8-61.9,114.5-68.4,116.4-8.7,2.5-30.7-20.1-33.3-27.6-2.1-6-1.9-10.6-1.9-16.8s6.4-24.4-1.4-26c-3.1-.6-44.1,31.4-52.8,36.3-32.3,18.2-113.6,48-140.7,12.7-23.2-30.3-18.1-70.7-20-106-3.8-72.5-6-145.6-9.3-218.1-4.3-91.7-.4-190.9-41.6-275-6.1-.1-19.4,43.3-19.4,50.6,0,13.1,13.1,48.8,16.4,66,25.2,133.4,12.7,280.6,23,416.5,4.3,56.3,8.2,136,79.2,145.2,54.6,7.1,107.3-35.8,145.3-69.9,1.4,21.3,6,38.9,20,55.3,3.9,4.6,16,6,17.2,14.3,1.2,8.3-11.5,31.7-6.4,44.1v-.3h3.1l20.3-36.5c56.8,19.3,121.8,11.7,180.2,1.7,75.1-12.8,143.9-43.2,213.4-72.9l4,.5c53.5,80.8,138.4,93.7,178.9-8,40.4-101.3,3.3-321.1-3.1-436.5-.8-14.4,2.2-29.1-3.2-42.9,11.2-2.1,18.2,16.4,29.3,19.9ZM723.8,1095.9c-7.7,0-45.5-4.7-50.6-7.8-5.1-3.1-1.5-3.8-.5-6.6,3.5-9.8,13.8-26.2,19.6-35.8,24.9-41.6,73.2-110.8,124.8-118.1h-.2c26-3.6,52.7,5.6,74.3,19.5,10.9,7,44.9,35.3,42.9,48.2-3.5,22.4-80.7,64.1-102,73.4-30.1,13.1-75.6,27.1-108.3,27.2ZM1053.3,1006.5c-13.1-18.8-29.6-47-21.7-70.3,2.2-6.5,14.9-20.1,22.1-21.2,17.2-2.6,49.3,39.5,49.5,53.9.2,14.4-43.4,39.1-49.9,37.6Z"/>
<path d="M308.7,687c-29.9,0-88.5,89.2-106.2,113.9-2.8,3.8-14.4,24.5-16.9,24.5l-16.9-21.4,30.8-3.1h-.3c-16.5-14.9-46.6-39.4-66.7-48.6-3.4-1.5-6-5.3-10.3-2.3-7,4.9-11.2,42.7-8.4,54.9s43.9,46.6,41.8,58.5c-3.5,20.1-96.6,91-113.2,111.2,5.2,3,14.4-2.9,19.5-5.1,43.8-18.7,76.1-51.2,106.4-86.7,4.9-5.7,24.5,21.1,24.3,23.6-.5,6.4-32,53.3-37.8,66.8-22.9,53.2-40.9,168.9,53.7,130.5,61.8-25.1,87.1-75.1,79.2-140.5-4.4-36.3-27.2-56.7-42.3-86.7,26.5-44.5,68.2-97.8,74-150.8,1.4-12.5,19.2-38.7-10.7-38.7ZM272.7,998.5c-3.3,12.7-48.1,38.9-61.2,43.6-15.9,5.8-56.7,17.1-58.3-9.4-.9-14.4,48.8-102.5,60.5-105.5h0c4.3-1.1,5.6.5,8.7,2.6,10.1,6.8,52.9,58.4,50.2,68.7ZM271.4,788.3c-9.7,17.1-27.7,47.1-40,61.4-2.3,2.7-6.2,9.6-10.5,8.1-7.9-2.8-19.7-12.9-16.2-22.2s59.5-77.4,70.4-83.6c4.3-2.5,8.2-4.3,13.4-3.5h0c-.2,15.3-10.1,27.3-17.2,39.8Z"/>
<path d="M1374.4,579.6c-14.3,1.1-19.4-16-30.7-21.5l27.6,376.5c2.1,64.4-.4,131.5-32.3,189-4.9-3.5-4.9-8.2-4.8-13.6.5-17.4,6.2-41,6.4-58.4,1.8-130.3-19.1-280.1-27.8-412-2.1-31.9-.6-66.9-3.1-98.3-1.3-15.5-9.8-39.2-9.3-52.2.6-16.3,14.4-37.9,18.6-53.9,1.3-1,3.3,1.8,4.5,3.2,9,11,29.5,70.2,35.6,87.4,2.7,7.7,18.3,50.8,15.3,53.8h0Z"/>
<path d="M900.7,444.4c1.6,1.5-12.4,37-17.7,39.9-116.1,28.1-231.6,62.3-341.2,110.1-41.2,18-80.4,39.7-120.9,58.9-2.7-2.8,11.5-44.1,16.8-49.3,10-9.9,60.8-33.1,76.5-40.3,89.9-41.2,194.1-72.6,289.9-97.6,12.4-3.2,92.9-25,96.6-21.7h0Z"/>
<path d="M1085.6,473.1c-1.3-4.4,8.6-35.1,9-45.5,2.7-63.2-48-43.7-73.4-8.8-29.2,40.1-12.6,64.9,27.1,85.5-17.6,18.5-38.3,34-59.3,48.4-45.1,31-102.1,54.8-144.7,85.9-4.2,3.1-9.4,6.6-11.3,11.7,86.2-22.3,179.2-64.1,230.8-138.3l29,18.4c41.2-42.3-3.8-45.7-7.2-57.3ZM1066.8,462.8c-3.3,2.3-37.4-18.3-32.5-27.7,4.9-9.4,15.1-10.3,19.9-10.8,16.4-1.5,17.7,27.3,12.6,38.5Z"/>
<path d="M1051.6,705.7c5.1-3.6,53,28.3,54.8,35.9,1.6,6.6-16.5,42.8-21,45.7-7.4,4.8-40-27.4-50.6-29.4-6.5,1.4-13.6,35-24.2,33.4-2.8-.4-45.5-30.9-46.9-33.4-3-5.5,16.2-45.3,20.1-47.8,8.5-5.5,46.4,30.4,50.7,29.4l17.1-33.7h0Z"/>
<path d="M214.9,579.7c5.1-3.6,53,28.3,54.8,35.9s-16.5,42.8-21,45.7c-7.4,4.8-40-27.4-50.6-29.4-6.5,1.4-13.6,35-24.2,33.4-2.8-.4-45.5-30.9-46.9-33.4-3-5.5,16.2-45.3,20.1-47.8,7.5-4.8,41.2,26.1,50.7,29.4l17.1-33.7h0Z"/>
<path d="M630,481.2c2.4-13.1-16.2-70.6-26.8-73.2-7.5-1.8-8.3,5.4-20.9-3.6-8.6-6.1-12.3-11-9.4-21.5,10.4-38.2,39.9-12,51.5,10.3l14.8,45.1c6.3-21.6,12-99,47.1-88.5,23.8,7.1,16.3,63.3-11.7,24.1-15.3,0-24.6,80.8-30.8,95.2-2.4,5.6-6.6,13.1-13.8,12.3v-.2Z"/>
<path d="M710,788.5l-55.1-35.6,21.4-47.2c5.1-3.6,52.9,28.3,54.8,35.9s-18.7,36.1-21.2,47h0Z"/>
<path d="M651.5,309.1c0,11.8-6.4,37-16.7,43.2-48.1,29.1-27.7-98.1-6.4-113.9,2.4,17.1-8.6,34-10.4,51-4,36.3,7.8,54.4,33.5,19.7h0Z"/>
</svg>

                    </div>
                    <div className='title_of_chapter'>
                        <p> Бани Исроил тарихида</p>
                        <p> ҳакамлар даври </p>
                    </div>
                  </div>

                <div className='paragraph_heading'>
                    <p> Дувора алайҳас-салом қиссаси  </p>  
                </div>

                  <div className='main_text'>
                    <p>
                        Кунлардан бир кун Оллоҳ таоло Бани Исроилни, қилган ёмон ишлари учун Канъон шоҳи Ёбиннинг қўлига топширади.
                    </p>

                    <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
                    onClick={() => handleSupClick(1)}
                    >
                    <p >
                        <sup>1</sup> Сисаро Хароши-­Хаимда
                        яшар эди.
                    </p>
                    </div>
                    <p onClick={() => handleSupClick(1)} >

                    Ёбиннинг лашкарбошиси Сисаро<sup>1</sup> тўққиз
                        юзта жанг араваси билан ҳужум қилиб,
                        Бани Исроилни мағлуб қилади ва йигирма
                        йил мобайнида уларга зулм ўтказади.
                        Қийналиб кетган халқ, ниҳоят, Оллоҳга
                        оҳу нола қила бошлайди.
                    </p>
                    <p>
                    Ўша пайтда Бани Исроилга Лапидонинг аёли Дувора ҳакамлик қилар эди. У Афройимда, Рама билан Байтил орасида ўсган хурмо тагида халқни қабул қилар, жанжалу баҳсларини ажрим этар эди. 
                    </p>
                    <p></p>
                </div>

            <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p >
                <sup>2</sup>  Бароқ ибн Абуноам Нафтоли
                қабиласидан эди.
              </p>
              <p> <sup>3</sup> Ислом уламолари ичида аёллардан пайғамбар чиққан-­чиқмаганлиги ихтилофли масала. Масалан, имом Қуртубий Ҳаво онамизни, Иброҳимнинг (а.с.) аёллари Ҳожар Бибини ва Марям онамизни пайғамбар деб ҳисоблаган.
              </p>
              <p>
              Аёлларга ваҳий берилгани ҳақида Қуръони каримнинг Қасас сурасида оят бор (28:7).
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
              Бир куни у Бароқ<sup>2</sup> ибн Абуноамни чақириб деди: «Оллоҳ менга ваҳий берди<sup>3</sup>, сен Нафтоли билан Завулун йигитларидан ўн мингтасини олиб, Тавор тоғига борар экансан. Оллоҳ Сисарони жанговар араваларию лашкари билан бирга ўша ерга келтириб, сенинг қўлингга таслим қилар экан», деди.
            </p>
            <p></p>
          </div>
          <div className='main_text'>
            <p> Бароқ эса: «Агар сен мен билан бирга борсанг бораман, бўлмаса йўқ», деб жавоб беради.  </p>
            <p>
            «Яхши, – дедилар Дувора (а.с.). – Лекин айтиб қўяй, энди ғалабанинг шону шуҳрати сенга насиб этмайди. Оллоҳ Сисарони хотин кишининг қўлига таслим қилади», деди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p> <sup>4</sup>  Ўша пайтда Кенит халқидан бўлмиш Хавор деган бир одам ўз уруғидан ажралиб, Кедеш яқинида, Зананимдаги эман дарахти олдида чодир урган эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
               Шундай қилиб Дувора (а.с.) Бароқ ибн Абуноам билан бирга Завулун ва Нафтолидан ўн минг йигитни қуроллантириб, Тавор тоғига бордилар.<sup>4</sup> 
            </p>
            <p>
            Сисаро уларнинг Таворга келганини эшитиб, ўзининг тўққиз юз зирҳли жанг аравасини ҳамда Хароши-­Хаимдаги ҳамма одамларини олиб Кишон дарёсига келади.
            </p>
            <p>
            «Олға, – дедилар Дувора (а.с.) Бароққа. – Бугун Оллоҳ таоло душманларингни қўлингга таслим қилади, Оллоҳнинг Ўзи олдингда боради!»
            </p>
            <p>
            Бароқ ўн минг жангчиси билан тепадан пастга – канъонликларга қараб ҳужум қилди. Ўша пайт, Оллоҳнинг қудрати билан Сисаро ҳам, унинг отлиғу пиёда аскарлари ҳам саросимага тушиб қолдилар. Сисаро аравасини ташлаб, пиёда қочди. Лашкарлари ҳам орқага қараб қоча бошладилар. Бани Исроил уларни Хароши­-Хаимгача қувлаб, йўл-­йўлакай ҳаммаларини қиличдан ўтказдилар.
            </p>
            <p>
            Сисаро эса қочиб бориб, кенитлик Хавор яшайдиган жойга келди. (Хавор билан канъонликлар орасида тинчлик битими имзоланган эди.)
            </p>
            <p>
            Хаворнинг хотини Яйил Сисарога пешвоз чиқиб: «Ҳазратим, келинг, чодирга киринг, қўрқманг», деди. Чодирга киргач устини чойшаб билан ёпиб қўйди. 
            </p>
            <p>
            «Менга озгина сув бер, – деди Сисаро. – Чанқадим». 
            </p>
            <p>
            Аёл сут солинган мешнинг оғзини ечиб, унга сут ичирди-­да яна устини чойшаб билан ёпди.
            </p>
            <p>
            «Сен ташқарида ўтир, – деди Сисаро. – Агар биров келиб, ичкарида одам борми, деб сўраса, йўқ, де». 
            </p>
            <p>
            Лашкарбоши қаттиқ чарчаган эди. Ҳадемай кўзларини уйқу босиб, ухлаб қолди. Яйил буни кўриб, қўлига болға билан чодирга қоқадиган қозиқ олди­да, Сисаронинг чаккасига қўйиб, зарб билан туширди. Қозиқ унинг бошини тешиб, ерга кириб кетди. Ётган жойида у дунёга равона бўлди.
            </p>
            <p>
            Бу пайтда Бароқ ибн Абуноам Сисаронинг кетидан қувиб излаб келаётган эди. Яйил чодирдан чиқиши билан Бароққа дуч келди.
            </p>
            <p> «Сисарони излаяпсизми? – деди унга. – Чодирга киринг, топасиз». </p>
            <p>Бароқ кириб қараса, Сисаро чодирнинг ичида чаккасига қозиқ қоқилганча ўлиб ётган эди. </p>
            <p>
            Шундай қилиб ўша куни Худо, Бани Исроилга подшоҳ Ёбиннинг лашкари устидан ғалаба ато этди.
            </p>
            <p></p>
          </div>

          <BottomNav 
            labelLeft='Танланг' 
            pathLeft='/public-resources/qisas-uzbek/chooseWhoToServe'

            labelCenter='Ҳакамлар даври'
            pathCenter='/public-resources/qisas-uzbek/deborah'

            labelRight='Ёффай (р.а.)'
            pathRight='/public-resources/qisas-uzbek/jephthah'
          />

            
        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default Deborah;