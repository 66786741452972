import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const IsTolutAlsoAmong = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>Толут ҳам пайғамбарми?</p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

                <div className='paragraph_heading'>
                    <p> Пайғамбарлар сафида зикр тушгани  </p>  
                </div>

            
          <div className='main_text'>
            <p> 
            Сомуил (а.с.) нинг ҳамма айтганлари ўша куни амалга ошди. Оллоҳ таоло Толутни янги одамга айлантирди. Гивага келиши билан пай­ғамбарлар гуруҳига дуч келдилар ва Толут ҳам уларга қўшилиб зикр туша бошлади. 
            </p>
            <p>
            Уни танийдиган одамлар ҳайрон бўлиб: «Толутга нима бўлди? Наҳотки Қайснинг ўғли ҳам пайғамбар бўлса?» дер эдилар. 
            </p>
            <p>
            «Нима қилибди? – деди ўша ерлик бир одам. – Пайғамбарларнинг отаси ким?» 
            </p>
            <p>
            Шу-шу халқ орасида, наҳотки Толут ҳам пай­ғамбар бўлса, деган гап мақолга ай­ла­ниб қолган.
            </p>
            <p>
              Зикр тушишдан тўхтагач, Толут қурбонгоҳ жойлашган тепаликка чиқди. Кейин уйига борди. Уйга келгач, амакиси: «Қаерларга бординг?» деб савол берди. 
            </p>
            <p></p>
          </div>

          <div className='main_text'>
          <p>
            —Эшакларни топмай Сомуил ҳазрат­ла­ри­нинг олдига борувдик, — деди у. 
            </p>
          </div>
          
          <div className='main_text'>
            <p>
            —У киши нима деди? — деб сўради амакиси. 
            </p>
          </div>
          <div className='main_text'>
            <p>
            —Эшак­ла­ринг топилди, деди, — деб жавоб бер­ди Толут. Подшоҳлик ҳақидаги сўзлар тўғ­ри­си­да ҳеч нарса демади.
            </p>
          </div>
          
          <div className='paragraph_heading'>
            <p> ТОЛУТНИНГ ПОДШОҲЛИККА ТАЙИНЛАНИШИ </p>  
          </div>
          
          <div className='main_text'>
            <p>
            Сўнгра Сомуил (а.с.) Исроил халқини Мис­фага, Худонинг ҳузурига йиғиб дедилар: 
            </p>
            <p>
            «Бизнинг эгамиз – Оллоҳ бундай дейди: 
            </p>
            <p>
            “Мен сизларни Мисрдан олиб чиқдим, миср­ликлардан ҳам, сизларга зулм қилган бошқа халқлардан ҳам қутқардим. Аммо бугун сизлар Мендан-нажоткорингиздан юз ўгирдингиз, бизни подшоҳ бошқарсин, де­дин­гиз. Энди қабила-қабила ва уруғ-уруғ бўлиб Менинг ҳузуримда саф тор­тинг­лар”». 
            </p>
            <p>
            Кейин Сомуил (а.с.) ҳар битта қабилани бир­ма-бир чақирдилар. Қуръа Бинямин қа­би­ла­сига тушди. 
            </p>
            <p>
            Сўнгра Бинямин қабиласидаги уруғлар учун қуръа ташланди. Қуръа Матрининг уруғига, ундан кейин эса Қайс ўғли Толутнинг отига тушди. Бироқ Толутнинг ўзи ҳеч қаерда йўқ эди. 
            </p>
            <p>
            Шунда Оллоҳдан: «У қаерда?» деб сў­ра­ди­лар. «Шу ерда, юклар орасида яшириниб ўти­рибди», деди Оллоҳ азза ва жалл. Одам­лар югуриб бориб, Толутни олиб кел­ди­лар. У кўпчилик ичида алоҳида ажралиб ту­рар, бўйи бошқалардан бир бош баланд эди.
            </p>
            <p>
            «Кўряпсизларми? Мана, Оллоҳ сизлар учун сайлаган подшоҳ! – дедилар Сомуил (а.с.). – Бутун Исроил ичида унга тенг ке­ла­ди­гани йўқ!»
            </p>
            <p>
            Халқ: «Яшасин подшоҳ!» деб ҳайқирди. 
            </p>
            <p>
            Сомуил  яна бир бора халққа под­шоҳ­нинг ҳуқуқ ва вазифаларини тушун­тир­ди­лар, кейин уларни китобга ёзиб, Оллоҳ­нинг ҳузурига қўйдилар. 
            </p>
            <p> Сўнгра одамлар тарқалди. Толут ҳам Гивага, ўз уйи­га қайтди. Худодан руҳланган бир гуруҳ жангчи йигитлар унга ҳамроҳ бўлдилар. 
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p> <sup>1</sup>«Уларнинг набийи уларга: «Албатта, Оллоҳ Толутни сиз­га подшоҳ қилиб та­йин­ла­ди», деди. Улар: «У бизга қаёқдан подшоҳ бўл­син. Ун­дан кўра подшоҳликка биз ҳақлироқмиз. Қо­ла­вер­са, унга кўпроқ мол ҳам бе­рил­ма­ган» дедилар. У: «Ал­бат­та, уни Оллоҳнинг Ўзи сиз­лар­га танлади ва илм­да, жисм­да кенглиги­ни зиёда қил­ди. Оллоҳ под­шоҳ­ли­ги­ни кимга хоҳласа, ўшанга бе­ра­ди. Ва Оллоҳ кенг қам­ровли ва билувчидир», деди» (Бақара сураси 247).
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
               Баъзи ёмон одамлар эса: «У бизни қандай қутқаради?» деб, унга таҳқир кўзи билан қарадилар,  ҳадя бермадилар.<sup>1</sup> Бироқ Толут уларга эътино қилмади.
            </p>
          </div>
          
          <div className='paragraph_heading'>
                    <p> ҲЎКИЗЛАРНИНГ ЧОПИЛИШИ
                    </p>  
          </div>
          
          <div className='main_text'>
            <p>
            Бир ойлардан сўнг амўний Наҳаш лашкар тортиб келиб, Жилъоднинг Ёбис шаҳрини қамал қилди. 
            </p>
            <p>
            Ёбисликлар унга: «Биз билан сулҳ тузинг, биз сизга хизмат қиламиз», деб илтимос қилдилар. 
            </p>
            <p>
           Наҳаш эса: «Яхши, сулҳ тузсам-туза қолай, лекин олдин ҳар бирингизнинг ўнг кўзингизни ўйиб, бутун Исроилни шарманда қиламан», деди. 
            </p>
            <p>
            Ёбис оқсоқоллари: «Бизга етти кун муҳлат беринг, Исроилнинг ҳамма ерига элчи юборамиз. Ҳеч ким ёрдамга келмаса, етти кундан кейин олдингизга чиқиб, таслим бў­ла­миз», дедилар. 
            </p>
            <p> Сўнгра ҳар тарафга элчи жўнатиб, амўнийлар қўйган шартни Бани Исроилга овоза қилишди.
            </p>
            <p> Бу хабар Гивага ҳам етиб келиб, шаҳарда йиғи-сиғи бошланди. Толут Гивада яшар, ўша пайтда ҳўкизларини даладан ҳайдаб келаётган эди. Узоқдан йиғи товушини эшитиб: «Нима гап, нима бўлди?» деб сў­ра­ди. Шумхабарни эшитгач, қаттиқ ға­заб­лан­ди. Ҳайдаб келаётган иккала ҳў­ки­зи­ни чо­пиб ўлдирди. Чунки Оллоҳ унинг қал­би­ни жунбушга келтирган эди. 
            </p>
            <p>
            Ҳўкизларини нимта-нимта қилиб элчиларнинг қўлига берди-да: «Исроилнинг ўн икки қабиласига етказинглар, кимда-ким Толут ва Сомуилнинг орқасидан эргашмаса ҳўкизларини чопиб ташлайман», деди. 
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> Халқнинг бирлашиши </p>  
          </div>
          
          <div className='main_text'>
            <p>
            Худодан қўрқув келиб, бутун халқ бирга­ликда – Исроилдан уч юзта гуруҳ, Яҳу­зиядан ўттизта гуруҳ – Вазакка тўп­лан­ди. 
            </p>
            <p>
            Кейин Толут ёбислик элчилар­га: «Бориб айтинглар, эртага қуёш қиз­дир­ган вақт­да душманлардан қутуласиз­лар», деди.
            </p>
            <p>
            Элчилар Ёбисга қайтиб келиб, Толутнинг гапини халққа етказдилар. 
            </p>
            <p>
            Ҳамманинг ичи­га хурсандчилик кирди. Кейин амў­ний­лар­га хабар юбориб: «Эртага олдингиз­га чиқамиз, бизга нимаики қилишни хоҳ­ла­сан­гиз­лар қиласизлар», дейишди.
            </p>
          </div>

          <div className='paragraph_heading'>
                    <p> АМЎНИЙЛАР УСТИДАН ҒАЛАБА </p>  
          </div>
          
          <div className='main_text'>
            <p>
            Толут кечаси йигитларини уч гуруҳга бў­либ, тонг саҳарда амўнийлар қарор­го­ҳи­га ҳужум қилди. 
            </p>
            <p>
            Жанг пешингача давом этди. Тирик қолган амўнийлар жон сақлаш учун якка-якка ҳар томонга қараб қочиб кетдилар.
            </p>
            <p>
              Кейин халқ: «Қани ўша, энди бизга Толут подшоҳ бўлар эканми, деган одамлар? Биз уларни ўлдирамиз», деб Сомуил пай­ғам­бар­нинг олдига келди.
            </p>
            <p>
            Бироқ Толут: «Бугун ҳеч кимни ўлдир­май­миз, бугун Оллоҳ бизни душ­ман­ла­ри­миз­дан қутқарди», деб уларга рухсат бермади. 
            </p>
            <p>
            Сомуил (а.с.): «Кетдик, Гилгалга бориб, То­лут­нинг подшоҳ бўлга­нини тас­диқ­лай­миз», дедилар. 
            </p>
            <p>
            Ҳамма Гилгал­га келиб, Оллоҳ олдида Толутни под­шоҳ деб тан олдилар. Қур­бон­лик­лар сўйиб, бутун Бани Исроил Толут билан бирга байрам қилди.
            </p>
          </div>
          



          {/* <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[9999] ? 'block' : 'none' }}
              onClick={() => handleSupClick(9999)}
            >
              <p> <sup>3</sup> YYYYYYY
              </p>
            </div>
            <p onClick={() => handleSupClick(9999)} >
               <sup>9999</sup> XXXXXXXXXXX
            </p>
            <p></p>
          </div> */}

<BottomNav 
            labelLeft='Хонлик даври' 
            pathLeft='/public-resources/qisas-uzbek/monarchyStarted'

            labelCenter='Толут ҳам набими?'
            pathCenter='/public-resources/qisas-uzbek/isTolutAlsoAmong'

            labelRight='Хонлик шартлари'
            pathRight='/public-resources/qisas-uzbek/monarchyConditions'
          />


            {/* // labelRight='(davomi bor)'
            // pathRight='/public-resources/qisas-uzbek/tobe_continued' */}

        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default IsTolutAlsoAmong;