import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const ChooseWhoToServe = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 6;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>
                   КИМГА ХИЗМАТ ҚИЛАСИЗ?
                  </p>
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>


                     {/* <div className='paragraph_heading'>
                        <p>ТАНЛАНГ: КИМГА ХИЗМАТ ҚИЛАСИЗ?</p>
                    </div> */}

                    <div className='main_text'>
                        <p>
                            Кейин Юъшо алайҳис-салом: «Исроилнинг бутун жамоасини Шакамга тўпланг», деб амр қилдилар. Ҳамма тўплангач, уларга мана бу сўзларни айтиб маъруза қилдилар:
                        </p>
                        <p>
                        «Оллоҳнинг гапларига қулоқ солинг. У менга бундоқ деди: “Сизларнинг ота-боболарингиз илгари бутларга чўқинган. Иброҳимнинг отаси ҳам бутларга сиғинарди. Кейин Мен Иброҳимни Канъонга олиб келиб, унга Исҳоқни, Исҳоққа эса Яъқуб билан Ийсуни бердим.
                        </p>
                        <p> 
                            Ийсуга Сеир адирларини ато этдим. Яъқуб эса ўз ўғиллари билан Мисрга кетди. Кейин Мен бандам Мусони пайғамбар қилиб Мисрга юбордим. Фиръавн устига офатлар ёғдириб, сизларни у ердан олиб чиқдим. 
                            Мисрликлар жанг аравалари билан ортингиздан қувганида уларни денгизга ғарқ қилдим. Бунга ўзларингиз шоҳид бўлдингиз. 
                        </p>
                        <p>
                            Чўлдаги саргузаштлардан сўнг, ниҳоят Ўрдун дарёсидан ўтиб, ваъда қилинган юртга кирдингиз. Ариҳоликлар сизларга қарши бош кўтарди, аммо Мен уларни қўлингизга таслим этдим. 
                            Амўрий ва перизийларни ҳам, канъоний ва ҳитийларни, жаржосий, ҳавий ва ябусийларни ҳам ўз кучингиз билан мағлуб қилганингиз йўқ. 
                        </p>
                        <p>
                            Мана энди меҳнат қилмай туриб далаларга, қурмай туриб шаҳарларга эга бўлдингиз. Ўзингиз экмаган зайтун дарахтларидан, парвариш қилмаган узумлардан истеъмол қиляпсиз”, деди Раббимиз».
                        </p>
                        <p>
                            «Шундай экан, энди танланглар, — деб гапларини давом этдирдилар Юъшо пайғамбар. — Оллоҳга хизмат қиласизларми ё ота-боболарингиз чўқинган бутларгами? 
                            Ё энди амўрийлар ерида яшаяпмиз, деб амўрийларнинг худоларига сиғинасизми? Мен ва менинг оилам Оллоҳга хизмат қиламиз, деб қарор қилдик».
                        </p>
                        <p>
                            Халқ: «Биз ҳеч қачон Оллоҳдан юз ўгирмаймиз, бошқа худоларга сиғинмаймиз! — деб жавоб берди. — Бизни Мисрдаги қулликдан Оллоҳ олиб чиқди. 
                            Мўъжизаларига ўз кўзимиз билан гувоҳ бўлдик. Шунча элатлар орасидан соғ-саломат олиб ўтди. Биз ҳам Оллоҳга хизмат қиламиз».
                        </p>
                        <p>
                            Ўша куни Юъшо алайҳис-салом, катта бир тошни эман дарахти тагига ўрнатиб, халққа: «Мана, кўриб қўйинглар, мана шу тош айтилган гапларга шоҳид. 
                            Агар ваъдамизда турмасак, келажакда у бизга қарши гувоҳлик беради», деб эълон қилдилар.
                        </p>
                        <p className='vitalic'>
                            Оллоҳ таоло улардан муқаддас бир халқ яратмоқчи эди. Улар ўзларининг кундалик ҳаётида Оллоҳнинг амрларига риоя қилиши ва бу амрларнинг қанчалик яхши ва одил эканини бошқа миллатларга кўрсатиши керак эди. 
                        </p>
                        <p className='vitalic'>
                          Бошқа халқлар улардаги меҳр-оқибатни, тартибни, покизаликни ва чиқарилаётган ҳукмларни кўриб: “Уларнинг худоси адолатли экан” деб айтар, натижада Оллоҳнинг гўзал исмлари бошқа эллар орасида овоза бўлар эди. 
                        </p>
                        <p className='vitalic'>
                          Шунинг учун ҳам Мусо пайғамбар Бани Исроилга: бошқа халқларга эргашманг, улардан бузуқчиликни, гуноҳ ишларни ўрганманг, деб бир неча бор қаттиқ тайинлаган эдилар. 
                        </p>
                        <p className='vitalic'>
                          Афсуски кейинчалик, Бани Исроил бошқа халқларни Худога олиб келиш ўрнига ўзлари уларнинг бут-санамларига сиғина бошлади. Бошқаларни гуноҳдан қайтариш ўрнига ўзлари уларнинг гуноҳ ишларини такрорладилар. 
                        </p>
                    </div>

                    <div className='paragraph_heading'>
                      <p>
                      БАНИ ИСРОИЛНИНГ ОЛЛОҲГА
                      ХИЁНАТИ
                      </p>
                  </div>
                 
                  <div className='main_text'>
                    <p>
                    Юъшо (а.с.) бир юз ўн ёшга чиқиб вафот этдилар. Кейин халққа, Худонинг мўъжизаларини ўз кўзлари билан кўрган оқсоқоллар раҳнамолик қилдилар. Бу давр мобайнида халқ Оллоҳ таолонинг хизматида бўлди. 
                    </p> 
                    <p>
                    У оқсоқоллар ҳам оламдан ўтгач, Худони билмайдиган, Худонинг Бани Исроилга қилган марҳаматларини тан олмайдиган янги бир авлод ўртага келди.

                    </p>
                    <p>
                    Улар Яратгандан юз ўгириб, қўшни халқлар сиғинадиган бутларга чўқина бошладилар. Ота­-боболарини Мисрдан олиб чиққан Оллоҳга хиёнат қилиб, Баал ва Асториш деб аталган санамларга хизмат қилдилар. Шунда Оллоҳнинг ғазаби келиб, уларни босқинчилар қўлига топширади.
                    </p>
                    <p>
                    Душман ҳар тарафдан ҳужум қилиб, уларнинг мол-­мулкини талайди. Ҳар бир жангда улар шарманда бўлиб енгилар, ҳар сафар душман қўли улардан устун келар эди. Худонинг қаҳрига учраб, Бани Исроил ачинарли бир аҳволга тушган эди.
                    </p>
                    <p></p>
                  </div>

                  <div className='main_text'>
                    <div className='speech-bubble'  style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
                        onClick={() => handleSupClick(1)}
                      >
                        <p>
                            <sup>1</sup> «Сабр қилган пайтларида (Бани Исроилдан) Бизнинг амримиз ила ҳидоятга бошлайдиган пешволар чиқардик. Улар Бизнинг оятларимизга аниқ ишонардилар» (Сажда сураси 32:24).
                        </p>
                      </div>

                      <p onClick={() => handleSupClick(1)}>
                      Вақти-­вақти билан Худо уларнинг орасидан ҳакамлар тайин этиб, халқни талончилардан қутқарган. Бироқ Исроил ҳакамларга ҳам қулоқ солмади. Ота­-боболари юрган итоат йўлини ташлаб, бегона худоларга илашди! <sup>1</sup>
                      </p>
                      <p>
                      Зулмдан қийналиб, оҳу нола қилган пайтларида, Оллоҳ уларга раҳм қилар, ораларидан ҳакамлар тайин этар, Оллоҳнинг ёрдами билан бу ҳакамлар халқни душман қўлидан халос қилар эдилар. 
                      </p>

                      <div className='speech-bubble'  style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
                        onClick={() => handleSupClick(2)}
                      >
                        <p>
                            <sup>2</sup>  «Қачон инсон зотига бирон зиён-­мусибат етса, ётган ҳолида ҳам, ўтириб ҳам, туриб ҳам Бизга дуо-­илтижо қилур. Энди қачонки Биз ундан зиён-­мусибатини кеткизсак, гўё ўзига етган бало-­мусибатдан Бизга дуо-­илтижо қилмагандек (юз ўгириб) кетур» (Юнус сураси, 12).
                        </p>
                      </div>
                      <p onClick={() => handleSupClick(2)}>
                      Лекин ҳакам оламдан ўтиши билан, Бани Исроил яна бутпарастлик йўлига кирар, бегона худоларга эргашиб, оталаридан ҳам бадтарроқ гуноҳ ишларга қўл урарди. Қайсарлик қилиб, фисқ йўлидан қайтишни хоҳламас эдилар. <sup>2</sup>
                      </p>
                      <p>
                      Ўшанда Худо улардан қаттиқ ғазабланиб: «Бу халқ, оталари Мен билан тузган аҳдни бузди. Менга қулоқ солишни истамади. Шу сабабдан Мен, Канъондаги қолган бегона халқларни ҳайдаб чиқармайман», деб айтган экан.
                      </p>
                      <p>
                      Шундай қилиб, Юъшо пайғамбар ҳайдаб чиқаришга улгурмаган бегона халқлар Канъонда қолади. Худо бу билан Исроил халқини синамоқчи эди.
                      </p>
                  </div>

          <BottomNav 
            labelLeft='Ваъда юрти' 
            pathLeft='/public-resources/qisas-uzbek/enterPromisedLand'

            labelCenter='Кимга хизмат қиласиз'
            pathCenter='/public-resources/qisas-uzbek/chooseWhoToServe'

            labelRight='Ҳакамлар'
            pathRight='/public-resources/qisas-uzbek/deborah'
          />

       
        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default ChooseWhoToServe;