import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import NavigationPublic from './navigationPublic/NavigationPublic'
import '../styles/shared.css'

const LayoutPublic = () => {

  const [activeLink, setActiveLink] = useState(null);
  return (
      <div className='general_view'>
        <div className='fixed_line'> </div>  
        <NavigationPublic activeLink={activeLink} setActiveLink={setActiveLink} />
        <Outlet context={[activeLink, setActiveLink]} />
      </div>
  )
}

export default LayoutPublic
