import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'
import BottomNav from './components/BottomNav';


const EXAMPLE = () => {

  const navigate = useNavigate();


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 24;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      //     console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p> Фиръавн Бани Исроилни Мисрга  </p>
            <p>таклиф қилади</p>
          </div>

          <div className='decor_line'>
            <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
          </div>

          <div className='main_text'>
            <p>
              “Юсуфнинг акалари келибди” деган хабар тезда фиръавннинг қулоғига етди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ФИРЪАВННИНГ ДАЪВАТИ
            </p>
          </div>

          <div className='main_text'>
            <p>
              Фиръавн ҳам, амалдорлари ҳам хурсанд бўлдилар.  Кейин фиръавн Юсуф (а.с.) ни олдига чақиртириб деди:
            </p>
            <p>
              «Акаларингга айт,
              отангни, оилаларини олиб ҳаммалари Мисрга кўчиб келишсин. Мен уларга Мисрнинг энг яхши жойини, энг серҳосил ерини олиб бераман.
              Отангни ва аёллар билан ёш болаларни олиб келиш учун акаларингга аравалар қўшиб жўнат.
              Айтгандай, яна Канъондан ҳамма нарсани олиб келамиз, деб ўзларини қийнамасин: Мисрдаги энг яхши нарсалар сизларники бўлади», деди.
            </p>
            <p className="vitalic">
              Ҳа, Миср подшоҳи фиръавн уларни шунчалик ҳурмат қилиб Мисрга таклиф қилди. Шунинг учун ҳам, Исроил халқи агар кейинчалик хоҳласа, Мисрдан чиқиб кетишга ҳақли эди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              КАРВОННИНГ ЙЎЛГА ЧИҚИШИ
            </p>
          </div>

          <div className='main_text'>
            <p className='boldItalic'>
              «Менинг мана бу кўйлагимни олиб бориб, отамнинг юзига ташланглар, кўзи очилади. Сўнгра аҳлингиз ила жам бўлиб ҳузуримга келинглар» (Қуръон 12:93).
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}

            >
              <p>
                <sup>1</sup>     1 мисқол — 11,6 грамм.
              </p>
            </div>
            <p className='' onClick={() => handleSupClick(1)}>
              Шундай қилиб Исроил (а.с.) нинг ўғиллари фиръавн берган аравалару озуқаларни олиб, Канъонга қараб йўлга тушдилар.
              Ҳазрати Юсуф акаларининг ҳар бирига битта-биттадан сарпо, Биняминга эса бешта сарпо ва уч юз мисқол<sup>1</sup> кумуш совға қилдилар.  Отасига эса Мисрнинг энг яхши нарсаларини ўнта эшакка юклаб жўнатдилар. Ундан ташқари, отасига сафар учун деб ғалла, нон ва бошқа озиқ-овқатлар юкланган яна ўнта эшак қўшиб бердилар.
            </p>
            <p></p>
          </div>
          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p>
                <sup>2</sup>  «Карвон йўлга чиққан вақтда оталари: «Мени ақлдан озган деманглар-у, албатта, мен Юсуфнинг ҳидини сезмоқдаман», деди» (12:94).
              </p>
            </div>
            <p className='' >
              Акалари билан хайрлашиб: «Қўрқманглар, ҳаммаси яхши бўлади», деб таъкидладилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ХУШХАБАР!
            </p>
          </div>

          <div className='main_text'>
            <p>
              Улар Мисрдан чиқиб Канъонга, оталари Яъқуб (а.с.) нинг олдига етиб келдилар.  Оталарини кўриши билан: «Юсуф тирик экан! У Мисрга ҳоким бўлибди!» дедилар. Яъқуб (а.с.) ишонгани йўқ.
            </p>

            <p>
              Фақат фиръавннинг араваларини кўргандан сўнг, Юсуф (а.с.) айтган гапларни эшитгандан сўнг, у кишининг руҳи жонланди.
              Қайта-қайта: «Ўғлим тирик! Юсуф ўғлим тирик! Бораман! Ўлмасимдан олдин бориб уни кўраман!» деб <span className="vitalic">йиғлар </span> эдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              «ЎҒЛИМНИНГ ОЛДИГА БОРАМАН!»
            </p>
          </div>

          <div className='main_text'>
            <p>
              Шундай қилиб Исроил (а.с.), бутун хонадонини олиб йўлга чиқдилар. Беър-Шеба шаҳрига келганларида Оллоҳ таолога қурбонликлар келтирдилар.
            </p>
            <p>
              Ўша куни тунда туш кўрадилар. Тушида: «Яъқуб! Яъқуб!» деган овоз эшитилди. «Лаббай», деб жавоб берадилар Яъқуб (а.с.).
            </p>
            <p>
              «Мен отанг Исҳоқнинг илоҳиман. Мисрга боришдан қўрқма. Мен Ўзим сен билан бирга бўламан. Мисрда сизлардан катта бир халқ яратаман. Сен ўша ерда, ўғлинг Юсуфнинг қўлида жон берасан. Кейинчалик Мен сенинг авлодларингни яна у ердан олиб чиқиб, Канъонга қайтараман», деди.
            </p>
            <p>
              Бу гапни эшитиб руҳланган  Яъқуб (а.с.), бутун зурриётини, ўғил-қизлари ва неваралари билан бирга олиб, бор мол-давлатини, подаю сурувларини олдига солиб, Беър-Шебадан Мисрга қараб йўлга тушдилар. Ҳазрати Яъқуб, аёллар ва кичкина болалар фиръавн юборган араваларга минган эдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ОТА-ЎҒИЛНИНГ УЧРАШУВИ
            </p>
          </div>

          <div className='main_text'>
            <p className="boldItalic">
              «Юсуф ҳузурига кирган чоғларида у отаси ва онасини ўзига яқинлаштирди ва: «Оллоҳнинг хоҳиши ила Мисрга омонликда киринглар!» деди. Ва ота-онасини тахтга кўтарди. Улар унга сажда қилиб йиқилдилар. У: «Эй отажон, бу аввал кўрган тушимнинг таъбиридир, Раббим уни ҳаққа айлантирди...» деди» <span className="vitalic">(12:99-100).</span>
            </p>

            <p>
              Улар Жўшанга келганларидан сўнг,  Юсуф (а.с.), ўзининг шоҳона аравасини миниб, шону шуҳрат билан Жўшанга, отасини кўришга келдилар. Отасини кўриши билан ўзини унинг бўйнига ташлаб анча йиғлади.
            </p>

            <p>
              «Сени кўрар куним ҳам бор экан! — дер эди ҳазрати Яъқуб. — Энди дунёдан беармон кетаман. Сени кўрмай ўлиб кетаманми, деб ўйлаган эдим».
            </p>

            <p>
              Кейин ҳазрати Юсуф акаларига: «Мен келганингизни фиръавнга хабар қилай, — дедилар.  — Сизларнинг чўпонлик қилишингизни, ҳамма қўй-сигирларингизни, бутун мол-давлатингизни ўзингиз билан олиб келганингизни айтаман.  Кейин фиръавн сизларни чақириб касбингизни сўрайди.  Сўраса, биз ота-боболаримиздай ёшликдан мол боқамиз, чўпонлик қиламиз, деб айтинглар. Шунда у сизларга Жўшанда яшашга рухсат беради», дедилар.
            </p>
          </div>


          <div className='paragraph_heading'>
            <p>
              ЯЪҚУБ (А.С.) НИНГ ХОНАВОДАСИ
            </p>
          </div>

          <div className='main_text'>
            <p>
              Ҳазрати Яъқубнинг Мисрга кўчиб борган ўғиллари ва невараларининг рўйхати қуйидагича:
            </p>
            <p>
              Яъқуб (а.с.) нинг Лайё хонимдан туғилган биринчи ўғли Роавбин (р.а.), Роавбиннинг ўғиллари: Ҳанўк, Фалу, Ҳасрўн ва Кармий;
            </p>
          </div>

          <div className='main_text'>
            <p>
              иккинчи ўғли Шимъун (р.а.) ва Шимъуннинг ўғиллари Ямуэл, Ямин, Ўҳад, Ёкин, Сўҳар ва Шимъуннинг канъонлик хотинидан туғилган ўғли Шоул;

              учинчи ўғли Лобий (р.а.) ва унинг ўғиллари Жаршўн, Қаҳот ва Марори;

              тўртинчи ўғли Яҳузо (р.а.) ва Яҳузонинг ўғиллари Ир, Ўнон, Шила, Форас ва Зореҳ, ҳамда Фораснинг ўғиллари Ҳасрўн ва Ҳомул (лекин Ир билан Ўнон бу пайтда ҳаёт эмас эди. Улар анча аввал Канъонда ўлиб кетишган);

              бешинчи ўғли Ясокир (р.а.) ва унинг ўғиллари Туло, Фавва, Йўб ва Шамрўн;

              олтинчи ўғли Забулун (р.а.) ва Забулуннинг ўғиллари Сорад, Элўн ва Ёҳелел.
            </p>
            <p>
              Лайё (р.а.) нинг Байнул-наҳрайнда Яъқуб (а.с.) га туғиб берган ўғиллари мана шулар эди. Лайёнинг қизи Дина (р.а.) ҳам Байнул-наҳрайнда туғилган. Ҳазрати Яъқубнинг Лайё Бибидан туғилган фарзандлари невара-чеваралари билан бирга ўттиз уч киши эди.
            </p>
            <p>
              Ҳазрати Яъқуб ва Зилфадан (Лобоннинг Лайёга қўшиб берган чўрисидан) дунёга келган фарзандлар ҳаммаси бўлиб ўн олти киши эди:
            </p>

            <p>
              Зилфанинг биринчи ўғли Жод (р.а.) ва Жоднинг ўғиллари: Сафюн, Ҳажи, Шуний, Эсбўн, Эри, Арўди ва Арели;
              ва Зилфанинг иккинчи ўғли Ашир (р.а.) ва Аширнинг ўғиллари Ямна, Яшва, Яшви, Бариъа ва қизи Сореҳ. Бариъанинг Ҳобир ва Малкиэл деган иккита ўғли ҳам бор эди.
            </p>

            <p>
              Яъқуб (а.с.) нинг Роҳийла Бибидан икки ўғли бор эди: Юсуф ва Бинямин.
            </p>

            <p>
              Ҳазрати Юсуф (а.с.) Мисрда, Ўн шаҳрининг руҳонийси Фўтифораҳнинг қизи Асенотни ўз никоҳига олган ва ундан Манси ва Афройим деган икки ўғил кўрган эдилар.  Бинямин (р.а.) нинг ўн ўғли бор эди: Болеъ, Бокир, Ашбил, Жиро, Наъмон, Эҳи, Рўш, Муфим, Ҳафим ва Ард.
            </p>

            <p>
              Шундоқ қилиб Яъқуб (а.с.) нинг Роҳийла хонимдан туғилган фарзандлари неваралари билан бирга жамъан ўн тўрт киши эдилар.
            </p>

            <p>
              Ҳазрати Яъқубнинг Билҳа (р.а.) дан (Лобоннинг Роҳийлага берган чўрисидан) туғилган фарзандлари ва неваралари етти киши эди.
            </p>

            <p>
              Белҳанинг биринчи ўғли Дон (р.а.) ва Доннинг ўғли Ҳушим;
              иккинчи ўғли Нафтоли (р.а.) ва Нафтолининг ўғиллари Яҳсел, Жуний, Ясар ва Шилим.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}


            >
              <p>
                <sup>3</sup>  Бу ҳисобда Дина ҳам қўшилган.
              </p>
            </div>
            <p onClick={() => handleSupClick(3)}>
              Ҳазрати Яъқубнинг наслидан бўлган ва у киши билан бирга Мисрга келганлар сони (келинларни ҳисобга олмаганда) жамъан олтмиш олти киши эди.<sup>3</sup>  Агар уларга Юсуф (а.с.) ни, унинг Мисрда туғилган икки ўғлини (ва Яъқуб (а.с.) нинг ўзини) қўшиб ҳисобласак, ҳаммаси етмиш кишини ташкил қилади. Шундай қилиб, ҳазрати Яъқубнинг Мисрдаги хонаводаси етмиш кишидан иборат эди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p>
              ЖЎШАНГА ЖОЙЛАШИШ
            </p>
          </div>

          <div className='main_text'>
            <p>
              Ҳазрати Юсуф кейин фиръавннинг олдига бориб: «Отам ва акаларим қўй-сигирларини олиб, бутун мол-мулки билан Канъондан келишибди. Ҳозир ҳаммалари Жўшанда», деб хабар бердилар. Кейин акаларидан бештасини фиръавннинг ҳузурига олиб кирдилар.
            </p>
            <p>
              «Нима иш қиласизлар?» деб сўради фиръавн.
            </p>
            <p>
              «Биз ота-боболаримиз каби чўпонмиз, — дейишди улар.  — Канъонда ҳозир қаттиқ қаҳатчилик бўляпти. Молларимизга озуқа йўқ. Шунга вақтинча юртингизга келдик. Рухсат берсангиз Жўшан вилоятига жойлашамиз».
            </p>
            <p>
              Фиръавн Юсуф (а.с.) га қараб: «Мана, отанг билан акаларинг юртимизга келибди. Жуда яхши.  Акаларингни Жўшанга жойлаштир. Мисрнинг энг яхши жойи бу. Агар ораларида муносиб одам топилса менинг сурувларимни ҳам назорат қилсинлар», деди.
            </p>

            <p>
              Кейин ҳазрати Юсуф отасини ҳам фиръавннинг олдига олиб келдилар. Яъқуб (а.с.) фиръавнни дуо қилганларидан сўнг фиръавн у кишидан:  «Неча ёшга кирдингиз?» деб сўради.
            </p>

            <p>
              «Ерда мусофир бўлиб юрганимга бир юз ўттиз йил бўлди. Ота-боболарим менга қараганда анча кўп яшаган. Бироқ уларнинг ҳаёти меникидай қийин бўлмаган», деб жавоб бердилар Яъқуб пайғамбар. Кейин кетишдан олдин яна бир бор фиръавнни дуо қилдилар.
            </p>
            <p className="vitalic">
              (Ҳа, ҳазрати Яъқубнинг ўз оғзидан айтилган бу гап у кишининг ҳаётига жуда тўғри хулоса бўла олади.)
            </p>
            <p></p>
          </div>

          <BottomNav 
            labelLeft='Акалари билан учрашув' 
            pathLeft='/public-resources/qisas-uzbek/meeting-with-brothers'

            labelCenter='Фиръавннинг таклифи'
            pathCenter='/public-resources/qisas-uzbek/pharaoh-invites-jacobs-family'

            labelRight='Яъқубнинг башорати'
            pathRight='/public-resources/qisas-uzbek/prophecy-of-jacob'
            />
         
        </main>
        <aside> </aside>
      </div>
    </div >
  )
}

export default EXAMPLE
