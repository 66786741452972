import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const JanatonsTavakkul = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>Янатоннинг таваккули</p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

          <div className='paragraph_heading'>
            <p> Филистийлар билан тўқнашув </p>  
          </div>
          
          <div className='main_text'>
            <p>
            Толут тахтга ўтириб, мамлакатни бошқара бошлади. Уч минг одамни ҳарбий хизмат учун танлаб олди. Улардан икки минги ўзи билан Байтил ва Михмасда, мингтаси эса ўғли Янатон раҳбарлигида Бинямин вилоя­тидаги Гива шаҳрида эди. 
            </p>
            <p>
            Янатон бир куни йигитлари билан Жабъа­даги филистийлар қароргоҳига ҳужум қи­либ, уларни мағлуб қилди. Филистийлар орасида ғулғула кўтарилди. 
            </p>
            <p>
            Шунда Толут: «Ҳаммага хабар қилинглар, ҳеч ким эшитмай қолмасин!» деб бутун мамлакатга жар солдирди. Бутун Исроилдан одамлар ке­либ Гилгалда Толутнинг олдига тўпландилар.
            </p>
            <p>
            Филистийлар уч мингта жанг араваси, олти мингта отлиқ ва сон-саноқсиз пиёда ас­кар­лари билан Михмасга, Байт-Авен­нинг шар­қига келиб маҳаллий қишлоқларни талай бошладилар.
            </p>
            <p>
            Аксарият исроиллик­лар уйларини тарк этиб, тоғларга, ғорлар­га, қаб­рис­тону ертўлаларга яширина бош­ла­ди. Баъзилари Ўрдуннинг нариги соҳилига, Жилъод ва Жодга кетди. 
            </p>
            <p> Гилгалда ҳам Толутнинг лашкарлари ора­си­да ваҳима бошланган, аскарлар сафни таш­лаб, секин-секин ҳар тарафга қоча­ёт­ган эди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ТОЛУТНИНГ ўзича ҚУРБОНЛИК КЕЛТИРГАНИ
            </p>  
          </div>
          
          <div className='main_text'>
            <p>
            Сомуил пайғамбар Толутга: «Мен етти кун ичида келаман, Оллоҳ таолога қур­бон­лик кел­тирганимдан сўнг, филис­тий­лар­га қарши жанг қиласан», деб тайинлаган эди­лар.
            </p>
            <p>
            Етти кун бўлди ҳам пайғамбардан дарак йўқ эди. Подшоҳ ниҳоят одамларига: «Қур­бон­лик қилинадиган ҳайвонларни олиб келинглар», деб буйруқ берди ва ўзи қур­бон­ликларни сўйиб оловда куйдирди. Энди тугатаётган вақтда Сомуил пай­ғам­бар келиб қолдилар.
            </p>
            <p>
            «Ассалому алайкум!» деди Толут, у кишининг олдига пешвоз чиқиб. 
            </p>
            <p>
            «Нима қи­либ қўйдинг?!» деб сўради Сомуил (а.с.). 
            </p>
            <p>
            «Қа­ра­сам, одамларим қочиб кетяпти, сиз ҳам айтилган вақтда келавермадингиз. Филистийлар Михмасда куч тўплаяпти, Гилгалга бостириб келса, мен Худога қурбонлик қилишга ҳам улгурмайман, деб ўйладим». 
            </p>
            <p>
            «Аҳмоқона иш қилдинг! – дедилар Со­муил пайғамбар Толутга. – Нега Худо­нинг амрига итоат этмадинг? Итоат этганинг­да Оллоҳ Исроил тахтини абадул-абад се­нинг хонадонингга топширар эди. Энди эса Исроилга бошқа одам подшоҳлик қилади. Оллоҳ Ўзига маъқул бўлган бошқа бир инсонни танлади». 
            </p>
            <p>
            Шу гапларни айтиб, Сомуил (а.с.) Гилгални тарк этдилар. Толут эса, қолган лашкарини олиб, ўғли Янатоннинг олдига – Жабъага борди ва у ерда қароргоҳ қурди. Текшириб кўрсалар, Толутнинг қўл остида олти юз одам бор экан. 
            </p>
            <p>
            Филистийлар Михмасда қароргоҳ қур­ган эдилар. Улар босқинчилик қилиш учун Исроил қишлоқларига борар, бир гуруҳи шимолга – Ўфро томонга, бир гуруҳи ғарбдаги Байт-ҳорон йўлига, учинчи гу­ру­ҳи эса шарқдаги саҳро тарафда жойлашган қишлоқларга ҳужум қиларди.
            </p>
        
          </div>

          <div className='paragraph_heading'>
            <p> ЯНАТОННИНГ ТАВАККУЛИ </p>  
          </div>
          
          <div className='main_text'>
            <p>
            Шундай қилиб, филистийлар қўшини Мих­мас кечувига келиб қароргоҳ қурган, То­лут эса бу пайтда Гивада, шаҳар че­ти­да­ги хирмон бўйида, анор дарахти остида ўтирган эди. 
            </p>
            <p>
           
            </p>
          </div>




          <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p> <sup>1</sup> Ҳазрати Алининг ўғли Фи­неҳ­дан Ахтуба ва Ихавод ту­ғил­ган, руҳоний Ахия ана шу Ахтубанинг ўғли эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
            Қўл остида олти юз йигит хизматда, Силомда руҳонийлик қилган ҳазрати Алининг эвараси, Ахтубанинг ўғли руҳоний Ахия ҳам шу ерда эди.<sup>1</sup> 
            </p>
            <p>
            Ўша пайтларда Исроилда темирчи бўлмаган. Филистийлар исроилликлар ўзларига қилич ё найза ясамасин, деб бутун Исроил ерида бирорта ҳам темирчи қолдирмаган эди. 

            </p>
            <p>
            Омоч тишини, болта, ўроқ ва чўкич­ла­рини ўткирлаш учун улар филис­тий­лар­нинг олдига борар, ҳар бири учун белги­лан­ган нархда пул тўлар эдилар. 
            </p>
            <p>
            Шу сабаб­дан уруш бошланган пайтда Толут ва Яна­тон­дан бошқа ҳеч кимда қилич ҳам, найза ҳам йўқ эди.
            </p>
            <p>
            Бир куни Толутнинг ўғли Янатон ота­си­га ҳеч нарса демай, қуролбардорини олиб филистийлар лашкари турган жойга равона бўлди.
            </p>
            <p>
            Бозаз билан Сенеҳ деб атал­ган икки қоянинг орасидаги дарани кесиб ўтганларидан кейин қуролбардорига:  «Юр, Оллоҳга таваккал қилиб, хатнасизлар олдига борамиз. Оллоҳ истаса, камчи­лик­ни ҳам ғалабага эриштиради», деди.
            </p>
            <p>
            «Нима десангиз, шу, – деб жавоб берди қу­ролбардори. – Қаёққа десангиз, мен сиз билан биргаман». 
            </p>
            <p>
            «Ҳозир улар кўрадиган жойга ўтамиз, – деди Янатон. – Агар, “Тўхтанглар, биз олдингизга борамиз,” деса, юқорига чиқ­май­миз. Агар, “Бу ёққа чиқинглар”, деса, демак Худо уларни бизга таслим қилган: тепага чиқиб уларни ўлдирамиз».
            </p>
            <p>
            Филистий соқчилари уларни кўргач: «Ана, исроилликлар беркинган инлари­дан чи­қиш­ни бошлабди», деб мазах қи­либ кул­ди­лар. Кейин: «Бу ёққа чиқинглар-чи, бир гап­лашиб қўяйлик», дейишди. 
            </p>
            <p>
            Янатон қуролбардорига: «Кетдик, ор­қам­дан юр, Оллоҳ уларни бизга таслим қил­моқ­чи», деди-да тепага тирмашиб чиқди. 
            </p>
            <p>
            Қуролбардори ҳам унинг орқасидан эргашди. Янатоннинг қиличидан филис­тий­лар бирин-кетин ер тишлар, ҳали ўл­ма­ган­ларини қуролбардори тинчитаётган эди. Янатон ўша ерда бирин-кетин йигирматача филистийни ўлдирди. 
            </p>
            <p>
              Шу вақт бутун филистийлар қўшинини ваҳима босди: қароргоҳда турганлар ҳам, соқ­чилар ҳам, қирдагилар ҳам даҳшатга тушди. Ер ҳам титраб, зилзила туриб, фи­лис­тийлар қаттиқ қўрқиб кетдилар!
            </p>
          </div>
        
        
          <div className='paragraph_heading'>
            <p> Филистийларнинг мағлуб бўлиши </p>  
          </div>
          
          <div className='main_text'>
            <p>
            Толутнинг соқчилари душман лашкарининг ҳар томонга қочаётганини кўриб, подшоҳга хабар бердилар. 
            </p>
            <p>
          Подшоҳ: «Одамларни тўпланглар! Қаранглар, ҳамма шу ердами?» деб буйруқ берди. Текширувда Янатон билан қуролбардори йўқлиги маълум бўлди. 
            </p>
          </div>
         
          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p>
                <sup>2</sup> Ўша пайтда Исроил қў­ши­нига Ахия руҳонийлик қи­ла­ёт­ган эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(2)} >
            Шунда Толут Ахияни чақириб: «Аҳд сан­ди­ғини олиб кел. Худодан сўра-чи, нима қилишимиз керак?» деди. <sup>2</sup> 
            </p>
            <p>
            Аммо филис­тий­лар қароргоҳида қий-чув яна-да авжига чиққанини кўриб, Ахия­ни тўхтатди ва одам­ла­рини олиб, наъра тортиб филис­тий­лар­га қарши жангга кирдилар. 
            </p>
            <p>
            Филистийлар қўрқувдан бутунлай ўз­ла­ри­ни йўқотган, саросимага тушиб, бир-бирини ўлдираётган эдилар. 
            </p>
            <p>
            Улар тарафда жанг қилаётган ёлланма ас­кар­лар уларни ташлаб, Бани Исроил томонга ўтиб кетди. 
            </p>
            <p>
            Афройим тоғида яшириниб ётган исроил­лик­лар ҳам, филистийларнинг қо­ча­ёт­га­ни­ни эшитиб, бекиниб турган ерларидан чиқиб, жанг қилаётган юртдошлари сафига қўшилдилар. 
            </p>
            <p>
            Ўша куни жанг то Байт-Авен ерига етгунча давом этди – Оллоҳнинг нусрати билан Бани Исроил омон қолди.
            </p>
            <p></p>
          </div>
          
          
          <div className='paragraph_heading'>
            <p> ТОЛУТНИНГ БОШҚА ХАЛҚЛАР БИЛАН ҚИЛГАН ЖАНГЛАРИ </p>
          </div>

          <div className='main_text'>
            <p>
            Толут подшоҳ бўлган вақтда мўобликлар, амўнийлар, эдўмийлар, Сова подшоҳлари, филистийлар ва амолиқлар ҳар тарафдан Бани Исроилни талаётган эдилар. 
            </p>
            <p>
            Толут уларнинг ҳар бири билан жанг қилди, ҳар сафар жангда Толутнинг қўли устун чиқди. Ҳатто Амолиқни ҳам тор-мор келтириб, Бани Исроилни талончилар қўлидан буткул халос қилди.
            </p>
          </div>
          
          <div className='paragraph_heading'>
            <p> ТОЛУТНИНГ ЯҚИНЛАРИ </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}
            >
              <p>
                <sup>3</sup> Толутнинг отаси Қайс билан Абнурнинг отаси Нур ака-ука бўлиб, оталарининг исми Абиил эди. 
              </p>
            </div>
            <p onClick={() => handleSupClick(3)} >
            Толутнинг Янатон, Яшви ва Малкишу деган уч ўғли, Мироба ва Михола деган икки қизи бўлган.  
            Хотинининг исми Ахиноам Ахимааз қизи эди. Лашкарбошиси Абнур эса Толутга амакивачча бўлган.<sup>3</sup> 
            </p>
            <p>
            Толут под­шоҳлик қилган даврда Бани Исроил ҳар доим филистийлар билан жанг қилиб келган. Шу сабабдан Толут қачон бирорта кучли ботир йигитни кўрса, уни дарров ўз хиз­матига олар эди.
            </p>
          </div>

          <BottomNav 
            labelLeft='Шартлар' 
            pathLeft='/public-resources/qisas-uzbek/monarchyConditions'

            labelCenter='Янатоннинг таваккули'
            pathCenter='/public-resources/qisas-uzbek/janatonsTavakkul'

            labelRight='Толутнинг айби'
             pathRight='/public-resources/qisas-uzbek/tolutIsRejected'
          />


            {/* // labelRight='(davomi bor)'
            // pathRight='/public-resources/qisas-uzbek/tobe_continued' */}

        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default JanatonsTavakkul;