import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import kamar from '../../images/kamar.png';
import BottomNav from './components/BottomNav';

const Maryam = () => {
    const navigate = useNavigate();
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 14;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading'>
                    <div className="kamar">
                        <img src={kamar} alt="" />
                    </div>
                    <div className="img_container" style={{ padding: '3px' }}>
                    <svg fill='rgb(175, 157, 0)'  id="_Слой_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1417.3 1417.3">
 
 <path d="M1190.6,642.6c0,.4.2.9.4,1.3,1.1,2.7-2.3-3.1-.4-1.3Z"/>
 <path d="M1276.7,526.7c-2.8-8.3-9.2-15.8-17-20-9.9-5.3-13.2-5.1-21.3,2.4-13,12-17.8,28.7-23.7,43-.7,1.7-1,6.4-1.6,7-.6.6-8,0-11.7,4.6-.4.4.4,1.9-.4,3.1-23.4,33.4-43.9,61.3-72.8,90.5-78.5,79.1-204,169.9-320.9,128.9-15.5-5.4-25.2-12.4-38.2-21.1-3.4-2.2-15.5-7.1-17.7-10.1-3.2-4.3-7.3-18.2-9.8-23.8-6.1-13.3-12.3-26-17.7-39.9-2-4.9-4.4-24.4-12.1-8.5-27.8,57.3,1.7,100.2,21.5,152.6,2.6,6.9-3.4-4,6.6,22.2s40,84.8,38.4,109.6c-2.7,41.7-41.8,77.3-69.3,104.9-25.7,25.8-52.8,51.7-79.8,75.2-63.3,55-136.8,100.6-213.1,135.9h0c0,0,0,0,0,0-3.3,1.6-3.8,2.1,0,2.7,21.8-7.2,57.8-27.8,77.6-31.5,4.5-.8,22.9-2.5,30.2-1.5s3.6,4.5,16.4,4.2c56.2-1.5,133.7-87,171.3-127,39.9-42.4,63.1-72.9,77.2-130.9,10.1-41.3,19.3-78.4,8.2-120.5-2.4-9.3-7.3-19.4-10.3-28.7,1.4-1.1,17.9,7.2,21.2,8.3,46.6,15.5,92.1,10.8,136.9-8.6,63.2-27.4,110.8-66.7,159.3-113.6,22.5-21.8,43.8-45,62.6-70.8,4.9-6.6,13.6-26.8,21.3-29.1,2.8-.8,1.8,2.9,2.7,6.5.9.8,2.9,3.3,7.1,9,13.2,18,29.6,35,44.8,51.2,5.3,5.7,11.4,13.5,18,4.5,1.2-1.7,4.9-9.5,5.9-11.9,18.9-46.5,26.3-121.6,10.3-168.7ZM1255.6,630.1c-9.7-7.5-30-29.3-35.7-39.5-.5-.9-2.7-.9-2.8-1.8-.6-4.8,7-11.1,10.2-14.4,3.2-3.3,2-6.9,8.2-5.1,6.2,1.8,1.9,2.9,2.7,3.5v-.2h.1c6.8,4.8,11,6.6,14.6,14.9,4.1,9.4,4.3,16.8,5.4,26,.6,5,6.2,23.5-2.7,16.6Z"/>
 <path d="M835.2,547.1c6-8.6,20.7-41.2,24.8-52,9-23.5,18.3-63,14.8-87.5-4.7-32.7-19.9-66.7-24.3-100.1-.5-13.2,9.8-16.5,7.1-33.5-3.1-19.7-22.9-45.9-26.8-65.1-1.4-7.1.6-18-.9-23.7-1.5-5.7-6.2-11-11.7-6.1-3.1,2.8-5.5-2.8-15.3,41-9.8,43.8,14.9,128.2,25.6,173.4,4.7,19.6,17.2,56.6,15.7,74.8-1.7,21.7-32,55.1-55.4,51.4-4.6-.7-3.9-3.1-9.7-5.5-5.8-2.4-23.9-6.7-28.9-2.6-4.2,3.4-8.1,13.6-10.7,16.1-24.6,24.1-39.9,66.8-57,98.6-73.8,137.2-151.4,239.6-266.8,344.1-4.1,3.7-13.3,13.1-16.7,15.6-2.2,1.6-4.8.8-6,1.6-.7.4-4.6,9.3-8.7,12.5-32,24.9-64.9,47.6-98.5,70.2-14.7,9.9-26.7,17.5-42.1,26.4-2.6,1.5-14.8,5.9-14.2,9.1.6,3.2,3.3.8,4.5.4,27.9-9,58.1-32.1,83.4-46.9,11.3-6.6,20.5-10.3,34-18.1s26.4-16.9,39.6-25c4.8-1.4,5.9,2,9.3,2s4.8-4.5,7.1-6.2c34.4-24.6,67.4-44.6,98.7-73.5,101.8-94,155-210.1,213.6-334.1,3.9-8.2,9-15.6,14-23,1-.2,1.3-1,1.4-1.4,0,.5.3,1.8,1,4.4,2.3,7.9-.7,10.2-.5,16.9.2,6.7,2.7,15.1,3,21.9.6,12.6-.8,26.1,1.6,39.4,2.2,11.8,18,37,26.2,45.8h-.1v.2c2.9,3.1,18,10.7,22.3,11.3,4.3.6,12.7-2,16.7-3.8,23.3-10.6,40.7-64.7,38.8-88.7-1.8-24.1-12.4-42.7-19.2-64.5-3.1-10.1,6.3-10,10.3-15.8ZM787.9,642.3c-14-16-21.9-31-22-52.7,1-1.1,7,3.8,8.2,4.8,12.2,10,30.3,37,32.5,52.5.4,2.4.5,4.7.3,7.1-8.3-.5-13.8-5.7-19-11.7Z"/>
 <path d="M409.3,734.5c-1.1-1.1-19.8,10.7-23.8,12.6-4,1.9-20.2,8.3-23.5,8-1.3-.2-3.3,0-9.3-5.5s-20.2-41.5-13.8-46.7c2.5-2.1,22.4-6.9,26-6.6,5.8.6,8.9,5.4,14.3,3.3,4.5-2.6-2-6.8-8.7-9.2s-26-4.8-30.8.6c-1.3,1.6-1,4.2-1.8,5.5-.8,1.3-2.4,1.3-3.1,2.8-6.7,13.9-7.9,22.3-3.6,37.3,1,3.6,8.9,17.3,5.4,19.3-.8.5-7.9-5.3-8.8-6.3-13.4-15.4-9.7-34.6-11.8-52.6-7.9-66.7-17.7-132.6-27.3-199-.4-2.9-.2-5.8-.2-8.7-2.4,2.7-9.5,11.5-9.3,18.3s5.2,20.3,6.4,27.8c8.7,56.2,14.4,115.7,19.5,172.5,1.2,13.5,6,28.8,2.3,42.4-3.2,11.9-20.9,10.4-23,.3h-.1v-.2c-1.2-5.7.9-15,0-21.2-2.5-.5-2.7.8-3.9,2.3-12.2,15.7-18.2,27.3-38.6,35.4-4,1.6-14.3,4.9-15.2-1.4-.7-4.5,3.6-15.8,4.2-23.2.6-7.4.6-18.1,0-26.1-.6-8-5-.4-6.6,3.1-5,11.2-10.8,30.7-14.7,42.9-.8,2.5,3.2,3.3,6.4,3.9v-5.8l6.8-17.2c-.6,6.6,2.1,16.5-3.2,21.5-.4.4-2,1.2-2.9,1.6,4.8.9,6.8,1.6-14.4,7.5-35.9,10-30.8-44.4-33.1-65.7-3-26.7-14.4-122.2-29.5-140.6-.7-.9-4.3-4.5-4.8-4.1-7.3,14.7.3,18.5,5,29.9,18.4,44.7,18.6,107.2,24.8,154.9,3.5,26.7,6.8,52.9,41.6,40-2.3,23.4,28.7,60.9,47.8,32,7-10.7,7.3-20.9.8-31.9-1.2-2.1-8.8-9-7.4-11.1,12.9-4.7,19.5-14.9,28.1-24.7-.5,17.6,13.9,32.7,28.9,16.5.4-.4,4-4.8,5.8-7.9,1.8-3.1,2.5-12.4,6.1-6.9,2,2.9,2.8,7.7,4.6,10.4,13.3,20.1,46.6,6.4,63.3-2.3,3.2-1.7,17.9-9.7,21-13.3,3.1-3.6,4.4-9.8,4.1-14.4ZM236.1,812.6c-21.4-10.2-18.2-16.6-21.6-28.4,9.8,2.1,18,4.7,25.3,11.7,10.1,9.8,17.7,26.9-3.7,16.7Z"/>
 <path d="M927.2,1078.5c-2.5,5.2-6.6,10.4-9.9,15.2s-4.6,2.5-2.3,5.9,15.3,9.8,19.6,13.1c15.4,11.9,36.6,33.2,36.8,53.7,0,4.5-6,23.3-8,28s-5.1,5.1-6.5,7.5c-2.6,4.4-4.1,14.7-10.8,15.3s-12.6-17.4-15.5-21.1c-3-3.7-9.3-9.9-13-13.1-5.2-4.5-25.8-16.8-27.4-20.6-6.2-13.9,14.3-49.6,19.3-63.7-5.5-8.8-11-16.3-18.6-23.3-5.5-5-25.5-16.2-27.5-19.1-5.2-7.3.8-26.6,3.6-35,3.5-10.6,8.8-20.4,12.8-30.7,6.2-4.1,8.8,3.1,12.6,5.2,4.3,2.4,9.3,3.3,14.1,6.5,29.1,19.7,36.2,44.3,20.9,76.1h-.2,0Z"/>
 <path d="M648,407.2c9.8-24.9,3.7-84,1.6-111.7,0-1.4,1.6-4.3,1.1-4.8-2.5-.3-4.3,1.4-6.1,2.8-5.4,3.9-20.9,17.7-25.2,22.8s-11.3,18.1-8.2,6.9c5.7-20.3,23.4-30,38.2-43,0-9.1-5.3-21.4-4.3-29.9.6-5.5,5.2-9.5,7.6-14.3,3.2,11.5,4.3,23.7,4.8,35.6,1.7,1.9,31.2-23.3,35.6-24.7,3.5,14.2-13.6,19.2-23.2,26.9-1.8,1.4-10.6,8.7-10.8,9.6.5,5.2,2.1,11.2,2.4,16.3,2.8,38.7,0,77.9,2.4,115.5,1,15.8-.7,39.5,21.7,40.1,7.3.2,26.9-6.2,31.8-11.4s4-9.3,6.6-10.5,2.2,1.6,2.7,2.7c3.4,7.8.3,15.4,11.8,11.7s8.9-9.7,14.3-14.4c1.6,8.1,4.2,15.9,14.1,11.4,7.7-3.5-5.2-13.9-5.3-20.3,0-1.8,3.2-12.2,4.1-13.6s1.3-.9,2.8-.8c9.4,15,15.7,23.8,5.8,40.7-3.1,5.3-14.6,16.6-20.9,13.5s-4.1-8.1-7.4-7.5-7.2,11.1-17.7,9.3c-6.2-1-4.7-7.3-8.6-10.7-11,11.2-27.9,21.1-44.3,16.9-20.5-5.4-17.4-42.7-19.5-58.7-1.1-8.6-7.3,10-8,11.2-8.8,15.7-31.8,48.6-50.7,50.9-12.5,1.5-18.8,2.7-31.1,5.1s-12.8,2-9-2,23.2-7.6,27.9-9.8c16.6-7.7,38.5-23.7,48.9-38.9,2.3-3.4,5.7-8.1,5.5-12.2-1.4-20.7-42.5-58-56.9-74.9-16.1-18.8-45.9-53.8-47.3-78.9-.2-2.9.7-12.3,2.7-13.7,4.6-3.4,3.8,1.5,5,3.3,3.5,5.5,16.4,22,17.1,27s-2.4,5.6-2.2,7.5,9.5,15.5,11.2,17.6c21.6,27.9,69.6,67,76,100.2,1.2,2.4,2.1,2.9,2.7,1.4l.3-.2h0Z"/>
 <path d="M1216.9,376c.3.3.5,7.6.4,8.5-1.5,14.2-3.5,23.3-12.4,34.4-13.8,17.2-28.1,26.9-44.2,40.4-29.6,24.9-59.9,49.2-87.3,76.2s-43.8,44-48.4,55.8c-4.8,12.3-3.3,23.4-9.9,35.4-2.4,4.4,0,6.2-7.9,8.5s-5.5-1.4-5.3-6.6c2.7-76,102.9-151.6,156.5-198.7,12.5-11,27.5-22.3,37.8-35.5,3.8-4.8,13.8-27.7,20.7-18.4h0Z"/>
 <path d="M1074.2,291.6c-2.8,2.8-8.2,3.9-10.8,6-32.3,25.3-63.3,52.7-93.4,80.3-19.3,17.7-36.5,30-51.3,52.3-1,1.4-1.2,3.6-2.4.3-1.9-5,3-10.1,1.1-15.4,2.7-34.6,34.5-55,58.6-75.8,19.7-17,42.2-32.1,62.5-50,9.4-8.3,20.8-17.3,29.5-26.7s9.4-18.4,15.6-10.3c4.6,6-4.2,34.1-9.3,39.2h0Z"/>
 <path d="M343.4,399c.4,4,3.6,16.6,5.2,20.2,5.3,12.1,7.9,1.1,8.5-5.8,2-23.2,3.2-42.2,12.7-64.1,3.1-7.1,6.6-12.5,6.6-.3,0,20-4.2,37.2-1.3,58.8s12.5,17.1,23.1,8.3c12.2-10.2,13.3-34.6,12.5-49.3-.7-13.5-7.8-35.1-7-46.5s3.6-16.8,6.9-14.4c1.1,13.4,6.7,30.2,6.9,43.3,0,3.7-1.8,7.1-1.5,10.9.9,10.6,2.1,36.2,4.9,44.6,6.3,19.3,31.9,12.4,30.8-6-1.6-26.5-28-72.9-16.5-97.7.7-1.6-.7-2.5,2.9-1.9l12.4,34.9c.6,4.5-4.9-1-4.1,5.4,2.2,16.6,13.7,39.6,12.8,57.2,0,1.3-6.3,20.6-7.1,22.6s-9.5,12.2-11.6,13.2c-16.1,8-24-7.4-25.7-21-.5-4.5-1.4-6.3-2.7-5.5-3.2,4.4-3.8,12.7-5.8,16.7s-11.8,16.6-16.8,16.9-10.9-.6-11.4-.9c-1.9-1.3-5.7-11.8-6.3-14.7-2.7-13.2-3.1-37.2-2.2-50.9.4-3.8,0-5.2-1.4-4.1-8.5,23.6-7.3,49.6-11,74-5.8.8-7.4-5-9.3-9.2-4.5-10.4-6.1-23.3-7.2-34.6.4-3.6,1.7-3.5,2.7,0h0Z"/>
 <path d="M1140.8,1021.7c-2.9,12.7-26.5,66.1-39.3,66.8-6.3.4-3.7-3.4-3.9-3.7s-2-.3-2.6-2.2c-3.1-10.3-2.5-22-4.2-32.7-1.1-6.9-3.4-14.3-4.7-21.3-.9-4.6.6-8.4-2.5-12.6-9.5-13.2-17.5,9.6-24,13-6.8,3.5-4.8-7.2-3.4-11.7,2.6-8.4,19.5-32.6,28.8-33,8.9-.4,13.3,7.6,15.1,15.1,1.9,7.7,4.2,16.4,5.8,24.4,2.3,11.3.6,24.7,6.6,34.6,7,1.6,21.1-38.8,24.7-45.3,4.7-8.6,18.2-27.3,26.6-11.5,6,11.2,3.3,23.7-8.8,29-5.2-5.4-3.4-13.6-14.1-9h0Z"/>
 <path d="M506.7,707.4c3.8,2.5,10.5-9.1,12.1-11.9,5.8-10.6,17.9-51.6,32.5-51.1s15.5,40.1-4.8,34.1c3-7.5-3-11.5-9.1-6.9s-11.5,28.2-14.3,33.8c-2.4,4.7-8.1,14.1-11.2,18.9-6.4,9.8-19.2,22.1-22.7,2.7-2-11-2.9-23.2-4.9-35s-3.8-27.9-6.7-29.5c-11.2-6.3-15.8,15.2-25.1,16.1-7.6-1.5,6.6-25.8,9.1-29.2,8.9-12.1,25.4-26,32.8-4.4s7.1,34.7,10.6,51.1c.4,2.1.6,10.6,1.7,11.3h0Z"/>
 <path d="M569.1,407.2c-17.2-14.8-26.8-4.4-29.2,14.7-.9,7,2.3,13.4-.3,19.9-10.4,9.4-22.8,16.7-33,26.3-11.2,10.4-19.3,24-29.3,34.5-18.2,19.2-28.9,15.7-26.9-12.5.8-10.2,4.7-23.2,9.6-32.2h0c1.2-2.6.5-3.3-1.4-1.5-11.4,16.7-18.5,43.4-13.3,63.3,2.4,9.4,6,16.2,16.9,12.2,8.8-3.3,23.3-26.4,32.6-36.6,9.3-10.2,18.4-16.7,29-25.1,2.5-1.9,16.6-17.1,19.9-13.7l-1.4,11.6c6.6,1.2,5-12.1,8.2-16.5,3.2-4.4,18-10.5,22-16.3,6.6-9.7,5.3-20.6-3.4-28.1ZM550.6,434.7l-2.8-16.4c1.4-1.4,15.9,6.1,16.4,6.9h0c1.9,2.6-12.7,10.2-13.7,9.5Z"/>
 <path d="M935.2,620.2c-.5.3-.6,1.9-2.2,2.6-4.7,2.2-27.3,9.1-28.6,1.6-2.1-12.5,12.2-9.1,19.3-12.3s10.8-7.3,12.6-8.9c4.8-4.2,18.6-22.5,20.3-28.1s-.2-2.6-2-2c-7.3,2.2-16.4,9.8-24.1,13s-7.5,1.6-10.9,3.4c-.3-10.9,6.5-22.2,14.2-29.6,6.5-2.2,46.3-29.9,48.9-28.7s3,6.4,2.4,9.4c-1.2,6-11.6,26.6-15,33-5.9,10.8-19.9,33.3-27.9,42s-4.3,3-7,4.7h0Z"/>
 <path d="M487.3,265.8c-3.7,2.1-5,1.2-8-1.2.8,20.7,2.3,41.7,4.2,62.3,2.6,29.9,10.7,64.5,3.4,94l-6.2,13.7c-1.4-8.5,2.2-17.1,2.2-25.1,0-34.8-9.6-79.7-13-115.6-1.5-16.6-1-33.6-3.3-50.2-.5-3.3-3.1-7.9-3.2-9.9s5.3-15.4,9.1-12.4c1.4,6.6,17.2,40.9,14.9,44.4h-.1Z"/>
 <path d="M1218.7,874.7c.3.2,5.8-1.2,6.5-1.8,2.1-3.9,14-27.9,16.2-28.4,5.5-1.3,6.4,11.6,5.8,15.3-1.4,8.7-18.3,37.3-24.3,44.3s-13.2,13.2-17.2,4.8c-11.5-24.6,11.7-65.9,15.5-91.3,2.7-1.3,5.2-11.4,7.8-10.2s1.4,6.8,1.1,9.3c-1,6.7-2.9,14.8-4.6,21.4-1.5,6-12,32.8-6.7,36.4v.2h-.1Z"/>
 <path d="M583.2,1047.1c.6-.2,7.5-8.6,8.5-10,2.4-3.1,10.9-18.3,12.3-19.2,4-2.4,5.8-.8,6.5,3.1,2.6,14-9.1,24.1-16.1,33.9-1.6,2.3-1.8,5.3-3.5,7.5-8.8,11.5-24.7,28.6-30.5,4.5-4.5-18.7.6-42.6,5.5-60.9s2.8-11.7,4.4-12c2.7-.6,3.3,2.7,3.5,4.8.8,8.4-3.6,17.3-3.7,24.9,0,6.3,3.3,27.3,12.9,23.4h.2,0Z"/>
 <path d="M800.3,1258.5c12.1-3.8,17-47.6,31.9-43.7s7.3,21.7-2.4,24.4c-9.3-18.7-12.6,5.3-16.5,13.7-3.1,6.6-13.2,28.1-21.7,26.2-5.4-7.4-3.6-18.6-5.1-26.8s-4.4-15.6-5.3-23.5c-10.5-7.8-9.9,8.4-19.3,9-1-9.1,6-20.6,12.4-26.7,21.9-21.1,22.3,36.7,26.1,47.3h-.1Z"/>
 <path d="M381.8,1181.8c3.2,3.3,10.9-10.4,10.7-13.2,3.5-6.2,13.6-37.3,24.4-29.4,7.4,5.5,2.2,25.1-4.2,23.4s-3.4-9.7-8.7-6.7-8.4,21.7-10,25-14,22.9-18.3,22.6c-6.7-.5-5.7-19.5-6.3-23.5s-5-27.1-6-28.3c-6.2-7.3-13.3,9.3-15.8,10.3-6,2.4-3.6-5.7-2.3-9.2,3-8.3,6.5-12.1,12.6-17.6s2-5.4,6.9-5.4,7.2,3.5,8.6,6.3,4.6,15.9,5.3,19.4,2.2,25.4,3.1,26.4h0Z"/>
 <path d="M234.6,423.2c4.1-3.6,9.8-23.6,13.4-29.8,3.5-6.2,12.8-17.7,18.3-7.2,4,7.7.9,20.7-9.3,19.6v-5.5c-2.2,0-4.5-.3-6.3,1.2s-10.7,25.2-13.7,30.2-15.1,24.1-19.2,8.4-2.7-26.5-4.8-37.7c-.8-4.1-2.1-7.9-6.6-8.3-6.9-.5-7.4,12.2-15.3,11.6-4.5-5,13.2-30.3,19.3-32.2s8.6,3.4,10.1,6.3,5.2,19,5.9,22.9-.7,28.2,8.2,20.5h0Z"/>
 <path d="M211.7,958.3c1.5,1.1,7.4-5.4,8.1-6.6,4.7-8,12.3-42.4,26.2-35.3,6.2,3.1,5,22.6-3.4,22.7-5.3,0-1.9-5.2-2.1-5.5-.7-1-6.4-1.4-7.9.3-2.1,2.5-6.6,17.9-8.7,22.1-2.2,4.5-13.3,24.2-18.4,23.8-7-.5-6.9-24.3-7.9-30.1s-4.1-19.9-5-21.1c-6.4-8.6-15.2,13.2-19.3,9s7.5-20.2,10.2-23.4,8.4-7.4,8.9-7.6c10.5-3.4,13.8,17.4,15.3,24.8s2.7,25.9,3.9,26.8h0Z"/>
 <path d="M746.6,157.9c.6.9.3,13.2-.2,15.3-1.1,4.7-12.4,19.1-15.9,23.8-3.7,4.9-4.2,10-12.3,12.5s-7.1-6.6-7.7-11.9c-1.4-12.4,9.5-45.5,14.4-58.3s5.4-15.7,8.8-10.4-14.3,45.2-11.1,49.3,2.9,1.8,4.9.7c5.1-2.8,10.5-21.3,15.7-22.2s3.2,1,3.3,1.1h0Z"/>
 <path d="M941.8,227.3c.3.4,2.8,1.5,3.2,1.4,3.7-.5,12.5-23.5,16.4-24.4s3.9,12.7,3.3,15.6c-1.1,5-15.5,27.6-19.5,31.9-9.4,9.9-14.6,8.3-16-5.7-1.4-13.8,10-48.5,15.5-62.6s3.9-8.8,5.8-7.9c4.4,2.1-1.8,21.2-3,25.7s-5.7,13.9-6.5,20.7c-.2,1.3.2,4.4.9,5.3h-.1Z"/>
 <path d="M429.8,216.7c1.6,8.9-2.3,15.2-8.2,21.2-13.5,13.8-44.4,30.3-54.8,44.2s-1.5,6.5-4.1,7.2c-.3-5.1,1.1-13,4.1-17.2,7.7-10.9,30.7-27,41.5-36.2l21.6-19.2h-.1Z"/>
 <path d="M413.4,455.2c.7.7-1.3,9.3-2.1,11-6.3,14.8-48.2,38.3-61.1,54.1s-1.8,5.6-4.1,6.2c-.4-6.4-.4-11.1,3.4-16.5,4.5-6.3,18.9-17.8,25.6-23.8,9.5-8.3,19.9-15.7,29.3-24.1,1.9-1.7,6.3-9.4,8.9-6.9h.1Z"/>
 <path d="M285.3,824.5c2.3,1.3,8,8.1,12.1,7.8s4.6-8,6.3-9.1c3.6-2.3,18.8,8.7,19.2,12.4s-5.7,13.4-10.2,13.1c-.5,0-4.7-6.2-6.6-7.4-12.7-8.2-8.9.6-12.9,3.3-6.6,4.3-23-5.5-20.5-13.9,2-6.5,6.1-9.9,12.6-6.3h0Z"/>
 <path d="M763.2,296.2c1.3,1.5-4.4,14.7-5.9,16.1-2.5,2.2-10.9,4-14.1,5.8-6,3.4-10.7,8.9-17.4,11.1-3.6,5.1-17,9.8-15.9,16.8s3,4.8,3.6,7.5c1.5,7-1.8,15.2-5.9,20.9-2.7,3.7-9.3,10.7-8.8,2.2.3-5.7,6.1-6.4,6.8-8.5s-8.1-12.7-.6-25.1c4.8-7.9,28.8-25.5,37.2-32.1,1-.8,1.5-3,3.3-4.2s17.1-11.1,17.8-10.4h0Z"/>
 <path d="M245.5,649.4c-2,3-12.9,17.3-14.9,19.3s-8.6,5.7-9.2,5.2c-3.3-2.5-1.9-9.3.2-12.2s8.8-5.5,12.2-8.4,14.7-14.4,6.8-12.5l-15.7,2.2c.7-18.1,8.2-15.5,21.4-18.4s8-5.8,10.2-2.2c1.7,2.8-8.6,23.6-10.8,26.9h-.2Z"/>
 <path d="M334.5,278.4c-3.2-1.4.3.2-2.9,5.5-3.2,5.3-8.4,9.5-8.4,17.4s6.3,6.5,6.6,8.1-7.9,15.5-13.8,23.1-21.1,22.1-28.8,28.1h0c-.7,1.7.2,2.1,2.7,1.5,17.3-7,31.8-29.9,41.2-44.8,1.1-1.7-1.2-3.4,1.5-5.8,2.7-2.4,16.6,5.1,16.3-9.1-.1-5.8-9.4-21.9-14.4-24ZM344.8,304.4c-3-.2-6.8-.5-9.6-1.4-.4-.9-.6-2.1-.7-3.2-.2.7-.5,1.3-.7,1.8-7.3-3.3-10.1-3.8-3.4-12.3,3.1-.7,2.7,3.1,3.4,4.1.8.7,1.2,1.7,1.2,2.9.3-.7.8-1.3,1.6-1.5.3-1-.2-5.3,2.7-2.7.9.8,6.7,11.1,5.5,12.3ZM335,296.3c-.4.9-.5,2.2-.5,3.5.3-1.1.5-2.3.5-3.5Z"/>
 <path d="M509.4,155c3.6,13.5-8.6,16-16.9,22.2s-26.7,20.7-34.5,29.9-7.1,13.9-10.3,11c3.6-18.7,20.9-32.1,35-43.8,3-2.5,25.2-20.4,26.7-19.2h0Z"/>
 <path d="M557.4,345.5c-8.9-1.1-23.3,23.7-16.3,28.1,3.5,2.2,7.1-2.8,1.8,6.1-5.3,8.9-23.4,25.6-32.7,30.4-1.8.9-2.4-1.2-4.9.5s-6.1,3.5.7,4.8,33-21.2,40.9-31.9c7.9-10.7,15.3-24.3,10.5-38ZM545,362.7c.9-8.9,5-5,8.3-4.8,2.6,8.1-9.2,13.7-8.3,4.8Z"/>
 <path d="M392.8,606c1.4,1.5-2.4,11-4.1,13-2.6,3-7.8,4.6-10.9,6.9-7,5.3-13.8,12.4-21.6,16.1l-19.7,20.1c.2-6.2,2.7-13.9,6.9-18.5,9-10,28.8-22.6,40.1-30.9,1.6-1.2,6.8-9.3,9.3-6.8h0Z"/>
 <path d="M287.2,456.6c.5.5,0,6.7-.6,8.3-1.7,5.3-31.2,23.9-38.1,30.1s-11.1,13.2-17.5,17.8c-.4-2.4.4-4.3,1.1-6.4,3.9-13,38.8-39.7,51-48.8s3.4-1.7,4.1-1Z"/>
 <path d="M182.8,589.6c1.5.9,5.3,19.6,5.3,22.5s-3.2,26.7-7.9,21.2c.5-4.8,1.4-8.1.6-13.1s-6-15.8-5.8-20.3,3.8-12.9,7.7-10.4h0Z"/>
 <path d="M593.1,266c-1,2.1-1.9,1.2-2.7-2.7-.5-17-6.6-32.3-4.1-48.6.3-1.5,1.8-.9,2.8,0,2.2,2,4.9,18.1,5.4,22,1.2,10.1.4,19.4-1.3,29.4h-.1Z"/>
 <path d="M394.2,282.5l4.8,1.4,2.7-9.6c.5,4.4-1.3,7.5,4.8,8.2.4-1.2.8-1.2,1.4,0-.5,1.9.3,3.8-2,5.6s-3.8-.2-5.4-.1-3.8,8.2-7.7-1.4c-1-3.2-.5-4.6,1.4-4.1h0Z"/>
 <path d="M343.4,399h-2.7c-1.1-10.6-1.8-17.3,0-28.1s0-4.2,2.7-4.8c.8,10.6-1,22.5,0,32.9Z"/>
 <path d="M416.1,1283.2v2.7c-3.5,1.2-7.4,4.9-11,3.4.9-4.9,7.7-4.6,11-6.2h0Z"/>
 <path d="M407.9,282.5h-1.4l.7-10.9c4,3.2,1.7,7.3.7,10.9h0Z"/>
 <path d="M458.7,456.6c2-2.9,3.5-8.3,8.2-8.2-2.1,3.2-5,6.2-6.9,9.6l-1.4-1.4h0Z"/>
 <path d="M807.1,654h0c0,.1-.2.1-.2.1,0,0,0,0,0-.1,0,0,.1,0,.2,0Z"/>
 <path d="M216.6,766.2c-.4.2-.7.2-.7.2v-.3c.2,0,.5,0,.7.1Z"/>
 <path d="M334.5,299.8c0-1.2,0-2.5.5-3.5,0,1.1-.2,2.4-.5,3.5Z"/>
 <path d="M334.5,299.8c0-1.2,0-2.5.5-3.5,0,1.1-.2,2.4-.5,3.5Z"/>
 <path d="M336.6,294.8c-.9,2.6-1.5,5.4-1.4,8.2-.4-.1-.9-1.2-1.4-1.4v-8.2c.3.5,2.1.9,2.7,1.4h0Z"/>
</svg>
                    </div>
                    <div className='title_of_chapter'>
                    <p> Марям алайҳас-салом  </p>
                    <p>қиссаси</p>
                    </div>
                  </div>

                  <div className='bism'><p>@</p></div>

                  <article className='flex_in_doublecolumn'>
                    <div className='uzbek_quron_doublecolumn'>
                      <p> «Албатта, Оллоҳ Одамни, Нуҳни, аҳли Иброҳимни, аҳли Имронни оламлар ус­ти­дан танлаб олди»  <span className='vitalic'> (Оли Имрон сураси 3:33). </span> </p>
                    </div>

                    <div className='arabic_quran_doublecolumn'>
                    <p style={{ lineHeight: '1.2'}}>
                      إِنَّ ٱللَّهَ ٱصْطَفَىٰٓ ءَادَمَ وَنُوحًۭا وَءَالَ إِبْرَٰهِيمَ وَءَالَ عِمْرَٰنَ عَلَى ٱلْعَـٰلَمِينَ 
                    </p>
                    </div>
                  </article>

                  <div className='paragraph_heading'>
                    <p> Марям (р.а.) нинг дунёга келиши </p>  
                </div>
            
   

                <div className='main_text'>
                  <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
                    onClick={() => handleSupClick(1)}
                  >
                    <p> <sup>1</sup>  Табарий. “Тарих”, 2-жилд, 13-бет.
                    </p>
                  </div>
                  <p onClick={() => handleSupClick(1)} >
                  Марям (р.а.) нинг онаси – Ҳанна бинти Фа­ҳуд<sup>1</sup>  деган аёл бўлиб, у Закариё пай­ғам­бар­нинг аёли Ашба Биби билан опа-сингил бўлган. 
                  </p>
                  <p></p>
                </div>

                <div className='main_text'>
                  <div className='speech-bubble' style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
                    onClick={() => handleSupClick(2)}
                  >
                    <p> <sup>2</sup> Салабий. “Ароис”, 371-бет.
                    </p>
                  </div>
                  <p className='vitalic' onClick={() => handleSupClick(2)} >
                   
                  Ҳанна опа, ёши анча жойга бориб қол­ган­да ҳомиладор бўлади<sup>2</sup>  ва бир қушнинг по­ла­по­нига ов­қат бераётганини кўриб Оллоҳга дуо қи­ла­ди. Бу дуо Қуръони каримнинг Оли Имрон сураси, 35-оятида бундай деб баён қилинган:
                  </p>
                  <p className='boldItalic'>
                  «Имроннинг хотини: «Эй Роббим! Ал­бат­та, мен қорнимдагини холис Ўзингга назр қилдим. Бас, мендан қабул эт. Албатта, Сенинг Ўзинг эшитувчи, би­лув­чи зотсан», деганини эсла!»
                  </p>
                  <p></p>
                </div>

            
                
                <div className='main_text vitalic'>
              
                  <div className='speech-bubble' style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
                    onClick={() => handleSupClick(3)}
                  >
                    <p><sup>3</sup>   Ибн-и Асир. “Комил”, 1-жилд 298-бет.
                    </p>
                  </div>

                  <p className='vitalic' onClick={() => handleSupClick(3)} >
                  Ўша пайтда маъбад хизматига фақат ўғил болалар назр қилинар эди.<sup>3</sup>  Ҳанна қор­ни­даги ҳамласини Оллоҳга назр қил­га­нида, эри Имрон: «Нега бундай қилдинг? Агар қиз бўлса нима қиласан?» деб сўрайди. Кўз ёриган пайтда кўрсалар, фарзанд дар­ҳа­қиқат қиз экан:
                  </p>
                  <p></p>
                </div>

            <div className='main_text'>
              
              <div className='speech-bubble' style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
                onClick={() => handleSupClick(4)}
              >
                <p><sup>4</sup> Зурриёт сўзига эътибор беринг: Иброҳим (а.с.) билан қилинган Аҳдда ҳам айни шу сўз тилга олинган эди.
                </p>
              </div>

              <p className='boldItalic border p-4 rounded-md border-1 border-green-600' onClick={() => handleSupClick(4)} >
              «Туққан чоғида: «Эй Раббим, мен буни қиз туғдим-ку!!» деди. Ҳолбуки, Оллоҳ унинг нима туққанини яхши билади. «Ўғил қиздек эмас. Мен унга Марям деб ном қўйдим ва, албатта, Сендан унга ва унинг зурриётига<sup>4</sup> шайтонир ражимдан паноҳ тилайман», деди» <span className='vitalic'>(Қуръони карим, Оли Имрон сураси, 36-оят).</span>
              </p>
             
              <p></p>
            </div>
           
            <div className='main_text vitalic'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[5] ? 'block' : 'none' }}
              onClick={() => handleSupClick(5)}
            >
              <p>
                <sup>5</sup> Салабийнинг “Ароис” кито­би­да ёзилишича, бу ҳужра маъбадда бўлган (372-373 бет­лар).
              </p>
            </div>
            <p onClick={() => handleSupClick(5)}>  Орадан бир қанча вақт ўткач, Закариё (а.с.) Марям учун ка­фил-оталикка сайланиб уни ўз тарбиясига оладилар. Балоғат ёши­га етгач, алоҳида бир ҳуж­ра­га<sup>5</sup>   жой­лайдилар. Шунда бир мўъ­жи­за­нинг гу­во­ҳи бўладилар, қачонки Марям­нинг хо­на­­си­­га кирсалар, фаслда йўқ ме­ва­лар­нинг, яъни қишда ёзги меваларнинг ва ёзда қиш­ки меваларнинг турганини кўрадилар.
            </p>
            <p className='boldItalic'>
            <span className='vitalic'>Бу xусусда Қуръони Каримда бундай деб ёзилган: </span> «Бас Парвардигори <span className='vitalic'>(Марямни)</span> хуш қабул айлаб, чиройли парвариш этди ва унга Закариёни кафил қилди. Ҳар қа­чон Закариё унинг олдига ҳуж­ра­га кир­га­нида, унинг ҳузурида бир ризқ-на­си­ба кўрди. «Эй Марям, бу нарсалар сенга қаeрдан келди?» деганида, <span className='vitalic'>(Марям)</span> айтдики: «Булар Оллоҳнинг ҳу­зу­ри­дан. Албатта Оллоҳ Ўзи истаган ки­ши­ларга беҳисоб ризқ берур» <span className='vitalic'>(Қуръони карим, Оли Имрон сураси, 37-оят.) </span>


            </p>
            <p style={{fontWeight: '900'}}>
            Бу воқеаларнинг ҳаммаси Ашба Биби Яҳё (а.с.) га ҳамладор бўлишидан аввал со­дир бўлган эди.
            </p>
             </div>

             <div className='paragraph_heading'>
                    <p> Биби Марямнинг ҳамладор бўлиши  </p>  
                </div>

             <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[6] ? 'block' : 'none' }}
              onClick={() => handleSupClick(6)}
            >
              <p>
                <sup>6</sup>  Оли Имрон сурасида бир нечта фариштанинг келгани ёзил­ган: «Фа­риш­та­лар­нинг: “Эй Марям, Раб­­бинг­га итоат қил ва ру­куъ қилувчилар би­лан бирга сажда ва рукуъ қил”, де­ган­ла­­ри­ни эсла» (43-оят.)
              </p>
            </div>
            <p onClick={() => handleSupClick(6)}>
            Закариё (а.с.) нинг хотини ҳомиладор бў­либ, ҳамласига олти ой тўлганда, Оллоҳ (с.в.т.) Марям (а.с.) нинг олдига ҳам Жаб­ро­ил (а.с.) ни <span className='vitalic'>(бир гуруҳ фаришталар билан)</span><sup>6</sup> юбо­ра­ди.
            </p>
            <p>
            Марям (а.с.) бу пайтда Носира шаҳ­ри­да яшар, Юсуф исмли бир йигитга унаш­ти­рил­ган эди.  
            </p>
            <p></p>
            </div>
 
 


<div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[7] ? 'block' : 'none' }}
              onClick={() => handleSupClick(7)}
            >
              <p>
                <sup>7-8</sup> Қуръони карим, Марям сураси, 19:17-18 оятлар.
              </p>
            </div>
            <p onClick={() => handleSupClick(7)}>
            Қуръонда Жаброил (а.с.) нинг инсон кў­ри­ни­шида юборилгани айтилган: «Биз (Мар­ям­га) руҳимизни юбордик ва у (Мар­ям­га) бус-бутун инсон кў­ри­ни­ши­да намоён бўлди».<sup>7</sup>  
            </p>
            <p></p>
</div>


<div className='main_text'>
          
            <p onClick={() => handleSupClick(7)}>
              Марям (а.с.) қўрқиб кетадилар. Кейин: <span className='boldItalic'>«Мен Раҳмондан сендан паноҳ беришини сў­рай­ман, агар тақводор бўлсанг...»,</span><sup>8</sup>  дей­ди­лар. 
            </p>
            <p>
            
            </p>
</div>

<div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[9] ? 'block' : 'none' }}
              onClick={() => handleSupClick(9)}
            >
              <p>
                <sup>9</sup>  Оли Имрон сураси 42-оят.
              </p>
            </div>
            <p onClick={() => handleSupClick(9)}>
            Фаришталар эса: «(Қўрқма) <span className='boldItalic'> эй Мар­ям, шуб­ҳа­сиз, Оллоҳ сени танлади, пок­лади ва бутун оламлардаги аёллар­дан аф­зал қил­ди</span>»,<sup>9</sup> деб башорат беришади.   
            </p>
            <p></p>
</div>

<div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[10] ? 'block' : 'none' }}
              onClick={() => handleSupClick(10)}
            >
              <p>
                <sup>10</sup>   Марям сураси 19:19. Покиза сўзи араб тилида «закий» сўзи билан берилган. Мана шу оятга асосан, ҳазрати Муҳаммад соллаллоҳу алайҳи ва салламнинг ҳадисларида айтилганки, ҳар бир инсон зоти дунёга келар экан, шайтон унга нишини санчади, кейин чақалоқ йиғлайди, лекин Исо (а.с.) туғилганларида бундай бўлмаган экан (имом Бухорий).
              </p>
            </div>
            <p onClick={() => handleSupClick(10)}>

            Кейин Жаброил (а.с.): «Оллоҳнинг мар­ҳа­ма­тига сазовор бўлдинг. <span className='boldItalic'>Оллоҳ сенга по­ки­за бир ўғил ҳадя этмоқчи,</span><sup>10</sup> – деди. – Энди ҳамладор бўлиб, ўғил туғасан. Ис­ми­ни Исо қўясан. У буюк инсон бўлғай, Оллоҳ­нинг Масиҳи деб аталгай. Пар­вар­ди­го­ри­миз уни Ўз Салтанати тах­ти­га ўт­қа­за­ди ва у тоабад под­шоҳлик қилади», деди.
            </p>
            <p></p>
</div>


<div className='main_text'>
  <p>
  Шунда Биби Марям нима бўлаётганини ту­шун­май: 
  </p>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[11] ? 'block' : 'none' }}
              onClick={() => handleSupClick(11)}
            >
              <p>
                <sup>11</sup>  Яъни, Биби Марям ўшанда бокира бўлган.
              </p>
            </div>
            <p onClick={() => handleSupClick(11)} className='boldItalic'>
           «Менга одамзод тегмаган бўл­са, бузуқ аёл бўлмасам, қаёқдан фар­занд бўлсин?» дейди.
               <sup>11</sup>  
            </p>
            <p></p>
</div>



<div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[12] ? 'block' : 'none' }}
              onClick={() => handleSupClick(12)}
            >
              <p>
                <sup>12</sup>  Марям сураси 19:20-21.
              </p>
            </div>
            <p onClick={() => handleSupClick(12)} className='boldItalic'>
            <span className='vitalic'>(Фаришта)</span> эса: «Ҳа, шун­дай. Раббимиз дейди-ки, бу Мен учун осон. Биз уни <span className='vitalic'>(зурриётни)</span> инсоният учун оят-мўъ­жиза ва Биздан бўлган раҳмат қилиб <span className='vitalic'>(юбор­яп­миз)</span>. Бу – битган иш», <span className='vitalic'>деб жа­воб беради.</span><sup>12</sup>  
            </p>
       <p></p>
</div>

<div className='main_text boldItalic'>
  <p>
  <span className='vitalic'>Оли Имрон сурасида бу во­қеа ҳақида бундай деб ёзил­ган: </span> «Эй Марям, дарҳақиқат Оллоҳ сенга Ўз Сўзининг хушхабарини бермоқда-ки, унинг исми ал-Масиҳ, Ийсо ибн Марямдир. У бу дунёда ҳам, охиратда ҳам обрўли ва яқин бандалардан. У одамларга бешикда ҳам, қариган чоғида ҳам гапиради ва солиҳлардандир». 
  </p>
  <p> <span className='vitalic'>(Шунда Марям)</span> деди: «Эй Раб­бим, менга башар қўл тек­киз­маган бўлса, қан­дай қи­либ болам бўлади?!» </p>
<p>

У зот: «Шундай, Оллоҳ хоҳ­ла­ганини қи­ла­ди. Бир ишни ирода қилса, «Бўл!» дейиши ки­фоя, бў­ла­ве­ра­ди», деди» <span className='vitalic'>(Оли Имрон 3:45-47 оятлар). </span>

            </p>
            <p></p>
</div>

<div className='border pt-0 p-4 rounded-md border-red-700'>
<div className='paragraph_heading'>
            <p> Исо (а.с.) га берилган сифатлар </p>
          </div>

          <div className='main_text vitalic'>
            <p>
            Қуръоннинг ушбу ояти ка­ри­ма­ла­ри­дан ҳазрати Исо  ҳақида берилган баъзи бир маълумотларни таъ­кид­ла­ши­миз мумкин. Айтилган оятларга биноан у киши:
            </p>
            <ul>
              <li>
              <span style={{fontWeight: '900'}}>• закий</span>, яъни бегуноҳ инсон бўлганлар (шайтон у кишининг устидан ҳукм юргиза олмаган);
              </li>
              <li>
              <span style={{fontWeight: '900'}}>• масиҳ</span>, яъни подшоҳлик мақомига эга бўлганлар;
              </li>
              <li>
              <span style={{fontWeight: '900'}}>• </span> инсоният учун <span style={{fontWeight: '900'}}>Оят</span>  эдилар (Оятуллоҳ исми Ислом динида фақат ҳазрати Исо (а.с.) га хос);
              </li>
              <li>
              <span style={{fontWeight: '900'}}>• </span>   Оллоҳнинг    <span style={{fontWeight: '900'}}>Раҳмати </span>    эдилар (Раҳматуллоҳ);
              </li>
              <li>
              <span style={{fontWeight: '900'}}>• </span>   Оллоҳдан келган  <span style={{fontWeight: '900'}}> Сўз </span>  эдилар (Калиматуллоҳ);
              </li>
              <li>
              <span style={{fontWeight: '900'}}>• </span> бу дунёда ҳам, у дунёда ҳам    <span style={{fontWeight: '900'}}> обрўли</span>;
              </li>
              <li>
              <span style={{fontWeight: '900'}}>• </span> Оллоҳга   <span style={{fontWeight: '900'}}> яқин </span>  (муқарраб) банда бўлганлар;
              </li>
              <li>
              <span style={{fontWeight: '900'}}>• солиҳ</span> инсон эдилар;
              </li>
              <li>
              <span style={{fontWeight: '900'}}>• </span> бешикда ҳам гапиришга қодир бўлганлар.
              </li>
            </ul>
          </div>

</div>


<div className='main_text'>
<p></p>
  <p>
  <span className='vitalic'>Инжили шарифда Жаброил (а.с) нинг мана бу сўзлари ҳам баён этилган: </span> «Оллоҳ таоло Ўз Руҳини юборади, Оллоҳнинг қудрати билан ҳамладор бўласан. Шу сабабдан туғилажак муқаддас фар­занд Оллоҳнинг Масиҳи деб аталади. Ана, холанг Ашба ҳам бе­пушт эди – қариган чоғида ҳомиладор бўлди. Оллоҳ учун ило­жи йўқ нарсанинг ўзи йўқ!»
  </p>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[13] ? 'block' : 'none' }}
              onClick={() => handleSupClick(13)}
            >
              <p>
                <sup>13</sup>  Марям сураси 22-оят.
              </p>
            </div>
            <p onClick={() => handleSupClick(13)}>
            «Майли, – деди Марям. – Мен Оллоҳ­нинг бандасиман, нима деса розиман». Шундан сўнг Жаброил фаришта кўздан ғойиб бўл­ди, Марям (р.а.) нинг қорнида эса фар­занд пайдо бўлди: <span className='boldItalic'> «Бас, у ҳамладор бўлди».</span><sup>13</sup>  
            </p>
</div>

{/* <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p>
                <sup>1</sup>  
              </p>
            </div>
            <p onClick={() => handleSupClick(1)}>
               <sup>1</sup>  
            </p>
</div> */}


          <BottomNav 
            labelLeft='Яҳё (а.с.)' 
            pathLeft='/public-resources/qisas-uzbek/zakariya'

            labelCenter='Биби Марям қиссаси'
            pathCenter='/public-resources/qisas-uzbek/maryam'

            labelRight='Исо (а.с.) таваллуди'
             pathRight='/public-resources/qisas-uzbek/jesusBirth'
          />  

        </main>
        <aside></aside>
        </div >
      </div >
    )      
}

export default Maryam;