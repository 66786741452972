import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const JolutIsDead = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>
                    Довуд (а.с.) Жолутни ўлдиради  
                  </p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

                <div className='paragraph_heading'>
                    <p> Подшоҳнинг ҳузурида  </p>  
                </div>

            
          <div className='main_text'>
          <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p> <sup>1</sup> «Жолут ва унинг аскарларига рўбарў келганларида: «Раббимиз, бизга сабр тўк­кин, бизни собитқадам қилгин ва ко­фир қавмларга қарши нусрат бер­гин», де­ди­лар. Бас, Оллоҳнинг изни билан улар­ни енгдилар. Ва Довуд Жолутни ўл­дир­ди...» (Бақара, 250-251.)
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
            Довуд (а.с.) келиб: «Ҳазратим, бу фи­лис­тий­ни деб руҳингиз тушмасин, мен у билан жанг­га тушаман», дедилар. <sup>1</sup>  
            </p>
            <p>
            «Сенинг кучинг етмайди, – деди подшоҳ. – Ҳали ёшсан, у эса урушда суяги қотган таж­ри­ба­ли жангчилардан». 
            </p>
            <p>
            «Мен чўлда отамнинг қўйларини боқиб юр­ганимда баъзан шер, баъзан айиқлар ке­либ сурувдан қўй тортиб кетарди, – де­ди­лар Довуд (а.с.). – Ўшанда мен уларнинг изи­дан қувиб бориб, қўйимни уларнинг оғ­зи­дан тортиб олар эдим. Менга ҳужум қи­ла­диган бўлса, ёлидан ушлаб уриб ўл­ди­рар­дим. Шерниям, айиқниям ўл­дир­ганман, бу кестирилмаган ким бў­либ­ди тирик Худо­нинг лашкарини ҳақорат қилади?! Мени шеру айиқ чан­га­ли­дан асраган Оллоҳ, бу фи­листийдан ҳам асрайди».
            </p>
            <p>
            Шундан сўнг Толут: «Майли, бор, Оллоҳ сенга ёр бўлсин!» деди ва эгнидаги жанг либосини ечиб, Довуд (а.с.) га берди. Бошига бронза дубулға, эгнига совут кий­дирди. 
            </p>
            <p>
            Лекин Довуд (а.с.), подшоҳнинг қилич осил­ган камарини совут устидан боғлаб, у ёқдан-бу ёққа юриб кўрдилар-да: «Мен буларни киёлмайман, ўрганмаганман», деб ечиб таш­ла­дилар. 
            </p>
            <p> Кейин сойдан бешта силлиқ тош териб, чўпон халтасига солдилар, таёғи билан палахмонини қўлларига олиб Жолут­га қарши майдонга тушдилар.
            </p>
            <p></p>
          </div>
         
          <div className='paragraph_heading'>
             <p>  Жолутнинг мағлуб бўлгани </p>  
          </div>
         
          <div className='main_text'>
            <p>
            Филистий ҳам қалқон кўтарган қу­рол­бар­до­ри билан Довуд  томон юрди ва “мен билан олишишга чиққан ким экан”, де­ган­дек у кишига бошдан-оёқ разм солди. 
            </p>
            <p>
            Довуд­нинг ҳали жуда ёш эканини кўриб мен­си­мади. Кейин таёғини кўриб:
            </p>
            <p>
            «Нима, мен сенга итманми, олдимга таёқ кўта­риб келдинг, – деб худоларининг номи билан у кишини қарғади. – Кел, келавер, ҳозир гўштингни тилкалаб, кўкдаги қуш­лар­га, даштдаги ҳайвонларга ем қиламан!» 
            </p>
            <p> Довуд алайҳиссалом эса бунга жавобан дедилар: 
            </p>
            <p>
            «Сен менга қарши найза ва қилич билан чиқдинг, мен эса сен ҳақорат қилган Оллоҳ номи билан, Исроил лашкарининг Худоси номи билан чиқдим, – деб жавоб бердилар. – Оллоҳнинг ёрдами билан мен бугун бошингни оламан. Фи­лис­тийлар жасадини кўкдаги қушларга, ердаги ҳайвонларга едираман. Шунда бутун ер юзи Исроилда Худо борлигини билиб олади. 
            </p>
            <p>
            Эй оломон! Нажот – қиличу най­задан эмас, балки Оллоҳдан келади! Чунки бу жанг – Оллоҳнинг жанги, У бугун душ­­манларимизни қў­ли­миз­га таслим қи­ла­ди!»
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p>
                <sup>2</sup>  Салабийнинг “Ароис” ки­то­бида ёзилиши, Довуд (а.с.) тошни палахмонга жой­ла­га­ни­да, “Иброҳимнинг илоҳи Оллоҳ исми билан” деб айтган эканлар (Ароис 271-бет).
              </p>
            </div>
            <p onClick={() => handleSupClick(2)} >
                Жолут олдинга ташланди. Довуд (а.с.) ҳам югу­риб чиқиб, халтасидан тош олиб, па­лах­монга солиб отдилар.<sup>2</sup> 
            </p>
            <p>
            Тош бориб Жо­лут­нинг пешанасига қадалди. Филистий паҳ­ла­вон ўша он ерга юзтубан йиқилди. 
            </p>
            <p>
            Шундай қилиб ҳазрати Довуд, ўша куни фи­листийни бир дона тош билан мағлуб қил­дилар. Жангга чиққанларида қўлларида қиличи ҳам йўқ эди. 

            </p>
            <p>
            Кейин югуриб бо­риб, ёвни ўз қиличи билан ўлдирдилар, кал­ла­си­ни танидан жудо қилдилар.
            </p>
            <p></p>
          </div>
          
          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}
            >
              <p>
                <sup>3</sup>  Табарий, “Тарих”, 1-жилд, 245-бет.
              </p>
            </div>
            <p onClick={() => handleSupClick(3)} >
            Филистийлар лашкари Жолутнинг ўл­га­­ни­ни кўриб, жанг майдонини ташлаб ҳар томонга қоча бошладилар. <sup>3</sup> 
            </p>
            <p>
            Исроил қўшини ғолибона қийқириқ билан уларни то Жат ва Аккаронгача таъқиб қилиб борди. Бутун Шаарим йўли бўйлаб филистийлар жасади ёйилиб ётган эди. 
            </p>
            <p>
            Таъқиб қилиб бўлгач, Бани Исроил ортга қайтиб, душман қа­рор­го­ҳини талон-тарож қилди. 
            </p>
          </div>
          
          <div className='paragraph_heading'>
                    <p> Довуд (а.с.) ҳарбий хизматда  </p>  
          </div>

          <div className='main_text'>
            <p>
            Довуд  филистий билан жангга чиқа­­ёт­га­н­ида Толут, лашкарбошиси Аб­нур­дан: «Бу йигит кимнинг ўғли?» деб сў­ра­ган эди.
            </p>
            <p>
            «Ҳазратим, билмайман!» деб жавоб беради Абнур. «Бил», дейди подшоҳ. 
            </p>
            <p>
            Довуд (а.с.) филистийни енгиб, калласини олиб келаётганларида Абнур у кишини подшоҳнинг олдига бошлаб келди. 
            </p>
            <p>
            «Кимнинг ўғлисан?» деб сўради Толут. 
            </p>
            <p>
            «Байт­лаҳмлик Ийшаанинг», деб жавоб бер­дилар Довуд алайҳис-салом.
            </p>
            <p>
            Подшоҳнинг ўғли Янатон Довудга кўнгил боғлаб, уни ўзини яхши кўргандай яхши кўриб қолган эди. Шунга у билан аҳд боғлаб, устидаги кийимини, белидаги ка­ма­рини, совутини, қилич-камонини у ки­шига совға қилди.
            </p>
            <p> Ўша кунги ғалабадан сўнг Толут Довуд (а.с.) ни ўз ёнида сақлади, отасининг уйига қай­тишига рухсат бермади.
            </p>
            <p>
            Қаерга жў­нат­масин, ҳазрати Довуд ҳар бир жангдан ғалаба билан қайтар эдилар.
            </p>
            <p>
            Шундан сўнг Толут, у кишини Исроил қўшинига сар­карда қилиб тайинлади. Халққа ҳам, амал­дор­ларга ҳам бу нарса маъқул эди.
            </p>

          </div>


          



          {/* <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[9999] ? 'block' : 'none' }}
              onClick={() => handleSupClick(9999)}
            >
              <p> <sup>3</sup> YYYYYYY
              </p>
            </div>
            <p onClick={() => handleSupClick(9999)} >
               <sup>9999</sup> XXXXXXXXXXX
            </p>
            <p></p>
          </div> */}

<BottomNav 
            labelLeft='Довуд (а.с.)' 
            pathLeft='/public-resources/qisas-uzbek/david'

            labelCenter='Жолутнинг ўлими'
            pathCenter='/public-resources/qisas-uzbek/jolutIsDead'

            labelRight='Хоннинг ҳасади'
             pathRight='/public-resources/qisas-uzbek/tolutsJealousy'
          />

        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default JolutIsDead;