import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const CovenantBoxLost = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>Аҳд сандиғини бой бериш</p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

                <div className='paragraph_heading'>
                    <p> АБИНЗОРДАГИ ЖАНГ  </p>  
                </div>
            
          <div className='main_text'>
            <p>
            Бир куни, Абинзорда Бани Исроил билан филистийлар орасида уруш бўлди. Жангда филистийлар лашкари устун келди. Бани Исроилдан тахминан тўрт минг киши ҳалок бўлди.
            </p>
            <p>
            Қароргоҳга қайтгач, Исроил пеш­волари ҳайрон бўлиб: «Нега Оллоҳ бугун бизни филистийларга мағлуб қилди? Си­лом­га бориб Аҳд сандиғини олиб келайлик, бизни душмандан қутқарсин», дедилар ва Силомга одам юбориб, Аҳд сандиғини қа­рор­гоҳга келтирдилар. Ҳазрат Алининг ўғиллари Афний билан Финеҳ ҳам улар билан бирга келди.
            </p>
            <p>
            Сандиқ қароргоҳга келиши билан, исроилликлар еру кўкни ларзага солиб, наъра тортдилар. Филистийлар буни эшитиб, нима бўлди экан, деб ҳайрон бўлишди. Кейин Аҳд сандиғи келганини билиб, қўрқиб кетдилар. 
            </p>
            <p> 
              «Бани Исроил жангга ўз­ларининг худосини олиб келибди, энди ҳоли­мизга вой! – дер эди улар. – Аввал ҳеч бундай нарса бўлмаган эди! Уларнинг худолари кучли! Энди ким бизни қутқаради?»
            </p>
            <p>
            Бошқалари: «Мана шу худолар мисрликларни даштда ҳар хил офатларга гирифтор қилган! Биродарлар, бўшашмайлик. Агар мардлик билан жанг қилмасак, ўзимиз иб­ронийларга қул бўламиз!» деб уларни урушга чорлади.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> АҲД САНДИҒИ ҚЎЛДАН КЕТДИ </p>  
          </div>
            
          <div className='main_text'>
            <p>
            Жангда филистийлар ғалаба қозонди. Бани Исроил қаттиқ зарба еб, мағлуб бўлди. Ўттиз минг одами ҳалок бўлди, қолганлари уй-уйларига қочиб кетдилар. Ҳазрат Алининг иккала ўғли – Афний ҳам, Финеҳ ҳам ўлди. Аҳд сандиғи эса душманга ўлжа бўлди.
            </p>
            <p>
            Биняминлик бир киши жанг майдонидан қо­чиб, кийимлари йиртилган, боши­га тупроқ сочилган ҳолда ўша куни Силом­га етиб келди. Шум хабарни эшитиб, ша­ҳарликлар доду фарёд кўтардилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> Ҳазрат Алининг вафот этиши 
              </p>  
          </div>
          <div className='main_text'>
            <p>
            Бу пайтда руҳоний Али йўл четида курсида, Аҳд сандиғи нима бўлди экан, деб хавотирланиб ўтирган эди. 
            </p>  
            <p>
            (У киши тўқсон саккиз ёшда бўлиб, кўзлари нурсизланиб, кўрмай қолган эди.)
            </p>
            <p>
            Йиғи-сиғини эшитиб, одамлардан: «Нима бўлди?» деб сўради. Ҳалиги киши руҳоний­нинг олдига келиб: «Мен урушдан қочиб келдим», деди. 
            </p>
            <p>
            «Нима бўлди, ўғ­лим?» 
            </p>
            <p>
            «Филистийлардан қочдик, йи­гит­ла­ри­мизнинг кўпи ҳалок бўлди. Иккала ўғ­лин­гиз – Афний ҳам, Финеҳ ҳам ўлди. Худо­нинг сандиғи ҳам қўлдан кетди», деди у.
            </p>
            <p>
            Аҳд сандиғи душман қўлига тушганини эши­тиб, ҳазрати Али ўтирган жойидан орқасига қараб йиқилди. Бўйни узилиб, ўша ерда жон берди. У Бани Исроилга қирқ йил ҳа­камлик қилган эди.
            </p>
            <p>
            Ҳазрат Алининг келини, Финеҳнинг хотини ҳомиладор бўлиб, ой-куни яқин эди. Аҳд сандиғи қўлдан кетганини, қайнотаси ва эри ўлганини эшитган пайт уни тўлғоқ тутди. Тўлғоқ дарди кучли келиб, зўрға кўз ёриди. Жон берар экан, доя хотинлар: «Қўрқма, қўрқма, ўғил кўрдинг», деб далда бердилар.
            </p>
            <p>
            У эса дояларнинг гапига эътибор бермади. Ўлар экан, «Оллоҳнинг сандиғи қўлдан кетди, Исроилдан шуҳрат юз ўгирди», деб ўғлига “Ихавод” деб от қўйди. Ҳақиқатан ҳам Оллоҳнинг сандиғи қўл­дан кетган, эри ҳам, қайнотаси ҳам вафот этган эди.
            </p>
          </div>
         
          <div className='paragraph_heading'>
            <p> АҲД САНДИҒИ ФИЛИСТИЙЛАР ЮРТИДА </p>  
          </div>
            
          <div className='main_text'>
            <p> 
            Филистийлар Аҳд сандиғини Абинзордан Ашдод шаҳрига олиб келиб, Дағон бутхонасига, Дағон ҳайкалининг ёнига қўй­ дилар. Эртаси куни эрталаб қарасалар, Дағон Аҳд сандиғининг олдида, ерда, юзтубан бўлиб йиқилиб ётган экан! Улар Дағонни жойига кўтариб қўйдилар. 
            </p>
            <p>
            Кейинги куни эрталаб яна Дағонни Аҳд сандиғи олдида юзтубан ётган ҳолда топдилар. Фақат бу сафар унинг боши билан икки қўли кесилиб, остонада ётган эди.
            </p>
            <p> (Шу сабабдан Дағон коҳинлари ҳамда бутхонани зиёрат қилиш учун келадиган одамлар бугунгача остонанинг ўша жойига оёқ босмайдилар.)</p>
            <p>
            Аҳд сандиғи Ашдодга келгач, Оллоҳ таоло ашдодликлар устига ва Ашдод атро­фида яшовчи аҳоли устига офат ва қўр­қув юбор­ди. Одамларнинг баданига шиш­лар тош­ди. Буни кўрган ашдодликлар талваса­га тушиб: 
            </p>
            <p>
              «Исроил худосининг санди­ғини бошқа жойга жўнатамиз! У бизни ҳам, маъбудимиз Дағонни ҳам жазолаяпти», деб филистий пешволарини бир жойга тўп­лади­лар. Раҳбарлар қарорига кўра сандиқни Жат­га кўчирадиган бўлишди.
            </p>
            <p>
            Сандиқ Жатга келиши билан Жатни ҳам офат ва қўрқув қамради. Халқ, баданларига шиш тошиб азобда қолган, ҳаммани ваҳима босган эди.
            </p>
            <p>
            Шунда улар Аҳд сандиғини Эҳронга, филис­тий­ларнинг бошқа бир шаҳрига жўнатди­лар. Сандиқни кўриб, эҳ­рон­лик­лар: «Бизни ўлдириш учун исроилликларнинг сандиғини олиб келдингларми?» деб фарёд кўтардилар.
            </p>
            <p>
            Дарҳақиқат, Эҳрон аҳлига ҳам ўлат тарқаб, тирик қолганлари оғриқдан азоб чека бошладилар. Бутун шаҳар аҳлини даҳшат босди. Одамларнинг фарёди осмону кўкка ўрлади.
            </p>
            <p>
            Филистий пешволари яна кенгаш қуриб: «Энди нима қиламиз? Яхшиси, Бани Ис­роил­нинг сандиғини ўзларига қай­та­райлик. Бўлмаса, ҳаммамиз қирилиб кетамиз», деб қа­рор қилдилар. 
            </p>
            <p>
            (Аҳд сандиғи етти ойдан бери филистийлар юртида эди.)
            </p>
            <p>
            </p>
          </div>

          <BottomNav 
            labelLeft='Сомуил (а.с.)' 
            pathLeft='/public-resources/qisas-uzbek/samuel'

            labelCenter='Сандиқни бой бериш'
            pathCenter='/public-resources/qisas-uzbek/covenantBoxLost'

            labelRight='Аҳд сандиғи'
            pathRight='/public-resources/qisas-uzbek/covenantBoxReturned'
          />

              
        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default CovenantBoxLost;