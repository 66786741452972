import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const ТolutIsRejected = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>Толутнинг ўзбошимча иш қилгани</p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

                
          <div className='paragraph_heading'>
            <p> «ҲЕЧ КИМНИ ТИРИК ҚОЛДИРМА!»
            </p>
          </div>

          <div className='main_text'>
            <p>
            Бир куни Сомуил пайғамбар Толутнинг олдига келиб бундай дедилар:
            </p>
            <p>
             «Оллоҳнинг амри билан мен сени подшоҳликка масҳ қилган эдим. Энди Унинг каломига қулоқ сол. 
            </p>
            <p>
            “Мен Амолиқ халқини мажозот қи­ла­ман! – дейди Исроил лашкарининг Худоси. – Бани Исроил Мисрдан чиққанида амо­лиқ­лар сизларга ҳужум қилган. Энди сен Амо­лиққа қарши ҳужум қил! Эркагу аёл, кат­таю кичик ҳеч кимни аяма! Ҳўкизидан тор­тиб қўйигача, туясидан тортиб эшаги­га­ча ҳаммасини нобуд қил!”»
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p>
                <sup>1</sup> Улардан ўн минги Яҳузо қабиласидан эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
               Шундан сўнг Толут ҳамма ёққа элчи юбориб, одамларни Талойим шаҳрига келишга даъват қилди. Ҳаммаси бўлиб икки юз ўн минг пиёда жангчи йиғилди.<sup>1</sup> 
            </p>
            <p>
            Кейин подшоҳ қўшини билан Амолиқ ша­ҳар­ларидан бирига яқин келиб, қў­ши­ни­ни бир дарага жойлаштирди ва ўша ерда яшай­ди­ган кенитликларни:
            </p>
            <p>
             «Амо­лиқ­лар ора­си­дан чиқиб кетинг­лар, бўлмаса улар билан бирга ҳалок бўласизлар. Бани Ис­ро­ил Мисрдан чиққан пайтда сизлар бизга ях­ши­лик қилган эдингизлар», деб огоҳлан­тир­ди. 
            </p>
            <p>
            Кенитликлар кетгач, Толут Амолиққа ҳу­жум қилиб, уларни Ҳавиладан Миср шар­қи­даги Шургача бўлган ҳудудда тор-мор қилди. Амолиқлар подшоҳи Ажожни асир олди, қолганларни битта қўймай қи­лич­дан ўт­казди. Ҳайвонларнинг заиф ва май­да­ла­ри­­ни ўлдириб, яхшиларини эса ўз­лари билан олиб кетдилар.
            </p>
            <p></p>
          </div>
         
          <div className='paragraph_heading'>
            <p> Толутнинг рад этилиши </p>
          </div>

          <div className='main_text'>
            <p>
            Шунда Оллоҳ таоло Сомуил пайғамбарга: «Мен Толутни подшоҳликдан маҳрум қи­ла­ман, у Мендан юз ўгирди, айтганларимни бажармади», деб ваҳий юборди. 
            </p>
            <p>
            Сомуил пайғамбар буни эшитиб, кўп мутаассир бўлдилар. Бутун кечани Оллоҳга нола қилиш билан ўтказдилар. Кейин саҳар ўрнидан туриб, Толутнинг олдига йўл ол­ди­лар. 
            </p>
            <p>
            Борсалар, у Кармилга кетган экан. Эриш­ган ғалабаси учун у ерда ёдгорлик ўр­на­тиб, кейин Гилгалга кетибди. Сомуил пай­ғам­бар кейин Гилгалга бордилар. 
            </p>
            <p>
            Толутни учратиб, ҳол-аҳвол сўрашгач, под­шоҳ: «Мен Худонинг амрини адо этдим!» деди. 
            </p>
            <p>
            «Адо этдинг?! Унда нега қулоғимга қўй­лар­нинг маъраши, молларнинг мўъраши эши­ти­ляпти?» деб сўради пайғамбар. 
            </p>
            <p>
            «Булар йигитларим амолиқлардан олган моллар, – деди подшоҳ. – Энг сара қўй-сигирларни Оллоҳга қурбон қиламиз, деб олиб қолишди. Лекин бошқа бирон нарсани тирик қолдирганимиз йўқ». 
            </p>
            <p>
              «Тўхта, – дедилар пайғамбар, – мен сенга Оллоҳ бугун тунда нима деганини айтай». 
            </p>
            <p>
            «Айтинг», деди Толут.
            </p>
          </div>
         
          <div className='paragraph_heading'>
            <p> “НИМА МУҲИМроқ: ҚУРБОНЛИКми Ё ИТОАТ?” </p>
          </div>

          <div className='main_text'>
            <p>
            «Сен ўзингни ҳеч ким эмасман, деб юрганингда ким сени Исроил устидан под­шоҳ­ликка масҳ этди? Оллоҳ эмасми? – дедилар ҳазрати Сомуил. – У сенга: “Бориб фосиқ амолиқларни йўқ қил, улардан ҳеч кимни тирик қолдирма!” деб амр қилган эди. Нега амрини адо этмадинг? Нега ўлжага ташланиб, Оллоҳнинг қаҳрини келтирдинг?» 
            </p>
            <p>
            «Мен Оллоҳнинг айтганини қилдим! – деб жавоб берди подшоҳ. – Амолиқларга ҳужум қилдим, подшоси Ажожни асир олдим, халқини қиличдан ўтказдим. Йигитларим Оллоҳга қурбонлик қилайлик, деб йўқ қилиш керак бўлган ҳайвонларнинг энг яхшиларини Гилгалга олиб келишди». 
            </p>
            <p>
            «Худога нима маъқул: қурбонликми ёки итоат? – деб сўрадилар Сомуил пайғамбар. – Итоат этиш қурбонлик келтиришдан, бўй­суниш қўчқорлар чарвидан афзал. Чунки исён жодугарлик билан, саркашлик бутпа­раст­лик билан тенг. Оллоҳнинг амрини рад эт­ганинг учун, У сени подшоҳликдан рад этди».
            </p>
            <p>
            «Мен гуноҳ қилдим, – деди Толут. – Одамлардан қўрқиб, Оллоҳнинг амрини, сизнинг сўзингизни бажарганим йўқ. Одамларнинг гапига кирдим. Гуноҳимдан ўтинг, бирга ибодатга борайлик!» 
            </p>
            <p>
            «Йўқ! Мен сен билан бормайман! Сен Оллоҳ­нинг гапини рад қилдинг, Оллоҳ под­шоҳ­ликни сендан рад қилди», дедилар Сомуил пайғамбар ва кетиш учун ор­қа­си­га бу­рил­дилар. 
            </p>
            <p>
            Толут тўхтатаман деб, кийи­ми­­нинг баридан ушлаган эди, этаги йиртилиб қўлида қолди. 
            </p>
            <p>
            Сомуил пайғамбар: «Бугун Оллоҳ Исроил подшоҳлигини сенинг қўлингдан йиртиб олиб, сендан яхшироқ бир юртдошингга берди, – дедилар, – Оллоҳ азза ва жалла ёлғон гапирмас, гапидан қайтмас. У инсон эмаски, гапидан қайтса». 
            </p>
            <p>
            «Мен гуноҳ қилдим, тўғри, – деди Толут, – лекин оқсоқоллар олдида, Исроил олдида ҳурматимни қилиб, мен билан ибодатга боринг, илтимос». 
            </p>
            <p> Бирга ибодатга бордилар, Толут ибода­ти­ни адо этди, кейин Сомуил ҳазратлари: «Амолиқ шоҳи Ажожни олиб келинглар», деб амр қилдилар. 

            </p>
            <p>
            Ажож қўрқувдан титрар, ўлим аччиқ бўлса керак, деб ўйларди. 
            </p>
            <p>
            Сомуил пайғамбар: «Сенинг қиличинг она­­ларни фарзандларидан жудо қиларди. Энди сенинг онанг ҳам фарзандидан жудо бў­ла­­ди», деб саждагоҳ олдида уни чопиб ўл­дир­ди. Сўнг Рамадаги уйига кет­ди­лар. Толут ҳам Гивага, уйига равона бўлди. 
            </p>
            <p>
            Шу-шу, ҳазрати Сомуил, қанчалик Толут учун ичи куймасин, то умрининг охиригача у билан бошқа юз кўришгани йўқ.
            </p>
          </div>

          <BottomNav 
            labelLeft='Янатон' 
            pathLeft='/public-resources/qisas-uzbek/janatonsTavakkul'

            labelCenter='Толутнинг ўзбошимчалиги'
            pathCenter='/public-resources/qisas-uzbek/tolutIsRejected'

            labelRight='Довуд (а.с.)'
             pathRight='/public-resources/qisas-uzbek/david'
          />


            {/* // labelRight='(davomi bor)'
            // pathRight='/public-resources/qisas-uzbek/tobe_continued' */}

        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default ТolutIsRejected;