import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'
import BottomNav from './components/BottomNav';


const MoreShariahLaws = () => {

  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 14;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      //     console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p> Чодирда нозил бўлган  </p>
            <p> амрлар </p>
          </div>

          <div className='decor_line'>
            <p>
              &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;
            </p>
          </div>

          <div className='main_text'>
            <p className="boldItalic">
              «Ва Биз Мусога китобни бердик ва уни Бани Исроилга ҳидоят қилиб: “Мендан ўзгани вакил тутманглар”, дедик» (Исро сураси 17:2).
            </p>
            <p>
              Оллоҳ таоло ўша даврда Мусо пайғамбарга берган амрлардан бири гуноҳ учун қилинадиган қурбонлик ҳақида эди:
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p >
                <sup>1</sup> “Каффора” сўзи “ёпиш” деган маънони билдиради.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)}>
              «Бани Исроилга айт, — деди Оллоҳ Мусо пайғамбарга. — Ким қурбонлик қилмоқчи бўлса, мол ёки қўй ёки эчки олиб келиб қурбонлик қилсин. Қурбонлиги нуқсонсиз, камчиликсиз ва эркак жинсига оид бўлсин. Қурбонлигим қабул бўлсин деса, уни Муқаддас Чодир эшиги олдига келтириб, ҳайвоннинг бошига қўлини қўйсин. Ўшанда Мен унинг айб-гуноҳларини қурбонликнинг ўлими ҳаққи каффора<sup>1</sup> қиламан».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ЎҒИРЛИК ҲАҚИДА ҲУКМ  </p>
          </div>

          <div className='main_text'>
            <p>
              «Агар кимдир омонатга хиёнат қилса ёки бировнинг нарсасини ўзиники қилса ёки бўлмаса бировнинг йўқотган нарсасини олиб, бу ҳақда ёлғон гапирса, гуноҳкор бўлади. У, ўғирлаган нарсанинг устига бешдан бир миқдорда қиймат қўшиб эгасига қайтарсин. Кейин бориб, бенуқсон бир қўчқорни қилган гуноҳи учун қурбон қилсин. Қурбонликнинг ўлими эвазига унинг гуноҳи кечирилади».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ҲАЛОЛ ВА ҲАРОМ ҲАЙВОНЛАР </p>
          </div>

          <div className='main_text'>
            <p>
              «Ҳайвонлардан кавш қайтарадиган ва туёқлари тўлиқ айри бўлган ҳайвонлар сизлар учун ҳалол. Кавш қайтарсаю, туёқлари айри бўлмаса, гўштини еманг. Масалан, туя гўшти сизлар учун ҳаром, чунки туя кавш қайтаради, лекин айри туёққа эга эмас. Чўчқанинг туёғи айри, лекин кавш қайтармайди: унинг гўшти ҳаром.
            </p>
            <p>
              Сувларда яшайдиган, тангачалари ва сузгич пардалари бўлган жониворлар сизлар учун ҳалол, уларни тановул қилишингиз мумкин. Қанот пардалари ёки тангачалари бўлмаган сув жонзодларини еманг.
            </p>
            <p>
              Қушлардан бургут, қорақуш, калхат, лочин, қарғалар, туяқуш, бойўғли, чағалай, қарчиғай, укки, қорабузов, ибрис, оққуш, сақоқуш, қумой, лайлак, қарқара, сассиқпопишак, кўршапалак сизлар учун ҳаром.
            </p>
            <p>
              Учадиган ҳашаротлардан сакрайдиган бўғинли оёқлари бўлмаганлари ҳаром. Чигиртканинг, харгол ва хагапнинг сакрайдиган оёқлари бор. Улар сизга ҳалол.
            </p>

            <p>

            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p >
                <sup>2</sup> Kафида юрадиган ҳайвонлар, масалан итсимонлар ё мушуксимонлар оиласи.
              </p>
            </div>
            <p onClick={() => handleSupClick(2)}>
              Панжасида<sup>2</sup>  юрадиган ҳайвонларнинг, кўрсичқон, сичқон, калтакесак каби жониворларнинг гўшти, судралиб юрадиган ҳайвонлар эти сизлар учун ҳаром».
            </p>
          </div>


          <div className='paragraph_heading'>
            <p>  ҚОН ЕМАНГ! </p>
          </div>

          <div className='main_text'>
            <p>
              «Қон ейдиган одамларни, ўз халқингиздан бўладими ё мусофирми, орангиздан йўқ қилинг. Мен ундай одамлардан юз ўгираман. Чунки ҳаёт мавжудотнинг қонида. Қон орқали ҳаётингизга каффора берилади. Шунинг учун ҳеч ким ҳеч нарсани қони билан емасин! Қуш ёки ҳайвон овласангиз, аввал қонини чиқариб, ерга кўминг. Чунки ҳар бир тирик мавжудотнинг жони унинг қонида. Қон истеъмол қилган одамни ораларингиздан йўқ қилинг».
            </p>

          </div>

          <div className='paragraph_heading'>
            <p> ЖИМОЪ ҲАҚИДА БЕРИЛГАН ҲУКМЛАР </p>
          </div>

          <div className='main_text'>
            <p>
              «Мана, Мен сизларни Мисрдан олиб чиқиб, Канъон юртига олиб кетяпман. Сизлар Меникисизлар. Мисрликларнинг ёки канъонликларнинг гуноҳ ишларини такрорламанглар. Менинг амрларимга риоя қилинг. Амрларимга амал қилган одамнинг ҳаёти файзли бўлади.
            </p>
            <p>
              Орангизда ҳеч ким ўз маҳрами билан жимоъ қилмасин:
            </p>
            <p>
              Онанг билан қўшилма. Онанг отангнинг номуси, онанг билан қўшилсанг, отангнинг номусига тажовуз қилган бўласан.
            </p>
            <p>
              Отангнинг хотини билан қўшилма, чунки у отангнинг номуси. Бу билан сен отангнинг номусига теккан бўласан.
            </p>
            <p>
              Отангнинг ёки онангнинг қизлари билан қўшилма.
            </p>
            <p>
              Невараларинг билан яқинлик қилма. Чунки улар ўз белингдан чиққан. Сен бу билан ўз номусингга тажовуз қилган бўласан.
            </p>
            <p>
              Холаларинг ёки аммаларинг билан қўшилма. Улар ота-онангнинг қондошлари.
            </p>
            <p>
              Амакингнинг хотини билан яқинлик қилма. Чунки бу билан сен амакингнинг номусига тажовуз қиласан.
            </p>
            <p>
              Келининг билан қўшилма. Чунки у ўғлингнинг жуфти ҳалоли.
            </p>
            <p>
              Ака-укаларингнинг хотини билан қўшилма, чунки бу билан сен аканг ё укангнинг номусига теккан бўласан.
            </p>
            <p>
              Бир хотиннинг ҳам ўзи билан, ҳам қизи ё невараси билан қўшилма. Чунки улар сенинг маҳраминг. Бундай амал қабоҳатдир.
            </p>
            <p>
              Хотининг тириклигида унинг опасини ёки синглисини хотинликка олма.
            </p>
            <p>
              Хотининг ҳайз кўрган вақтда у билан қўшилма.
            </p>
            <p>
              Эри бор хотин билан қўшилиб, ўзингни нажис қилма.
            </p>
            <p>
              Эркак киши эркаклар билан жимоъ қилмасин. Бу — Оллоҳ таолонинг олдида разолат.
            </p>
            <p>
              Ҳеч қачон ҳайвонлар билан жимоъ қилманглар. Бундай амал — ғайритабиий <span className="vitalic">(яъни, бузуқликдир).</span>
            </p>
            <p>
              Бу ишларнинг бирортаси билан ўзингизни булғаманг. Канъондаги халқлар мана шу амаллар билан машғул. Уларнинг қилган ишларидан замин булғанди. Ҳатто ўз ўғил-қизларини бутларга қурбон қилиб оловда ёндирадилар. Шу сабабдан Мен уларни маҳкум этиб, яшаб турган ерларидан ҳайдаб чиқараман. Сизлар эса Менинг амрларимни кўз қорачиғидай асранг. Бу қабиҳ ишларнинг биронтасига ҳам қўл урманг. Менинг қонунларим сизларга ҳам, орангизда яшайдиган бошқа миллатларга ҳам бирдай тааллуқли. Канъон халқлари ўзларининг қабиҳ ишлари билан юртни нажосатга ботирди. Агар шу ишларни қилсангиз, Мен сизларни ҳам бу юртдан ҳайдаб чиқараман.
            </p>
            <p>

              Муқаддас бўлинглар, чунки Мен — сизларнинг Раббингиз муқаддасман».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> БАЪЗИ БОШҚА ҚОНУНЛАР </p>
          </div>

          <div className='main_text'>
            <p>
              Экинларингизни ўраётганда, даланинг четларигача ўрманг. Узум узганда қолиб кетганларини йиғиштириб оламан, деб қайтиб борманг. Ерга тушган шингилларни олманг — камбағалу мусофирлар есин. Чунки сизларнинг ҳаммангиз Менинг халқимсиз.
            </p>
            <p>
              Ўғирлик қилманг, ёлғон гапирманг, бир-бирингизни алдаманг.
            </p>
            <p>
              Менинг номим билан ёлғонга қасам ичманг: бу билан Менинг номимни беҳурмат қилган бўласизлар. Мен сизларнинг Раббингизман.
            </p>
            <p>
              Бировнинг нарсасини олманг. Бошқаларга зулм қилманг. Мардикорлар ҳақини тунгача қолдирмай беринг.
            </p>
            <p>
              Карни қарғаманглар, кўрнинг олдига қозиқ қоқманглар: Мендан қўрқинглар.
            </p>
            <p>
              Ҳукм қилишда одил бўл. Камбағални камбағал деб ёки бойни бой деб ён босма. Энг яқин одамингни ҳам адолат асосида ҳукм қил.
            </p>
            <p>
              Халқ орасида бўҳтон гаплар тарқатма. Одамларнинг ўлимига сабабчи бўлма. Қазоват Менинг қўлимда экани ёдингиздан чиқмасин.
            </p>
            <p>
              Юрагингизда ҳеч кимга қарши нафрат сақламанг. Кимдир гуноҳ йўлга қадам қўйса, ошкора тушунтиринг, акс ҳолда сиз ҳам гуноҳ қилган бўласиз.
            </p>
            <p>

              Қасос олманглар, ичингизда бошқаларга нисбатан ғазаб эмас, муҳаббат ҳукм сурсин. Сен ўз яқинингни ўзингни яхши кўргандай яхши кўришинг керак. Сизлар Мен билан аҳдга киргансизлар. Менинг қонунларимга итоат этинг.

            </p>
            <p>
              Ҳеч нарсани қони билан истеъмол қилманглар. Фолбинлик ёки жодугарлик билан шуғулланманглар.
            </p>
            <p>
              Қизларингизни фоҳишага айлантирманг, акс ҳолда юртингиз зинога, бузуқликка ботади.
            </p>
            <p>
              Жодугарларга ёки арвоҳлар билан алоқа қиладиганларга мурожаат этманг. Бундай ишлар сизларни булғайди.
            </p>
            <p>
              Қарияларни кўрганда ўрнингиздан туринг. Кексаларнинг ҳурматини жойига қўйинг. Акс ҳолда Мени беҳурмат қилган бўласизлар.
            </p>
            <p>
              Юртингизга келган мусофирларга зулм қилманг. Уларни ўзингизни яхши кўргандек яхши кўринг, чунки сизлар ҳам Мисрда мусофир ва бегона эдингизлар.
            </p>
            <p>
              Миқдорда, узунлик ё оғирликни ўлчашда адолатсизликка йўл қўйманглар. Тош-тарозингиз, ўлчагингиз, сиғим идишларингиз тўғри бўлсин. Чунки Мен сизларни Мисрдан олиб чиқдим, сизлар Менга тегишлисиз. Менинг ҳамма қонунларим ва аҳкомимга итоат этинг. Сизлар Мен билан аҳд қилгансизлар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}
            >
              <p >
                <sup>3</sup> Оллоҳ таолонинг бу амрлари Бани Исроилда фақат мавжуд ҳокимият тузуми воситасида амалга оширилган. Яъни ҳар ким ўз ўй-хаёли билан бу ишларни амалга оширишига ҳуқуқ берилмаган.
              </p>
            </div>
            <p onClick={() => handleSupClick(3)}>
              Отасини ёки онасини сўккан одам ўлимга лойиқ. Ота-онасини сўккани учун унинг қони ўз бўйнига тушади.<sup>3</sup>
            </p>
            <p>
              Ким агар бировнинг хотини билан зино қилса, иккаласининг ҳам жазоси ўлим.
            </p>
            <p>
              Ким отасининг хотини билан қўшилса, отасининг номусига тажовуз қилган бўлади. Уни ҳам, аёлни ҳам ўлдиринглар. Қонлари ўз бўйинларига.
            </p>
            <p>
              Ким келини билан ётса, иккаласи ўлдирилсин. Улар разолатга ботди, қонлари ўз бўйнига.

            </p>
            <p>
              Эркак эркак билан жимоъ қилса, ўлим жазосига маҳкум қилинг. Улар жирканчликка қўл урдилар, қонлари ўз бўйнига.
            </p>
            <p>
              Она ва қизи билан қўшилган эркак ифлосликка қўл урган бўлади. Уларнинг учовини ҳам тириклайин оловда куйдириб, бундай ифлосликни орангиздан йўқотинг.

            </p>
            <p>
              Биронта эркак, ҳайвон билан қўшилса, эркакни ҳам, ҳайвонни ҳам ўлдиринглар.
            </p>
            <p>
              Биронта аёл ҳайвон билан қўшилса, аёлни ҳам, ҳайвонни ҳам ўлдиринглар. Қонлари ўз бўйнига.
            </p>
            <p>
              Ким отасининг ё онасининг қизи билан қўшилса, бу — шармандалик. Иккаласини халқнинг кўз олдида орангиздан йўқотинг. Гуноҳлари ўз бўйнига.
            </p>
            <p>
              Ким хотинига ҳайз вақтида қўшилса, иккаласини халқ орасидан йўқ қилинг.
            </p>
            <p>
              Аммаси ёки холаси билан қўшилганлар ўз қариндошини шарманда қилади ва жазога сазовор бўлади.
            </p>
            <p>
              Амакисининг хотини билан қўшилган одам, амакисининг номусига тажовуз қилган бўлади. Уларнинг гуноҳи ўз бўйнига, дунёдан бефарзанд ўтадилар.
            </p>
            <p>
              Ким акасининг ёки укасининг хотини билан қўшилса, разолатга қўл урган бўлади. Улар бефарзанд ўтадилар.
            </p>
            <p>
              Менинг ҳамма амрларимга риоя қилинг. Канъон халқларининг гуноҳларини такрорламанг. Улар мана шу жирканч ишларнинг ҳаммасини ўзларига одат қилганлар.   Ҳатто ўз ўғил-қизларини бутларга қурбон қилиб оловда ёндирадилар. Шу сабабдан Мен уларни юртдан ҳайдаб чиқармоқчиман».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ХУДОНИ ҲАҚОРАТ ҚИЛСА... </p>
          </div>

          <div className='main_text'>
            <p>
              Бир куни бир исроиллик аёлнинг мисрлик одамдан туғилган ўғли қароргоҳда битта иброний билан жанжаллашиб қолиб, Оллоҳ таолонинг исмига ёмон гаплар айтди. Шунда одамлар уни ушлаб, Мусо пайғамбарнинг олдига келтиришди.
            </p>
            <p>
              Расулуллоҳ, то Худодан ваҳий келмагунча уни қамаб қўйинглар, деб амр қилдилар. Кейин Оллоҳ субҳанаҳу ва таоло Мусо пайғамбарга бундоқ деб ваҳий берди: «Уни қароргоҳдан ташқарига олиб чиқинглар. Унинг гапини эшитганларнинг ҳаммаси қўлларини унинг бошига қўйсин. Кейин бутун жамоа уни тошбўрон қилсин. Бани Исроилга айт: ким Худони ҳақорат қилса, ўлим жазосига маҳкум бўлади. Бутун жамоа уни тошбўрон қилади. Мусофирми-ибронийми, ким бўлишидан қатъи-назар, ким Менинг исмимга ҳақорат гап айтса, ўлимга маҳкум қилинглар».
            </p>
            <p>
              Расулуллоҳ шундан кейин ҳалиги кишини қароргоҳдан ташқарига олиб чиқинглар, тошбўрон қилинглар, деб фатво бердилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ДАМ ОЛИШ ЙИЛИ ҲАҚИДА </p>
          </div>

          <div className='main_text'>
            <p>
              «Бани Исроилга айт, Мен берадиган юртга етиб борганингизда, ҳар етти йилда бир марта ерга дам беринглар. Олти йил ерга экин экинг, токка қаранг, маҳсулотингизни йиғиштиринг. Лекин еттинчи йили ер дам олиши керак.
            </p>
            <p>
              Ўша йили ерга ҳеч нарса экмайсизлар, токларингизни кесмайсизлар. Ўзи кўкарган экинлардан хирмон кўтармайсиз, узумлардан ҳосил йиғмайсиз. Чунки бу йил ер учун дам олиш йили бўлади. Бу йил ер нимаики ҳосил берса, ҳамма учун — сизлар учун, қулларингиз, чўриларингиз, ишчиларингиз ва орангизда яшаётган бегоналар учун озуқа бўлади. Уй ҳайвонларингиз ҳам, дашт ҳайвонлари ҳам ундан озиқланади.
            </p>
            <p>
              Агар халқ: “Экмасак, ҳосил йиғмасак, еттинчи йили нима еймиз?” деб сўраса айт: Мен олтинчи йили ҳосилингизга барака бераман, ўша йили уч йилга етадиган хирмон кўтарасизлар. Саккизинчи йили уруғ экасиз, лекин тўққизинчи йилгача эски ҳосилдан озиқланасиз.
            </p>
            <p>
              Ҳар етти йилнинг охирида қарзларингиздан кечинг.  Кимки қарз берган бўлса, қарзидан кечсин, бошқа қарзини сўраб бормасин.  Бегона халқларга қарз берган бўлсангиз, сўрасангиз бўлади, лекин юртдошларингизнинг қарзларини кечишингиз керак.   Шунда Мен сизларга барака бераман».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ФАРОҒАТ ЙИЛИ </p>
          </div>

          <div className='main_text'>
            <p>
              «Ҳар эллик йилда бир марта Фароғат йилини байрам қилинг. Еттинчи ойнинг ўнинчи куни, яъни каффора куни, бутун мамлакат бўйлаб бурғу чалинглар. Ҳаммага Фароғат йили келганини эълон қилинглар. Қуллар озод бўлиб уйларига қайтсин, ерини сотганлар қайтариб олсин. Эллигинчи йил сизлар учун Фароғат йили бўлади. Ўша йили ҳеч нарса экмайсизлар, экинларга ишлов бермайсизлар, токларни хомток қилмайсизлар. Чунки бу — Фароғат йилидир. Ўша йилда сизларнинг емагингиз экинзорларда ўзи ўсган маҳсулот бўлади».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> БИРОДАРИНГ МУҲТОЖ БЎЛСА </p>
          </div>

          <div className='main_text'>
            <p>
              «Агар яқининг камбағаллашиб, ночор аҳволга тушса, ёрдам бер. Уни уйингга олиб кел, сен билан худди меҳмондек ёки мусофирдек яшасин. Унга пул берсанг, устига фоиз қўйма, таом бериб, даромад олма, Худодан қўрқ. Чунки сизлар Меникисиз. Мен сизларни иймон аҳли бўлишингиз учун Мисрдан олиб чиқдим, мол-мулк билан таъминладим, эсингиздан чиқмасин».
            </p>
          </div>


          <div className='paragraph_heading'>
            <p> ҚУЛЛИК ҲАҚИДА </p>
          </div>

          <div className='main_text'>
            <p>
              «Агар юртдошларингиздан бири камбағалликдан ўзини қул қилиб сотса, уни қулдек ишлатманглар. Унга ёлланма ишчиларга ё мусофирларга муомала қилгандек муомала қилинг. Фароғат йилигача ёнингизда ишласин. Кейин болалари билан бирга озодликка чиқиб, ўз хонадонига, ота мулкига қайтсин. Чунки сизлар Меникисиз.
            </p>
            <p>
              Озод қилаётганда қуруқ қўл билан қўйиб юборманг,   ғаллангиздан, чорвангиздан беринг.  Мисрда қул бўлганингизни, Оллоҳ сизларни қандай озод қилганини эсингизда тутинг.
            </p>
            <p>
              Қулингиз сизни, хонадонингизни ёқтириб қолган бўлса, “кетмайман, менга шу ер яхши” деса,  бигиз олиб, қулоғини эшикка михланг, шунда у умрбод сизнинг қулингиз бўлиб қолади.
            </p>
            <p>
              Бир-бирингизни қулликка сотманглар.
            </p>
            <p>
              Худодан қўрқ, биродарингга қаҳр билан муомала қилма».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> Нимани танлайсиз, бараканими ё лаънатними? </p>
          </div>

          <div className='main_text'>
            <p>
              «Агар Менинг амрларимга амал қилиб, ҳидоятларимга риоя этсангиз, Мен сизларга ўз вақтида ёмғир ёғдираман, меваю хирмонингизга барака бераман. Ғаллангизни то узум узадиган пайтгача янчасиз, узумни эса экиш мавсумигача терасиз. Ейиш-ичишингизда камчилик бўлмайди.

            </p>
            <p>
              Амрларимга итоат этсангиз, ҳаётингиз бехатар бўлади. Юртингизга тинчлик бераман. Ҳеч ким ҳужум қилиб сизларни қўрқитмайди. Йиртқич ҳайвонлар далангизга йўламайди. Душманларингиз олдингизга тушиб қочади: бештангиз юзтасини, юзтангиз ўн мингтасини қувлайсизлар. Менинг паноҳим остида, қилган аҳдимиз соясида ўсиб-кўпайиб катта бир халққа айланасиз. Мен орангизда маскан қилиб, сизлар билан бирга яшайман. Сизлар Менинг халқим, Мен сизларнинг Худойингиз бўламан.
            </p>
            <p>
              Агар Менга қулоқ солмасангиз, аҳдимизга риоя этмасангиз, ибодатларингиз қабул бўлмайди. Юртингизга мусибатлар ёғади, касалликларга чалиниб, жонингиз азоб чекади, кўзларингиз нури қурийди. Етиштирган ҳосилингиз душманларга ем бўлади. Сизлардан юз ўгирганим учун, бегоналар зулми остида қоласиз. Ҳеч ким қувмаса ҳам қочадиган бўлиб қоласизлар.
            </p>
            <p>
              Шунда ҳам Менга қулоқ солмасангиз, сизларни тўғри йўлга солиш учун, қилган гуноҳларингиз учун етти баравар кўп жазога мубтало қиламан.
            </p>
            <p>
              Ғурурингиз синиши учун осмондан ёмғир ёғмай қўяди, ерларингиз тош қотади. Далаларингизда экин битмай, меҳнатингиз зоеъ кетади, дарахтларингизда мева унмайди.
            </p>
            <p>
              Ундан кейин ҳам Менга қарши юрсангиз, йиртқич ҳайвонлар келиб, болаларингизни ейди, молларингизни талайди. Аҳдни бузганингиз учун ўлат тушиб жонингизни олади. Қаҳатчилик келиб, ўнта хотин бир тандирда нон ёпадиган бўлади. Нонни тарозида тортасизлар. Ейсизлар, лекин қорнингиз тўймайди. Ниҳоят ўз фарзандларингиз гўштини: ўғилларингиз, қизларингиз этини ейсизлар. Бутхоналарингиз вайрон бўлади, жасадларингиз бутлар устида қолади. Юртингизга келиб ўрнашган душманлар сизнинг ҳолингизни кўриб ҳайрон бўладилар. Мен сизларни халқлар орасида пароканда қиламан.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}
            >
              <p >
                <sup>4</sup>  Эътибор бердингизми, гуноҳ қилган инсон агар тавба қилса, Оллоҳ субҳанаҳу ва таоло унинг тавбасини Иброҳим (а.с.) билан қилинган аҳд туфайли қабул қилар экан.
              </p>
            </div>
            <p onClick={() => handleSupClick(4)}>
              Қачон хатна бўлмаган юракларингиз тавбага келиб, Менга қарши гуноҳ қилганингизга иқрор бўлсангиз, ота-боболарингиз гуноҳ қилганини тан олсангиз, Мен Иброҳим билан, Исҳоқ билан ҳамда Яъқуб билан қилган аҳдимни<sup>4</sup>  эсга оламан».
            </p>

          </div>


          <div className='paragraph_heading'>
            <p> Оллоҳни зикр қилинглар </p>
          </div>

          <div className='main_text'>
            <p>
              «Ҳорунга айт, одамларни дуо қилганда: “Оллоҳ сенга барака берсин! Оллоҳ сени паноҳида сақласин! Ўзига яқин олсин. Меҳр-шафқатига, марҳаматига ноил қилсин! Оллоҳ сени Ўз назари остига олиб, тинчлигидан ато этсин!” деб дуо қилсин. Шунда Менинг исмим Бани Исроилга ёд бўлади, Бани Исроилга Менинг баракам ёғилади».
            </p>
            <p></p>
          </div>

          <BottomNav 
            labelLeft='Аҳдлашиш' 
            pathLeft='/public-resources/qisas-uzbek/bani-israel-accepted-shariah'

            labelCenter='Амрлар'
            pathCenter='/public-resources/qisas-uzbek/more-shariah-laws'

            labelRight='40 йил саҳрода'
            pathRight='/public-resources/qisas-uzbek/fourty-years-in-the-desert'
            />
 
        </main >
        <aside> </aside>
      </div >
    </div >
  )
}

export default MoreShariahLaws
