import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const TolutsJealousy = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>
                    Подшоҳнинг Довуд (а.с.) га ҳасад қилгани  
                  </p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

                  <div className='main_text'>
                    <p> 
                    Ҳазрати Довуд филистий паҳлавонни ўл­дир­ган­ларидан сўнг, жангчилар уйга қайта­ёт­ганида, Бани Исроилнинг хотин-қиз­лари чирмандалар билан уларни кутиб олишга чиқадилар ва: «Мингини ўлдирди Толут, ўн мингин эса Довуд!» деб қўшиқ айтадилар. 

                    </p>

                    
                    <p>
                    Довуд (а.с.) жангларда ғалаба қозона бош­ла­гач, бу сўзлар подшоҳга ёқмай қолди. 
                    </p>
                
                    <p>
                    «До­вуд­га ўн мингтани, менга эса мингтани бе­ри­шибди холос! Энди унинг подшоҳ бў­ли­шини ҳам хоҳлаб қолар балки?!» деб ўй­лар­ди Толут. Ва шу кундан бошлаб у Довуд (а.с.) дан хавфсирай бошлади.
                    </p>
                  </div>

                <div className='paragraph_heading'>
                    <p> Ҳазрати Довуднинг саройга куёв бўлиши
                    </p>  
                </div>

            
                <div className='main_text'>
                  <p>
                  Бир куни подшоҳ Довуд (а.с.) га: «Катта қизимни сенга бермоқчиман. Лекин сен олдин Оллоҳ йўлида жангга чиқиб, ботир жангчи эканингни исботла», деди.
                  </p>
                  <p>
                  (У ўзича: «Довуднинг жанг майдонида филистийлар қўлидан ўлгани яхши, менинг қўлимдан ўлмасин», деб режа қилган эди.) 
                  </p>
                  <p>
                  Довуд  (а.с.)  бунга жавобан: «Мен ким­ман­ки подшоҳ ҳазратларига куёв бўлсам?! Биз­нинг оиламиз бундай ифтихорга ло­йиқ эмас-ку», дедилар. 
                  </p>
                  <p>
                  Лекин Толут, тўй вақ­ти яқинлашганда, катта қизини бош­қа би­ров­га, махлалик Адриёл деган одамга бе­риб юбор­ди.
                  </p>
                  <p>
                  Подшоҳнинг бошқа қизи ҳазрати Довудни яхши кўрар эди. 
                  </p>
                  <p>
                  Подшоҳ буни эшитиб, хурсанд бўлиб: «Довудни шу қизим орқали тузоққа тушираман, филистийларга ўл­дир­тираман», деб ўйлади. 
                  </p>
                  <p>
                  Кейин До­вуд (а.с.) ­га: «Мана энди менга куёв бўладиган вақ­тинг келди», деди. 
                  </p>
                  <p>
                  Аъёнларига эса: «Довудга айтинглар: шоҳ сендан рози, ҳамма хизматкорлари сени яхши кўради, подшоҳга куёв бўладиган вақ­тинг келди, деб айтинглар», деб та­йин­лади. 
                  </p>
                  <p>
                  Довуд (а.с.) эса: «Подшоҳга куёв бўлиш осонми? Мен арзимас бир камбағал одам бўл­сам?» деб жавоб бердилар. 
                  </p>
                  <p>
                  Аъёнлар бу гапни подшоҳга етказган эди­лар, подшоҳ: «Довудга айтинглар, мен душ­манларимдан ўч олмоқчиман. Шунинг учун қизимнинг қалинига юз филистий­нинг хатна терисини келтирса бас, бошқа ҳеч нарса керак эмас», деди.
                  </p>
                  <p>
                  Мақсади, шу йўл билан у кишини ўлдириш эди.
                  </p>
                  <p> Айтилган муддат тугамай, ҳазрати Довуд йигитлари билан икки юз филистийни ўлдирдилар ва уларнинг хатна терисини подшоҳга тақдим қилдилар. 
                  </p>
                  <p>
                  Шундан сўнг шоҳ Толут, қизи Маҳални Довуд (а.с.) га хотинликка берди. 
                  </p>
                  <p>
                  Тез орада халқ ичида ҳазрати Довуднинг обрўси кў­та­рилиб кетди. Чунки қўмондонлар ичи­да у кишига тенг келадиган ҳеч ким йўқ эди. Ҳар сафар филистийлар ҳужум қил­ган­да Довуд (а.с.)  уларни енгар, жанг майдонидан ғалаба билан қайтар эдилар.
                  </p>
                </div>
              
                <div className='paragraph_heading'>
                  <p> «Наҳотки Толут ҳам пайғамбар бўлса?» </p>
                </div>
                
                <div className='main_text'>
                  <p>
                  Бир куни Толут ўғли Янатонга ва аъён­ла­рига: «Довудни ўлдиринглар», деб буйруқ берди. 
                  </p>
                  <p>
                  Янатон Довуд (а.с.) ни жуда яхши кўрар эди. Шунинг учун бориб: «Отам сени ўлдирмоқчи», деб у кишини огоҳ­лан­тирди. Довуд (а.с.) қочиб, Рамага, Сомуил пайғамбарнинг олдига бордилар. 
                  </p>
                  <p>
                  «Довуд Рамада, Нойўтда», деб эшитгандан сўнг, Толут подшоҳ: «Довудни ҳибсга олинг­лар», деб у ерга одамларини юбо­ра­ди.  Улар келиб, Сомуил алайҳис-са­лом бош­чи­ли­гидаги зикр тушаётган пай­ғам­бар­лар жамоасини кўрдилар. Оллоҳ тао­ло­нинг Руҳи уларни ҳам қамраб олди. Улар ҳам зикр тушдилар. 
                  </p>
                  <p>
                  Толут буни эшитиб, бошқа нафарларини юборди. Улар ҳам келиб, пайғамбарлар билан зикр тушдилар. 
                  </p>
                  <p>
                  Кейин подшоҳ учинчи гуруҳ одамларини юборди. Улар ҳам келиб, Довуд (а.с.) ни уш­лаш ўрнига пайғамбарлар билан зикр туш­ди­лар. 
                  </p>
                  <p>
                  Охири подшоҳнинг ўзи Рамага қараб йўл олди. Сахуҳдаги катта қудуқ олдига келиб у ердагилардан: «Сомуил пайғамбар билан Довуд қаерда?» деб сўради. 
                  </p>
                  <p>
                  «Нойўтда», деди бир киши. 
                  </p>
                  <p>
                  Нойўтга кетар экан, Оллоҳнинг Руҳи Толутнинг устига ҳам тушди. Нойўтга боргунча зикр тушиб борди. Кийимларини ечиб, Сомуил (а.с.) нинг олдида зикр тушди. Шу кун ва шу тун яланғоч ётди. «Толут ҳам пайғамбар бўлибдими?!» деган сўз шундан қолган.
                  </p>
                </div>

                <div className='paragraph_heading'>
                  <p> Довуд (а.с.) нинг қочиб юрганлари
                  </p>
                </div>
                
                <div className='main_text'>
                  <p>
                  Довуд (а.с.) кейин Нов шаҳрига, руҳоний Ахи­малик олдига бордилар. Ахималик у ки­ши­ни кўриб қўрқиб кетди: «Нега бир ўзинг­сан? Нега ёнингда ҳеч ким йўқ?» 
                  </p>
                  <p>
                  «Подшоҳ топшириқ билан юборди, – де­ди­лар Довуд (а.с.). – Бу ҳақда ҳеч кимга айт­ма, деб буюрган. Нафарларимга эса, фалон жойда учрашамиз, деб айтганман. Ейишга бирон нарсангиз борми? Бўлса бешта нон, бўлмаса бошқа бирон нарса беринг». 
                  </p>
                  <p>
                  «Оддий нонимиз йўқ, – деб жавоб берди руҳоний, – агар йигитларинг хотинларга яқин­лашмаган бўлса назр қилинган но­ни­миз бор». 
                  </p>
                  <p>
                  «Яқинлашганимиз йўқ, – деб жавоб бер­ди­лар Довуд (а.с.). – Йўлга чиққанда биз доим ўзимизни хотинлардан сақлаймиз. Хусусан бу сафар махсус топшириқ билан чиқ­қанмиз, хавотир олманг». 
                  </p>
                  <p>
                  Шундан сўнг ру­ҳо­ний, назр қилинган нон­ларни берди. 
                  </p>
                  <p>
                  «Сизда найза ё қилич йўқми? – деб сўради Довуд (а.с.). – Подшоҳнинг топшириғи шо­ши­линч бўлганидан ўзим билан на қилич олибман, на бошқа қурол». 
                  </p>
                  <p>
                  «Элоҳ водийсида сен ўлдирган филистий Жолутнинг қиличи шу ерда, – деди Ахи­ма­лик. – Латтага ўраб эҳромнинг ор­қа­сига яшириб қўйганман. Хоҳласанг ол. Бу ерда бошқа қилич йўқ».
                  </p>
                  <p>
                  «Бера қолинг, ундан яхшироғи бўлмайди», дедилар Довуд (а.с.) ва ўша куни Жат ерига, шаҳар амири Ахиш­нинг олдига бордилар. 
                  </p>
                  <p>
                  Ахишнинг амалдорлари: «Бу ўша Исроил подшоси Довуд эмасми? Халқ “Мингини ўлдирди Толут, ўн мингин эса Довуд!” деб қўшиқ айтган одам шу-ку!» дедилар. 
                  </p>
                  <p>
                  Довуд (а.с.) бу гапларни эшитиб, Ахишдан қўрқиб, ўзини девоналикка солдилар. Дар­во­за­ларни тимдалаб, сўлагини со­қо­лига оқиздилар. 
                  </p>
                  <p>
                  Жат амири буни кўриб, амал­дорларига: «Ақлдан озган одамни нега менинг олдимга келтирдингиз? Нима, ўзи­миз­да девоналар озми, буни ҳам тамошо қилсин, деб саройга олиб келибсизлар!» деди.
                  </p>
                  <p> Довуд (а.с.) кейин у ердан қочиб Аду­лом­даги ғорга бориб яшадилар. Акалари ва бошқа қариндошлари келиб у кишига қўшилдилар. 
                  </p>
                  <p>
                  Зулм кўрган, қарздор ва норози одамлар ҳам Довуд (а.с.) нинг атрофига йиғилаётган эди. Шу билан у киши, теъдоди тўрт юзга яқин йигитга раҳбарлик қила бошладилар.
                  </p>
                </div>
           

          <BottomNav 
            labelLeft='Жолут ўлди' 
            pathLeft='/public-resources/qisas-uzbek/jolutIsDead'

            labelCenter='Подшоҳнинг ҳасади'
            pathCenter='/public-resources/qisas-uzbek/tolutsJealousy'

            labelRight='Қочқинликда'
             pathRight='/public-resources/qisas-uzbek/davidOnRun'
          />

        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default TolutsJealousy;