import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const DavidOnThrone = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>
                  Довуд (а.с.)  тахтга ўтиради  
                  </p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

                <div className='paragraph_heading'>
                    <p> Довуд (а.с.) Яҳузо устидан подшоҳ бўлдилар  </p>  
                </div>

            
          <div className='main_text'>
            <p>
            Кейин Довуд пайғамбар Оллоҳдан: «Мен энди нима қилай? Яҳузия шаҳарларидан бирортасига борайми?» деб сўрадилар. 
            </p>
            <p>
            «Бор!» деган ваҳий келди.
            </p>
            <p>
            «Қайси шаҳарга борай?» «Ҳабрунга», деб жавоб берди Парвардигор. 
            </p>
            <p> 
          
            Шундан сўнг Довуд (а.с.) икки хотинини олиб Ҳабрунга кетдилар. Йигитлари ҳам оилалари билан бирга йўлга тушдилар. Ҳаммалари келиб Ҳабрун теварагидаги қиш­лоқларга ўрнашдилар.
            </p>
         
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p> <sup>1</sup> «Оллоҳ унга подшоҳлик ва ҳикмат берди ва хоҳлаган нарсасини ўргатди» (Бақара 251).
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
            Кейин Яҳузия халқи Ҳабрунга йиғилиб, Довуд (а.с.) ни Яҳу­зия устидан подшоҳ қилиб тахтга ўт­қаз­дилар.<sup>1</sup>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p> <sup>2</sup> яъни, Жилъод, Ашир, Язарел, Афройим, Биня­мин каби.
              </p>
            </div>
            <p onClick={() => handleSupClick(2)} >
            Бани Исроилнинг бошқа<sup>2</sup> минтақаларига эса Толутнинг қирқ яшар ўғли Эш­бушат подшоҳлик қила бошлади. 
            </p>
            <p></p>
          </div>

          <div className='paragraph_heading'>
            <p> Довуд  нинг Ҳабрунда туғилган фарзандлари
            </p>
          </div>
     
          <div className='main_text'>
            <p>
            Довуд пайғамбарнинг мана бу ўғиллари Ҳаб­рунда туғилган: тўнғич ўғли Амнўн – язареллик Ахиноамдан туғилди, иккинчиси Килоб – кармиллик Наволнинг беваси Абижойлдан туғилди, учинчиси Абусалом – Жашур шоҳи Талмойнинг қизи Макодан туғилган, тўртинчиси Адўниё – Ҳажит хонимдан туғилган, бешинчиси Шафатиё – Обидадан туғилган, олтинчиси – Ятаръом, Ажло Бибидан туғилган.
            </p>
          </div>
          
          <div className='paragraph_heading'>
            <p> Довуд (а.с.) нинг бутун Бани Исроил устидан тахтга ўтириши </p>
          </div>
     
          <div className='main_text'>
            <p>
            Орадан бир қанча вақт ўтгач, Толутнинг ўғли Эшбушат ўз аъёнлари тарафидан ўлдирилди. Шундан сўнг Исроилнинг ўн икки қабиласи Ҳабрунга – Довуд алайҳис-саломнинг олдига келиб: 
            </p>
            <p>
«Биз ҳаммамиз қавм-қариндошмиз. Толут подшоҳ тириклигида сиз урушларда Ис­ро­ил лашкарига бошчилик қилар эдин­гиз. Оллоҳ сизга: сен Менинг халқим Бани Ис­ро­ил­га чўпонлик қиласан, уларга раҳ­на­мо бўла­сан, деб ваъда берган. Энди ўзин­гиз ҳаммамизга бош бўлинг», де­ди­лар. 
            </p>
            <p>
            Шу тариқа Довуд (а.с.) Ҳабрунда Исроил оқсоқоллари билан аҳд туздилар. Исроил оқсоқоллари у кишини ёғ билан масҳ этиб, Бани Исроил устидан подшоҳ қилиб тайинладилар. 
            </p>
            <p>
            Ўша пайтда Довуд пай­ғам­бар ўттиз ёшга кирган бўлиб, ҳаммаси бўлиб қирқ йил подшоҳлик қилдилар. Ҳабрунда етти йилу олти ой Яҳузия устидан, Байт-ул-Муқаддасда эса ўттиз уч йил бутун Бани Исроил устидан ҳукм юргиздилар. 
            </p>
            <p className='vitalic'>
           
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}
            >
              <p>
                <sup>3</sup> Байт-ул-Муқаддас аввал ябусийлар қўлида бўлган.
              </p>
            </div>

            <p onClick={() => handleSupClick(3)} className='vitalic'>
            Бу давр мобайнида бир марта ябусийлар билан, бир марта эса филис­тий­лар билан жанглар бўлиб ўтган. Оллоҳ таолонинг кўмаги билан До­вуд пайғамбарнинг қўли баланд келиб, душманлар устидан ға­ла­ба­га эришганлар. Байт-ул-Муқаддас<sup>3</sup> ҳам ўша даврда тасарруф бўл­ган.
            </p>
            <p>
            Шундай қилиб, Довуд (а.с.) нинг қудрати ва азамати кундан-кунга ошиб борар, чунки Қодири мутлақ Худо у киши билан бирга эди. Байт-ул-Муқаддасга келганларидан кейин, бу ерда яна канизак ва хотинлар ол­дилар, улардан ўғил-қизлар кўрдилар. 
            </p>
          </div>
          
          <div className='paragraph_heading'>
            <p>  «Сенинг наслинг тоабад подшоҳлик қилади» </p>
          </div>
     
          <div className='main_text'>
            <p>
            Ҳазрати Довуд  ўз қасрида роҳату фа­ро­ғатда ҳаёт кечирар, Оллоҳ  у ки­ши­ни ҳамма душ­манларидан тинч-омон сақ­ла­ёт­ган эди. 
            </p>
            <p>
            Бир куни шоҳ, На­тан пайғамбарга: «Қара, мен садр ёғо­чи­дан қурилган қасрда яшай­ман, Аҳд Сан­ди­ғи эса чодирда ту­риб­ди», дедилар. 
            </p>
            <p>
            «Кўнг­лингиздаги ишни қи­ла­веринг, – деди пай­ғам­бар. – Оллоҳ сиз билан». Лекин ўша куни кечаси унга Оллоҳ­дан ваҳий нозил бўлди: 
            </p>
            <p>
            «Бориб, қулим Довудга айт: Менинг ам­рим бу: сен Мен учун уй қуролмайсан. Бани Исроилни Мисрдан олиб чиққанимдан бери Аҳд Сандиғи у ердан бу ерга кўчиб юрди, уйда эмас, чодирда турди. Лекин Мен ҳеч қачон Исроил пешволарига: нега Мен учун садрдан уй қурмадинглар, деб айтганим йўқ.
            </p>
            <p>
            Довудга айт: Мен сени яйловдан, қўй суруви орасидан танлаб олиб, Ўз халқимга раҳнамо қилдим. Қаерга борсанг, сен билан бирга бўлдим, жанг­лар­да душманларингни ҳалок қил­дим. 
            </p>
            <p> 
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}
            >
              <p>
                <sup>4</sup> Эсингиздами, Яъқуб (а.с.) ўзининг ўн икки ўғли ҳақида башорат қилганларида, тўр­тин­чи ўғли Яҳудо ҳақида бун­дай деган эдилар: «Ҳамма халқ­лар итоат этгувчи ворис кел­гунга қадар салтанат асоси сенинг қўлингда бўлади». Яъни, ўша пайтда барака занжи­ри Яҳудо наслига ўтган эди. Мана энди, ваъда қилинган зурриёт Довуд пайғамбарнинг наслидан чиқиши эълон этилди.
              </p>
            </div>
            <p onClick={() => handleSupClick(4)} >
            Энди номингни ер юзида улуғ қи­ла­ман, хонадонингни мустаҳкамлайман. Вақт-соати келиб ота-боболаринг ёнига кетгач, Мен сенинг зурриётингдан бир кишини подшоҳ қилиб кўтараман. Унинг подшоҳлигини мустаҳкам қиламан. Мен учун уйни у қуради. Мен унинг валийси бўламан, у Менинг ворисим<sup>4</sup> бўлади. 
            </p>
            <p>
            Гуноҳ қилса, оталарча жазолайман, ле­кин му­ҳаббатим уни ҳеч қачон тарк этмай­ди. Сен­дан олдинги подшоҳни-Толутни раҳ­ма­тим­дан маҳрум қил­ган эдим. Лекин се­нинг нас­линг тоабад подшоҳлик қилади, тах­ти аба­дий бўлади».
            </p>
            <p>
            Пайғамбар келиб Оллоҳ таолонинг сўз­ла­ри­ни ҳазрати Довудга етказдилар. 
            </p>
            <p></p>
          </div>
         
          <div className='paragraph_heading'>
            <p> Довуд (а.с.) нинг ютуқлари </p>
          </div>
     
          <div className='main_text'>
            <p>
            Довуд (а.с.) кўп зафарларга эришдилар. Фи­лис­тийларга ҳужум қилиб, уларни мағлуб қилдилар ва Бани Исроилни уларнинг назоратидан тўлиқ озод этдилар.
            </p>
            <p>
            Кейин Мўоб лашкари устидан ғалаба қозондилар ва Мўоб халқи Бани Исроилга ўлпон тўлайдиган бўлди.
            </p>
            <p>
            Сўбо подшоҳи Ҳадад Азар Фурот дарёси бўйидаги ноҳияларга ҳужум қилган эди, Довуд пайғамбар уни ҳам тор-мор қилдилар: бир минг етти юз суворийсини, йигирма минг пиёда ас­ка­ри­ни асир олдилар. 
            </p>
            <p>
            
              Арам лашкари Да­машқдан Сўбога ёрдамга келган эди, До­вуд (а.с.) уларни ҳам мағлуб қилди, йи­гир­ма икки минг нафарини ўлдирди. Кейин ўз аскарларидан бир қисмини Дамашққа жойлаштирдилар ва Арам халқи ҳам Бани Исроилга ўлпон берадиган бўлди. 
            </p>
            <p>
            Хуллас, До­вуд (а.с.) қаерга бормасинлар, Оллоҳ тао­ло у киши билан бирга эди. Ҳазрати Довуд Бани Исроил учун адолат ва инсофни таъмин қилдилар. 
            </p>
             </div>
            
             <div className='paragraph_heading'>
            <p>  Амўнийлар билан жанг </p>
          </div>
     
          <div className='main_text'>
            <p>
            Бир куни Амўн шоҳи Наҳаш оламдан ўтиб, унинг ўрнига ўғли Ҳонун тахтга ўтирди. Довуд (а.с.): «Наҳаш менинг содиқ дўстим эди, ўғли билан ҳам дўст бўлайлик», деб таъзия билдириш учун унинг ҳузурига ўз элчиларини жўнатдилар. 
            </p>
            <p>
            Шунда Амўн беклари Ҳонунга: «Довуд бу одамларни отангизни ҳурмат қилиб жў­нат­ган эмас! Ёмон ният билан, шаҳарни кузатиб-текшириб қўлга киритиш учун жўнатган», дедилар. 
            </p>
            <p>
            Шундан сўнг Ҳонун, келган элчи­лар­ни ушлаб, соқолларининг бир то­мо­ни­ни қириб, кийимларини кин­ди­гигача ке­сиб, орқага қайтаради.
            </p>
            <p>
            Довуд (а.с.) бу воқеани эшитиб элчилари ол­дига нафар жўнатдилар ва: «То со­қо­лин­гиз ўсгунча Ариҳода қолинглар», деб та­йин­ла­ди­лар.
            </p>
            <p>
            Амўнийлар энди уруш бўлишини билиб, ҳар томондан ўзларига куч тўплай бош­ла­ди. Байт-Раҳуб ва Сўбадан йигирма минг арамий пиёда аскар ёлланди. Маако под­шо­ҳидан мингта ва Тўб халқидан ўн икки минг аскар чақирдилар. Довуд (а.с.) буни эшитиб, лашкарбошиси Ёваб бошчилигида ҳамма йигитларини урушга сафарбар қилдилар.
            </p>
            <p>
            Амўн лашкари шаҳар дарвозаси олдида саф тортган эди. Байт-Раҳуб ва Сўбадан кел­ган Арам аскарлари ҳамда Тўб ва Маа­ко­дан келган лашкарлар ялангликда алоҳида саф тортишди. 
            </p>
            <p>
            Бани Исроилнинг лашкарбошиси Ёваб душ­ман­ларнинг ҳам орқадан ва ҳам ол­дин­дан ҳужум қилмоқчи эканини кўриб, Ис­ро­ил­нинг сара аскарларини Арам лаш­ка­рига қарши қўйди. Қолганларга эса укаси Абушайни бош қилиб, амўнийларга қарши ташлади ва Абушайга: 
            </p>
            <p>
            «Агар Арам лашкари мендан устун келадиган бўлса, ёрдамга кел, – деди. – Агар амўнийлар сени енгадиган бўлса, мен ёрдамга бораман. Дадил бўл! Халқимиз учун, Оллоҳнинг шаҳарлари учун жанг қиламиз. Оллоҳ нимани хоҳласа қилади», деди.
            </p>
            <p> Жанг бошланди. Арам лашкари Ёвабнинг йи­гит­ларига дош беролмай қоч­ди­. Буни кўриб, амўнийлар ҳам қочиб, шаҳарга беркиндилар. Шундан сўнг Ёваб жангни тўхтатиб, Байт-ул-Муқаддасга қайтди.

            </p> 
            </div>
            
            <div className='paragraph_heading'>
            <p>   Ҳадад Азарнинг мағлуб бўлгани </p>
          </div>
     
          <div className='main_text'>
            <p>
            Мағлубиятдан сўнг, Арам лашкари яна куч тўплаб жангга ҳозирлана бошлади. Шоҳ Ҳадад Азар Фурот дарёсининг на­ри­ги томонидаги арамийларга ҳам хабар бериб, уларни чақиртирди ва ҳаммаларини лаш­кар­бошиси Шўбак бошчилигида Ҳелом шаҳрига тўплади. Шу билан Ҳеломда яна жанг бўлди. Арам лашкари Бани Исроилга қарши жанг қилди. 
            </p>
            <p>
            Бу сафар ҳам Бани Исроилнинг қўли устун келиб, Арам лашкарининг етти юзта жанг аравакашини ва қирқ минг отлиқ аскарини ўлдирди. Ҳадад Азарнинг лашкарбошиси Шўбак жанг майдонида жон берди. 
            </p>
            <p>
              Шундан сўнг, Ҳадад Азарнинг қўл ос­ти­даги ҳамма подшоҳлар Исроил халқи билан сулҳ тузишга мажбур бўлдилар ва Бани Исроилга қарам бўлдилар.
            </p>
          </div>
         
          <div className='paragraph_heading'>
            <p> Орадан анча йиллар ўтди... </p>
          </div>
     
          <div className='main_text'>
            <p>Ора­дан кўп йиллар ўтди. Довуд (а.с.) ҳам қаридилар. Устларига қанча кўрпа сол­са­лар ҳам ҳеч исимас эдилар.</p>
            <p>
            Шунда сарой аъёнлари: «Аълоҳазрат, сиз­га қараш учун бир бокира қиз топсак, қўй­нин­гиз­да ётса исийсиз», дейишди ва гўзал қиз излаб бутун Исроилни ахтариб чиқ­ди­лар.
            </p>
            <p> Ниҳоят, Шўнамдан Обиша деган бир чиройли қизни танлаб, подшоҳнинг хиз­ма­ти­га қўйдилар. Лекин подшоҳ у билан қўшилгани йўқ.
            </p>
          </div>


          <BottomNav 
            labelLeft='Толут ўлди' 
            pathLeft='/public-resources/qisas-uzbek/tolutsDeath'

            labelCenter='Довуд (а.с.) тахтда'
            pathCenter='/public-resources/qisas-uzbek/davidOnThrone'

            labelRight='Сулаймон (а.с.)'
             pathRight='/public-resources/qisas-uzbek/sulayman'
          />      
        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default DavidOnThrone;