import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'
import BottomNav from './components/BottomNav';


const EgyptPlagues = () => {

  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };


    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 14;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      // console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p> Миср устига ёғилган  </p>
            <p>офатлар</p>
          </div>

          <div className='decor_line'>
            <p>
              &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;
            </p>
          </div>

          <div className='main_text'>
            <p>
              Кейин Худо Мусо (а.с.) га деди: «Яна бориб фиръавнга айт, Бани Исроилни қўйиб юборсин.  Фиръавн мўъжиза талаб қилганида, Ҳорун асосини унинг олдига ташласин. Асо илонга айланади».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ҲАССАНИНГ ИЛОНГА АЙЛАНИШИ </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p >
                <sup>1</sup> «Асони ташлаган эди асо аниқ илонга айланди. Кейин қўлини чиқарган эди, баногоҳ у қараб турганларга оппоқ бўлиб кўринди» (Аъроф сураси, 107-108).
              </p>
            </div>
            <p onClick={() => handleSupClick(1)}>
              Шундай қилиб, Мусо ва Ҳорун (а.с.) яна фиръавннинг ҳузурига бордилар. Худо айтганидек, фиръавн мўъжиза талаб қилди. Ҳорун (а.с.) асосини фиръавн олдида ерга ташлади. Асо шу лаҳзада илонга айланди.<sup>1</sup>
            </p>
            <p>

            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p >
                <sup>2</sup>  Шуаро сураси, 37-оятга қаранг.
              </p>
            </div>
            <p onClick={() => handleSupClick(2)}>
              Мўъжизани кўриб фиръавн, ўз саройидаги олимлар ва сеҳргарларни ҳузурига тилатди.<sup>2</sup> Мисрлик сеҳргарлар ҳам асоларини ерга ташлаб, илонга айлантирдилар.
            </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}
            >
              <p >
                <sup>3</sup> Аъроф сураси, 117-оят.
              </p>
            </div>
            <p onClick={() => handleSupClick(3)}>
              Лекин Ҳоруннинг илони улардан кучли эди. У мисрликлар ташлаган асоларнинг ҳаммасини, яъни, илонларни ютиб юборди.   <sup>3</sup>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}
            >
              <p >
                <sup>4</sup> Қуръонда ёзилишича, Оллоҳнинг мўъжизасини кўриб, сеҳргарлардан баъзилари саждага йиқилади (қаранг: Тоҳо сураси 20:70). Лекин фиръавн Оллоҳга бўйсунмаётган эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(4)}>
              Шундай бўлса ҳам фиръавн ўжарлик қилиб, Оллоҳ таоло аввалдан Мусо (а.с.) ни огоҳлантирганидек, Бани Исроилни қўйиб юбормади.<sup>4</sup>
            </p>
            <p>Ўша пайтда Мусо (а.с.) саксон ёшда, Ҳорун (а.с.) эса саксон уч ёшда эдилар.</p>
          </div>

          <div className='paragraph_heading'>
            <p> БИРИНЧИ ОФАТ </p>
          </div>
          <div className='main_text'>
            <p>
              «Фиръавннинг юраги ҳали ҳам тош, халқимни қўйиб юборгиси йўқ, — деди Оллоҳ Мусо (а.с.) га. — Эртага, дарёга чиққанида, Нил бўйида уни кутиб ол. Илонга айланган асонг қўлингда бўлсин.
            </p>
            <p>
              Унга: “Оллоҳ мени яна олдингизга юборди. У сизга халқимни қўйиб юбор деб амр қилган эди. Сиз ҳалигача итоат этмадингиз. Шунинг учун юртингизга офат юбормоқчи. Мен асомни Нил дарёсига ураман, дарё қонга айланади. Шунда сиз Оллоҳнинг қудратига амин бўласиз”, де.
            </p>
            <p>
              Асони сувга теккизишинг билан сув қонга айланади. Ҳамма балиқлар ўлади. Сув сасиб мисрликлар дарёдан бир томчи ҳам сув ичолмайди. Кейин Ҳорунга айт, асони Мисрдаги ҳамма дарёлар, чашмалар, ариқ ва ҳавзаларга қаратсин. Улардаги сувлар ҳам, ҳатто уйларда кўзаларда турган сувлар ҳам қонга айланади», деди.
            </p>
            <p>
              Мусо ва Ҳорун (а.с.) Худо буюрганидек қилдилар. Нил дарёси қонга айланди. Балиқлар ўлди, сув сасиди. Мисрликлар дарё сувидан ичолмай қолдилар. Бутун Миср бўйлаб сув ҳавзалари қонга айланган эди. Кейин Миср жодугарлари ҳам ўз сеҳрлари билан сувни қонга айлантирдилар.
            </p>
            <p>
              Буни кўрган фиръавн яна юрагини тош қилди ва Оллоҳ аввалдан огоҳлантирганидек, Бани Исроилни қўйиб юборгани йўқ. (У Оллоҳнинг қудратини писанд ҳам қилмаган эди.)
            </p>
            <p>
              Мисрликлар эса ичимлик суви топиш учун дарё соҳилида қудуқлар қаза бошладилар. Чунки дарёда сув ўрнига қон оқаётган эди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ИККИНЧИ ОФАТ </p>
          </div>

          <div className='main_text'>
            <p>
              Орадан бир ҳафта вақт ўтди. Бир ҳафтадан сўнг Оллоҳ (азза ва жалл) Мусо (а.с.) га деди:
            </p>
            <p>
              «Фиръавннинг олдига бор. Кетишимизга рухсат беринг, де. Айт: “Оллоҳ Менинг халқимни қўйиб юбор. Бўлмаса, Нилни бақа билан тўлдираман. Кейин бақалар дарёдан чиқиб одамларинг уйига, амалдорларинг уйига ҳужум қилади. Саройинг ҳам, ётадиган хонанг ҳам, кўрпа-тўшаклару хамир тоғораларинг ҳам бақа билан тўлади, деб амр қилди”, де».
            </p>
            <p>
              Фиръавн барибир қулоқ солгани йўқ. Шундан сўнг Худо Мусо пайғамбарга деди: «Ҳорунга айт: асони олиб дарё ва кўлларга қаратсин. Миср юрти бақага тўлсин», деди.
            </p>
            <p>
              Шундай ҳам бўлди. Бироқ мисрлик жодугарлар ҳам сеҳр ёрдамида айни ишни ижро қилдилар: бақаларни сувдан қуруқликка чиқардилар. Ҳамма ёқни бақа босиб кетди. Кейин фиръавн Мусо билан Ҳорун (а.с.) ни ҳузурига чақиртириб:
            </p>
          </div>

          <div className='main_text'>
            <p>
              —Оллоҳга дуо қилинглар, бақаларни устимиздан йўқ қилсин. Кейин мен сизларга чўлга бориб ибодат қилишга рухсат бераман, —  деди.
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Вақтини ўзингиз тайинланг, — деди Мусо пайғамбар. — Қачон сизлар учун дуо қилай?
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Эртага, — деди фиръавн.
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Яхши, айтганингиздек бўлсин, — деди расулуллоҳ. — Шундан биласизки, Раббимиз Оллоҳга ҳеч ким тенг эмас!
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[5] ? 'block' : 'none' }}
              onClick={() => handleSupClick(5)}
            >
              <p >
                <sup>5</sup> Салабий, “Ароис”, 193-194 бетлар.
              </p>
            </div>
            <p onClick={() => handleSupClick(5)}>
              Эртаси куни Мусо (а.с.) нинг дуоси ижобат бўлиб, қуруқликда юрган бақаларнинг ҳаммаси ўлди. Ўлган бақаларни йиғиб, уюм-уюм қилдилар. Миср юрти сасиди. Фиръавн эса бақалардан қутулганини кўриб яна юрагини тош қилди ва берган ваъдасини бажармади.<sup>5</sup>
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> УЧИНЧИ ОФАТ  </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[6] ? 'block' : 'none' }}
              onClick={() => handleSupClick(6)}
            >
              <p >
                <sup>6</sup> Қуръонда бу ҳашарот «қуммал» деб аталган (Аъроф сураси 133). Унинг маъноси ҳақида ихтилоф бор. Кўп таф­сирларда “бит” деб таржи­ма қилинган. Абдурраҳмон ибн Зайд ибн Аслам: «Қуммал – бургадир», деган. Ибн Аббос (р.а.) эса: «Қуммал доннинг ичидан чиқадиган мита», деган. Тавротда келган сўз ҳам бир неча маънони: бит, бурга ва шу билан бирга пашша маъноларини англатади.
              </p>
            </div>
            <p onClick={() => handleSupClick(6)}>
              Кейин Худо Мусо (а.с.) га: «Ҳорунга айт, асосини олиб ерга урсин. Ердаги чанг бургага айланиб бутун Мисрни боссин», деди. Шундай ҳам бўлди. Ҳорун (а.с.) асони олиб ерга урган эдилар, бутун Миср бўйлаб ердаги чанг бургага<sup>6</sup> айланди, одамларни ҳам, ҳайвонларни ҳам талай бошлади.
            </p>
            <p>
              Миср жодугарлари ҳам чангни бургага айлантиришга уриндилар, лекин уддасидан чиқолмадилар. Кейин фиръавнга: «Бу ишда Худонинг қўли бор», деб айтишди.
            </p>
            <p>
              Бироқ фиръавннинг қалби тош эди ва у, Оллоҳ айтганидек, Бани Исроилни қўйиб юборгани йўқ.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ТЎРТИНЧИ ОФАТ </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[7] ? 'block' : 'none' }}
              onClick={() => handleSupClick(7)}
            >
              <p >
                <sup>7</sup> Таврот китобида ёзилишича Оллоҳ субҳанаҳу ва таоло: «Мен шу тариқа Ўз халқим билан сенинг халқинг орасига фарқ қўяман», деб айтган экан.
              </p>
            </div>
            <p onClick={() => handleSupClick(7)}>
              «Эртага вақтли туриб, дарё соҳилида фиръавнни кутиб ол, — деди Худо Мусо (а.с.) га. — Унга: “Бизни қўйиб юборинг, бўлмаса Оллоҳ уйларингизни итчивинлар галасига тўлди­ра­ди. Мисрликлар яшаётган ҳамма жой чивинга тўлади. Фақат Бани Исроил яшаётган Жўшан ерида чивин бўлмайди. Шундан биласизки, бу мамлакатнинг эгаси сиз эмас, Оллоҳдир.   <sup>7</sup> Бу оят эртагаёқ амалга ошади”, деб айт».
            </p>
            <p>
              Ҳамма нарса Оллоҳ айтганидек бўлди. Фиръавннинг қасри ҳам, сарой аъёнларининг уйлари ҳам тўда-тўда итчивин билан тўлди. Ҳамма ёқни чивин босиб, яшаш қийин бўлиб кетди.
            </p>
            <p>
              Фиръавн охири чидай олмай Мусо ва Ҳорун (а.с.) ни чақиртириб:
            </p>
          </div>

          <div className='main_text'>
            <p>
              —Майли, боринглар, худойингизга қурбонлик қилинглар, фақат Мисрдан чиқмайсизлар, — деди.
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Мисрда бу ишни қила олмаймиз, биз келтирадиган қурбонликлар мисрликлар учун жирканч ҳисобланади, — деди Мусо пайғамбар. — Бизни тошбўрон қилишади-ку! Бунинг устига, Оллоҳ бизга уч кунлик йўл юриб саҳрода қурбонлик келтиринглар, деб буюрган, — деди.
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Майли, — деди фиръавн. — Саҳрода бўлса саҳрода. Фақат унча узоққа борманглар. Энди мен учун дуо қилинглар! Бу бало бошимиздан арисин!
            </p>
          </div>
          <div className='main_text'>
            <p>
              —Яхши, — деди ҳазрати Мусо. — Саройдан чиқиб Оллоҳга дуо қиламан. Эртагаёқ бу офат Мисрдан даф бўлади. Фақат бизни яна алдаманг. Худога қурбонлик қилишимизга тўсқинлик қилманг.
            </p>
            <p>
              Шу гаплар билан расулуллоҳ фиръавннинг ҳузуридан чиқдилар ва қўлларини дуога очдилар. Дуоси қабул бўлиб, Мисрдан офат ариди. Одамлар устида бир дона ҳам итчивин қолмади. Чивиндан фориғ бўлгач, фиръавн яна ўжарлик қилиб, Бани Исроилга Мисрдан кетишга рухсат бермади.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> БЕШИНЧИ ОФАТ </p>
          </div>

          <div className='main_text'>
            <p className='boldItalic'>
              «Қачонки уларнинг устига бу азоб тушгач, улар: «Эй Мусо, Парвардигорингга сенга берган ваъдаси ҳаққи-ҳурмати, дуо қил! Қасамки, агар сен бизлардан шу азобни кўтарсанг, албатта сенга иймон келтирурмиз ва бани Исроилни сен билан бирга жўнатурмиз», дедилар. Энди Биз улардан бу азобимизни ... кўтарганимизда эса, баногоҳ (ичган қасамларини) бузиб турибдилар» (Аъроф сураси, 134-135).
            </p>
            <p>
              Кейинги офат — ҳайвон вабоси эди. Оллоҳ таоло фиръавнга Мусо пайғамбар орқали: «Агар халқимни қўйиб юборишдан бош тортсанг, қўл остингда ушлаб тураверсанг, чорваларингга вабо юбораман», деб хабар берди.
            </p>
            <p>
              Лекин фиръавн итоат этгани йўқ. Натижада мисрликлар чорваси ва­бо­га учраб, туялари, от-эшаклари, қора­мо­лу қўйлари қирилиб кетди. Лекин ибронийларнинг чорвасига ҳеч нарса бўлмади.
            </p>
            <p>
              (Чунки Оллоҳ бу сафар ҳам фиръавнни олдиндан огоҳлантирган, эртага Мен сенинг подаларингга офат юбораман, Бани Исроилнинг эса биронта ҳайвони ҳам ўлмайди, деб айтган эди.)
            </p>
            <p>
              Фиръавн ибронийларнинг чорваси ўлган-ўлмаганини текшириш учун одам юборди. Мавзуънинг ҳақиқат эканини билган­дан сўнг яна юрагини тош қилди – Оллоҳга бўйсунмади.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ОЛТИНЧИ ОФАТ </p>
          </div>

          <div className='main_text'>
            <p>
              Шундан сўнг Худо Мусо ва Ҳорун (а.с.) га: «Ўчоқдан бир ҳовуч кул олиб, фиръавннинг олдига боринглар. Унинг кўз олдида кулни ҳавога сочинглар. Кул ҳавога кўтарилиб Миср юзини қоплайди. Одамлару ҳайвонлар устига ирингли чипқон тошади», деди.
            </p>
            <p>
              Шундай ҳам бўлди. Мусо ва Ҳорун (а.с.) ўчоқдан кул олиб, фиръавннинг олдига бордилар ва кулни ҳавога совурдилар. Одамларнинг ҳам, ҳайвонларнинг ҳам устига чипқон тошди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[8] ? 'block' : 'none' }}
              onClick={() => handleSupClick(8)}
            >
              <p >
                <sup>8</sup> Тавротда ёзилишича, ўша пайтда фиръавн қўрқиб кет­мас­лиги учун Оллоҳ таоло унинг қалбини қўрқувдан озод қилган экан. Қуръони каримнинг Юнус сурасида ҳам Мусо (а.с.) нинг: «Ё Раббимиз, уларнинг ... қалбларини шиддатли қилки, то аламли азобни кўрмагунларича иймон келтирма­син­лар», деган дуоси ёзилган (88-оят).
              </p>
            </div>
            <p onClick={() => handleSupClick(8)}>
              Бу сафар Миср жодугарлари Мусо (а.с.) билан рақобат қила олмадилар, чунки ҳамма қатори уларнинг устига ҳам чипқон чиққан эди. Шундай бўлса ҳам, фиръавн Оллоҳнинг расулига қулоқ солгани йўқ.<sup>8</sup>
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ЕТТИНЧИ ОФАТ </p>
          </div>

          <div className='main_text'>
            <p>
              «Эртага яна фиръавннинг олдига бор, — деди Оллоҳ. — Унга, бизнинг Худойимиз бундай дейди, деб айт: “Мен аллақачон сени ҳам, одамларингни ҳам нобуд қилишим мумкин эди. Бироқ қудратимни ер юзига намойиш қилиш учун сизларни ҳалигача омон сақладим. Қачонгача кибрланасан? Қачонгача халқимни ушлаб турасан? Агар энди ҳам қаршилик қилсанг, бошингга ўлим офатлари келади. Эртага шу пайтда устингизга даҳшатли дўл ёғдираман. Одамларингга айт: даладаги ҳайвонларини пана жойга олсинлар. Билиб қўй: бу сафар ташқарида қолган ҳамма одам, ҳамма ҳайвон ҳалок бўлади”».
            </p>
            <p>
              Ушбу огоҳлантиришдан сўнг, фиръавннинг хизматкорлари ичида Худодан қўрққанлари, одамлари ва ҳайвонларини панага олдилар. Қўрқмаганлар қулу ҳайвонларини далада қолдиришди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ДЎЛ ЁҒДИ </p>
          </div>

          <div className='main_text'>
            <p>
              «Қўлингни осмонга чўз, — деди Худо Мусо пайғамбарга. — Мисрнинг ҳамма ерига, одамлар, ҳайвонлар, ўсиб турган дов-дарахтлар устига дўл ёғсин».
            </p>
            <p>
              Ҳазрати Мусо асосини осмонга кўтардилар. Момақалдироқ гумбурлаб чақмоқ чақа бошлади. Миср бино бўлгандан бери, мисрликлар бундоқ шиддатли дўлни ҳали кўрмаган эдилар. Очиқда қолган инсонми, ҳайвонми, ҳаммаси ўлди. Ўт-ўланлар нобуд бўлди. Дарахтларнинг шохлари синиб, яп-яланғоч қолди. Дўл ёғмаган ягона жой Бани Исроил турган Жўшан ери эди.
            </p>
            <p className="vitalic">
              Ҳеч эътибор берганмисиз: Оллоҳ таоло бизга нимаики амр қилган бўлса бизнинг фойдамиз учун қилган. Мана шу ҳолатда ҳам, Худо уларга ҳайвонларини панага олишни амр қилган эди.
            </p>
            <p className="vitalic">
              Парво қилган қилди. Парво қилмаган йўқотди. Худонинг сўзига эътибор бермаслик ана шундай оқибатларга олиб келади.
            </p>
            <p className="vitalic">
              Худо Ўз амрларини биз учун, бизнинг фойдамиз учун берган. Амрларига эътибор бермасак, ўзимизга ўзимиз ёмонлик қилган бўламиз. Ўзимизни ўзимиз жазолаган бўламиз.
            </p>
            <p className="vitalic">
              Айни гап охиратимизга ҳам тааллуқли: агар биз ҳозир — ерда Худонинг йўлини танламасак, у дунёда Худосиз яшашни танлаган бўламиз: ерда Худо билан бирга бўлишни истамаганлар у ёқда Худонинг ҳузуридан баҳраманд бўлолмайди.
            </p>

            <p>
              Шундан кейин фиръавн Мусо билан Ҳорун (а.с.) ни ҳузурига чақиртириб: «Мен айбдорман. Оллоҳ ҳақ, мен ва халқим ноҳақмиз. Раббингизга айтинг, момоқалдироқ билан дўлни тўхтатсин. Мен сизларни қўйиб юбораман, бу ерда бошқа қолмайсизлар», деди.
            </p>
            <p>
              Расулуллоҳ бунга жавобан: «Мен шаҳардан чиқишим билан Оллоҳга дуо қиламан. Момоқалдироқ ҳам, дўл ҳам тўхтайди. Шунда сиз бутун ер юзи Оллоҳники эканини билиб оласиз. Бироқ сиз ҳам, амалдорларингиз ҳам ҳали Ундан қўрқаётганингиз йўқ, биламан», дедилар.
            </p>
            <p>
              (Дўл, арпа билан зиғирни бутунлай нобуд қилган эди. Чунки арпа бу пайтда бош чиқарган, зиғир эса гуллаётган эди. Бироқ оқ буғдой билан қизил буғдой кечпишар бўлгани учун, зарар кўрмади.)
            </p>
            <p>
              Мусо (а.с.) шаҳардан чиқиб дуо қилдилар. Момақалдироқ ҳам, дўл ҳам тўхтади. Ёмғир тинди. Фиръавн билан сарой аҳли эса буни кўриб яна ўжарлик йўлини танладилар — юракларини тош қилиб Бани Исроилнинг кетишига рухсат бермадилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> САККИЗИНЧИ ОФАТ </p>
          </div>

          <div className='main_text'>
            <p>
              Кейин расулуллоҳ, Ҳорун (а.с.) билан бирга яна фиръавннинг олдига бордилар ва дедилар:
            </p>
            <p>
              «Оллоҳ таоло, қачонгача Менга қаршилик қиласан, қачон Менга бош эгасан, деб айтмоқда. Менинг халқимни қўйиб юбор. Бўлмаса эртага юртингни чигирткага тўлдираман, дейди Оллоҳ. Дўлдан омон қолган ҳамма экинлар, дарахту буталар чигирткага ем бўлади. Чигиртка кўплигидан ҳамма ёқ қоп-қора бўлиб, ер кўринмай қолади!»
            </p>
            <p>
              Шу гапларни айтиб фиръавннинг ҳузуридан чиқиб кетдилар.
            </p>
            <p>
              Сарой амалдорлари эса фиръавнга мурожаат қилиб: «Аълоҳазрат, қачонгача шу одамни деб қийналамиз? Қўйинг, қавмини олиб жўнасин, худосига тоат-ибодатини бажарсин. Кўрмаяпсизми, бутун Миср вайрон бўляпти?» дедилар.
            </p>
            <p>
              Шунда фиръавн, Мусо (а.с.) билан Ҳорун (а.с.) ни қайтадан ҳузурига чақиртирди: «Майли боринглар, Худойингизга ибодат қилинглар. Хўш, кимлар кетади?» деб сўради.
            </p>
            <p>
              «Ёш-қари ҳаммамиз кетамиз, — деди Мусо пайғамбар. — Ўғил-қизларимизни, пода-сурувларимизни ҳам ўзимиз билан олиб кетамиз».
            </p>
            <p>
              «Оллоҳ сақласин! — деди фиръавн киноя билан. — Сизларни хотин-бола-чақаларингиз билан қўйиб юборайми?! Нияти бузуқлар! Йўқ, фақат эркаклар бориб Худойингизга ибодат қиласизлар. Ибодат қиламиз, деган эдингиз, боринглар!» деди. Кейин Мусо ва Ҳорун (а.с.) ни саройдан ҳайдаб чиқарди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ЧИГИРТКАЛАР ҲУЖУМИ  </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[9] ? 'block' : 'none' }}
              onClick={() => handleSupClick(9)}
            >
              <p >
                <sup>9</sup> Ибн-и Асир. “Комил”, 1-жилд, 186-бет.
              </p>
            </div>
            <p onClick={() => handleSupClick(9)}>
              Кейин Худо Мусо (а.с.) га: «Қўлингни Мисрга қаратиб чўз», деди. Ўша куни бир кун-у бир тун шарқдан шамол турди. Эрталаб шамол билан бирга бир олам чигиртка тўдаси Мисрга учиб келди. Ҳамма ёқни чигиртка босди. Чигиртка кўплигидан ер бети кўринмай қолди. Дўлдан омон қолган ўтлару дарахтларнинг ҳаммаси чигирткаларга озиқ бўлди. Мисрнинг ҳеч қаерида, на дарахтларда, на сою қирларда кўк ранг қолмади.<sup>9</sup>
            </p>
            <p>
              Ташвишга тушган фиръавн тезда Мусо (а.с.) ларни ўз ҳузурига чақиртириб: «Мен сизларнинг олдингизда, сизларнинг Худойингиз олдида яна гуноҳкор бўлдим. Энди яна бир бор мени кечиринглар, узримни қабул қилиб Худойингиздан сўранглар, бу ўлим балосини мендан узоқлаштирсин», деб илтимос қилди.
            </p>
            <p>
              Расулуллоҳ фиръавннинг олдидан чиқиб Оллоҳга дуо қилдилар. Шунда ғарбдан кучли шамол эсиб, чигирткаларнинг ҳаммасини Қизил Денгизга олиб бориб тўкди. Лекин фиръавн Бани Исроилни бу сафар ҳам қўйиб юбормади. (Оллоҳ фиръавнга ўз қарорида собит туришига йўл очган, қалбидаги қўрқувни юмшатган эди.)
            </p>
            <p className='boldItalic'>
              «Энди қачонки Биз (Мусонинг дуоси билан) улардан азобни аритсак, баногоҳ улар (ўз ваъдаларини) буза бошладилар» (Зухруф сураси, 50-оят).
            </p>
            <p className="vitalic">
              Фиръавн ўзининг ноҳақлигини биларди. Лекин билишнинг ўзи ҳали етарли эмас. Масалан, шайтон ҳам Оллоҳнинг кимлигини билади. Лекин Оллоҳга бўйсунмайди. Билишнинг ўзи етарли эмас. Киши билган нарсасига ҳаётда амал қилиши керак. Сизнинг ҳаётингизда ҳам сиз нотўғрилигини билган, лекин амал қилмаётган нарсаларингиз борми?
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ТЎҚҚИЗИНЧИ ОФАТ  </p>
          </div>

          <div className='main_text'>
            <p className='boldItalic'>
              «Батаҳқиқ, Биз Мусога тўққизта очиқ-ойдин оят-мўъжиза бердик» (Исро 17:101).
            </p>
            <p>
              Кейин Оллоҳ таоло ҳазрати Мусога: «Қўлингни осмонга узат, Миср юртини зулмат қопласин», деди. Мусо (а.с.) қўлини осмонга узатдилар.
            </p>
            <p>
              Бутун Миср юрти уч кунгача қуюқ зулмат ичида қолди. Уч кун мобайнида кўз кўзни кўролмади, ҳеч ким жойидан қимирлай олмади. Фақат Бани Исроил яшаётган ерлар ёруғ эди.
            </p>
            <p>
              Фиръавн яна Мусо (а.с.) ни чақириб: «Майли, боринглар, Худойингизга ибодат қилинглар. Бола-чақаларингизни ҳам олиб кетинг. Фақат молларингиз Мисрда қолади», деди.
            </p>
            <p>

              «Йўқ, — дедилар Оллоҳнинг расули. — Молларни олиб кетмасак Худога нимани қурбон қиламиз? Биронта ҳам ҳайвонни ташлаб кетолмаймиз, сабаби то қурбонлик қиладиган жойга бормагунимизча қайси ҳайвонларни қурбон қилишимизни билмаймиз».
            </p>
            <p>
              «Йўқол кўзимдан! — деди фиръавн. — Бошқа қорангни кўрсатма. Қорангни кўрсатган кунинг ўласан!»
            </p>
            <p>
              «Бўпти, — дедилар Мусо пайғамбар. — Қорамни бошқа кўрмаганингиз бўлсин!»
            </p>
            <p>
              Шу он Оллоҳ субҳанаҳу ва таоло у кишига деди: «Энди Мен мисрликлар устига яна бир офат юбораман, кейин у сизларга жавоб беради. Ўзи ҳаммангизни Мисрдан чиқариб юборади». Расулуллоҳ фиръавнга дедилар: «Оллоҳ таоло дейди: қоронғу кечада Мен Мисрни жазолайман. Мисрдаги ҳар бир тўнғич ўғил — фиръавннинг боласидан бошлаб, чўриларнинг ўғлигача, ҳатто молларнинг тўнғичигача ҳаммаси нобуд бўлади. Бутун Мисрда ҳозиргача мисли кўрилмаган ва бундан кейин ҳам мисли кўрилмайдиган мотам фарёди кўтарилади. Лекин Бани Исроилга ҳеч қандай зарар етмайди. Шундан сен Мен Ўз халқим билан сенинг халқинг орасига фарқ қўйганимни билиб оласан».
            </p>
            <p>
              «Шунда, — дедилар Мусо (а.с.) гапларини давом эттириб. — Ҳамма амалдорларингиз олдимга келиб, бош эгиб, Мисрдан чиқиб кетишимизни илтимос қилади. Биз шундан сўнг кетамиз». Кейин асабоният билан фиръавннинг олдидан чиқиб кетдилар.
            </p>
            <p></p>
          </div>

          <BottomNav 
            labelLeft='Учта мўъжиза' 
            pathLeft='/public-resources/qisas-uzbek/three-miracles-of-Moses'

            labelCenter='Офатлар'
            pathCenter='/public-resources/qisas-uzbek/Egypt-plagues'

            labelRight='Қурбонлик'
            pathRight='/public-resources/qisas-uzbek/death-of-firstborn'
            />

 
        </main >
        <aside> </aside>
      </div >
    </div >
  )
}

export default EgyptPlagues
