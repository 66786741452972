import React from 'react'
 
 
import '../../feature/posts/components/PostShablon.css'

import LikeButton from '../../feature/posts/components/LikeButton';

import BookmarkingButton from '../../feature/posts/components/BookmarkingButton';

import authorImage from '../../images/ozod.jpeg';
import attachedImage from '../../images/KevinImages/intro.jpg';
import reply from '../../images/reply.png';

import heart1 from '../../images/heart1.png';
import heart2 from '../../images/heart_red.png';
 
import flag from '../../images/flag.png';
import views from '../../images/views.png';
import share from '../../images/share.png';
import repost_btn from '../../images/shared.png';
import { useTranslation } from 'react-i18next';

const PostShablonPublic = React.forwardRef(({
  post,
  globalPostsArrayName,
  setCurrentSelectedPost,
  setShowReportPostPopup,
  setShowPostDetailsPopup,
  setShowReplyPopup,
  setShowRepostPopup }, ref) => {

  const { t } = useTranslation()
  // const navigate = useNavigate()
   
  
  // const navigateToPostDetails = () => {
  //   navigate(`/post/${post._id}`, {
  //     state: {
  //       content: post.content,
  //       authorNick: post.author.nick,
  //       authorSearchName: post.author.searchName,
  //       updatedAt: post.updatedAt,
  //       authorImage: post.author.image,
  //       postImage: post.image
  //     },
  //   });
  // };

 

  const additional_class = post?.content ? 'additional_class' : '';
  const additional_class2 = !post?.content ? 'additional_class2' : '';

  

 

  const handlePopupOpen = () => {
    setCurrentSelectedPost(post)
    setShowReplyPopup(true)
  };

  const handleRepostOpen = () => {
    setCurrentSelectedPost(post)
    setShowRepostPopup(true)
  };


  const handlePostDetailsOpen = () => {
    setCurrentSelectedPost(post)
    setShowPostDetailsPopup(true)
  };

  const handleReportPostOpen = () => {
    setCurrentSelectedPost(post)
    setShowReportPostPopup(true)
  };

 
   
 
  const content =
    <div>
      {post?.content || !post?.reposted ? null : (
        <div className='when_reposting_without_comment'
        >
          <header >
            <div className='repost_icon' >
              <img src={repost_btn} alt="" />
            </div>
            <p> by Ali Rahman </p>
              <button
                title={t('PostShablon.ReportThisPost')}
                className='flag_this_post'
              
              >
                <img src={flag} alt="" />
              </button>
          </header>
        </div>
      )}

      <div className="both_contents">
     
          <div className="profile_img_container_small"
            // onClick={() => navigateToProfile(post.author._id)}
            >
            <img src={authorImage} alt="" />
          </div>
 

        <div className='inside_post'>
         
              <header >
                <div className='my_user_ism'>
                  <div className='bold'>Эркин Эмин</div>
                  <div className='searchName'> @erk_emin42 </div>
                  <div className='time_date'> 24.12.2024 </div>
                </div>

             
                  <button
                    title={t('PostShablon.ReportThisPost')}
                    className='flag_this_post'
                
                  >
                    <img src={flag} alt="" />
                  </button>
             
              </header>

          <div className='reposters_content post_content'
            style={{ marginBottom: '7px' }}
          >
              <p>
              We have updated the sign up form. Now it is much easier. And does not require uploading images. Images can be uploaded later on in Profile page.  
              </p>
          </div>

          <div className='attach_img'>
            <img src={attachedImage} alt='' />
          </div>
    
          <div className={`network_icons ${additional_class2}`}>

            <button className='reply' title={t('PostShablon.ReplyButton')}
            >
              <div className='img_contain'>
                <img src={reply} alt="" />
              </div>
              <div>
                <p> 230 </p>
              </div>
            </button>

            <button title={t('PostShablon.RepostButton')} className='repost_button'
            >
              <div className='img_contain'>
                <img src={repost_btn} alt="" />
              </div>
              <div>
                <p> 34 </p>
              </div>
            </button>
                <button
                  className='like_button_count'
                >
                  <div className='relative'>
                    <div className='img_contain'>
                      <img src={heart2} alt="" />
                    </div>
                    <div className='like-count'>
                      <p className='red'> 44 </p>
                    </div>
                  </div>
                </button>

            <button title={t('PostShablon.ViewButton')} >
              <div className='img_contain'>
                <img src={views} alt="" />
              </div>
              <div><p> 111 </p></div>
            </button>

            <div className='last_two_btns'>
              <BookmarkingButton post={post} globalPostsArrayName={globalPostsArrayName} />

              <button title={t('PostShablon.ShareButton')}>
                <div className='img_contain'>
                  <img src={share} alt="" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  return ref ? <div ref={ref} className='actual-posts' >{content}</div> : <div className='actual-posts'>{content}</div>
})


export default PostShablonPublic
