import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const DavidOnRun = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>
                    Довуд (а.с.) қочқинликда  
                  </p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

                <div className='paragraph_heading'>
                    <p> Қаила шаҳрини озод қилиш </p>  
                </div>

            
          <div className='main_text'>
            <p>
            Бир куни Довуд (а.с.) га кимдир: «Филис­тий­лар Қаила шаҳрига ҳужум қилиб, хир­мон­ни та­лашяпти», деган хабар олиб келди.
            </p>
            <p>
            Шунда Довуд (а.с.) дуо қилиб Оллоҳдан: «Фи­лис­тий­ларга қарши жанг қилайми?» деб сўра­дилар. 
            </p>
            <p>
            «Қил, – деган жавоб келди. – Қаилани қутқар». 
            </p>
            <p>
            Лекин Довуд (а.с.) нинг йигитлари: «Биз ўзимиз қўрқиб, бу ерда Яҳузияда яшириниб юрибмиз, Қаилага бориб, фи­лис­тийлар билан урушишга йўл бўлсин!» дедилар. 
            </p>
            <p>
            Довуд (а.с.) яна дуога юз тут­ди­лар. Оллоҳ таоло: «Бор, Мен филис­тий­лар­ни сенинг қўлингга бердим», деди. Шундан сўнг улар Қаилага бориб, филистийларга қарши жанг қилдилар. 
            </p>
            <p> Душман оғир талафот кўрди. Довуд (а.с.) нинг йигитлари Қаила халқини қутқариб, филистийларнинг қанча бош молини ўлжа олдилар. 
            </p>
         
          </div>

          <div className='paragraph_heading'>
            <p> Қаиладан қочиш </p>
          </div>
          
          <div className='main_text'>
            <p>
            Подшоҳ Толут Довуд (а.с.) нинг Қаилага кел­га­нини эшитиб: «Худо уни менинг қўлим­га берди, – деб ўйлади. – Дарвоза­ла­ри тамбаланадиган шаҳарга кириб, ўзи­ни тузоққа туширди. Бориб уларни қамал қи­ла­ман», деб бутун лашкарини йиғиб Қаилага қараб ҳаракат қилди. 
            </p>
            <p>
            Довуд (а.с.) Толутнинг режасини эшитиб: «Ё Оллоҳ! Толут бу ерга келиб, мен учун шаҳар­ни вайрон қилмоқчи экан. Қаи­ла­лик­лар мени Толутнинг қўлига тутиб бе­ра­ди­ми? Толут бу ерга келадими? Қулингга аён қил!» деб дуо қилдилар. 
            </p>
            <p>
            «Ҳа, Толут келади», деди Худо. 
            </p>
            <p>
            «Қаила халқи бизни Толутга тутиб берадими?»
            </p>
            <p>
            «Ҳа, тутиб беради». 
            </p>
            <p> Шундан сўнг ҳазрати Довуд олти юз­та­ча одами билан Қаилани тарк этдилар. Бир ерда узоқ турмай, тез-тез жойларини ал­маш­тириб юрдилар. 
            </p>
            <p>
            “Довуд Қаиладан қо­чиб кетибди”, деган хабарни эшит­ган­дан сўнг Толут Қаилага келмади.
            </p>
          </div>

          
          <div className='paragraph_heading'>
            <p>  Довуд (а.с.) нинг Толут билан ғорда учрашгани </p>
          </div>
         
          <div className='main_text'>
            <p>
            Бир сафар Довуд алайҳис-салом Айн Жадди даш­ти­да яшириниб юрган эдилар. Толут буни эши­тиб, энг сара аскарларидан уч минг­та­си­ни олиб, у кишини излаб келди. 
            </p>
            <p>
            Йўлда бир қўй қўра бўлиб, ёнида бир ғор бор эди. Толут ҳожат учун ўша ғорга кирди. Ғорнинг энг ичкарисида эса ҳазра­ти Довуд йигитлари билан яшириниб ўтир­ган эдилар. 
            </p>
            <p>
            Йигитлари Довуд (а.с.) га: «Раббимиз сизга, душ­манингни қўлингга бераман, нима қил­санг қил, деб айтган кун келди!» де­ди­лар. 
            </p>
            <p>
            Шунда Довуд (а.с.) секин бориб, Толутнинг этагидан билдирмай бир парча кесиб олди. Кейин ҳатто шу ишидан ҳам пушаймон бўлиб, йигитларига:
            </p>
            <p>
            «Худо сақласин! Мен Худо тайин этган подшоҳнинг жонига қасд қилмайман, унга қарши қўл кўтармайман. Оллоҳ уни масҳ этган. Сизларга ҳам рух­сат бермайман», деб Толутга зарар ет­ка­зиш­ларига йўл бермади.
            </p>
          <p> Толут ғордан чиққач, Довуд (а.с.) ҳам унинг орқасидан чиқиб: «Подшоҳим, под­шо­ҳим!» дедилар. Толут орқасига ўги­рил­ди. </p>  
          </div>
          
          <div className='paragraph_heading'>
            <p> “Нега мени таъқиб қиляпсиз?” </p>
          </div>
     
          <div className='main_text'>
            <p>
            Довуд (а.с.) эгилиб таъзим қилдилар-да: «Нега одамларнинг гапига қулоқ соласиз? Мен сизга қачон ёмонлик қилишни хоҳ­лаб­ман?! – дедилар. – Мана, ҳозир ҳам сизни ўлдирсам иложим бор эди. Ғорда йигитларим сизни ўлдирайлик деди, лекин йўл бермадим. “Бу кишини Оллоҳ масҳ этган, мен Оллоҳнинг танлаганига қўл кўтармайман”, дедим. 
            </p>
            <p> Қаранг, ота! Мана бу парчани мен сизнинг этагингиздан кесиб олдим. Сизга душман эмасман, нега ишонмайсиз? Нега мени таъқиб қилиб юрибсиз, нега ўлдирмоқчисиз? Оллоҳнинг Ўзи орамизда ҳукм чиқарсин! Жазоласа Ўзи жазоласин! Mен сизга қарши қўл кўтармайман. Эскилар, ёмонлик ёмондан келади, дейдилар, лекин мен сизга ҳеч ёмонлик қилмадим-ку! 
            </p>
            <p>
            Унда нега мени таъқиб қилиб юрибсиз? Таъқиб қи­ли­шин­гизга арзимайман-ку! Худонинг Ўзи ким ҳақ эканини ажрим қилсин. Мени сиздан Ўзи қутқарсин!»
            </p>
          </div>
          
          <div className='paragraph_heading'>
            <p> “Сен албатта подшоҳ бўласан!”
            </p>
          </div>
     
          <div className='main_text'>
            <p>
            Довуд (а.с.) гапини тугатар эканлар, подшоҳ: «Довуд, ўғлим, бу сенмисан?» деб ба­ланд овоз билан йиғлай бошлади.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p> <sup>1</sup> Ибн-и Асир, “Комил”, 1-жилд, 221-бет.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
                «Сен ҳақ­сан, мен ноҳақман, – дерди подшоҳ. – Сен менга яхшилик қилиб келдинг, мен эса ёмонлик.<sup>1</sup> Бугун ҳам Оллоҳ мени қўлингга топширган экан, ўлдирмадинг. 
            </p>
            <p>
            Ким душманини ушласа соғ-саломат қў­йиб юборади!? Қилган яхшилигинг Оллоҳ­дан қайтсин! Энди ишончим комил: сен албатта тахтга ўтирасан, Салтанатни идо­ра этасан. Менга, авлодингизни йўқ қил­­май­ман, отангиз хонадонидан отин­гиз­ни ўчир­майман, деб Худонинг олдида онт ич», деди. 
            </p>
            <p>
            Довуд (а.с.)  онт ичдилар. Шундан сўнг под­шоҳ уйига кетди. Довуд пайғамбар эса йигитлари билан қўрғонига қайтдилар.
            </p>
          </div>

          <BottomNav 
            labelLeft='Ҳасад' 
            pathLeft='/public-resources/qisas-uzbek/tolutsJealousy'

            labelCenter='Қочқинликда'
            pathCenter='/public-resources/qisas-uzbek/davidOnRun'

            labelRight='Сомуил (а.с.) ўлими'
             pathRight='/public-resources/qisas-uzbek/somuelsDeath'
          />  
        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default DavidOnRun;