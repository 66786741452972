import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Search from '../../components/Search'
import PostCreate from '../posts/components/PostCreate'

// import converter from '../../images/shared.png';
// import post_something from '../../images/post_new.png';
// import hamburger from '../../images/hamburger.png';
import './qisasTopbar.css';

import useAuth from '../../hooks/useAuth';
import navigateToResources from '../../config/navigateToResources';


const QisasTopbar = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [isPostCreateOpen, setPostCreateOpen] = useState(false)
  const [search, setSearch] = useState('')

  return (
    <div className='qisas_navbar'  >
      <div className='middle_part1'>
      <div className='left_corner_div'  >
        <div className='go_main_menu' title='go to the main menu'
          onClick={() => navigate('/blogPage')}>
          ←
        </div>
        <div className='div_with_h1'>
          <h1 title='go one step back' onClick={() => navigate(-1)} >
            <span className='tiny_small_text330'>  <span className='green'>Пайғамба</span>р<span className="green">лар</span> </span> та<span>р</span>их<span className='tiny_small_text330'>и</span>
          </h1>
        </div>
      </div>

      <div className='qisas_network_icons_topbar' onClick={(e) => {
        e.stopPropagation();
      }} >

        <button className='px-3 py-1 text-sm mr-1 bg-green-600 hover:!bg-green-800 !border-1 text-white hover:text-gray-800'
          title='menu only for this book. For the main menu click the left side'
          onClick={() => {
            navigateToResources('/public-resources/qisas-uzbek/home-qisas', navigate, auth?.accessToken)
          }}
        >
          Menu
        </button>
      </div>
      </div>

      <div className="middle_part2">

      {isPostCreateOpen && <PostCreate setPostCreateOpen={setPostCreateOpen} />}
      <Search setSearch={setSearch} />
      </div>
    </div>
  )
}

export default QisasTopbar