import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'
import BottomNav from './components/BottomNav';


const DesertAdventures = () => {

  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 14;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      // console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p> Бани Исроилнинг саҳродаги </p>
            <p> саргузашти </p>
          </div>

          <div className='decor_line'>
            <p>
              &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;
            </p>
          </div>

          <div className='main_text'>
            <p className='boldItalic'>
              «Ворис этдик хўрланган бу <span className="vitalic">(Бани Исроилни) </span> ўша ер­нинг шарқу ғарбларига» <span className="vitalic">(Аъроф сураси, 137-оят).</span>
            </p>
            <p className="vitalic">
              Шу билан Бани Исроил, Оллоҳ таолонинг раҳнамолигида мерос қи­линган ерга бориш учун йўлга тушдилар. Мисрдан Канъон­га­ча бўлган масофа унча узоқ бўлмай, от-улов билан борилса, атиги бир неча ҳафтагина вақт олар эди. Лекин Худонинг халқи учун икки ҳафталик йўл сафари кейинчалик қирқ йилга чўзилиб кетади.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> Таом устида жанжал </p>
          </div>

          <div className='main_text'>
            <p>
              Кейин Исроил жамияти Елимни тарк этиб, Елим билан Сино орасидаги Сийн саҳросига келди. Уларнинг Мисрдан чиққанига бир ярим ой бўлган эди.
            </p>
            <p>
              Сийн саҳросига келганларида одамлар ҳазрати Мусо билан Ҳорун (а.с.) га қарши шикоят қила бошладилар. Мусо пайғамбарга: «Мисрдан кетмаслигимиз керак эди. Худо бизни Мисрда гўшт қозонлар олдида ўлдирганида бундан минг марта яхши бўлар эди. У ерда ҳеч бўлмаса хоҳлаганимизча гўшт ер эдик. Сизлар эса бизни чўлга олиб келиб очдан ўлдирмоқчисизлар», деган гапларни айтдилар.
            </p>
            <p>
              Оллоҳ таоло ўшанда Мусо пайғамбарга: «Мен сизларга осмондан нон ёғдираман. Халқ ҳар куни бориб кунлик ризқини йиғсин. Шу орқали уларни синайман, қани, ҳидоятларимга итоат қилармикан-йўқми?» деб айтган экан.
            </p>
            <p>
              Мусо билан Ҳорун (а.с.) сўнгра бориб, халқни йиғиб уларга: «Сизларни Мисрдан биз эмас, Худо олиб чиқди. Ризқ-рўзингизни ҳам биз эмас, Худо беради. Сизлар бизга эмас, Худога қарши чиқяпсизлар. У сизларни кечқурун гўштга, эртага нонга тўйдиради. Нахотки ушбу оятлардан кейин ҳам ақлингизни йиғмасангиз?! Билингларки, сизлар Худодан шикоят қиляпсизлар!» деб айтдилар.
            </p>
            <p>
              Ўша пайт, одамларнинг кўз олдида Оллоҳнинг дурухшон нури булут кўринишида пайдо бўлди ва Мусо (а.с.) га: «Мен Бани Исроилнинг шикоятларини эшитдим. Уларга айт, оқшом гўшт ейсизлар, эрталаб қорнингизни нонга тўйдираман. Шунда биласизларки, Мен сизларнинг Парвардигорингизман», деган ваҳий келди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ОСМОНДАН ЁҚҚАН УН </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p >
                <sup>1</sup> Ибн Касирнинг айтишича, «Манн» ўзини еса, таом ва ширинлик, сувга қўшиб ичса, ичимлик бўладиган нарсадир.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} className='boldItalic'>
              «Булутни устингиздан соя қилдирдик, сизлар учун манн<sup>1</sup> ва бедана туширдик. Ризқ қилиб берган нарсаларимизнинг покларини енглар, дедик. Улар Бизга эмас, ўзларига зулм қилаётган эди» (Бақара сураси 57).
            </p>
            <p>
              Кечқурун беданалар келиб, бутун қароргоҳни тўлдирди. Эрталаб эса қароргоҳ атрофини шудринг қоплади. Шудринг йўқолгач, ерда қировга ўхшаш майда заррачалар кўринди. Одамлар бир-бирларидан: «Бу нима экан?» деб сўрар эдилар.
            </p>
            <p>
              Расулуллоҳ уларга: «Бу — Оллоҳнинг сизларга берган нони. Ҳар ким керагича йиғсин. Одам бошига бир умродан териб олинглар. Оллоҳ шундоқ деб амр қилди!» дедилар.
            </p>
            <p>
              Одамлар айтилганидек қилишди. Оила узвларининг сони кўп бўлганлар кўпроқ, кам бўлганлар камроқ йиғдилар. Ўлчаб кўрганларида на кўп йиққаннинг ортиқчаси ва на оз йиққаннинг камчилиги бор эди. Ҳар ким ўз оиласига етарли қилиб йиққан эди.
            </p>
            <p>
              (Бу ундан пиширилган ноннинг ранги гашниз уруғига ўхшаб оқчил, мазаси эса асал қўшиб пиширилган нонга ўхшарди.)
            </p>
            <p className="vitalic">
              Ҳа, инсон ҳаёти қийинчиликлардан холи эмас. Баъзан «чўллардан» ўтишимизга, очликдан қийналишимизга тўғри келади. Шундай пайтлар келадики, “иймоним борми ўзи, йўқми” деган хаёлларга ҳам борасиз.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> Фақат кунлик ризқни теринглар </p>
          </div>

          <div className='main_text'>
            <p>
              Мусо пайғамбар одамларга яна бундай деб айтган эканлар: «Худо сизларнинг кундалик ризқингизни кунига бериб туради. Шунинг учун унни эртага еймиз деб олиб қўйманглар. Эртага Оллоҳ яна ризқингизни беради! Ҳар куни фақат бир кунга етарли қилиб олинглар».
            </p>
            <p>
              Лекин баъзилар Мусо (а.с.) нинг гапига эътибор бермай, ўша куни йиққан уннинг бир қисмини эртага деб олиб қўяди. Эртасига қарасалар, олиб қўйган унлари сасиб, қуртлаб кетибди.
            </p>
            <p className="vitalic">
              Ҳа, эртаги кунингизни Худога топшириш ҳар доим ҳам осон эмас. Чунки ташвиш деган нарса ўзига яраша кучга эга. Сизни ташвиш­га соладиган нарсалар борми? Нималар? Улардан қайсиларини Худога топшира оласиз?
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ДАМ ОЛИШ КУНИНИНГ РИЗҚИ </p>
          </div>

          <div className='main_text'>
            <p>
              Одамлар ҳар куни эрталаб ўзларига керагича унни йиғиб олар эдилар. Кун исигач, ерда қолган ун эриб кетарди. Олтинчи кун қарасалар, йиғилган ун икки баравар кўп экан! Буни кўрган оқсоқоллар нима бўлди экан, деб Мусо пайғамбарнинг олдига келдилар.
            </p>
            <p>
              «Оллоҳ айтдики, эртага еттинчи кун, дам олиш куни, — деди расулуллоҳ. — Худо бир кунни дам олиш учун алоҳида ажратди. Шу сабабдан эртага пиширадиганингизни ҳам бугун пиширасиз, қайнатадиганингизни бугун қайнатасиз. Пиширган таомингизнинг бир қисмини бугун, бир қисмини эса эртага ейсизлар».
            </p>
            <p>
              Одамлар шундан сўнг, расулуллоҳ буюрганларидек, емакнинг ярмини эрта учун олиб қўйдилар. Ўша куни таом на сасиди, на қуртлади.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ЕТТИНЧИ КУН — ДАМ ОЛИШ КУНИ </p>
          </div>

          <div className='main_text'>
            <p>
              Мусо пайғамбар кечқурун халқни тўплаб дедилар: «Эртага дам олиш куни. Шунинг учун осмондан нон ёғмайди. Ҳафтанинг олти куни нон йиғасизлар, аммо еттинчи кун ташқарида нон бўлмайди».
            </p>
            <p>
              Бу гаплардан кейин ҳам, баъзи одамлар ун йиғиш учун далага чиқдилар, аммо ҳеч нарса топмадилар. Шунда Оллоҳ таоло Мусо (а.с.) га: «Қачонгача Менинг буйруқ ва ҳидоятларимни бажаришдан бош тортасизлар? Сизлар учун дам олиш кунини та­йин этдим, эсингиздан чиқмасин. Олтинчи кун сизларга икки кунлик ризқ бераман. Еттинчи кун дам олинглар. Ун йиғаман деб ҳеч ким ташқарига чиқмасин», деб ваҳий юборди. Шу-шу, халқ еттинчи кун дам оладиган бўлди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> *** </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p >
                <sup>2</sup> Аҳд Сандиғи Оллоҳнинг ам­ри билан ясалган бўлиб, Қуръ­о­ни каримда «тобут» сў­зи билан зикр бўлган (Ба­қа­ра сураси, 248). Узунлиги икки ярим тирсак, эни билан бўйи бир ярим тир­сак бўлиб, ичу таши соф ол­тин билан қопланган. Сир­ти­да олтиндан тўртта ҳалқа бўлиб, тилла қопланган узун икки соп василасида кў­та­рил­ган. Сандиқ устида эса соф олтиндан ясалган қопқоқ бў­либ, қопқоқ устида эса ол­тин­дан иккита карубнинг ҳайкали ясалган. Оллоҳ азза ва жалл: «Менинг сакийнам ка­рублар орасига нузул қи­либ, ўша ерда сизлар учун амр­ла­рим­ни ваҳий қиламан», деб айтган эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(2)}>
              Яъқуб пайғамбарнинг фарзандлари ваъда қилинган юртга етиб боргунча ҳамиша ҳафтасига олти кун осмондан ун ёғиб турган экан. Кейинчалик – Оллоҳнинг буйруғи билан – келажак авлодлар кўриши ва эслаб юриши учун, Ҳорун алайҳис-салом бир идишга ўша ундан солиб, Аҳд сандиғи<sup>2</sup>
              ичига қўядилар. Бани Исроил ваъда қилинган юртга – Канъон ерига киргунча қирқ йил самовий нон еб кун кечирган эди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> СУВ УЧУН ЖАНЖАЛ </p>
          </div>

          <div className='main_text'>
            <p>
              Кейин Оллоҳ таолонинг амри билан, Исроил жамоаси Сийн саҳросини тарк этди, Рафи­димга келиб қароргоҳ қурди. Лекин Рафидимда ичимлик суви йўқ эди. Халқ: «Бизга ичишга сув топиб берасан!» деб расулуллоҳнинг олдларига келди.
            </p>
            <p>
              «Нима учун мени айблаяпсизлар? Нима учун Худога ўз ҳукмингизни ўтказмоқчи бўласиз?» деб у киши халқни йўлга солмоқчи бўлдилар. Лекин халқ қулоқ солишни истамас эди: «Нега бизни Мисрдан олиб чиқдинг? Энди бола-чақамиз билан, мол-ҳолимиз билан сувсиз ўлдирмоқчимисан?» деб жанжал қилди.
            </p>
            <p></p>
          </div>


          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1000] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1000)}
            >
              <p >
                <sup>1000</sup>  Аъроф сурасида: «Шоядки Раббингиз ... сизларни ер юзига халифа қилиб, қандай амал қилишингизга назар сол­са», деган оят бор (7:129).
              </p>
            </div>
            <p onClick={() => handleSupClick(1000)} className='vitalic'>
              Мусо (а.с.) нега уларни Мисрдан олиб чиққан эди? Аслида уларни Мусо эмас, Худо олиб чиққан эди! Худо нега уларни Миср­дан олиб чиққан эди? Ўлдириш учунми? Йўқ! Оллоҳ таоло уларни ер юзига халифа<sup>1000</sup> қилмоқчи эди! Сут ва асал ўлкасига олиб бормоқчи эди! Худо бир неча марта шундай деб ваъда берган! Бироқ одамлар сал қийинчилик топилса дарров исён қилишга ўтар, ўз ўжарликлари билан Оллоҳнинг сабрини синаётган эдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ҚОЯДАН СУВ ЧИҚҚАНИ </p>
          </div>

          <div className='main_text'>
            <p>
              Шунда расулуллоҳ: «Ё Раббим, мен бу халқни қандай йўлга соламан?» деб Оллоҳга фарёд қилдилар. Одамлар ҳақиқатан ҳам Мусо (а.с.) ни тошбўрон қилишга тайёр эди. Шунда Оллоҳдан Мусо пайғамбарга овоз келди: «Нил дарёсига урган асонгни қўлингга ол. Оқсоқоллардан бир нечтасини олиб, Сино тоғига бор. Мен ўша ерда сенга бир қояни кўрсатаман, асо билан урасан. Шунда қоядан сув чиқади», деди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}
            >
              <p >
                <sup>3</sup> Яъни, синов ва жанжал.
              </p>
            </div>
            <p onClick={() => handleSupClick(3)}>
              Мусо (а.с.) айтилганидек қилдилар. Асо қояга тегиши билан оқсоқолларнинг кўз олдида қоядан сув отилиб чиқа бошлади. Расулуллоҳ у жойни Масса ва Мариба деб атадилар.<sup>3</sup>  Чунки халқ у ерда “Оллоҳ ҳақиқатан биз билан биргами-йўқми?” деб Худони синаган эди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> Шуайб (а.с.) нинг келиши </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1000] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1000)}
            >
              <p >
                <sup>1000</sup>  Мусо (а.с.) бир қанча вақт аввал хотини Сафира билан икки ўғлини Мадянга, қайнотасининг уйига юборган эдилар.
              </p>
            </div>
            <p onClick={() => handleSupClick(1000)}>
              Шуайб (а.с.) — Мусо пайғамбарнинг қайнотаси — Бани Исроилнинг Мисрдан чиққанини эшитиб, қизи Сафира билан невараларини<sup>1000</sup>  олиб, куёвининг олдига жўнадилар. Бани Исроил бу вақтда Сино тоғи яқинидаги даштга келган эди.
            </p>
            <p>
              Мусо пайғамбар қайнотаси келаётганини эшитиб, истиқболига чиқдилар. Олдларида тиз чўкиб, қўлларидан ўпдилар. Ҳол-аҳвол сўрашганларидан сўнг, чодирга кирдилар. Кейин расулуллоҳ қайнотасига бўлиб ўтган воқеаларни, Мисрдан қандай чиққанларини, Бани Исроилнинг йўлда чеккан машаққатларини бирма-бир гапириб бердилар.
            </p>
            <p>
              Шуайб (а.с.) Оллоҳнинг уларга қилган яхшиликларини эшитиб қувондилар ва Оллоҳга ҳамду санолар айтиб дедилар: «Ҳа! У барча маъбудлардан буюк! У сизларни мутакаббир мисрликлар қўлидан халос қилди». Сўнгра Оллоҳ таолога атаб қатор қурбонликлар тақдим қилдилар.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> Қозиларнинг тайинланиши </p>
          </div>

          <div className='main_text'>
            <p>
              Эртасига Мусо пайғамбар одамларнинг даъволарини ҳал қилиш билан машғул бўлдилар. Халқ эрталабдан кечгача у кишининг атрофида тик турди. Қайнотаси буни кўриб: «Бу нима қилганинг? Нима учун бир ўзинг қозилик қиласан, шунча одам эртадан кечгача олдингда тик туради?» деб сўрадилар.
            </p>
            <p>
              Ҳазрати Мусо (а.с.) дедилар: «Халқ Оллоҳнинг иродасини излаб менинг олдимга келади. Бирон низо чиқса, мен уларга Оллоҳнинг ҳукм ва қонунларини тушунтириб иккала томон учун қарор қабул қиламан».
            </p>
            <p>
              Шунда Шуайб (а.с.) дедилар: «Сен бу йўл билан ҳам ўзингни қийнайсан, ҳам ёнингдаги халқни. Ёлғиз ўзинг бу ишни уддалай олмайсан. Оллоҳ сенга ёр бўлсин. Гапимга қулоқ сол, сенга бир насиҳат берай: сен Худонинг олдида халқ учун вакилсан, халқнинг саволларини Оллоҳга тақдим қиласан. Уларга ҳукм ва қонунларни тушунтирасан, тўғри йўлда яшашни ўргатасан. Лекин идора масаласига келсак, сен халқ ичидан Худодан қўрқадиган, истеъдодли, пора олишдан жирканадиган, лаёқатли ва ишончли одамлар танлаб, уларни мингликлар, юзликлар, элликликлар ва ўнликлар устидан бошлиқ қилиб тайинла. Халққа ҳамиша улар қозилик қилсин. Фақат энг муҳим масалаларни сенга олиб келсинлар. Кичик масалаларни ўзлари ҳал қилишсин. Шу зайлда сен учун иш осонлашади, юкинг енгиллайди, чарчамайсан. Одамлар ҳам уй-уйига кўнгиллари тўлиб қайтадилар. Оллоҳга ҳам шу маъқул».
            </p>
            <p>
              Мусо (а.с.) қайнотасининг сўзига қулоқ солдилар: халқ орасидан лаёқатли одамларни танлаб олиб, уларни мингбоши, юзбоши, элликбоши ва ўнбоши қилиб тайинладилар. Энди ҳар доим қозилик ишларига улар жавобгар эдилар. Кичик маъмулий масалаларни ўзлари ҳал қилиб, муҳим ва мураккаб масалаларни расулуллоҳнинг олдига келтирар эдилар. Шуайб (а.с.) бир неча кундан сўнг Мусо (а.с.) билан хайрлашиб, ўз юртига қайтиб кетдилар.
            </p>
            <p></p>
          </div>

          <BottomNav 
            labelLeft='Қурбонлик' 
            pathLeft='/public-resources/qisas-uzbek/death-of-firstborn'

            labelCenter='Саҳрода'
            pathCenter='/public-resources/qisas-uzbek/adventures-in-desert'

            labelRight='Шариат аҳди'
            pathRight='/public-resources/qisas-uzbek/shariah_revealed'
            />
         

        </main >
        <aside> </aside>
      </div >
    </div >
  )
}

export default DesertAdventures
