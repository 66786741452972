import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import kamar from '../../images/kamar.png';
import BottomNav from './components/BottomNav';

const DishonourInGibeah = () => {
    const navigate = useNavigate();
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>
                  Гивадаги разолат 
                  </p>
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>
            
          <div className='main_text'>
            <p> 
            Афройим тоғида Лобий қабиласидан бир одам яшар эди. У Байтлаҳмдан бир жорияни хотинликка олади. Кунлардан бир куни хотини аччиқланиб Байтлаҳмга – отасининг уйига кетиб қолди. Орадан тўрт ой ўтгач, эри уни олиб келиш учун икки эшакда хизматкори билан қайнотасиникига борди.
            </p>
            <p>
            Қайнотаси уни яхши кутиб олди. Уч кунгача яхшилаб меҳмон қилди. Тўртинчи куни эрталаб вақтли турдилар. Меҳмон кетишга ижозат сўради. Қайнотаси эса: «Йўқ, аввал овқат енглар, кейин кетасизлар», деди. Овқат еяётган пайтда куёвини яна бир кун қолишга кўндирди.
            </p>
            <p>
            Бешинчи куни куёв яна йўлга отланди. Қайнотаси эса: «Бирор нарса еб олинглар, салқинда кетарсизлар», деб яна уларни олиб қолди. 
            </p>
            <p>
            Кун салқинлаганда эса: «Энди кеч кириб қолди, бугун тунни шу ерда ўтказинглар, эрталаб вақтли йўлга тушарсизлар», деди. Лекин куёви қолишга унамай, хотинини олиб, икки эшагини етаклаб, йўлга тушди.
            </p>
            <p>
            Ябус шаҳрига яқинлашганларида кеч кириб қолган эди. Шунда хизматкори: «Ябусга кириб, кечани шу ерда ўтказа қолайлик», деди. «Йўқ, – деди хўжайини. – Бегона халқнинг ичида нима бор? Оғайнилар олдига борамиз».
            </p>
            <p>
            Қуёш ботаётганда ибронийлар яшайдиган Гива шаҳрига етиб келдилар. Шаҳарга кириб, анча вақтгача кўчада ўтиришди, ҳеч ким уларни уйга таклиф қилмаётган эди.
            </p>
            <p>
            Ниҳоят, даладан келаётган бир қария: «Кимсизлар, қаердан келяпсизлар? Қаерга кетяпсизлар?» деб сўраб қолди. 
            </p>
            <p>
            «Байтлаҳмдан келяпмиз, уйимиз Афройимда», деб жавоб берди лобий. Қарангки, бу қария ҳам теги афройимлик эди.
            </p>
            <p className='vitalic'>
            Гивада эса асосан Бинямин қабиласига тегишли одамлар яшар эди. Эсингизда бўлса керак, ҳазрати Яъқубнинг ўн икки ўғлидан ўн икки қабила вужудга келган бўлиб, улардан бири Бинямин қабиласи эди.
            </p>
            <p>
            «Нега кўчада ўтирибсизлар?» деб сўради қария. 
            </p>
            <p>
            «Ҳеч ким уйига таклиф қилмаяпти, – деб жавоб берди у. –  Озиқ-овқатимиз ўзимиз билан, эшакларга ем-сомонимиз ҳам бор». 
            </p>
            <p>
            «Ташвишланманглар, – деди қария. – Нима керак бўлса бераман. Фақат кўчада ўтирманглар», деб уларни уйига олиб борди.
            </p>
            <p>
            Дам олиб, овқат еб ўтирганларида шаҳардаги ярамас эркаклар келиб, эшикни тарақлата бошладилар. «Уйингга келган одамни буёққа чиқар, мазасини кўрайлик!» дер эди улар.
            </p>
            <p>
            Уй эгаси ташқари чиқиб: «Биродарлар, бундай қилманглар, у ахир менинг уйимда меҳмон, – деб ялинди. – Мана, менинг бокира қизим бор, унинг ҳам хотини ёнида, хоҳласангиз, мен уларни олдингизга чиқараман, нима қилсангиз қилинг, фақат бу одамга тегманглар», деди.
            </p>
            <p>
            Бироқ улар қулоқ соладиган эмас эдилар. Шунда меҳмон ўрнидан туриб, хотинини кўчага итариб чиқарди. Улар эрталабгача бу аёл билан билганларини қилдилар. Тонг отарда бечора хотин эри ётган уйнинг олдига зўрға келиб йиқилди­ю бошқа юролмади.
            </p>
            <p>
            Лобий эрталаб туриб, кетаман, деб эшикни очса, хотини ташқарида ётибди. Қўллари бўсаға устида. 
            </p>
            <p>
            «Тур, кетамиз», деди хотинига. Лекин ундан садо чиқмади. Заифа жонини таслим қилиб бўлган эди. 
            </p>
            <p>
            Шу билан у хотинини эшакка ортиб, уйига жўнади. Уйга келиб, жасадни пичоқ билан ўн икки га бўлди­да Исроилнинг ўн икки қабиласига жўнатди. Кўрган­-эшитганларнинг ҳаммаси ҳайратдан ёқасини ушлаб: «Мисрдан чиққанимизга неча йил бўлибди, ҳеч бизнинг халқда бундай иш бўлган эмас эди!» дер эди.
            </p>
         
            <p></p>
          </div>

          <BottomNav 
            labelLeft='Ёффайнинг назри' 
            pathLeft='/public-resources/qisas-uzbek/jephthahSacrifice'

            labelCenter='Гивадаги разолат'
            pathCenter='/public-resources/qisas-uzbek/dishonourInGibeah'

            labelRight='Халқ кенгаши'
            pathRight='/public-resources/qisas-uzbek/mizpahNationalCouncil'
          />
      
        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default DishonourInGibeah;