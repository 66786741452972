import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './qisasNavigation.css';

import Adam from '../../images/adam.svg';
import Noah from '../../images/noah.svg';
import Ibrohim from '../../images/Ibrahim.svg';
import Ishaq from '../../images/ishaq.svg';
import Ismoil from '../../images/Ismoil.jpeg';
import Yusuf from '../../images/Yusuf.svg';
import Jacob from '../../images/Jacob.svg';
import Musa from '../../images/Moses.svg';
import Yashua from '../../images/yusha.svg';
import ShariaRevealed from '../../images/ShariaAhd.svg';
import Judges from '../../images/Judges.svg';
import Samuel from '../../images/Samuel.svg';
import Tolut from '../../images/Tolut.svg';
import David from '../../images/David.svg';
import Sulayman from '../../images/sulayman.svg';
import Yunus from '../../images/yunus.svg';
import ZakariyaWaYahya from '../../images/zakaria&yahya.svg';
import Maryam from '../../images/Maryam.svg';
 
import useAuth from '../../hooks/useAuth';
import navigateToResources from '../../config/navigateToResources';

const genesisData = [
  {
    id: 1,
    chapterName: '1-боб: Одам алайҳис-салом қиссаси: жаннатдаги ҳаёт',
    path: '/public-resources/qisas-uzbek/adam',
    image: Adam,
    masteredPercentage: 18,
    subChapters: [
      {
        id: 11,
        subItemText: '2-боб: Дунёнинг яратилиши ҳақида баён',
        path: '/public-resources/qisas-uzbek/creation-of-world',
      },
      {
        id: 12,
        subItemText: '3-боб: Одам (а.с.) ва Момо Ҳавонинг жаннатдан ҳайдалиши',
        path: '/public-resources/qisas-uzbek/the-fall',
      },
      {
        id: 13, subItemText: '4-боб: Қобил билан Ҳобил ҳикояси',
        path: '/public-resources/qisas-uzbek/kain-abel',
      },
    ],
  },
  {
    id: 2,
    chapterName: '5-боб: Нуҳ (а.с.) қиссаси: Ер юзини тўфон босиши',
    path: '/public-resources/qisas-uzbek/noah',
    image: Noah,
    masteredPercentage: 25,
    subChapters: [
      {
        id: 21,
        subItemText: '6-боб: Нуҳ алайҳис-салом билан боғланган Аҳд',
        path: '/public-resources/qisas-uzbek/noah-covenant',
      },

    ],
  },
  {
    id: 3,
    chapterName: '7-боб: Иброҳим халилуллоҳ қиссаси',
    path: '/public-resources/qisas-uzbek/abrahamic-story',
    image: Ibrohim,
    masteredPercentage: 0,
    subChapters: [
      {
        id: 31,
        subItemText: '8-боб: Барака ҳақидаги ваъда',
        path: '/public-resources/qisas-uzbek/abrahamic-covenant',

      },
      {
        id: 32,
        subItemText: '9-боб: Аҳднинг тасдиқланиши',
        path: '/public-resources/qisas-uzbek/covenant_confirmation',
      },
      {
        id: 33,
        subItemText: '10-боб: Ҳожар Бибининг ҳикояси, Исмоил (а.с.) нинг таваллуди',
        path: '/public-resources/qisas-uzbek/mother-agar',
      },
      {
        id: 34,
        subItemText: '11-боб: Иброҳим (а.с.) Аҳдининг аломати',
        path: '/public-resources/qisas-uzbek/covenant-sign',
      },
      {
        id: 35,
        subItemText: '12-боб: Садўм ва Ғамўра ҳикояси',
        path: '/public-resources/qisas-uzbek/sadom-gamorra',
      },
    ],
  },
  {
    id: 4,
    chapterName: '13-боб: Исмоил алайҳис-салом қиссаси',
    path: '/public-resources/qisas-uzbek/ishmael',

    image: Ismoil,
    masteredPercentage: 0,
    subChapters: [
      {
        id: 41,
        subItemText: '14-боб: Аҳднинг яна бир бор синалиши',
        path: '/public-resources/qisas-uzbek/zabihullah',
      },
    ],
  },
  {
    id: 5,
    chapterName: '15-боб: Исҳоқ алайҳис-салом қиссаси',
    path: '/public-resources/qisas-uzbek/isaak',
    image: Ishaq,
    masteredPercentage: 0,
    subChapters: [
      {
        id: 51,
        subItemText: '16-боб: Эгизаклар туғилиши',
        path: '/public-resources/qisas-uzbek/isaaks-twins',
      },
      {
        id: 52,
        subItemText: '17-боб: Яъқуб (а.с.) нинг отасидан барака олгани',
        path: '/public-resources/qisas-uzbek/jacob-steals-blessing',
      },
    ],
  },

  {
    id: 6,
    chapterName: '18-боб: Яқуб алайҳис-салом қиссаси',
    path: '/public-resources/qisas-uzbek/jacob',
    image: Jacob,
    masteredPercentage: 0,
    subChapters: [
      {
        id: 61,
        subItemText: '19-боб: Яқуб алайҳис-саломнинг уйланиш тарихи',
        path: '/public-resources/qisas-uzbek/marrying-two-daughters',
      },
      {
        id: 62,
        subItemText: '20-боб: Яъқуб (а.с.) нинг Канъонга қайтиши',
        path: '/public-resources/qisas-uzbek/return-to-kanaan',
      },
      {
        id: 63,
        subItemText: '21-боб: Фаришта билан олишув',
        path: '/public-resources/qisas-uzbek/fight-with-angel',
      },
    ],
  },
  {
    id: 7,
    chapterName: '22-боб: Юсуф алайҳис-салом қиссаси',
    path: '/public-resources/qisas-uzbek/joseph',
    image: Yusuf,
    masteredPercentage: 0,
    subChapters: [
      {
        id: 71,
        subItemText: '23-боб: Тушлар боби',
        path: '/public-resources/qisas-uzbek/dreams-chapter',
      },
      {
        id: 72,
        subItemText: '24-боб: Акалари билан учрашув',
        path: '/public-resources/qisas-uzbek/meeting-with-brothers',
      },
      {
        id: 73,
        subItemText: '25-боб: Фиръавннинг таклифи',
        path: '/public-resources/qisas-uzbek/pharaoh-invites-jacobs-family',
      },
      {
        id: 74,
        subItemText: '26-боб: Яъқуб (а.с.) нинг башорати',
        path: '/public-resources/qisas-uzbek/prophecy-of-jacob',
      },
    ],
  },
  {
    id: 8,
    chapterName: '27-боб: Мусо пайғамбар қиссаси',
    path: '/public-resources/qisas-uzbek/musa',

    image: Musa,
    masteredPercentage: 0,
    subChapters: [
      {
        id: 81,
        subItemText: '28-боб: «Мен кимман?»',
        path: '/public-resources/qisas-uzbek/who-am-I',
      },
      {
        id: 82,
        subItemText: '29-боб: Мусо (а.с.) га берилган учта мўъжиза',
        path: '/public-resources/qisas-uzbek/three-miracles-of-Moses',
      },
      {
        id: 83,
        subItemText: '30-боб: Миср устига ёғилган офатлар',
        path: '/public-resources/qisas-uzbek/Egypt-plagues',
      },
      {
        id: 84,
        subItemText: '31-боб: Қурбонлик байрами, Мисрни тарк этиш',
        path: '/public-resources/qisas-uzbek/death-of-firstborn',
      },
      {
        id: 85,
        subItemText: '32-боб: Бани Исроилнинг саҳродаги саргузашти',
        path: '/public-resources/qisas-uzbek/adventures-in-desert',
      },
    ],
  },

  {
    id: 9,
    chapterName: '33-боб: Шариат Аҳдининг нозил бўлиши',
    path: '/public-resources/qisas-uzbek/shariah_revealed',
    image: ShariaRevealed,
    masteredPercentage: 0,
    subChapters: [
      {
        id: 91,
        subItemText: '34-боб: Шариат ҳукмларидан намуналар',
        path: '/public-resources/qisas-uzbek/examples-of-shariah',
      },
      {
        id: 92,
        subItemText: '35-боб: Бани Исроил шариат Аҳдини қабуллайди',
        path: '/public-resources/qisas-uzbek/bani-israel-accepted-shariah',
      },
      {
        id: 93,
        subItemText: '36-боб: Чодирда нозил бўлган амрлар',
        path: '/public-resources/qisas-uzbek/more-shariah-laws',
      },
      {
        id: 94,
        subItemText: '37-боб: Бани Исроилнинг 40 йил саҳро кезгани',
        path: '/public-resources/qisas-uzbek/fourty-years-in-the-desert',
      },
      {
        id: 95,
        subItemText: '38-боб: Мусо (а.с.) нинг васияти',
        path: '/public-resources/qisas-uzbek/wasiya-of-Moses',
      },
    ],
  },
];

const exodusData = [
  {
    id: 100,
    chapterName: '1-боб: Юъшо алайҳис-салом қиссаси',
    path: '/public-resources/qisas-uzbek/joshua',
    image: Yashua,
    masteredPercentage: 18,
    subChapters: [
      {
        id: 1001,
        subItemText: '2-боб: Ваъда қилинган юртга кириш',
        path: '/public-resources/qisas-uzbek/enterPromisedLand',
      },
      {
        id: 1002,
        subItemText: '3-боб: Кимга хизмат қиласиз, танланг',
        path: '/public-resources/qisas-uzbek/chooseWhoToServe',
      },
    ],
  },
  {
    id: 101,
    chapterName: '4-боб: Ҳакамлар даври',
    path: '/public-resources/qisas-uzbek/deborah',
    image: Judges,
    masteredPercentage: 18,
    subChapters: [
      {
        id: 1011,
        subItemText: '5-боб: Ёффай розияллоҳу-анҳу қиссаси',
        path: '/public-resources/qisas-uzbek/jephthah',
      },
      {
        id: 1012,
        subItemText: '6-боб: Ёффай (р.а.) нинг назри',
        path: '/public-resources/qisas-uzbek/jephthahSacrifice',
      },
      {
        id: 1013,
        subItemText: '7-боб: Гивадаги разолат ',
        path: '/public-resources/qisas-uzbek/dishonourInGibeah',
      },
      {
        id: 1013,
        subItemText: '8-боб: Мисфада халқ кенгаши',
        path: '/public-resources/qisas-uzbek/mizpahNationalCouncil',
      },
    ],
  },
  {
    id: 102,
    chapterName: '9-боб: Сомуил (р.а.) қиссаси',
    path: '/public-resources/qisas-uzbek/samuel',
    image: Samuel,
    masteredPercentage: 18,
    subChapters: [
      {
        id: 1021,
        subItemText: '10-боб: Аҳд сандиғи душманлар қўлида',
        path: '/public-resources/qisas-uzbek/covenantBoxLost',
      },
      {
        id: 1022,
        subItemText: '11-боб: Аҳд сандиғининг Исроилга қайтиши',
        path: '/public-resources/qisas-uzbek/covenantBoxReturned',
      },
    ],
  },
  {
    id: 103,
    chapterName: '12-боб: Хонлик даврининг бошланиши',
    path: '/public-resources/qisas-uzbek/monarchyStarted',
    image: Tolut,
    masteredPercentage: 18,
    subChapters: [

      {
        id: 1031,
        subItemText: '13-боб: Толут ҳам пайғамбарми?',
        path: '/public-resources/qisas-uzbek/isTolutAlsoAmong',
      },

      {
        id: 1032,
        subItemText: '14-боб: Подшоҳлик шартлари',
        path: '/public-resources/qisas-uzbek/monarchyConditions',
      },

      {
        id: 1033,
        subItemText: '15-боб: Янатоннинг таваккули',
        path: '/public-resources/qisas-uzbek/janatonsTavakkul',
      },
      {
        id: 1034,
        subItemText: '16-боб: Толутнинг ўзбошимчалиги',
        path: '/public-resources/qisas-uzbek/tolutIsRejected',
      },     
    ],
  },
  {
    id: 104,
    chapterName: '17-боб: Довуд (а.с.) қиссаси',
    path: '/public-resources/qisas-uzbek/david',
    image: David,
    masteredPercentage: 18,
    subChapters: [
      {
        id: 1041,
        subItemText: '18-боб: Жолутнинг ўлими',
        path: '/public-resources/qisas-uzbek/jolutIsDead',
      },
      {
        id: 1042,
        subItemText: '19-боб: Толутнинг ҳасади',
        path: '/public-resources/qisas-uzbek/tolutsJealousy',
      },
      {
        id: 1043,
        subItemText: '20-боб: Довуд (а.с.) қочқинликда',
        path: '/public-resources/qisas-uzbek/davidOnRun',
      },
      {
        id: 1044,
        subItemText: '21-боб: Сомуил (а.с.) нинг вафоти',
        path: '/public-resources/qisas-uzbek/somuelsDeath',
      },
      {
        id: 1045,
        subItemText: '22-боб: Толутнинг ўлими',
        path: '/public-resources/qisas-uzbek/tolutsDeath',
      },
      {
        id: 1045,
        subItemText: '23-боб: Довуд (а.с.) нинг тахтга ўтириши',
        path: '/public-resources/qisas-uzbek/davidOnThrone',
      },
    ],
  },
  {
    id: 105,
    chapterName: '24-боб: Сулаймон (а.с.) қиссаси',
    path: '/public-resources/qisas-uzbek/sulayman',
    image: Sulayman,
    masteredPercentage: 18,
    subChapters: [
      {
        id: 1051,
        subItemText: '25-боб: Сулаймон (а.с.) нинг ҳикмат сўрагани',
        path: '/public-resources/qisas-uzbek/sulaymanAsksWisdom',
      },
      {
        id: 1052,
        subItemText: '26-боб: Ал-Ақсонинг қурилиши',
        path: '/public-resources/qisas-uzbek/aqsa',
      },
    ],
  },
  {
    id: 106,
    chapterName: '27-боб: Юнус (а.с.) қиссаси',
    path: '/public-resources/qisas-uzbek/yunus',
    image: Yunus,
    masteredPercentage: 18,
    subChapters: [
      {
        id: 1061,
        subItemText: '28-боб: Балиқ ичида',
        path: '/public-resources/qisas-uzbek/insideFish',
      },
      {
        id: 1062,
        subItemText: '29-боб: Юнус (а.с.) нинг ғазабланиши',
        path: '/public-resources/qisas-uzbek/insideAnger',
      },
      // {
      //   id: 1052,
      //   subItemText: '26-боб: Ал-Ақсонинг қурилиши',
      //   path: '/public-resources/qisas-uzbek/aqsa',
      // },
      // {
      //   id: 100000,
      //   subItemText: ' XXX-боб: Яқинда қўшилади',
      //   path: '/public-resources/qisas-uzbek/tobe_continued',
      // },
    ],
  },
];

const mujdaData = [
  {
    id: 200,
    chapterName: '1-боб: Закариё ва Яҳё (а.с.) қиссаси',
    path: '/public-resources/qisas-uzbek/zakariya',
    image: ZakariyaWaYahya,
    masteredPercentage: 18,
    subChapters: [
      // {
      //   id: 2001,
      //   subItemText: '2-боб: Ваъда қилинган юртга кириш',
      //   path: '/public-resources/qisas-uzbek/enterPromisedLand',
      // },
      // {
      //   id: 2002,
      //   subItemText: '3-боб: Кимга хизмат қиласиз, танланг',
      //   path: '/public-resources/qisas-uzbek/chooseWhoToServe',
      // },
    ],
  }, 
  {
    id: 201,
    chapterName: '3-боб: Марям (а.с.) қиссаси',
    path: '/public-resources/qisas-uzbek/maryam',
    image: Maryam,
    masteredPercentage: 18,
    subChapters: [
      {
        id: 2001,
        subItemText: '4-боб: Исо (а.с.) нинг туғилиши',
        path: '/public-resources/qisas-uzbek/jesusBirth',
      },
      // {
      //   id: 2002,
      //   subItemText: '3-боб: Кимга хизмат қиласиз, танланг',
      //   path: '/public-resources/qisas-uzbek/chooseWhoToServe',
      // },
    ],
  }, 
];

const QisasNavigation = () => {
  const navigate = useNavigate();
  const { auth } = useAuth()

const [selectedData, setSelectedData] = useState(() => {
  try {
    const savedData = localStorage.getItem('selectedData');
    const selectedCollection = localStorage.getItem('selectedCollection');

    // Check the value of selectedCollection and return the appropriate data
    return savedData && JSON.parse(savedData)
      ? JSON.parse(savedData)
      : selectedCollection === 'exodusData'
        ? exodusData
        : selectedCollection === 'mujdaData'
          ? mujdaData
          : genesisData;
  } catch {
    return genesisData;
  }
});

const [selectedCollection, setSelectedCollection] = useState(
  localStorage.getItem('selectedCollection') || 'genesisData'
);


  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    // Store selected data as JSON and the selected collection as a string
    localStorage.setItem('selectedData', JSON.stringify(selectedData));
    localStorage.setItem('selectedCollection', selectedCollection);
  }, [selectedData, selectedCollection]);

  const toggleVisibility = (chapterId) => {
    setVisibleItems((prevVisibleItems) =>
      prevVisibleItems.includes(chapterId)
        ? prevVisibleItems.filter((id) => id !== chapterId)
        : [...prevVisibleItems, chapterId]
    );
  };

  const toggleAllVisibility = () => {
    setVisibleItems((prevVisibleItems) =>
      prevVisibleItems.length === selectedData.length
        ? [] // Collapse all
        : selectedData.map((chapter) => chapter.id) // Expand all
    );
  };

  const handleDataSelection = (data, collectionName) => {
    setSelectedData(data);
    setSelectedCollection(collectionName);
    setVisibleItems([]);
  };


  return (
    <div className="minister-home resources home_qisas">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className="collections_of_books">
            <div className="books_container">
              <button
                className={`genesis ${selectedCollection === 'genesisData' ? 'selected_collection' : ''}`}
                onClick={() => handleDataSelection(genesisData, 'genesisData')}
              >
                Шариа
              </button>
              <button
                className={`exodus ${selectedCollection === 'exodusData' ? 'selected_collection' : ''}`}
                onClick={() => handleDataSelection(exodusData, 'exodusData')}
              >
                Заминда
              </button>
              <button
                className={`exodus ${selectedCollection === 'mujdaData' ? 'selected_collection' : ''}`}
                onClick={() => handleDataSelection(mujdaData, 'mujdaData')}
              >
                Мужда
              </button>
            </div>
  
            <button onClick={toggleAllVisibility}>
            {visibleItems.length === selectedData.length ? "Камроқ" : "Кўпроқ"}
            </button>

          </div>

          <div className="course_item_contain">
            {selectedData.map((chapter) => (
              <div className="course_item" key={chapter.id}>
                <div className="chapter" onClick={() => navigateToResources(chapter.path, navigate, auth?.accessToken)}>
                  <div className="chapter_image_name">
                    <div className="round_div">
                      <img src={chapter.image} alt="" />
                    </div>
                    <div>
                      <p>{chapter.chapterName}</p>
                      <p className="mastered">{`${chapter.masteredPercentage}% mastered`}</p>
                    </div>
                  </div>
                  <button
                    className="plus_button"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleVisibility(chapter.id);
                    }}
                  >
                    <p> &#8226; &#8226; &#8226; </p>
                  </button>
                </div>
                {(visibleItems.includes(chapter.id) || visibleItems.length === selectedData.length) && (
                  <div className="sub_item">
                    {chapter.subChapters.map((subChapter) => (
                      <div
                        className="sub_chapter"
                        key={subChapter.id}
                        onClick={() => navigateToResources(subChapter.path, navigate, auth?.accessToken)}
                      >
                        <div className="sub_item_text">
                          <p>{subChapter.subItemText}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </main>

        <aside>
          <div className='right_wing_stuff'>
            <h2>Эътибор беринг:</h2>
            <div>
              <p> Бобларнинг ҳаммасини кўриш учун "Кўпроқ" деб аталган тугмани босинг.</p>
            </div>
          </div>
          {/* <div className="bigger_image">
            <img src={big_images} alt="" />
          </div> */}
        </aside>
      </div>
    </div>
  );
};

export default QisasNavigation;
