import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const CovenantBoxReturned = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>Аҳд сандиғининг қайтиши  </p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

             
        

          <div className='paragraph_heading'>
            <p> КОҲИНЛАР БЕРГАН МАСЛАҲАТ </p>  
          </div>
            
          <div className='main_text'>
            <p> 
            Шу билан филистий пешволари ўзларининг коҳин ва бахшиларини чақириб: «Исроилликларнинг сандиғини нима қилсак бўлади? Уни қандай қилиб жойига қайтариш мумкин?» деб сўрадилар.
            </p>
            <p>
            «Агар Исроил худосининг сандиғини жойи­га қайтармоқчи бўлсангиз, ҳаргиз қуруқ қайтарманг, – деб жавоб беришди улар. – Гу­но­ҳингизни ювиш учун худосига атаб назр беринг. Шунда дардингизга шифо топиб, бошингизга тушган кулфат Оллоҳдан келганини биласизлар».
            </p>
            <p>
            «Тавбамиз қабул бўлиши учун нимани назр қилишимиз керак?» деб сўради пешволар.
            </p>
            <p>
            «Филистий ҳокимларининг сонига қараб бешта тилла шиш ва бешта тилла сичқон назр қилинглар. Сабаби, ҳаммангизнинг устингизга бир хил офат тушган, – деб жавоб беришди улар. – Оллоҳнинг улуғ­лиги­ни тан олинглар. Танангиздаги шишларнинг, ғаллангизни еяётган сичқонларнинг шак­лини ясаб, Бани Исроилнинг худосига назр қилинглар. 
            </p>
            <p>
            Шунда У балки бизни ва юртимиздаги худоларни жазолашдан тўхтар. Фиръавнга ва мисрликларга ўхшаб ўжарлик қилманглар. Улар ҳам кейин барибир, Оллоҳнинг қудратига дош беролмай, Бани Исроилни қўйиб юборган эди!
            </p>
            <p>
              Битта янги аравага бўйинтуруқ кўрмаган, энди­гина болалаган икки сигир қўшасиз. Бу­зоқ­ларини ажратиб қамаб қўйинг. Сандиқ­ни аравага ортиб, назр қилинган тилла буюмларни бир қутига солиб, сандиқнинг ёнига қўйинглар. Кейин сигирларни қўйиб юборинг. 
            </p>
          </div>

           <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p> <sup>1</sup>  Бани Исроилга қарашли ҳудуд.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
               Агар улар Байтул-Шамсга<sup>1</sup>  қараб юрса, демак бошимизга тушган бу кулфат­лар Оллоҳдан. Юрмаса, биламизки, бу дард­лар Оллоҳдан эмас, тасодифан бўлган».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> АҲД САНДИҒИНИНГ ИСРОИЛГА ҚАЙТИШИ </p>  
          </div>
            
          <div className='main_text'>
            <p> 
            Шундан сўнг, улар иккита янги туққан сигирни бузоқларидан ажратиб аравага қўшдилар. Аҳд сандиғини, тилла сич­қонлар ва тилла шишлар солинган қути билан бирга аравага қўйдилар. Кейин сигирларни қўйиб юборишди. Арқон ечилиши билан, улар на ўнгга ва на чапга қайрилмай, мўраганча тўғри Байтул-Шамс­га қараб равона бўлдилар.
            </p>
            <p>
            Филистий беклари си­гирларни то Байтул-Шамс чега­раси­гача кузатиб боришди.
            </p>
            <p>
            Ўша пайтда Байтул-Шамс аҳолиси водийда буғдой ўраётган эди. Худонинг сандиғи қайтиб келганини кўриб, роса хурсанд бўлдилар. Арава тўғри Ёшуа деган одамнинг даласига, катта тош олдига келиб тўхтади. 
            </p>
            <p>
            Руҳонийлар Аҳд сандиғи билан тилла буюмлар солинган қутини олиб, катта тошнинг устига қўйдилар. Кейин аравани бузиб, ўтин қилиб, сигирларни Оллоҳ учун қурбон қилиб куйдирдилар.
            </p>
            <p>
            (Кейинроқ Ҳират-ёрмдан одамлар ке­либ, Аҳд сандиғини олиб кетишади ва Абу­на­даб­нинг тепаликда жойлашган уйига қў­йи­шади. Сандиққа қараб туриш учун Абу­на­даб­нинг ўғли Алъазарни масъул этиб тайин­ла­йдилар.)
            </p>
            <p>
            Сандиқ йигирма йил Ҳират-ёрмда турди. Бу вақт ичида Бани Исроил филистийлардан кўп зулм кўрди ва ёрдам сўраб Оллоҳга нолалар қилди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> СОМУИЛ (а.с.) РАҲБАРЛИГИДА </p>  
          </div>
            
          <div className='main_text'>
            <p> 
            Бу пайтга келиб, халқни Сомуил пайғамбар бошқараётган эдилар. У киши Бани Исроилни бир жойга тўплаб дедилар: «Агар ҳақи­қа­тан Оллоҳга қайтмоқчи бўлсангиз ажна­бий бут­ларни, Асториш каби санамларни оран­гиз­дан йўқ қилинг! Қалбингизни Оллоҳ­га қаратинг, Унгагина ибодат қилинг! Шунда У сизларни филистийлар зулмидан озод қилади!» деб даъват этдилар.
            </p>
            <p>
            Шундан сўнг Исроил халқи Баал ва Асториш бутларини йўқотиб, ёлғиз Оллоҳнинг Ўзига сиғина бошлади. Сўнгра ҳазрати Сомуил халқни Мисфага тўпладилар. Ҳамма рўза тутиб, тавба қилиб, Оллоҳ таолонинг олдида қилган гуноҳларини эътироф этди.
            </p>
            <p>
            Исроилнинг Мисфада тўпланганини эшитиб, филистийлар улар устига лашкар юборди. Филистийлар келаётганини эшитиб, халқ қаттиқ қўрқди ва Сомуил пайғам­барга: «Оллоҳдан сўранг, бизни ҳимояси­га олиб, душмандан халос қилсин! Дуо қилишдан тўхтаманг!» деб ялиндилар.
            </p>
            <p>
            Ҳазрати Сомуил эмадиган бир қўзичоқни оловда куйдириб, Оллоҳга қурбон қил­дилар. Парвардигорга нола қилиб, Бани Исроилга нажот тиладилар.
            </p>
            <p>
            Оллоҳ таоло у кишининг дуосини ижобат қилди. 
            </p>
            <p>
            Филистийлар бу пайт Мисфага етиб келиб, ҳужумга ўтган эдилар. Худои таоло улар устига кучли момоқалдироқ гумбирлатди. Филистийлар даҳшатга тушиб қочдилар. 
            </p>
            <p>
            Бани Исроил уларни Мисфадан то Байткоргача таъқиб қилиб борди. Душманлар жуда қаттиқ талафот кўрдилар. Ўшанда Сомуил бир тошни Мисфа билан Шон чегарасига қўядилар ва: «Мана шу ергача Оллоҳ бизга мадад берди», деб унга Абинзор деб от қўядилар. Филистийлар кейин Исроил ҳудудига тажовуз қилолмади.
            </p>
            <p>
            То Сомуил пайғамбар оламдан ўтгунча Худо Бани Исроилни улардан сақлади. Эҳрондан тортиб Жатгача бўл­ган ҳамма шаҳарлар Сомуил пайғам­бар даврида филис­тий­лардан озод этилган эди. Амўрий­лар билан ҳам ўша даврда тинч­лик келишимига эришган эдилар.
            </p>
            <p>
            Сомуил пайғамбар бир умр Бани Исроилга ҳакамлик қилдилар. Ҳар йили Байтил, Гилгал ва Мисфа шаҳарларини айланар, исро­ил­ликларнинг даъволарини ажрим қилар­дилар. Қолган пайтда Рамадаги уйи­дан Бани Исроилга ҳакамлик қилганлар. Рамадаги қурбонгоҳни ҳам Сомуил (а.с.) қурдирган.
            </p>
          </div>

          <BottomNav 
            labelLeft='Сандиқни йўқотиш' 
            pathLeft='/public-resources/qisas-uzbek/covenantBoxLost'

            labelCenter='Аҳд сандиғи қайтди'
            pathCenter='/public-resources/qisas-uzbek/covenantBoxReturned'

            labelRight='Хонлик даври'
            pathRight='/public-resources/qisas-uzbek/monarchyStarted'
          />

          
        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default CovenantBoxReturned;