import React from 'react';
import { useNavigate } from 'react-router-dom';
import navigateToResources from '../../../config/navigateToResources';
import useAuth from '../../../hooks/useAuth';

const BottomNav = ({
  pathLeft,
  labelLeft,
  labelCenter,
  pathCenter,
  labelRight,
  pathRight
}) => {
  const navigate = useNavigate();
  const { auth } = useAuth();

  return (
    <article className='py-4 flex justify-between my-2 gap-2'>
      <button
        className='ml-0 bg-gray-600 hover:bg-gray-800 flex gap-1 text-white p-3'
        onClick={() => navigateToResources(pathLeft, navigate, auth?.accessToken)}>
        <p className='text-xs'>&#8592;</p>
        <p className='text-xs hidden sm:block truncate'>{labelLeft}</p>
      </button>
      <button
        className='w-full mx-2 p-3  bg-gray-600  hover:bg-gray-800 flex'
        onClick={() => navigateToResources(pathCenter, navigate, auth?.accessToken)}>
        <p className='text-xs text-white text-center w-full'>{labelCenter}</p>
      </button>
      <button
        className='bg-gray-600  hover:bg-gray-800 flex gap-1 text-white p-3 w-fit'
        onClick={() => navigateToResources(pathRight, navigate, auth?.accessToken)}>
        <span className='text-xs truncate hidden sm:block'>{labelRight}</span>
        <span className='text-xs'>&#8594;</span>
      </button>
    </article>
  );
}

export default BottomNav;
