import React, { useEffect, useState } from 'react'
import Resources from '../feature/resources/Resources'
import useAuth from '../hooks/useAuth'
import h2_icon from './../images/open_book.png';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from "../components/LanguageSwitcher";
import Search from '../components/Search';

const PublicResources = () => {
  const { t } = useTranslation();
  const { setAuth, setPersist } = useAuth();

  useEffect(() => {
    setAuth({});
    setPersist(false);
  }, []);

  const [search, setSearch] = useState('')

  return (
    <div className='minister-home servants_or_resources_board resources'>
      <div className='topbar'>
        <div className='middle_part1'>
          <div className='icon_and_h2'>
            <div
              className="h2_icon_container">
              <img src={h2_icon} alt="" />
            </div>
            <h1>
              {t('Resources.MainHeading')}
            </h1>
          </div>


          <div className='network_icons_topbar'>
            <LanguageSwitcher />
          </div>
        </div>

        <div className="middle_part2">

          <div className="topbar_btn_container">


            <Search setSearch={setSearch} />
          </div>
        </div>

      </div>
      <Resources />
    </div>
  )
}

export default PublicResources