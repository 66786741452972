import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const JesusBirth = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 14;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>
                  Исо алайҳис-саломнинг туғилиши  
                  </p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

                <div className='paragraph_heading'>
                    <p> Юсуфга ваҳий келиши
                    </p>  
                </div>
             

            
          {/* <div className='main_text'>
            <p> 
              ХХХХХ
            </p>
         
            <p></p>
          </div> */}

        
<div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p>
                <sup>1</sup>  Юсуф (а.с.) – Довуд пай­ғам­бар авлодидан эди.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)}>
                Марям (а.с.) Юсуф<sup>1</sup>  деган бир йигитга унаш­ти­рилган эдилар. Юсуф яхши одам эди. Шунинг учун Марямнинг иккиқат экан­ини билгач, уни шарманда қилгиси кел­май, халқ ол­ди­да айбламасдан никоҳни бекор қил­моқ­чи бўлдилар.
            </p>
            <p></p>
</div>



<div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p>
                <sup>2</sup>  Исо (а.с.) нинг исми арамий тилида Еҳашуа бўлиб, араб тилида бир неча шаклда мав­жуд (Ёшуа, Ёсуа, Юъшо ҳамда Исо, Қуръони ка­рим­да “Ъийсаа”). Еҳа­шуа деган сўз “Нажот Эга­миз­дан” де­ган маънони анг­ла­та­ди.
              </p>
            </div>
            <p onClick={() => handleSupClick(2)}>
            Ўша куни кечаси Оллоҳнинг фариштаси унинг тушига ки­риб: «Эй Юсуф, Марямни уйингга олиб ке­лишдан қўрқма. Унинг қорнидаги чақалоқ Оллоҳнинг қудрати би­лан бўлди! У ўғил туғади, исмини Исо<sup>2</sup> қўясан, чунки у мў­мин­ларни гуноҳларидан қутқаради», деди.  
            </p>
            <p>
            Шу тариқа Ишаъё пай­ғам­бар берган мана бу башорат амалга ошди: 
            </p>
            <p></p>
</div>


<div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}
            >
              <p>
                <sup>3</sup>  «Марям Имрон қизи... фаржини пок сақлаган эди» (Таҳрим сураси 66:12).
              </p>
            </div>
            <p onClick={() => handleSupClick(3)}>
              «Бокира<sup>3</sup>   қиз ҳа­м­ладор бўлиб ўғил туғади ва одамлар, Оллоҳ биз билан, деб айтадилар».

            </p>
            <p>
            Юсуф (а.с.) уйқудан тургач, берилган ва­­ҳий­­га итоат этиб, Биби Марямга уй­лан­ди­лар, лекин то кўз ёри­ма­гунча яқин­лик қил­ма­дилар.
            </p>
</div>

<div className='paragraph_heading'>
                    <p> Ашба бибининг уйида
                    </p>  
                </div>
                

<div className='main_text'>
  <p>
  Кейин ҳазрати Марям холасининг, яъни За­кариё (а.с.)  нинг уйига бо­р­­ди­­лар. Ашба Биби ўшанда Яҳё (а.с.) га ҳамла­дор эди. Марям (а.с.) ни қаршилар экан:
  </p>
    <div className='speech-bubble'
      style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
      onClick={() => handleSupClick(4)}
    >
      <p>
        <sup>4</sup>  Табарийнинг ёзишича Ашба Биби: «Эй Марям, менинг қор­ним­дагининг сe­нинг қор­нинг­да­гига таъзим эт­га­ни­ни ҳис эт­­дим», деб айтган эканлар (“Тарих”, 2-жилд, 22-бет).
      </p>
    </div>
    <p onClick={() => handleSupClick(4)}>
      «Му­бо­рак­сан-муборак, қорнингдаги фар­занд ҳам му­бо­рак! Не бахтки Мав­ло­но­нинг она­си мени кўришга ташриф бую­риб­ди! Са­ло­минг­ни эшитиши билан қор­ним­да­ги бола шодликдан ҳаракатга тушди!» деган экан.<sup>4</sup>  
    </p>
</div>

<div className='paragraph_heading'>
                    <p> Исо (а.с.) нинг туғилиши
                    </p>  
                </div>

<div className='main_text'>
            <p className='boldItalic'>
            «Бас, унга ҳамладор бўлди. Сўнгра у би­лан узоқ маконга чет­лан­ди»  <span className='vitalic'> (Марям, 23-25). </span>
            </p>
            <p>
            Бир неча ойдан сўнг император Августус бутун аҳолини рўйхатга олиш ҳақида фар­мон эълон қилди. Рўйхатдан ўтиш учун ҳамма ўз шаҳрига бориши керак эди. Юсуф  ҳам Биби Марямни олиб, Но­си­ра шаҳридан Байтлаҳмга – аждоди Довуд пайғамбар шаҳрига равона бўлдилар. Байт­лаҳм­га келганларида Марямнинг ой-куни тўлиб, ўғил туғди.
            </p>
          
            <p></p>
          </div>
                
        

<div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[5] ? 'block' : 'none' }}
              onClick={() => handleSupClick(5)}
            >
              <p>
                <sup>5</sup>   (Марям, 23-25). 
              </p>
            </div>
            <p onClick={() => handleSupClick(5)} className='boldItalic'>
            «Бас, тўлғоқ уни хурмо танасига олиб борди. У: «Кошки... ўлсам эди ва бутунлай унутилиб кетсам эди», деди. Шунда (хурмо) остидан: «Хафа бўлма, батаҳқиқ, Раббинг остингдан оқар ариқ қилди. Хурмони силкит, сенга янги мева туширади», деган нидо келди».<sup>5</sup>  
            </p>
            <p>
            Ўша куни тунда, Байтлаҳм бўйидаги яй­лов­да, бир неча чўпон сурувларини қў­риқ­лаб ўтирган эдилар. Ногаҳон, <span className='vitalic'>илоҳий</span> бир нур уларни ўз оғушига олди. Оллоҳнинг фариштаси кўз ўнгларида зо­ҳир бўлди. Чўпонлар қаттиқ қўрқиб кетдилар.
            </p>
            <p>
            «Қўрқманглар, – деди фаришта. – Мен сизларга бутун халқ учун берилган, улуғ бир шодлик ато этувчи Хушхабар олиб кел­дим! Бугун Довуд шаҳрида сизлар учун Нажот­кор – Оллоҳ тайин этган Подшоҳ, Мав­ло­но дунёга келди!!!» 
            </p>
            <p> Тўсатдан фаришта олдида сон-саноқсиз ма­лак­лар лашкари пайдо бўлиб Оллоҳга ҳамду сано айта бошладилар.
            </p>
            <p></p>
</div>
        

<div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[6] ? 'block' : 'none' }}
              onClick={() => handleSupClick(6)}
            >
              <p>
                <sup>6</sup>  Исо (а.с.) нинг Байтлаҳмда ту­ғил­ганлиги Ибн-и Қу­тай­банинг “Маориф” ки­то­би­да (24-бет) ҳамда Масъ­у­дий­нинг “Муруж-уз заҳаб” ида ёзилган (1-жилд, 63-бет).
              </p>
            </div>
            <p onClick={() => handleSupClick(6)}>
            Фаришталар ғойиб бўлгач чўпонлар бир-бирларига: «Юринглар, Байтлаҳмга<sup>6</sup>  ки­риб, Раббимиз билдирган нарсани ўз кў­зи­миз билан кўрайлик», дедилар ва югу­риб ша­ҳарга келдилар – Марямни, Юсуфни ва охур ичида ётган чақалоқни кўрдилар.    
            </p>
</div>

<div className='paragraph_heading'>
  <p> Чақалоқнинг бешикда гапиргани </p>
          </div>



<div className='main_text vitalic'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[7] ? 'block' : 'none' }}
              onClick={() => handleSupClick(7)}
            >
              <p>
                <sup>7</sup>  Марям сураси 26-оят.
              </p>
            </div>
            <p onClick={() => handleSupClick(7)}>
                Хурмо тагидан чиққан овоз ҳазрати Мар­ям­га: <span className='boldItalic'>«Бирор одамни кўрар бўл­санг, мен Раҳмонга рўза назр қилдим. Бу­гун одам­лар билан зинҳор гаплашмайман, де»,</span><sup>7</sup>  деб таъкидлаган эди. 
            </p>
            <p onClick={() => handleSupClick(8)}>
            Одамлар орасига чақалоғига кўтариб кел­гач улар унинг вақтидан олдин туғиб қўй­га­ни­ни кўриб: <span className='boldItalic'>«Отанг ёмон одам эмас эди, онанг ҳам бузуқ бўлмаган»,</span><sup>8</sup>  деб Биби Мар­ям­ни айбладилар. Шунда Марям ча­қа­лоғига ишора қилиб, ундан сўранглар де­ган маънода имлади. Одамлар ҳайрон бўлиб:
            </p>
            <p></p>
</div>


<div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[8] ? 'block' : 'none' }}
              onClick={() => handleSupClick(8)}
            >
              <p>
                <sup>8</sup> Марям сураси 29-34 оятлар.
              </p>
            </div>
            <p onClick={() => handleSupClick(8)} className='boldItalic'>
              «Бешикдаги чақалоқ билан қандай гаплашамиз?!» дедилар. Шунда <span className='vitalic'>  (гўдак тилга кириб)</span>: «Мен Оллоҳнинг бандасиман. У менга китоб берди, мени пайғамбар қилди. Мени қаерда бўлсам ҳам муборак этди. Токи ҳаёт эканман, солат ва закотни адо этишни, онамга меҳрибон бўлишни тавсия қилди. Жабр-ситам қилгувчи, бадбахт қилмади. Менга туғилган кунимда ҳам, ўладиган кунимда ҳам ва қайта тириладиган ку­ним­да ҳам салом бордир», деди. Мана шу шубҳа қилишаётган Ийсо ибн Марям – Ҳақнинг сўзидир».<sup>8</sup>  
            </p>
</div>

<div className='paragraph_heading'>
            <p> Шимён ҳазратлари билан учрашганлари </p>
          </div>
  
          <div className='main_text'>
            <p>
            Саккизинчи куни чақалоқни хатна қи­либ, Исо деб от қўйдилар. Нифос даври тугагач, Юсуф  билан Биби Марям Таврот ҳук­ми­га биноан, Оллоҳга ба­ғиш­лаш учун гўдакни Байтуллоҳга олиб бордилар.
            </p>
           <p> Байт-ул-Муқаддасда Шимён исмли тақ­во­дор бир инсон, Оллоҳга суянадиган, Оллоҳ­нинг тасаллисини инти­зор­лик билан ку­та­ёт­ган бир одам бор эди. Худо унга: “Сен то Мен юборадиган ал-Масиҳни кўрмагунча ўлмайсан”, деб ваҳий берган эди. 
            </p> 
            <p>
            Юсуф  билан Биби Марям Байтуллоҳга кел­ган­ла­ри­да Шимён ҳаз­рат­лари ҳам Оллоҳнинг амри билан Байтуллоҳга ке­ла­ди­лар ва ча­қа­лоқ­ни қўлларига олиб, Оллоҳ­га шук­ро­на­лар айтадилар: 
            </p>
            <p></p>
            </div>

<div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[9] ? 'block' : 'none' }}
              onClick={() => handleSupClick(9)}
            >
              <p>
                <sup>9</sup> «Уларнинг изларидан ўзи­дан олдинги Тавротни тас­диқ­лов­чи бўл­ган Ийсо бинни Мар­ямни эргаштирдик ва у кишига ҳи­до­ят ва нурни ўз ичига олган, ўзидан олдинги Тавротни тасдиқла­гув­чи ва тақводорлар учун ҳидоят, панд-насиҳат бўлган (Хуш­ха­бар китоби) Инжилни бер­дик» (Моида сураси, 5:46).
              </p>
            </div>
            <p onClick={() => handleSupClick(9)}>
            «Ё Раббим, мана Ўзинг айтгандек, мен қу­линг­ни тинчлик билан қўйиб юборяпсан, чунки кўзларим элу халқлар учун ҳидоят бўладиган,<sup>9</sup>   халқинг Бани Исроилга шукуҳ кел­ти­радиган на­жо­тингни кўрди». 
            </p>
            <p>
            Юсуф  билан Биби Марям бу гап­лар­дан ҳайратга тушдилар.
            </p>
            <p>
            Ҳазрати Шимён уларни дуо қилиб, Биби Марямга де­ди­лар: «Бу гўдак кўпларнинг қулашига ва кўпларнинг юксалишига сабаб бўлади. У Оллоҳнинг оятики халқнинг ўй-фикр­ла­ри­ни ошкор қилади. Кўп одамлар унга қарши чиқади, сенинг қалбингга ҳам ханжар санчилади».
            </p>
            <p> Байт-ул-Муқаддасда саксон тўрт яшар бир пай­ғам­бар аёл ҳам бор эди. Исми Ҳано бўлиб, Ашир қабиласидан бўлган. Ёш­ли­ги­да эрга чиқиб етти йилдан кейин тул қолган, кейин эса бошқа эр қилмаган. У кўп вақтини Байтуллоҳда ўтказар, кечаю кундуз рўза ва дуолар билан машғул эди. 
            </p>
            <p></p>
</div>


<div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[10] ? 'block' : 'none' }}
              onClick={() => handleSupClick(10)}
            >
              <p>
                <sup>10</sup>  Абу Ҳурайра ривоят этган ҳадиси шарифга кўра, Му­ҳам­мад (с.а.в.): «Мен дунё­да ҳам, охиратда ҳам Мар­ям ўғли Исога энг яқин одам­ман», деган эканлар.
              </p>
            </div>
            <p onClick={() => handleSupClick(10)}>
            Ўша соатда Ҳано Биби ҳам уларнинг ол­ди­га келиб, Оллоҳга ҳамду санолар айтади. Кейин нажот келишини кутиб юрган одам­лар­нинг ҳаммасига бу бола ҳақида гапи­ра­ди­лар.<sup>10</sup>  
            </p>
</div>

<div className='paragraph_heading'>
            <p> Мунажжимлар ташрифи </p>
          </div>
       

          <div className='main_text'>
            <p>
            Шундай қилиб Исо (а.с.), Ҳирод под­шоҳ­лик қилаётган даврда Байтлаҳм шаҳ­ри­да дунёга келган эдилар. Икки ёшга чиқади­ган пайтида шарқдан бир нечта мунажжим Байт-ул-Муқаддасга ташриф бую­ра­ди. Улар келиб: «Юртингизда подшоҳ бў­лиш учун туғилган бола қани? Биз шарқ­дан унинг юлдузини кўриб, унга таъзим қи­лиш учун келдик», деб айтадилар.
            </p>
            <p>
            Бу хабар подшоҳ Ҳироднинг қулоғига етиб келиб, подшоҳнинг ҳам, у билан бирга бу­тун Байт-ул-Муқаддас аҳлининг ҳам тинч­лиги йўқолди. Ҳирод дарров ру­ҳо­нийларни ва фиқҳ олимларини ча­қи­риб: «Масиҳ қа­ер­да ту­ғи­лиши керак?» деб сўради. 
            </p>
            <p>
            «Байт­лаҳмда, – деб жавоб бе­ришди улар. – Чунки пайғамбарлар ки­то­бида шундай деб ёзил­ган – Эй Яҳу­зия­нинг Байтлаҳми, сен бошқа ша­ҳар­лар­дан асло кам эмассан, чунки Менинг халқимга чў­пон­лик қилувчи Раҳ­на­мо сендан келиб чиқажак!»

            </p>
          </div>
         
         
          <div className='paragraph_heading'>
            <p>  Ҳироднинг макр ишлатмоқчи бўлгани </p>
          </div>
       

          <div className='main_text'>
            <p>
           
            Шунда Ҳирод мунажжимларни ҳузурига хуфёна чақиртириб, юлдуз қачон пайдо бўлганини аниқлаб олди. Кейин уларни Байтлаҳмга жўнатиб: «Болани албатта из­лаб топинглар. Топгандан сўнг менга ха­бар қи­линглар, мен ҳам бориб, унга таъ­зим қил­моқчиман», деб қаттиқ тайинла­ди.
              </p>
              <p>
              Мунажжимлар йўлга тушдилар. Шунда ногоҳ, ўша шарқда кўринган юлдуз яна уларнинг олдида пайдо бўлиб, уларга йўл кўрсатиб борди ва бир уйнинг тепасида тўхтади. </p> 
              <p> Улар юлдузни кўриб беҳад қувондилар. Уйга кириб, тиз чўкиб чақалоққа таъзим қил­дилар. Кейин қутиларини очиб, ўзлари би­лан олиб келган тилла, қимматбаҳо мир ати­ри ва хушбўй совғаларни чақалоққа ҳадя қил­ди­лар.</p>
              <p>
              Кейин бошқа йўл билан ва­тан­ла­ри­га қайтиб кетишди, чунки Худо улар­ни ту­ши­да, Ҳиродга кўринманглар, деб огоҳ­лан­тир­ган эди.</p>
              </div>

              <div className='paragraph_heading'>
            <p>  Мисрга қочиш </p>
          </div>
       

          <div className='main_text'>
            <p>
            Улар кетганларидан сўнг Юсуф (а.с.) нинг ту­ши­га бир фаришта кириб: «Тур! Бола би­лан она­си­ни олиб Мисрга қоч, Ҳирод ча­қа­лоқ­ни топиб ўлдирмоқчи. Мен хабар бергунча Мисрда яшаб турасизлар», деди.
            </p>
            <p> Юсуф (а.с.) ўша куни кечаси оиласини олиб йўлга чиқдилар ва то Ҳирод дунёдан ўт­гун­ча Мисрда яшадилар. Шу тариқа пай­ғам­бар орқали “Аржумандимни Миср­дан ча­қи­риб олдим”, деб айтилган ба­шо­рат амал­га ошди.
            </p>

          </div>
              <div className='paragraph_heading'>
            <p>  Чақалоқлар қирғини </p>
          </div>
       

          <div className='main_text'>
            <p>
            Мунажжимлар кетиб қолганини англагач Ҳирод ғазабга миниб, Байтлаҳм ҳамда Байтлаҳм атрофидаги икки ёшгача бўлган ҳамма ўғил чақалоқларни ўлдиришга буйруқ берди. Чунки мунажжимлар айтган вақт шунга тўғри келар эди. 
            </p>
            <p> Шу тариқа Ирмиё пайғамбар орқали берилган мана бу башорат амалга ошди:
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p>  «Йиғи, мотам товуши эшитилар Рама­дан,
            </p>
          </div>
          <div className='main_text'>
            <p> Фарёд чекар тўхтамай Роҳийлнинг қиз­ла­ри.  
            </p>
          </div>
          <div className='main_text'>
            <p>   Ҳеч кимса юпатолмас доғда қолган онани, 
            </p>
          </div>
          <div className='main_text'>
            <p> Ўлиб кетди, йўқ энди, севикли фар­занд­ла­ри!»
            </p>
          </div>
       
     
         <div className='paragraph_heading'>
            <p>  Носирага қайтиш </p>
          </div>
       

          <div className='main_text'>
            <p>
            Подшоҳ Ҳирод оламдан ўтгач, Оллоҳ тао­ло Ўз фариштасини Мисрга, Юсуф (а.с.) нинг олдига юборади. Фаришта унинг ту­ши­да зоҳир бўлиб: 
           
            «Энди юртингларга қай­тинг­лар, болани ўлдирмоқчи бўлганлар дунёдан ўтди», дейди. 
            </p>
            <p>
         Юсуф (а.с.) оила­си­ни олиб, ўз юртига қайтиб келди. Лекин Ҳироднинг ўрнига ўғли Архелай тахтга ўтирганини эшитиб, Яҳузияга боришга қўрқди. Тушида ваҳий олиб, Жалила ви­лоя­ти­нинг Носира шаҳрига бориб жой­лаш­дилар.</p> 
         <p>
         Исо (а.с.) кундан-кунга улғайиб, кучга ва ҳик­мат­га тўлар, Оллоҳнинг марҳамати у киши билан бирга эди.</p>  
         </div>

{/* <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[88888] ? 'block' : 'none' }}
              onClick={() => handleSupClick(88888)}
            >
              <p>
                <sup>88888</sup>  
              </p>
            </div>
            <p onClick={() => handleSupClick(88888)}>
               <sup>88888</sup>  
            </p>
</div> */}


        <BottomNav 
            labelLeft='Биби Марям' 
            pathLeft='/public-resources/qisas-uzbek/maryam'

            labelCenter='Исо (а.с.) таваллуди'
            pathCenter='/public-resources/qisas-uzbek/jesusBirth'

            labelRight='davomi bor'
             pathRight='/public-resources/qisas-uzbek/jesusBirth'
          />  


 
        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default JesusBirth;