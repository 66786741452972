import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const SulaymanAsksWisdom = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>
                  Сулаймон (а.с.) нинг ҳикмат сўрагани  
                  </p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

             

            
          <div className='main_text'>
            <p>
            Орадан анча вақт ўтди. Ҳазрати Сулаймон Миср подшоҳи фиръавн билан дўстлик пай­мони боғлаб, унинг қизини никоҳига ол­ди­лар ва “Довуд қалъасига” олиб кел­дилар. 
            </p>
            <p> 
            То ўз қасрини, Байтуллоҳни ва Байт-ул-Муқаддас шаҳрининг деворларини қу­риб битирмагунча Сулаймон  ўша ерда яшаганлар. Бу пайтларда Исроил халқи тепаликларга чиқиб Худога қурбонлик­лар келтирар, чунки Байтуллоҳ – Оллоҳ­нинг уйи – ҳали қурилмаган эди. 
            </p>
            <p>
            Ҳазрати Сулаймон Оллоҳни севар, отаси айтган ҳамма насиҳатларга амал қилар эдилар. Бир сафар, қурбонлик қилиш учун Жабъунга борганларида, Оллоҳ (с.в.т.) Су­лай­мон пайғамбарнинг тушига кириб: «Тила тилагингни, бераман!» деди. 
            </p>
            <p>
            Ҳазрати Сулаймон: «Ё Раббим! Сен отам До­вудга кўп меҳрибонлик қилдинг, чунки у Сенга содиқ, амин ва қалби тўғри одам эди. Отам билан боғлаган аҳдингга биноан, мен қулингни отам ўрнига тахтга ўтқаздинг. Лекин мен ёшман, тажрибам оз. Сенинг халқинг эса сон-саноқсиз. Шунинг учун менга яхшилик ва ёмонликни фарқлаш учун ҳикмат бер, то бу халқни адолат билан бошқарай. Бўлмаса бу буюк халқни қандай қилиб идора қиламан?» дедилар. 
            </p>
            <p>
            Оллоҳ таоло, унинг ҳикмат тилаганидан хушнуд бўлиб: «Сен Мендан бойлик, ё узоқ умр, ё душманларингга ўлим сўрамадинг, балки халқни адолат билан бошқарай деб ҳикмат тиладинг. Бас, ти­ла­гингни бераман, – деган экан. – Сенга шун­дай бир донолик ва шундай бир ҳикмат берай­ки, сендан олдин ўтганлар ҳам, сен­дан кейин келадиганлар ҳам бундай до­но­лик­ка эга бўлмайди. Мен сенга яна сен сўра­ма­ган нарсаларни ҳам ато этаман. Бойлик, иззат, шону шараф бераман. Ум­ринг охиригача ҳеч бир подшоҳ сен билан тенглаша олмайди. Отанг Довуд каби бан­да­ликни адо этиб, амрларимга риоя қилсанг, умрингни ҳам узоқ қиламан». 
            </p>
            <p> Сулаймон (а.с.) уйғондилар. Қарасалар, туш кўрган эканлар. Кейин Байт-ул-Муқаддас­га бориб, Аҳд Сандиғи олдида Оллоҳ тао­лога қурбонликлар келтириб, бутун сарой аҳлига катта бир зиёфат бердилар.
            </p>
          
          </div>

                <div className='paragraph_heading'>
                    <p>  «Бола кимники?»  </p>  
                </div>

            
          <div className='main_text'>
            <p>
            Бир куни подшоҳнинг ҳузурига икки фо­ҳи­ша хотин келади. «Подшоҳим, биз икки­миз бир уйда яшаймиз, – деди улардан бири. – Яқин­да мен ўғил туғдим. Уч кундан кейин бу хо­тин ҳам ўғил кўрди. Уйда иккимиздан бош­қа ҳеч ким йўқ. Бир куни тунда у уйқуси­да боласини босиб ўл­ди­риб қўйибди. Кейин ярим кечаси туриб, ўғлимни бағримдан олиб ўзининг ёнига ётқизибди, ўлган боласини эса менинг ёнимга қўйибди. Мен ухлаб ётган эдим. Саҳарда боламни эмиз­моқ­чи бўлиб турсам, бола ўлик. Кун оқар­гандан кейин ўғлим эмаслигини бил­дим», деди. 
            </p>
            <p>
            Иккинчи хотин эса: «Йўқ, ўлган сенинг бо­ланг эди, бу меники!» деди. Шу билан икки хотин, “Ўлгани сеники, тириги ме­ни­ки!” деб Сулаймон  ҳузурида жанжал қил­дилар.
            </p>
            <p>
            Шунда подшоҳ: «Иккалангиз менинг бо­лам тирик, ўлик бола меники эмас, де­яп­сиз», дедилар. Кейин: «Менга қилич кел­ти­ринглар», деб амр қилдилар. Қилич кел­тир­ган­ларидан сўнг: «Тирик болани ик­ки­га бўлинглар! Ярмини бунга, ярмини унга беринглар!» деб буйруқ бердилар. 
            </p>
            <p>
            Боланинг онаси ўғлига жони ачиб: «Под­шо­ҳим, керак эмас, болани ўлдирманг, майли, ана у хотин олаверсин!» деди. На­ри­ги хотин эса: «Менга ҳам эмас, сенга ҳам, бўлиб оламиз!» деди. 
            </p>
            <p> Шунда Сулаймон (а.с.): «Ўлдирманглар, унинг ҳақиқий онаси мана бу. Болани унга бе­ринг­лар», деб ҳукм чиқардилар. Под­шоҳ­нинг ҳукми ҳадемай бутун Ис­ро­ил­га овоза бўлди. Халқ билдики, Оллоҳ таоло ҳақиқатан Сулаймон пайғамбарга ҳик­мат ато қилибди, халқни адолат билан бош­қа­риши учун ақл-донолик берибди.
            </p>
          </div>
         
          <div className='paragraph_heading'>
            <p> Сулаймон (а.с.) давридаги фаровонлик </p>
          </div>
     
          <div className='main_text'>
            <p>
            Бани Исроил соҳилдаги қумдай беҳисоб эди. Еб-ичиб, шоду хуррам ҳаёт кечирар эдилар. Ҳазрати Сулаймон Фурот дарё­си­дан филистийлар диёригача, Миср чегарасигача ҳамма халқларни ўз назорати остига олган эдилар. Бу халқларнинг ҳаммаси у кишига ўлпон тўлар эди. 
            </p>
            <p>
            Саройнинг бир кунлик озуқаси қуйи­да­ги­лар­дан иборат бўлган: уч юз тоғора оқ ун, олти юз тоғора кепак ун, ўнта бўрдоқи ҳўкиз ва йигирмата ўтлоқда боқилган ҳў­киз, юзта қўй, шунингдек буғулар, ки­йик­лар, оҳулар ва семиз қушлар. 
            </p>
            <p> У кишининг подшоҳлиги даврида бани Исроил тинчлигу осойишталикда давр сурган. Ҳар бир хонадон ўз уйига ва ўз боғига эга эди. 
            </p>
            <p></p>
             </div>

          <div className='main_text'>
            <div className='speech-bubble' style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p> <sup>1</sup> Ҳаммаси бўлиб қирқ мингта отга жой қилинган.
              </p>
            </div>
            <p onClick={() => handleSupClick(1)} >
            Сулаймон подшоҳнинг ўн икки мингта оти бўлган. Жанговар ара­ва­лар­га қўшиладиган отлар учун от­хо­на­лари жуда кўп эди.<sup>1</sup> 
            </p>
            <p></p>
          </div>
          
          <div className='paragraph_heading'>
            <p>  Сулаймон (а.с.) даги ҳикмат ва шуҳрат </p>
          </div>
     
          <div className='main_text'>
            <p>
            Оллоҳ таоло у кишига буюк ақл, ҳикмат ва донолик ато этган эди. Ҳикматда бутун шарқ донишмандларидан ва Миср олим­ла­ри­дан устун эдилар.
            </p>
            <p>
            Ҳатто изроҳлик Ий­тон­дан, Моҳул ўғиллари Ҳимон, Калкул ва Дардаъдан ҳам доно эдилар. Шуҳрати бутун элларга ёйилди. Уч мингта масал ва бир минг бешта қўшиқ ёзганлар. Наботот оламини ҳам яхши билар, Лубнондаги садр дарахтидан тортиб, девор тирқишида ўса­ди­ган зуфо бутасигача танир, улар ҳақи­да дарс бера олар эдилар.
            </p>
            <p>  Шунингдек ҳай­вон­лар, паррандалар, хазандалар ва балиқлар ҳақида ҳам кўп нарса билганлар. Бутун дунё подшоҳлари ҳикмат ўрганиш учун ўз одамларини у кишининг саройига юборар эдилар.
            </p>
          </div>
         
          <div className='paragraph_heading'>
            <p>  Ҳиром билан келишув </p>
          </div>
     
          <div className='main_text'>
            <p>
            Сур подшоҳи Ҳиром, “Довуднинг ўрнига ўғли Сулаймон тахтга ўтирибди” деган хабарни эшитиб, ўз элчиларини Исроилга жўнатди. Чунки Довуд  билан дўст эди. 
            </p>
            <p>
            Сулаймон пайғамбар ҳам Ҳиром ҳузури­га мана бу номани юбордилар: 

«Биласиз, отам Довуд жангларда юриб Оллоҳга уй қура олмади. Чунки душман­ла­ри кўп эди.
            </p>
            <p>
            Аммо энди Исроилда тинчлик барқарор бўлди, менга ҳужум қиладиган душманларим йўқ. Оллоҳ таоло отамга: сендан кейинги подшоҳ, сенинг ўрнингга тахтга ўтирадиган ўғлинг, Мен учун ибо­дат уйи қуради, деб айтган эди. 
            </p>
            <p>
             Шунинг учун мен Байтуллоҳни қуришни ният қилдим. Сиздан илтимосим, одамларингиз менга Лубнон тоғларидан садр ёғочи кесиб берсин. Мен ҳам йигитларимни сизнинг одамларингиз билан ишлаши учун жў­на­та­ман. Одамларингиз учун қанча ҳақ тайин қилсангиз бераман. Ўзингиз биласиз, да­рахт кесишда ҳеч ким сайдунликлардек моҳир эмас». 
            </p>
            <p> Ҳиром Сулаймон (а.с.) нинг номасидан кўп хурсанд бўлди ва: «Оллоҳга ҳамд-у санолар бўлсин! У Довудга Исроилдек буюк халққа подшоҳ бўлиш учун бундай бир доно ўғил берибди!» деб Оллоҳни улуғлади. 

            </p>
          </div>

          <BottomNav 
            labelLeft='ортга қайтиш' 
            pathLeft='/public-resources/qisas-uzbek/sulayman'

            labelCenter='Сулаймон (а.с.) нинг тилаги'
            pathCenter='/public-resources/qisas-uzbek/sulaymanAsksWisdom'

            labelRight='ал-Ақсо'
             pathRight='/public-resources/qisas-uzbek/aqsa'
          />        

        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default SulaymanAsksWisdom;