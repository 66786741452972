import React, { useState } from 'react';
import './Search.css';
import { useTranslation } from 'react-i18next';

const Search = ({ setSearch, ifSearchingInsideChat, activeUsersLength, className }) => {
  const [inputClassName, setInputClassName] = useState('');

  const { t } = useTranslation();

  const getPlaceholder = () => {
    if (ifSearchingInsideChat) {
      return t('Global.PlaceholderWithUsers', { count: activeUsersLength });
    }
    return t('Global.Placeholder');
  };

  // const getPlaceholder = () => {
  //   if (ifSearchingInsideChat) {
  //     return `Search: Number of online users: ${activeUsersLength}`;
  //   }
  //   return 'Search';
  // };

  return (
    <div className={`sidebar_search_contain ${className}`}>
      <div className="search_container">
        <span className="search_icon">&#9906;</span>
        <input
          type="search"
          onChange={(e) => {
            setSearch(e.target.value);
            setInputClassName(e.target.value.trim() !== '' ? 'typed-input' : '');
          }}
          placeholder={getPlaceholder()}
          className={`search_input ${inputClassName}`}
        />
      </div>
    </div>
  );
};

export default Search;
