import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const InsideFish = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>
                    Юнус (а.с.) балиқ ичида
                  </p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>
            
          <div className='main_text'>
            <p className='boldItalic'> 
            «Бас, уни маломатга лойиқ бўлган ҳолида наҳанг балиқ ютди»  <span className='vitalic'>Вас-Соффот 37:142.</span>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}
            >
              <p>
                <sup>1</sup> Қатода (р.а.) нинг айтишича, Юнус (а.с.) балиқ ичида уч кун бўлганлар.
              </p>
            </div>
       
            <p onClick={() => handleSupClick(1)}> 
              Оллоҳ таоло эса, Юнус (а.с.) ни ютсин деб катта бир балиқ юборди ва у киши уч кечаю уч кундуз<sup>1</sup>  ба­лиқ­нинг ичида бўлдилар. Балиқ ичида Юнус (а.с.) Оллоҳ таолога дуо қилади: 
            </p>
            <p></p>
          </div>

          <div className='main_text'>
          <p>Ўлим оғушида чекдим оҳу-дод, </p>
          </div>

          <div className='main_text'>
          <p>Оллоҳим, нажот бер, деб қилдим фарёд. </p>
          <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'
              style={{ display: visibleBubbles[2] ? 'block' : 'none' }}
              onClick={() => handleSupClick(2)}
            >
              <p>
                <sup>2</sup> «(Юнус) зулматларда туриб, Сендан ўзга Илоҳ йўқ, Сен поксан, албатта, мен золимлардан бўлдим, деб нидо қилди» (Анбиё сураси 21:87).
              </p>
            </div>
            <p
              onClick={() => handleSupClick(2)}
            >
              Оллоҳ мени денгиз қаърига отди,<sup>2</sup>.
            </p>
          </div>

          <div className='main_text'>
          <p> 
           Қаро тўлқин вужудимни қуршади. 
          </p>
          <p></p>
          </div>

          <div className='main_text'>
          <p> 
          Тушкунликка тушиб дедим: «Мабодо, 
          </p>
          </div>

          <div className='main_text'>
          <p className='ml-5'> 
          Ҳаёт нафасидан бўлдимми жудо? 
          </p>
          </div>

          <div className='main_text'>
          <p> 
          Магар энди ибодатлар қилолмам, 
          </p>
          </div>

          <div className='main_text'>
          <p className='ml-5'> 
            Ал-Ақсони зиёрат ҳам этолмам?» 
          </p>
          <p></p>
          </div>

          <div className='main_text'>
          <p> 
          Пастга тортар эди гирдоб сувлари, 
          </p>
          </div>

          <div className='main_text'>
          <p> 
          Бўйнимдан бўғарди денгиз ўтлари. 
          </p>
          <p></p>
          </div>

          <div className='main_text'>
          <p> 
          Чўкиб Барзақ тубига етган эрдим, 
          </p>
          </div>

          <div className='main_text'>
          <p> 
          Умид узиб жаҳондан кетган эрдим. 
          </p>
          <p></p>
          </div>

          <div className='main_text'>
          <p> 
          Ўликлар маскани ичра қамалда 
          </p>
          </div>
          <div className='main_text'>
          <p className='ml-5'> 
          ётардим, эй Оллоҳ, шундай маҳалда
          </p>
          </div>
          <div className='main_text'>
          <p className='ml-5'> 
        	иноят кўрсатиб тутдинг қўлимдан, 
          </p>
          </div>
          <div className='main_text'>
          <p className='ml-5'> 
        	мени қутқаздинг-ку мангу ўлимдан. 
          </p>
          <p></p>
          </div>

          <div className='main_text'>
          <p> 
          Нафасим узилиб, барча умидим 
          </p>
          </div>
          <div className='main_text'>
          <p> 
          Тугаганда, Оллоҳ, Сени эсладим. 
          </p>
          </div>
          <div className='main_text'>
          <p> 
          Сени эсладим-у, Сенга дод солдим, 
          </p>
          </div>
          <div className='main_text'>
          <p className='ml-5'> 
          Байтуллоҳга етиб борди дуойим! 
          </p>
          <p></p>
          </div>

          <div className='main_text'>
          <p> 
            Кимки тавоф этар арзимас бутни, 
          </p>
          </div>
          <div className='main_text'>
          <p className='ml-5'> 
          инкор этар Худо меҳри-лутфини. 
          </p>
          </div>
          <div className='main_text'>
          <p> 
          Мен-чи шукур қилиб, айтиб олқишлар, 
          </p>
          </div>
          <div className='main_text'>
          <p className='ml-5'> 
          Сенга қиладурман қурбон-назрлар.
          </p>
          <p></p>
          </div>

          <div className='main_text'>
          <p> 
            Қутқарувчи Сенсан, Сендадир ҳаёт, 
          </p>
          </div>

          <div className='main_text'>
          <div className='speech-bubble'
              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}
            >
              <p>
                <sup>3</sup> «Бас, Биз унинг (дуосини) ижобат қилдик. Унга ғамдан нажот бердик. Мўминларга шундай нажот берурмиз» (Анбиё сураси 21:88).
              </p>
            </div>
            <p
              onClick={() => handleSupClick(3)}
            >
              Сендан келар қудрат, Сендадир нажот!<sup>3</sup>
            </p>
          </div>
         
          <div className='paragraph_heading'>
            <p>  НАЙНАВО АҲЛИНИНГ ТАВБА ҚИЛИШИ  </p>
          </div>


          <div className='main_text'>
            <p className='boldItalic'>
            «Энди агар у тасбеҳ айтувчилардан бўл­маса эди, албатта у балиқ қорнида то қайта тириладиган кунгача қолиб кетган бўлур эди. Бас Биз уни... қуруқликка отдик» <span className='vitalic'>Вас-Соффот сураси, 143-145.</span>
            </p>
            <p>
            Кейин Оллоҳ таоло балиққа амр қилди ва балиқ ҳазрати Юнусни қуруқликка чиқариб ташлади. Ва Оллоҳдан Юнус пай­ғам­бар­га иккинчи марта буйруқ келди: «Тур, Найнаво шаҳрига бор! Уларга Ме­нинг сўзларимни эълон қил!» деди. Юнус (а.с.) Оллоҳ­нинг амрига бўйсуниб, Най­на­вога бор­­ди­лар. 
            </p>

            <p className='boldItalic'>
            «Биз уни юз мингдан ортиқ <span className='vitalic'>(одам яшай­ди­ган жой)</span> га пайғамбар қилиб юбордик».  <span className='vitalic'> Вас-Соффот 37:147.</span>
            </p>
            <p>
            Найнаво жуда катта ва муҳим шаҳар бўлиб, уни зиёрат қилишга уч кун вақт кетар эди. 
            </p>
            <p>
            Юнус (а.с.) шаҳарда юриб куни билан: “Қирқ кундан кейин Найнаво вайрон бўлади”, деб жар солдилар.
            </p>
            <p>
            Найнаволиклар Оллоҳ­нинг сўзини эшитиб, ишониб, тавбага юз тутдилар. Шаҳардаги каттаю кичик қанорга ўраниб рўза тута бошлади. Най­на­во подшоҳи ҳам тахтидан тушиб, шоҳо­на кийимларини ечди, қанорга ўра­ниб, усти­га кул сочди ва бутун Найнаво аҳлига:
            </p>
            <p>
            «Ҳеч ким – на инсон, на ҳайвон овқат ема­син, сув ичмасин. Ҳамма қанор кийсин! Оллоҳ­га ялининглар! Илтижо қи­линг­лар! Гу­ноҳ ишлардан қайтинглар, зулм­дан қўл тор­тинг­лар! Ким билади, бал­ки Оллоҳнинг раҳми келиб ғазабидан қайтар, биз­ни ўлдирмас?» деб фар­мон бер­ди.
            </p>
            <p>
            Шундай қи­либ Найнаво халқи тав­ба қилди, ёмон ишлардан қўл тортди. Буни кўрган Худо уларга раҳм қилиб, айт­ган му­си­ба­тини уларга юбор­гани йўқ. 
            </p>
            <p></p> 
            </div>
 
 

          <BottomNav 
            labelLeft='Юнус (а.с.)' 
            pathLeft='/public-resources/qisas-uzbek/yunus'

            labelCenter='Юнус (а.с.) балиқ ичида'
            pathCenter='/public-resources/qisas-uzbek/insideFish'

            labelRight='Ғазаб ичида'
             pathRight='/public-resources/qisas-uzbek/insideAnger'
          />   

        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default InsideFish;