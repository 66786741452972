import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css'
import BottomNav from './components/BottomNav';


const SadomGamorra = () => {

  const navigate = useNavigate();

  // const [search, setSearch] = useState('')


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleBubbles, setVisibleBubbles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    const initialVisibility = {};
    const numberOfCases = 24;

    for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
      //     console.log(initialVisibility[i])
    }
    setVisibleBubbles(initialVisibility);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSupClick = (index) => {
    setVisibleBubbles(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  return (

    <div className="minister-home stories">
      <div className="separate_into_two_columns">
        <main className="sep_part1">
          <div className='chapter_heading2'>
            <p> Садўм ва Ғaмўpa  </p>
            <p>ҳикояси</p>
          </div>

          <div className='decor_line'>
            <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
          </div>


          <div className='div_with_borders'>
            <p className='boldItalic'>
              Қиладиган ишимни Иброҳимдан яшираманми? — дейди Оллоҳ. —
              Мен уни, ўз фарзандлари ва хонадонини одил ва солиҳ амалларга ўргатсин, Оллоҳ йўлида раҳнамолик қилсин, деб танлаганман. Чунки унга берган ваъдамни амалга ошириб, ундан буюк қудратли халқ яратаман, ердаги ҳамма миллатларга Иброҳим орқали барака улашаман».
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> КЕЛИШДАН МАҚСАД </p>
          </div>

          <div className='main_text'>

            <div className='speech-bubble'

              style={{ display: visibleBubbles[1] ? 'block' : 'none' }}
              onClick={() => handleSupClick(1)}


            >
              <p className='boldItalic'>
                <sup>1</sup> <span className='vitalic'>Шу ҳақда Қуръонда яна бундай оятлар бор:</span> «(Иброҳим) деди: «Эй элчилар, нима иш билан келдингиз?» Улар дедилар: «Биз бир жиноятчи қавмга уларни ҳалок қилиш учун юборилганмиз. Лекин Лутнинг оиласини тўла-тўкис қутқарамиз. Магар унинг хотинигина».
              </p>
              <p className='who_wrote'>(Ҳижр сураси 15:57-60)</p>

              <p className='vitalic '>
                <sup>2</sup> Садўм ва Ғамўрадаги одамлар гуноҳга ботган, ифлос ишлар билан шуғулланадиган ёмон одамлар эди. <span className="boldItalic">«Элчиларимиз Иброҳимга..: «Албатта, биз ушбу шаҳар аҳлини ҳалок қилгувчидирмиз. Албатта, унинг аҳли зулм қилгувчилардан бўлдилар», дедилар. У деди: «Унда Лут бор-ку?!»</span>
              </p>
              <p className='who_wrote'>(Анкабут 29:31-32)</p>
            </div>

            <p onClick={() => handleSupClick(1)}>
              Меҳмонлар кейин ўринларидан туриб Садўмга қараб йўлга тушадилар.<sup>1</sup>  Расулуллоҳ ҳам кузатиб қўйиш учун улар билан бирга кетди.
              Улар у кишига: «Садўм ва Ғамўрадаги одамлар  гуноҳ ишларга ботган, — дедилар. —Уларнинг дастидан доду фарёд қилаётган одамлар жуда кўп. Шунинг учун бориб, арши аълогача етган бу фарёдни тафтиш қилмоқчимиз».<sup>2</sup>


            </p>
          </div>


          <div className='paragraph_heading'>
            <p> ИБРОҲИМ (а.с.) НИНГ ДУОСИ... </p>
          </div>


          <div className='main_text'>
            <p>
              «Ё Раббим, нахотки Сен солиҳларни ҳам ёмонлар билан бирга нобуд қилсанг? — деб дуо қиладилар расулуллоҳ. — Агар у ерда эллик яхши одам топилса, шаҳарни вайрон қилмасанг керак? Ёмонларга қўшиб яхшиларни ҳалок этмассан?! Ё раббал-оламийн, Сен ҳамиша адолат билан ҳукм қиласан-ку».
            </p>
            <p>
              «Ҳа, — деди Оллоҳ. — Агар Садўмда эллик яхши одам топилса, шаҳарни вайрон қилмас эдим».
            </p>
            <p>
              «Ё Раббим, мен қулинг ҳеч нарсага арзимасам ҳам гапиришга рухсат бер, — деди Иброҳим (а.с.). —  Агар қирқта одам топилса-чи?»
            </p>
            <p>
              «Қирқта одам учун ҳам шаҳарни сақлаб қолардим», деди Худо.
            </p>
            <p>
              «Ё Раббим, кўп гапирсам қаҳринг келмасин, мабодо фақат ўттизта яхши одам топилса-чи, унда нима бўлади?»
            </p>
            <p>
              «Ўттизта одам топилса ҳам шаҳарни вайрон қилмайман», деди Худо.
            </p>
            <p>
              «Ё Эгам, гапиришга журъат этганим учун кечир; агар фақат йигирма одам бўлса-чи?» «Йигирма одам учун ҳам», деди Оллоҳ.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[3] ? 'block' : 'none' }}
              onClick={() => handleSupClick(3)}


            >
              <p className=''>
                <sup>3</sup>  Қуръоннинг Аз-Зориёт сураси 51:36.
              </p>
            </div>
            <p onClick={() => handleSupClick(3)}>
              Расулуллоҳ яна кечирим сўраб: «Агар ўнта одам бўлса-чи? Ўнта одам топилса нима қилардинг?» деб сўради. «Ўнта одам учун ҳам шаҳарни вайрон қилмасдим, — деб жавоб берди Парвардигор. <span className="boldItalic"> — Аммо Биз у ерда бир хонадондан бошқа мусулмон топмадик».<sup>3</sup> </span>
            </p>
          </div>


          <div className='paragraph_heading'>
            <p> ШАҲВАТ БАНДАЛАРИ </p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[4] ? 'block' : 'none' }}
              onClick={() => handleSupClick(4)}


            >
              <p className=''>
                <sup>4</sup>   Лут (а.с.) Садўм халқи учун пайғамбар эдилар. Лекин халқ у кишининг даъватини қабул қилмаган (Абулфарад Ибн Жавзий, “Табсиро”, 1-жилд, 150-бет).
              </p>
            </div>
            <p onClick={() => handleSupClick(4)}>
              Фаришталар Садўмга кеч кирганда етиб келдилар. Улар шаҳарга кирган пайтда ҳазрати Лут <sup>4</sup>  дарвоза қаршисида майдонда ўтирган эдилар. Меҳмонларни кўриб ўрнидан турди-да, таъзим қилиб деди:
              «Марҳамат, бизнинг уйимизда меҳмон бўлинг. Кечани бизникида ўтказасиз. Кейин эртага борадиган жойларингизга бораверасиз». «Йўқ, биз кечани шаҳар майдонида ўтказамиз», дейишди улар.
              Лекин Лут (а.с.) “юринг-юринг” қилавергач унинг уйига бордилар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[5] ? 'block' : 'none' }}
              onClick={() => handleSupClick(5)}


            >
              <p className='boldItalic'>
                <sup>5</sup>   «(Лутнинг уйига чиройли йигитлар келганини эшитиб) шаҳар аҳолиси шод-хуррам бўлган ҳолларида келдилар. (Шунда Лут) деди: «Ахир улар менинг меҳмонларим! Мени шарманда қилманглар. Оллоҳдан қўрқинглар! Мени расво қилманг!» Улар айтдилар: «Биз сени (эл-юрт ишига аралашишдан) қайтармаганмидик?!» (Лут) деди: «Агар қилгувчи бўлсангизлар, ана қизларим (турибди)» .
              </p>
              <p className='who_wrote'>(Ҳижр сураси 15:67-71)</p>
            </div>
            <p onClick={() => handleSupClick(5)}>


              Кечки таомдан сўнг ҳали уйқуга ётмаган пайтда, Садўмнинг тўрт буржидан ёшу қари эркаклари йиғилиб келиб, Лут (а.с.) нинг уйини ўраб олишди   ва:
              «Э Лут, э Лут, бугун сеникига келган одамлар қани? Олиб чиқ, уларнинг маззасини тотиб кўрайлик», деб бақира бошладилар.<sup>5</sup>
            </p>

            <p>
              Шунда ҳазрати Лут ташқарига — оломон олдига чиқиб эшикни ёпди-да уларга:
              «Акалар, бу гуноҳ ишни қилманглар, — деди. — Мана, менинг иккита бокира қизим бор. Хоҳласангизлар олдингизга чиқараман, нимаики қилсангиз қилинг. Фақат бу одамларга тегманглар. Ахир улар менинг меҳмонларим!»
            </p>
            <p>
              «Қоч йўлдан! — деб бақирди улар. — Ким ўзи бу, келгинди! Бизга хўжайин бўлиб қолдингми?! Энди сенга бундан бадтарини қиламиз», деб Лут (а.с.) га ташланиб, эшикни бузмоқчи бўлишди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[6] ? 'block' : 'none' }}
              onClick={() => handleSupClick(6)}


            >
              <p className='boldItalic'>
                <sup>6</sup>   «Бас, Биз уларнинг кўзларини кўр қилиб қўйдик» (Қуръон, Қамар сураси, 54:37).
              </p>
            </div>
            <p onClick={() => handleSupClick(6)}>
              Шунда ичкарида турган меҳмонлар қўл узатиб, Лутни тортиб олдилар ва эшикни ичидан бекитиб қўйдилар.
              Кейин ташқаридаги одамларнинг ҳаммасининг, каттасидан кичигигача, кўзини кўр қилдилар.<sup>6</sup>  Кўзлари ҳеч нарсани кўрмай, ҳеч қайсиси эшикни тополмай қолди.
            </p>
          </div>

          <div className='paragraph_heading'>
            <p> ГУНОҲ УЧУН БЕРИЛГАН ЖАЗО </p>
          </div>

          <div className='div_with_borders'>
            <p className='boldItalic'>
              «Маълумки, сиздан аввалги миллатларга ҳам элчилар юборганмиз. Шояд тавба-тазарруъ қилсалар, деб уларни бало ва зиёнлар билан ушлаганмиз. Бизнинг балойимиз етганда ҳам тазарруъ қилмадиларми?! Лекин уларнинг диллари қотиб қолган ва шайтон уларга ўзлари қилиб юрган ишларини чиройли кўрсатиб қўйгандир» (Анъом сураси, 42-43).
            </p>
          </div>

          <div className='main_text'>
            <p>
              «Биз шаҳарни бутунлай вайрон қиламиз, — дейишди фаришталар Лутга. — Бу одамларнинг дастидан шунчалик кўп оҳу нола кўтарилдики, Худо охири бизни шаҳарни йўқ қилиш учун юборди. Бу ерда яна киминг бор? Ҳаммасини олиб, тез шаҳарни тарк эт».
            </p>

            <p className="vitalic">
              Нима деб ўйлайсиз, агар бутун шаҳар аҳолиси гуноҳга шунчалик берилган бўлса, ким уларнинг дастидан Худога оҳу нола қилган экан?
            </p>

            <p className="vitalic">

              Фараз қилинг, сиз хотин-бола-чақангиз билан сафарда эканингизда йўлингиз ана шу шаҳарга тушди. Садўм аҳолиси меҳмондўст эмас эди. Унда кечани қаерда ўтказасиз? Тўғри, кўчада. Лекин агар кўчада қолсангиз, улар сизга нима қилишади?.. Тўғри... Кейин сиз додингизни кимга айтасиз? Худога. Фаришталарнинг айтиши бўйича, бу одамларнинг дастидан шунчалик кўп оҳу нола кўтарилган эдики, асти қўяверасиз.
            </p>
            <p>
              Кейин Лут (а.с.) бориб, қизларини унаштирган куёвларига: «Тез бўлинглар, бу ердан кетиш керак! Оллоҳ шаҳарни вайрон қилмоқчи!» деди. Лекин улар Лутни ҳазиллашяпти, деб ўйладилар.
            </p>
            <p>
              Тонг ёришганда, фаришталар Лут (а.с.) ни қистаб: «Бўл тез-тез, хотининг билан қизларингни олиб жўна, бўлмаса ўласан», дейишди.
            </p>

            <p>
              Бироқ у иккиланаётган эди. Шунда, Оллоҳнинг марҳаматини кўрингки, фаришталар уни, хотинини ва икки қизини қўлларидан ушлаб, шаҳар ташқарисига чиқариб қўйдилар.
            </p>

            <p>
              «Ҳеч ерда тўхтаманглар, — деди фаришталардан бири. — Орқангларга қараманглар. Тоққа қараб қочинглар, бўлмаса ўласизлар!»
            </p>
            <p>
              «Йўқ, ҳазратим! — деб ялинди Лут (а.с).  — Мана, каминадан рози экансиз, жонимизни қутқариб бизга яхшилик қилдингиз. Бироқ тоққа қочолмаймиз, тоққа етмасимиздан, фалокат остида қолиб ҳалок бўламиз.  Мана, нарироқда кичкина бир шаҳар бор, кичкинагина шаҳарча. Ўша ерга бориб, жонимизни қутқарсак...», деди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[7] ? 'block' : 'none' }}
              onClick={() => handleSupClick(7)}


            >
              <p >
                <sup>7</sup>   “Суғар” деган сўз “кичик” деган маънони англатади (сод билан ёзилади).
              </p>
            </div>
            <p onClick={() => handleSupClick(7)}>
              «Яхши, — деди фаришта. — Тилагингни қабул қилдим. Сен айтган шаҳарни вайрон қилмайман.
              Энди тез бўл, жонингни қутқар! Чунки сен то у ерга бормагунингча мен ҳеч нарса қилолмайман». (Шунинг учун ҳам кейинроқ бу шаҳарга Суғар деб ном берилган.)<sup>7</sup>
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[8] ? 'block' : 'none' }}
              onClick={() => handleSupClick(8)}


            >
              <p className='boldItalic'>
                <sup>8</sup> «Улар   мастликда тентираб юрган ҳолида уларни тонгда даҳшатли қичқириқ тутди. Бас, у шаҳарни остин-устун қилдик ва устларига сополдан тош ёғдирдик. Албатта, бу ҳодисада фаросатли кишилар учун ибратлар бор» (Ҳижр 15:72-75).
              </p>
            </div>
            <p onClick={() => handleSupClick(8)}>
              Лут (а.с.) Суғарга қуёш чиқаётганда етиб бордилар.
              Кейин Оллоҳ таоло Садўм ва Ғамўра шаҳарлари устига осмондан ёниб турган тошлар ёғдира бошлади.
              Водийдаги бошқа шаҳару қишлоқлар ҳам, у ерларда яшайдиган бутун тирик мавжудот майсаю дарахтлар билан бирга куйиб нобуд бўлди.<sup>8</sup> Лут (а.с.) нинг хотини орқасига қараган эди, туз устунига айланиб қолди.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <p onClick={() => handleSupClick(9)}>
              Иброҳим пайғамбар ўша куни вақтли ўрнидан турган ва Садўм тарафга қараб йўлга тушган эдилар.
              Водийга яқинлашиб, Садўм билан Ғамўра шаҳарларига узоқдан назар солдилар. Ҳамма ёқдан ўчоқдан чиқаётгандек тутун чиқаётган эди.
              Худо бу шаҳарларни нобуд қилаётганда Иброҳим (а.с.) сабабли Лутни фалокатдан сақлаб қолган эди.<sup>9</sup>
            </p>

            <div className='speech-bubble'

              style={{ display: visibleBubbles[9] ? 'block' : 'none' }}
              onClick={() => handleSupClick(9)}


            >
              <p className='boldItalic'>
                <sup>9</sup> «Биз унга (Лутга) ва аҳлига нажот бердик. Магар хотини қолиб ҳалок бўлгувчилардан эди» (Аъроф сураси, 83).
              </p>
              <p >
                <sup>10</sup>  Ибн Қутайба, “Маориф”, 16-бет.
              </p>
            </div>
          </div>

          <div className='paragraph_heading'>
            <p> ҲАЗРАТИ ИСҲОҚНИНГ ТУҒИЛИШИ </p>
          </div>

          <div className='main_text'>
            <p>
              Оллоҳ таоло берган ваъдасини амалга оширди: Соро онамиз ҳомила бўлиб,
              Иброҳим (а.с.) га айтилган вақтда ўғил туғиб берди.
            </p>

            <p onClick={() => handleSupClick(10)}>
              Расулуллоҳ ўғлига Исҳоқ деб от қўйдилар
              ва Оллоҳ амр қилганидек, уни саккизинчи куни хатна қилдилар. Ўшанда у киши юз ёшга<sup>10</sup> кирган эдилар.
            </p>
            <p></p>
          </div>

          <div className='main_text'>
            <div className='speech-bubble'

              style={{ display: visibleBubbles[11] ? 'block' : 'none' }}
              onClick={() => handleSupClick(11)}


            >
              <p >
                <sup>11</sup>    “Исҳоқ” сўзи “у кулади” деган маънони билдиради.
              </p>
            </div>
            <p onClick={() => handleSupClick(11)}>


              «Худо менга кулги юборди, — дерди Соро (р.а.). – Энди эшитган одамлар мен билан бирга кулади.<sup>11</sup>  Ким ҳам мени бир кун чақалоқ эмизади, деб ўйлаган эди?! Хўжайинимга қариган чоғида ўғил туғиб бердим!»
            </p>
            <p></p>
          </div>

          <BottomNav 
            labelLeft='Аҳднинг Аломати' 
            pathLeft='/public-resources/qisas-uzbek/covenant-sign'

            labelCenter=' Садўм ва Ғамўра '
            pathCenter='/public-resources/qisas-uzbek/sadom-gamorra'

            pathRight='/public-resources/qisas-uzbek/ishmael'
            labelRight='Исмоил (а.с.) ' 
            />  
 
        </main>
        <aside>
        </aside>
      </div>
    </div >
  )
}

export default SadomGamorra
