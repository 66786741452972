import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './stories.css';
import BottomNav from './components/BottomNav';

const MonarchyConditions = () => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [visibleBubbles, setVisibleBubbles] = useState({});

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        const initialVisibility = {};
        const numberOfCases = 4;

        for (let i = 1; i <= numberOfCases; i++) {
      initialVisibility[i] = window.innerWidth > 970;
        //     console.log(initialVisibility[i])
        }
        setVisibleBubbles(initialVisibility);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSupClick = (index) => {
        setVisibleBubbles(prevState => ({
        ...prevState,
        [index]: !prevState[index]
        }));
    };

  return (
    <div className="minister-home stories">
        <div className="separate_into_two_columns">
            <main className="sep_part1">
                <div className='chapter_heading2'>
                  <p>Подшоҳлик шартлари</p> 
                </div>

                  <div className='decor_line'>
                      <p> &#8213;&#8213;  &#10044; &#8213;  &#10044;  &#8213;  &#10044;  &#8213;&#8213;   </p>
                  </div>

                <div className='paragraph_heading'>
                    <p> «ЭНДИ СИЗЛАРНИ ПОДШОҲ БОШҚАРАДИ»
                    </p>  
                </div>

            
          <div className='main_text'>
            <p>
            Кейин Сомуил (а.с.) халққа: «Мана, айтга­нин­гиз­дек сизлар учун подшоҳ тайинладим, энди сизларни подшоҳ бошқаради, – дедилар. – Мен ёшликдан халққа раҳбарлик қилдим. Энди қариб, соч-соқолим оқарди, ўғилларим ҳам вояга етди. Мана, Оллоҳ­нинг олдида, Оллоҳ масҳ этган подшоҳ олдида айтинглар: бирортангизнинг ҳўкиз ё эшагингизни олдимми? Кимни алдадим? Кимга зулм қилдим? Ё кимдан пора олиб, ўзимни кўрмасликка солдим? Айтинглар! Агар бирор нарса олган бўлсам қайтарай», дедилар.
            </p>
            <p>
            «Сиз ҳеч қайсимизни алдаганингиз ё зулм қилганингиз йўқ. Ҳеч кимдан ҳеч нарса олмагансиз», деб жавоб беришди одамлар. «Гапингизга Оллоҳнинг Ўзи ва У масҳ қилган подшоҳ шоҳидми?» «Ҳа, шоҳид!» деб жавоб беришди одамлар.
            </p>
            <p>
            «Энди гапимни эшитинг, – дедилар ҳазрати Сомуил. – Мен Оллоҳнинг биз учун, ота-боболаримиз учун қилган ишларини сизларга эслатай. 
            </p>
            <p>
            Яъқуб пайғамбар Мисрга бор­ган­лари­дан сўнг оталаримиз мисрлик­лар зулмидан нола қилганда, Оллоҳ суб­ҳо­наҳу ва таоло Мусо ва Ҳорунни юбориб, уларни Мисрдан олиб чиқди, мана шу юртга олиб келиб жойлаштирди. 
            </p>
            <p>
            Лекин оталаримиз Оллоҳни унутди, оқибат филистийларнинг, канъонлик Сисаронинг, Мўоб подшосининг зулмига гирифтор бўлишди. Кейин Худога: “Парвардигоро, биз гу­ноҳ­кор бўлдик, Сендан юз ўгириб, баал ва асторишларга сиғиндик. Энди фақат Сенга ибодат қиламиз, бизни душмандан қутқар!” деб нола қилдилар. 
            </p>
            <p>
            Оллоҳ таоло ҳар сафар ҳакамлар тайин қилиб, Ёруболнинг, Баракнинг, Ёффайнинг ҳамда менинг раҳнамолигимда сизларни душманлардан озод қилди. Бехавотир яшанглар, деб ҳифзу ҳимоясига олди. 
            </p>
            <p> Бутун мана шу вақт ичида бизнинг подшомиз Оллоҳ таолонинг Ўзи эди. Лекин амўнлик Наҳаш юртимизга ҳужум қиладиган вақтда сизлар: “Йўқ, бизни подшоҳ бошқарсин”, деб туриб олдингизлар. Мана, қаршингизда ўзингиз тилаган, ўзингиз сайлаган подшоҳ: Худо сизларга подшоҳ тайин қилди. 
            </p>
            <p>
            Агар Оллоҳдан қўрқсангиз, Оллоҳга сиғинсангиз, амрларидан бўйин товламанг. Сизлар ҳам, сизни бошқарувчи подшоҳингиз ҳам Ҳақ йўлидан юрсин. Оллоҳга қулоқ солмасангиз, амр­ла­рига итоат этмасангиз, Уни ўзингизга қарши қиласиз. 
            </p>
            <p>
            Энди Оллоҳ таолонинг қудратига гувоҳ бўлинглар. Ҳозир ғалла ўрадиган пайт эмасми? Лекин мен Худодан момақалдироқ ва ёмғир юборишини сўрайман. Шунда ўзингизга подшоҳ тилаб катта гуноҳга ботганингизни биласиз».
            </p>
            <p></p>
          </div>

    
          
          <div className='paragraph_heading'>
            <p>  ОСМОНДАН БЕРИЛГАН АЛОМАТ
            </p>  
          </div>
          
          <div className='main_text'>
            <p>
            Сомуил пайғамбар дуо қилган эдилар, осмонга булут йиғилиб, момақалдироқ гумбурлади, жала қуйди.
            </p>
            <p>
            Одамлар лол қолган, ҳамманинг ичида Оллоҳдан ҳамда Сомуил пайғамбардан қўрқув пайдо бўлган эди.
            </p>
            <p>
             Ке­йин пайғамбарга: «Биз учун дуо қилинг, ўзи­мизга подшоҳ сўраб, гуноҳимизга гу­ноҳ қўшибмиз. Оллоҳдан мағфират ти­ланг, бизни ҳалок қилмасин», деб ялиндилар.
            </p> 
            <p>
            «Қўрқманглар! – дедилар Сомуил (а.с.). – Гуноҳ қилдингиз, лекин шундай бўлса ҳам Оллоҳдан юз ўгирманг. Сидқидилдан Унга хизмат қилинг. Ёрдаму нажот беролмайдиган ҳар хил беҳуда нарсаларга эргашманг. 
            </p>
            <p>
            Оллоҳ таоло Ўз танлаган халқини ҳамиша муҳофаза қилади, токи ҳамма Унинг улуғ­ли­гига амин бўлсин. У сизларни ташлаб қўй­майди. 
            </p>
            <p>
            Мени ҳам, ҳаққингизга дуо қи­лмай гуноҳга ботишдан Худо ас­ра­син. 
            </p>
            <p>
            Бундан кейин ҳам сизларни тўғри йўлда юришга даъват қиламан. Фақат Оллоҳдан қўрқинглар, садоқат билан Унга хизмат қилинглар! Кўрсатган азим ка­ро­мат­ларини эсдан чиқарманг. Лекин гуноҳ қилишни давом эттирсангиз, ўзингиз ҳам, подшоҳингиз ҳам ҳалок бўласизлар».
            </p>
          </div>
          
          <BottomNav 
            labelLeft='У ҳам набими?' 
            pathLeft='/public-resources/qisas-uzbek/isTolutAlsoAmong'

            labelCenter='Подшоҳлик шартлари'
            pathCenter='/public-resources/qisas-uzbek/monarchyConditions'

            labelRight='Янатон'
             pathRight='/public-resources/qisas-uzbek/janatonsTavakkul'
          />


            {/* // labelRight='(davomi bor)'
            // pathRight='/public-resources/qisas-uzbek/tobe_continued' */}

        </main>
        <aside></aside>
        </div >
        </div >
    )      
}

export default MonarchyConditions;